import React, { Fragment, useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import moment from 'moment';
import axios from '../../services/axios';
import Request from '../../services/core/http/fees';
import Icon from '@alboompro/boom-components/general/icon';
import Modal from '@alboompro/boom-components/data-display/modal';
import Checkbox from '@alboompro/boom-components/data-entry/checkbox';
import Button from '../Button';
import Spinner from '../Spinner';
import { Table, TableRow, ButtonWrapper, UsePolicyAlert, ModalContent, Accordion } from './styles';

const AnticipationTable = ({
  verifyStatus,
  totalTransactions,
  createdAt,
  loadedData,
  allowAntecipation,
}) => {
  const [currentFee, setCurrentFee] = useState({});
  const [newPlan, setNewPlan] = useState({});
  const [loading, setLoading] = useState(false);
  const [radio, setRadio] = useState('');
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [agrees, setAgrees] = useState(false);
  const [changed, setChanged] = useState(false);
  const daysOfAccountCreated = moment().diff(createdAt, 'days');

  const [tableOptions, setTableOptions] = useState([
    {
      id: '6db3a477-97fd-4e24-85f5-8a42c031d1eb',
      antecipation: '2 dias',
      antecipation_days: 2,
      credit_fee: 2.99,
      credit_installments_fee: 3.49,
      description: 'Recebimento em 2 dias com taxa de antecipação.',
      isEnabled:
        verifyStatus === 'verified' && daysOfAccountCreated >= 30 && totalTransactions >= 10,
    },
    {
      id: '1130d1e9-75df-4c3e-90c3-eae55b9882b6',
      antecipation: '14 dias',
      antecipation_days: 14,
      credit_fee: 2.99,
      credit_installments_fee: 3.49,
      description: 'Receba em 14 dias com taxa de antecipação.',
      isEnabled:
        verifyStatus === 'verified' && daysOfAccountCreated >= 30 && totalTransactions >= 10,
    },
    {
      id: '61d450e1-0bb7-44b5-8d79-5e0f9950973b',
      antecipation: '30 dias',
      antecipation_days: 30,
      credit_fee: 2.99,
      credit_installments_fee: 3.49,
      description:
        'Receba em 30 dias sem taxa de antecipação na venda à vista no cartão de crédito e com taxa de antecipação na venda parcelada no cartão de crédito.',
      isEnabled: verifyStatus === 'verified',
    },
    {
      id: '3b7cffd2-6ac1-4c4b-8911-b92b1fa68438',
      antecipation: 'Sem antecipação',
      alwaysEnable: true,
      antecipation_days: 0,
      credit_fee: 2.99,
      credit_installments_fee: 3.49,
      description: 'Receba cada parcela a cada 30 dias sem taxa de antecipação.',
      isEnabled: true,
    },
  ]);

  const getFees = async () => {
    // Defining the default option based on user current option
    await axios.get('/accounts/fees').then(
      ({
        data: {
          data: {
            account: { fees: options },
          },
        },
      }) => {
        setCurrentFee(options);
        if (verifyStatus === 'verified') {
          const tableData = [...tableOptions];
          tableData[2].alwaysEnable = true;
          setTableOptions(tableData);
          if (daysOfAccountCreated >= 30 && totalTransactions >= 10) {
            const tableData = [...tableOptions];
            tableData[0].alwaysEnable = true;
            tableData[1].alwaysEnable = true;
            setTableOptions(tableData);
          }
        }
        if (options.antecipation_automatic) {
          if (options.antecipation_days === 2) {
            const tableData = [...tableOptions];
            tableData[0].alwaysEnable = true;
            setTableOptions(tableData);
            setRadio('6db3a477-97fd-4e24-85f5-8a42c031d1eb');
            if (verifyStatus === 'verified') {
              const tableData = [...tableOptions];
              tableData[2].alwaysEnable = true;
              setTableOptions(tableData);
              if (daysOfAccountCreated >= 30 && totalTransactions >= 10) {
                const tableData = [...tableOptions];
                tableData[1].alwaysEnable = true;
                setTableOptions(tableData);
              }
            }
          }
          if (options.antecipation_days === 14) {
            const tableData = [...tableOptions];
            tableData[1].alwaysEnable = true;
            setTableOptions(tableData);
            setRadio('1130d1e9-75df-4c3e-90c3-eae55b9882b6');
            if (verifyStatus === 'verified') {
              const tableData = [...tableOptions];
              tableData[2].alwaysEnable = true;
              setTableOptions(tableData);
              if (daysOfAccountCreated >= 30 && totalTransactions >= 10) {
                const tableData = [...tableOptions];
                tableData[0].alwaysEnable = true;
                tableData[1].alwaysEnable = true;
                setTableOptions(tableData);
              }
            }
          }
          if (options.antecipation_days === 30) {
            const tableData = [...tableOptions];
            tableData[2].alwaysEnable = true;
            setTableOptions(tableData);
            setRadio('61d450e1-0bb7-44b5-8d79-5e0f9950973b');
            if (verifyStatus === 'verified') {
              if (daysOfAccountCreated >= 30 && totalTransactions >= 10) {
                const tableData = [...tableOptions];
                tableData[0].alwaysEnable = true;
                tableData[1].alwaysEnable = true;
                setTableOptions(tableData);
              }
            }
          }
        } else {
          setRadio('3b7cffd2-6ac1-4c4b-8911-b92b1fa68438');
        }
        // Enables all anticipation options if the user has the controlled flag (allow_antecipation) in Manager
        allowAntecipation &&
          setTableOptions(
            [...tableOptions].map(option => ({
              ...option,
              alwaysEnable: true,
              isEnabled: true,
            })),
          );
      },
    );
  };

  useEffect(() => {
    if (loadedData) getFees();
  }, [loadedData]);

  const handleChange = ({ target: { value } }) => {
    setRadio(value);
    setIsButtonDisabled(false);
  };

  useEffect(() => {
    if (!changed) {
      if (radio === '3b7cffd2-6ac1-4c4b-8911-b92b1fa68438') {
        currentFee.antecipation_days === tableOptions[3].antecipation_days &&
          setIsButtonDisabled(true);
      } else if (radio === '61d450e1-0bb7-44b5-8d79-5e0f9950973b') {
        currentFee.antecipation_days === tableOptions[2].antecipation_days &&
          setIsButtonDisabled(true);
      } else if (radio === '1130d1e9-75df-4c3e-90c3-eae55b9882b6') {
        currentFee.antecipation_days === tableOptions[1].antecipation_days &&
          setIsButtonDisabled(true);
      } else if (radio === '6db3a477-97fd-4e24-85f5-8a42c031d1eb') {
        currentFee.antecipation_days === tableOptions[0].antecipation_days &&
          setIsButtonDisabled(true);
      }
    }
  }, [radio]);

  const Reset = async () => {
    if (!currentFee.antecipation_automatic) setRadio('3b7cffd2-6ac1-4c4b-8911-b92b1fa68438');
    else if (currentFee.antecipation_days === 30) setRadio('61d450e1-0bb7-44b5-8d79-5e0f9950973b');
    else if (currentFee.antecipation_days === 14) setRadio('1130d1e9-75df-4c3e-90c3-eae55b9882b6');
    else if (currentFee.antecipation_days === 2) setRadio('6db3a477-97fd-4e24-85f5-8a42c031d1eb');
  };

  const UpdateAntecipation = async () => {
    setLoading(true);
    try {
      const {
        data: {
          data: { success },
        },
      } = await Request.put({
        fee_id: radio,
        antecipation_automatic: radio === '3b7cffd2-6ac1-4c4b-8911-b92b1fa68438' ? false : true,
      });
      if (success) {
        toast.success('Antecipação alterada com sucesso!');
        setChanged(true);
        tableOptions.forEach(row => {
          if (row.id === radio) return setCurrentFee(row);
        });
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsModalVisible(false);
      setLoading(false);
    }
  };

  const Submit = () => {
    if (radio === '3b7cffd2-6ac1-4c4b-8911-b92b1fa68438') {
      if (tableOptions[3].antecipation_days !== currentFee.antecipation_days) {
        setNewPlan(tableOptions[3]);
        setIsModalVisible(true);
      } else {
        UpdateAntecipation();
      }
    } else if (radio === '61d450e1-0bb7-44b5-8d79-5e0f9950973b') {
      if (tableOptions[2].antecipation_days !== currentFee.antecipation_days) {
        setNewPlan(tableOptions[2]);
        setIsModalVisible(true);
      } else {
        UpdateAntecipation();
      }
    } else if (radio === '1130d1e9-75df-4c3e-90c3-eae55b9882b6') {
      if (tableOptions[1].antecipation_days !== currentFee.antecipation_days) {
        setNewPlan(tableOptions[1]);
        setIsModalVisible(true);
      } else {
        UpdateAntecipation();
      }
    } else if (radio === '6db3a477-97fd-4e24-85f5-8a42c031d1eb') {
      if (tableOptions[0].antecipation_days !== currentFee.antecipation_days) {
        setNewPlan(tableOptions[0]);
        setIsModalVisible(true);
      } else {
        UpdateAntecipation();
      }
    }
  };

  return (
    <>
      <Table>
        <tbody>
          {tableOptions.map(({ id, antecipation, alwaysEnable, description, isEnabled }) => (
            <TableRow
              key={id}
              isEnable={isEnabled}
              onClick={() => {
                if (alwaysEnable || isEnabled) {
                  setRadio(id);
                  setIsButtonDisabled(false);
                }
              }}
            >
              <td>
                {alwaysEnable || isEnabled ? (
                  <label className="radioLabel">
                    <input type="radio" value={id} checked={radio === id} onChange={handleChange} />
                    <span className="checkmark">
                      <span className="indicator" />
                    </span>
                  </label>
                ) : (
                  <Icon
                    kind="bold"
                    group="interface-essential"
                    category="lock-unlock"
                    file="lock-1.svg"
                    size="17"
                    color="#878787"
                  />
                )}
              </td>
              <td>{antecipation}</td>
              <td>
                <span>{description}</span>
              </td>
            </TableRow>
          ))}
        </tbody>
      </Table>
      <Accordion>
        {tableOptions.map(({ id, antecipation, alwaysEnable, description, isEnabled }) => (
          <Fragment key={id}>
            <section id={id + '-s'}>
              <section>
                {alwaysEnable || isEnabled ? (
                  <label className="radioLabel">
                    <input type="radio" value={id} checked={radio === id} onChange={handleChange} />
                    <span className="checkmark">
                      <span className="indicator" />
                    </span>
                  </label>
                ) : (
                  <Icon
                    kind="bold"
                    group="interface-essential"
                    category="lock-unlock"
                    file="lock-1.svg"
                    size="17"
                    color="#878787"
                  />
                )}
                <h1>{antecipation}</h1>
              </section>
              <button
                id={`${id + '-b'}`}
                onClick={() => {
                  const content = document.getElementById(`${id + '-d'}`);
                  const button = document.getElementById(`${id + '-b'}`);
                  const section = document.getElementById(`${id + '-s'}`);
                  if (content.classList.contains('active')) {
                    content.classList.remove('active');
                    button.classList.remove('opened');
                    button.id === '3b7cffd2-6ac1-4c4b-8911-b92b1fa68438-b' &&
                      section.classList.add('border');
                  } else {
                    content.classList.add('active');
                    button.classList.add('opened');
                    button.id === '3b7cffd2-6ac1-4c4b-8911-b92b1fa68438-b' &&
                      section.classList.remove('border');
                  }
                }}
              >
                <Icon
                  kind="bold"
                  group="arrows-diagrams"
                  category="arrows"
                  file="arrow-down-1.svg"
                  size="12"
                  color="#494949"
                />
              </button>
            </section>
            <div id={id + '-d'}>{description}</div>
          </Fragment>
        ))}
      </Accordion>
      <UsePolicyAlert>
        * As opções de prazo de recebimento podem ser alteradas ou bloqueadas com base em nossa{' '}
        <a target="_blank" href="https://www.alboompro.com/terms-of-use/">
          Política de uso do Alboom Pay
        </a>
        .
      </UsePolicyAlert>
      <ButtonWrapper>
        <Button className="buttonGray" onClick={Reset} disabled={isButtonDisabled}>
          Cancelar alterações
        </Button>
        <Button className="buttonBlue" onClick={Submit} disabled={isButtonDisabled}>
          Salvar
        </Button>
      </ButtonWrapper>
      <Modal
        title="Confirmar alteração"
        onClose={() => setIsModalVisible(false)}
        visible={isModalVisible}
        width={530}
        bodyStyle={{ padding: '20px', backgroundColor: '#F8F8F8' }}
        closeButton
        footer={
          <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
            <Button onClick={() => setIsModalVisible(false)}>Cancelar</Button>
            <Button
              onClick={UpdateAntecipation}
              style={{ marginLeft: '27px', backgroundColor: '#1B9DFA' }}
              disabled={loading || !agrees}
              type="primary"
            >
              {loading ? <Spinner size={20} /> : 'Confirmar'}
            </Button>
          </div>
        }
      >
        <ModalContent>
          <h3>Aviso:</h3>
          <ul>
            <li>
              Pagamentos no cartão de crédito que estão pendentes de liberação serão antecipados
              conforme a nova configuração e terão o valor das taxas de antecipação recalculadas.
            </li>
            <li>
              As taxas de crédito para novas transações serão ajustadas conforme tabela abaixo:
            </li>
          </ul>
          <table>
            <tr>
              <th></th>
              <th>Antecipação</th>
              <th>Taxa no crédito à vista</th>
              <th>Taxa no crédito parcelado</th>
            </tr>
            <tr>
              <td>Atual:</td>
              <td>
                {currentFee
                  ? currentFee.antecipation_days === 0
                    ? 'Sem Antecipação'
                    : currentFee.antecipation_days + ' dias'
                  : 'Carregando...'}
              </td>
              <td>
                {currentFee.credit_fee || currentFee.credit_fee === 0
                  ? currentFee.credit_fee.toLocaleString('pt-br') + '%'
                  : 'Carregando...'}
              </td>
              <td>
                {currentFee.credit_installments_fee || currentFee.credit_fee === 0
                  ? currentFee.credit_installments_fee.toLocaleString('pt-br') + '%'
                  : 'Carregando...'}
              </td>
            </tr>
            <tr>
              <td>Para:</td>
              <td>
                {newPlan.antecipation_days === 0
                  ? 'Sem Antecipação'
                  : newPlan.antecipation_days + ' dias'}
              </td>
              <td>
                {newPlan.credit_fee || newPlan.credit_fee === 0
                  ? newPlan.credit_fee.toLocaleString('pt-br') + '%'
                  : 'Carregando...'}
              </td>
              <td>
                {newPlan.credit_installments_fee || newPlan.credit_installments_fee === 0
                  ? newPlan.credit_installments_fee.toLocaleString('pt-br') + '%'
                  : 'Carregando...'}
              </td>
            </tr>
          </table>
          <Checkbox
            onChange={() => setAgrees(!agrees)}
            label="Estou ciente e de acordo com as alterações acima"
            labelStyle={{
              fontSize: '13px',
              lineHeight: '100%',
              color: '#454545',
            }}
            checked={agrees === true}
          />
        </ModalContent>
      </Modal>
    </>
  );
};

export default AnticipationTable;
