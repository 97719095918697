import React, { useEffect, useState } from 'react';
import Wizard from '../../components/Wizard';
import { Container } from './styles';
import history from '../../services/history';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import Wizard0 from './Wizard0';
import Wizard1 from './Wizard1';
import Wizard2 from './Wizard2';
import Wizard3 from './Wizard3';
import Wizard4 from './Wizard4';
import Wizard5 from './Wizard5';
import Wizard6 from './Wizard6';

import accounts from '../../services/core/http/accounts';
import TemporalyDisabled from './TemporalyDisabled';
import { useLocation } from 'react-router';

export default function CreateAccount() {
  const [disableButton, setDisableButton] = useState(false);
  const { alprotoken, alpaynewcustomerdisabled } = useSelector(state => state.auth);
  const [isSMSCorrect, setIsSMSCorrect] = useState(null);
  const [sendingMessage, setSendingMessage] = useState(false);
  const {search} = useLocation();
  const [iframe, setIframe] = useState(false);

  useEffect(() => {
    const searchParams = new URLSearchParams(search);
    const iframeQuery = searchParams.get('iframe');
    
    if (iframeQuery)  setIframe(true)
  }, [iframe]);

  async function handleSubmit(data) {
    const validUser = await accounts.check(data.username, alprotoken);
    if (!validUser) {
      return toast.error('Este nome de usuário já existe. Escolha outro nome.');
    }

    if (!data.bank_agency_vd) {
      data['bank_agency_vd'] = '0';
    }

    try {
      await accounts.create(data, alprotoken, iframe);

      window.dataLayer && window.dataLayer.push({
        event: 'CustomEvent',
        action: 'PayConfigured',
        pageCategory: 'alboom-pay'
      });
      console.log('Foi push');

      return history.push('/create/success', { username: data.username, iframe: iframe, user_data: data });
    } catch (err) {
      console.error(err);
      return history.push('/create/failed');
    }
  }

  if (alpaynewcustomerdisabled) {
    //return <TemporalyDisabled />;
  }

  return (
    <>
      <div className="container">
        <Container>
          <Wizard
            initialValues={{ doc_type: 'pf', selling_intends: 'products', country: 'brasil' }}
            onSubmit={handleSubmit}
            submitMessage="Finalizar"
            verifyMessage="Verificar"
            disableButton={disableButton}
            setDisableButton={setDisableButton}
            setSendingMessage={setSendingMessage}
            setIsSMSCorrect={setIsSMSCorrect}
          >
            <Wizard0 />
            <Wizard1 />
            {/* <Wizard2 /> */}
            <Wizard3 />
            <Wizard4 />
            <Wizard5
              setDisableButton={setDisableButton}
              sendingMessage={sendingMessage}
              isSMSCorrect={isSMSCorrect}
              setIsSMSCorrect={setIsSMSCorrect}
            />
            <Wizard6 />
          </Wizard>
        </Container>
      </div>
    </>
  );
}
