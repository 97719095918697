import React from 'react';
import axios from '../../services/axios';
import Icon from '@alboompro/boom-components/general/icon';
import { Container, Title, Subtitle } from './styles';
import { toast } from 'react-toastify';

export default function DocumentInput({ onChange, smaller, canBeOpened }) {
  async function postFiles(files) {
    const {
      data: { data, success },
    } = await axios.post('/account_files', { account_files: [...files] });

    if (success) {
      onChange(data);
    }
  }

  async function handleChange() {
    if(canBeOpened){
      window.alb(
        'cloud',
        'open',
        {
          multipleSelect: true,
        },
        {
          onFinish: async fileList => {
            const filesToSend = [];

            await fileList.map(({ versions, original: { path, filename }, _id }) => {
              if (versions) {
                const item = {
                  file_id: _id,
                  file_url: versions[0].url,
                  file_name: filename,
                };

                filesToSend.push(item);
              } else {
                const url = `https://cdn.alboompro.com/${path.split('/')[0]}_${
                  path.split('/')[1]
                }/${filename}`;

                const item = {
                  file_id: _id,
                  file_url: url,
                  file_name: filename,
                };

                filesToSend.push(item);
              }
            });

            postFiles(filesToSend);
          },
        },
      );
    }else{
      toast.error('Envio de arquivos bloqueado enquanto estiver com verificação em andamento');
    }
  }

  return (
    <Container onClick={handleChange} className={smaller && 'smaller'}>
      <Icon
        color="#1B9DFA"
        kind="light"
        group="internet-networks-servers"
        category="cloud"
        file="cloud-upload.svg"
        size={smaller ? '24' : '64'}
      />
      <Title className={smaller && 'smaller'}>
        {smaller ? 'Enviar novo documento' : 'Adicionar arquivo'}
      </Title>
      <Subtitle className={smaller && 'smaller'}>
        Clique para selecionar o arquivo ou arraste-o aqui
      </Subtitle>
    </Container>
  );
}
