import { takeLatest, call, put, all } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import {
  PAYMENT_LINKS_GET,
  PAYMENT_LINKS_POST,
  PAYMENT_LINKS_PUT,
  PAYMENT_LINKS_DELETE,
  PAYMENT_LINKS_DUPLICATE,
  paymentLinksRequestSuccess,
  paymentLinksPostSuccess,
  paymentLinksPutSuccess,
  paymentLinksDeleteSuccess,
  paymentLinksDuplicateSuccess,
  paymentLinksFailure,
} from './actions';

import PaymentLinks from '../../../services/core/http/paymentLinks';

export function* createPaymentLink({ payload }) {
  try {
    const response = yield call(PaymentLinks.create, { ...payload });
    yield put(paymentLinksPostSuccess(response));
    toast.success('Link de pagamento criado');
  } catch (error) {
    console.log(error);
    yield put(paymentLinksFailure());
  }
}

export function* getPaymentLinks({ payload }) {
  try {
    const response = yield call(PaymentLinks.get, { ...payload });
    yield put(paymentLinksRequestSuccess(response));
  } catch (error) {
    console.log(error);
    yield put(paymentLinksFailure());
  }
}

export function* putPaymentLinks({ payload }) {
  try {
    const response = yield call(PaymentLinks.put, { ...payload });
    yield put(paymentLinksPutSuccess(response));
    toast.success('Link de pagamento alterado');
  } catch (error) {
    console.log(error);
    yield put(paymentLinksFailure());
  }
}

export function* deletePaymentLinks({ payload }) {
  try {
    const response = yield call(PaymentLinks.delete, payload);
    yield put(paymentLinksDeleteSuccess(response));
  } catch (error) {
    console.log(error);
    yield put(paymentLinksFailure());
  }
}

export function* duplicatePaymentLink({ payload }) {
  try {
    const response = yield call(PaymentLinks.duplicate, { ...payload });
    yield put(paymentLinksDuplicateSuccess(response));
  } catch (error) {
    console.log(error);
    yield put(paymentLinksFailure());
  }
}

export default all([
  takeLatest(PAYMENT_LINKS_GET, getPaymentLinks),
  takeLatest(PAYMENT_LINKS_POST, createPaymentLink),
  takeLatest(PAYMENT_LINKS_PUT, putPaymentLinks),
  takeLatest(PAYMENT_LINKS_DELETE, deletePaymentLinks),
  takeLatest(PAYMENT_LINKS_DUPLICATE, duplicatePaymentLink),
]);
