import styled from 'styled-components';

export const Container = styled.div`
  position: fixed;
  top: 45px;
  left: 0;
  bottom: 0;
  z-index: 6;

  .globalBoxRaffle {
    width: 100%;
    height: 140px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    h2 {
      color: #0090bd;
      font-size: 20px;
      line-height: 2.3em;
    }

    h3 {
      font-size: 14px;
      font-weight: 500;
    }

    p {
      line-height: 1.2em;
    }

    a {
      line-height: 1.2em;
      text-decoration: underline;

      :hover {
        color: #000000;
      }
    }
  }

  @media screen and (max-width: 767px) {
    .after-content {
      position: relative;
      flex: 1;
      background-color: #fff;
      width: 100%;
      z-index: 1;
      transition: all ease-in 0.3s;
      height: 100%;

      .menuAfter-Wrapper {
        padding: 0 !important;
        width: 35px;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .menuAfter .menuAfter-IconWrapper {
        margin-right: 0 !important;
      }

      .menuAfter-textWrapper {
        display: none;
        opacity: 0;
        overflow: hidden;
        transition: all ease-in 0.3s;
      }
    }
    .collapsed .globalBoxRaffle {
      display: flex;
    }
    .globalBoxRaffle {
      display: none;
    }
  }

  .collapsed .menuAfter-Wrapper {
    width: 100%;
    height: 50px;
    padding: 10px;
  }

  .collapsed .menuAfter .menuAfter-IconWrapper {
    margin-right: 10px !important;
  }

  .collapsed .menuAfter-textWrapper {
    display: block;
    opacity: 1;
  }
`;
