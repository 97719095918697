import styled from 'styled-components';

export const PaymentLabel = styled.span`
  background-color: ${props => (props.disabled ? '#dc624b' : '#46ae70')};
  border-radius: 30px;
  font-size: 10px;
  text-transform: uppercase;
  font-weight: 500;
  color: #ffffff;
  padding: 3px 10px;
  text-align: center;
  margin-left: 10px;

  @media screen and (max-width: 767px) {
    margin-left: 0;
    margin-top: 5px;
  }
`;

export const ButtonWrapper = styled.div`
  justify-content: space-between;
  display: flex;

  button {
    width: 50%;

    &:last-child {
      margin-left: 60px;
    }
  }

  @media screen and (max-width: 768px) {
    flex-direction: column;
    button {
      width: 100%;
      margin-bottom: 10px;

      &:last-child {
        margin-left: 0px;
      }
    }
  }
`;

export const PaymentWrapper = styled.div`
  padding-bottom: 40px;

  &:first-child {
    margin-bottom: 40px;
  }

  .tabTitle {
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: 15px;
    letter-spacing: 0em;
    text-align: left;
    color: #343434;
    text-transform: uppercase;
    margin-bottom: 20px;
  }

  .tabDescription {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: left;
    color: #4f4f4f;

    & + p {
      margin-top: -8px;
      margin-bottom: 60px;
    }
  }

  @media screen and (max-width: 767px) {
    padding: 15px 15px 0px 0px;
  }
`;

export const PaymentHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 40px;

  @media screen and (max-width: 767px) {
    margin-bottom: 20px;
  }
`;

export const PaymentTitle = styled.h4`
  font-size: 20px;
  color: #383838;
  display: flex;
  align-items: center;

  @media screen and (max-width: 767px) {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
`;

export const PaymentEnable = styled.div``;

export const PaymentOption = styled.div`
  margin-bottom: 60px;

  span {
    display: inline-block;
    margin-left: 4px;
    position: relative;

    &:hover div {
      display: block;
      cursor: help;
    }

    div {
      display: none;
      position: absolute;
      top: 4px;
      left: 24px;
      box-shadow: 1px 1px 4px 0px #0000001a;
      background-color: #ffffff;
      width: 212px;
      height: 101px;
      padding: 14px 12px;
      border-radius: 2px;
      border: 1px solid #e7e7e7;
      z-index: 2000;
      font-size: 10px;
      font-style: normal;
      font-weight: 400;
      line-height: 13px;
      text-align: left;
    }
  }

  @media screen and (max-width: 767px) {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }

  &:last-child {
    margin-bottom: 0;
  }
`;

export const PaymentRadio = styled.div`
  margin-bottom: 50px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: 28px;

  label {
    & + label {
      margin-left: 40px;
    }
  }

  @media screen and (max-width: 767px) {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    label {
      & + label {
        margin-left: 0px;
      }
    }
  }
`;

export const OptionDescription = styled.div`
  font-size: 15px;
  color: #383838;
  line-height: 1.3;
  margin-top: 17px;

  @media screen and (max-width: 767px) {
    max-width: 100%;
    width: 100%;
    margin-bottom: 10px;
  }
`;

export const OptionList = styled.div`
  width: 273px;

  > div {
    margin-top: 17px;

    span {
      display: none;
    }
  }

  @media screen and (max-width: 767px) {
    width: 100%;
  }
`;

export const PaymentMethodsCards = styled.div`
  font-size: 15px;
  color: #484848;

  > strong {
    font-weight: 500;
    margin-bottom: 5px;
    display: block;
  }
`;

export const CCTitle = styled.h1`
  margin-top: 42px;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 15px;
  letter-spacing: 0em;
  text-align: left;
  color: #343434;
  margin-bottom: 25px;
`;
