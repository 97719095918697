import { combineReducers } from 'redux';

import orders from './orders/reducer';
import auth from './auth/reducer';
import balance from './balance/reducer';
import modal from './modal/reducer';
import activities from './activities/reducer';
import bankAccounts from './bankAccounts/reducer';
import banks from './banks/reducer';
import accounts from './accounts/reducer';
import withdraw from './withdraw/reducer';
import paymentLinks from './paymentLinks/reducer';
import paymentButtons from './paymentButtons/reducer';

const reducers = combineReducers({
  orders,
  auth,
  balance,
  modal,
  activities,
  bankAccounts,
  banks,
  accounts,
  withdraw,
  paymentLinks,
  paymentButtons
});

export default reducers;
