import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Form } from '@rocketseat/unform';
import { toast } from 'react-toastify';
import axios from '../../../services/axios';
import Zipcodes from '../../../services/core/http/zipcodes';
import Segments from '../../../services/core/http/segments';
import { getAccountsRequest, putAccountsRequest } from '../../../store/modules/accounts/actions';
import { maskSsn, maskRn, maskUsername, maskPhone, maskZipCode } from '../../../utils';
import Button from '../../Button';
import RadioInput from '../../RadioInput';
import Input from '../../Input';
import AvatarInput from '../../AvatarInput';
import Select from '../../Select';
import Spinner from '../../Spinner';
import AbsoluteSpinner from '../../Spinner/Absolute';
import { configAccount } from '../../../validators/schema';
import Checkbox from '../../Checkbox';
import Textarea from '../../Textarea';
import DocumentInput from '../../DocumentInput';
import InfoDescription from '../../InfoDescription';
import Icon from '@alboompro/boom-components/general/icon';
import moment from 'moment';
import { ReactComponent as PDFIcon } from '../../../assets/static/images/icons/pdf-file.svg';
import { ReactComponent as JPGIcon } from '../../../assets/static/images/icons/jpg-file.svg';
import { ReactComponent as EyeSVG } from '../../../assets/static/images/icons/eye.svg';
import { ReactComponent as BinSVG } from '../../../assets/static/images/icons/bin.svg';
import {
  AccountType,
  SubCategory,
  AddressWrap,
  SelectLabel,
  RadioLabel,
  RadioTitle,
  RadioSubtitle,
  RadioText,
  CheckboxLabel,
  CategorySubtitle,
  DocumentCard,
  CopyLink,
  StickyButtons,
  RadioCategory,
  FilesUploaded,
  File,
} from '../styles';

export default function CompanyProfile() {
  const { account_id, user_data, loading: accountsLoading } = useSelector(state => state.accounts);
  const { alprotoken } = useSelector(state => state.auth);

  const dispatch = useDispatch();

  const [maskedDate, setMaskedDate] = useState('');

  const [prositeDomain, setPrositeDomain] = useState('');
  const [protocol, setProtocol] = useState('');
  const [url, setURL] = useState('');
  const [accountFiles, setAccountFiles] = useState([]);
  const [segmentsOptions, setSegmentsOptions] = useState(null);
  const [subSegmentsOptions, setSubSegmentsOptions] = useState(null);

  const emptyData = {
    fullname: '',
    doc: '',
    company_doc: '',
    type: '',
    address: '',
    neighborhood: '',
    postal_code: '',
    complement: '',
    number: '',
    city: {
      name: '',
      state_name: '',
      country_name: '',
      ibge: '',
      gia: '',
    },
    display_name: '',
    username: '',
    phone: '',
    avatar_url: null,
    email: '',
    rg: '',
    website: '',
    use_account_info: false,
    birth_date: '',
    telephone: '',
    hide_address: false,
    contact_name: '',
    contact_phone: '',
    contact_phone2: '',
    contact_email: '',
    company_description: '',
    have_website: false,
    selling_intends: 'all',
    verification_doc: '',
    responsible_name: '',
    responsible_doc: '',
    segments: {
      parent: '',
      child: '',
    },
    verify_status:"not_verified",
  };

  const [dataChanged, setDataChanged] = useState(false);
  const [initialData, setInitialData] = useState(emptyData);
  const [data, setData] = useState(emptyData);

  const {
    type,
    doc,
    company_doc,
    fullname,
    city,
    username,
    phone,
    loading,
    address,
    display_name,
    complement,
    postal_code,
    number,
    email,
    rg,
    website,
    use_account_info,
    birth_date,
    telephone,
    hide_address,
    gender,
    contact_name,
    contact_phone,
    contact_phone2,
    contact_email,
    company_description,
    have_website,
    segments,
    selling_intends,
    verification_doc,
    responsible_name,
    responsible_doc,
    verify_status,
  } = data;

  const countries = [
    {
      label: 'Brasil',
      value: 'brasil',
      icon: 'https://bifrost.alboompro.com/core/flags/br.svg',
    },
  ];

  const genders = [
    {
      label: 'Feminino',
      value: 'female',
    },
    {
      label: 'Masculino',
      value: 'male',
    },
  ];

  const protocols = [
    {
      label: 'https://',
      value: 'https://',
    },
    {
      label: 'http://',
      value: 'http://',
    },
  ];

  const handleUsername = ({ target: { value } }) =>
    setData(d => ({ ...d, username: maskUsername(value) }));

  const handlePhone = ({ target: { value } }) => setData(d => ({ ...d, phone: maskPhone(value) }));

  const handleZipCode = ({ target: { value } }) => {
    let zipNums = value.replace(/[^\d]/g, '');
    if (zipNums.length === 8) {
      setData(d => ({ ...d, loading: true }));
      const fetch = async () => {
        const { data } = await Zipcodes.get(zipNums);
        setData(d => ({
          ...d,
          loading: false,
          address: data.logradouro,
          neighborhood: data.bairro,
          city: {
            name: data.localidade,
            state_name: data.uf,
            ibge: data.ibge,
            gia: data.gia,
          },
        }));
      };
      fetch();
    }
    setData(d => ({ ...d, postal_code: maskZipCode(value) }));
  };

  const handleCancel = () =>
    setData({
      ...emptyData,
      ...user_data,
      phone: user_data.phone || '',
      company_description: user_data.company_description || '',
    });

  const handleSubmit = () => {
    if (data.postal_code.replace(/[^\d]/g, '').length < 8) return toast.error('CEP inválido');
    dispatch(putAccountsRequest(account_id, data));
  };

  const changeAvatar = avatar_url => setData({ ...data, avatar_url });
  const changeDocument = newFiles => setAccountFiles([...accountFiles, ...newFiles]);

  useEffect(() => {
    !!account_id && dispatch(getAccountsRequest(account_id));
  }, [account_id, dispatch]);

  useEffect(() => {
    setDataChanged(!(JSON.stringify(data) === JSON.stringify(initialData)));
  }, [data]);

  useEffect(() => {
    user_data.type &&
      setData(d => ({
        ...d,
        fullname: user_data.fullname,
        doc: user_data.doc,
        company_doc: user_data.company_doc,
        type: user_data.type,
        address: user_data.address,
        neighborhood: user_data.neighborhood,
        postal_code: user_data.postal_code,
        complement: user_data.complement || '',
        number: user_data.number,
        city: {
          name: user_data.city.name,
          state_name: user_data.city.state_name,
          country_name: user_data.city.country_name,
          ibge: user_data.city.ibge,
          gia: user_data.city.gia,
        },
        display_name: user_data.display_name,
        username: user_data.username,
        phone: user_data.phone || '',
        avatar_url: user_data.avatar_url,
        email: user_data.email,
        rg: user_data.rg,
        website: user_data.website,
        use_account_info: user_data.use_account_info,
        birth_date: user_data.birth_date,
        telephone: user_data.telephone,
        hide_address: user_data.hide_address,
        contact_name: user_data.contact_name,
        contact_phone: user_data.contact_phone,
        contact_phone2: user_data.contact_phone2,
        contact_email: user_data.contact_email,
        company_description: user_data.company_description || '',
        have_website: user_data.have_website,
        segments: {
          parent: user_data.segments.parent,
          child: user_data.segments.child,
        },
        selling_intends: user_data.selling_intends,
        verification_doc: user_data.verification_doc,
        gender: user_data.gender,
        responsible_name: user_data.responsible_name,
        responsible_doc: user_data.responsible_doc,
        verify_status: user_data.verify_status,
      }));

    setMaskedDate(maskInitialDob(user_data.birth_date));

    user_data.website && setURL(user_data.website.split('://').pop());
    user_data.website
      ? setProtocol(user_data.website.split('://')[0].concat('://'))
      : setProtocol('https://');

    setInitialData(d => ({
      ...d,
      fullname: user_data.fullname,
      doc: user_data.doc,
      company_doc: user_data.company_doc,
      type: user_data.type,
      address: user_data.address,
      neighborhood: user_data.neighborhood,
      postal_code: user_data.postal_code,
      complement: user_data.complement || '',
      number: user_data.number,
      city: {
        name: user_data.city.name,
        state_name: user_data.city.state_name,
        country_name: user_data.city.country_name,
        ibge: user_data.city.ibge,
        gia: user_data.city.gia,
      },
      display_name: user_data.display_name,
      username: user_data.username,
      phone: user_data.phone || '',
      avatar_url: user_data.avatar_url,
      email: user_data.email,
      rg: user_data.rg,
      website: user_data.website,
      use_account_info: user_data.use_account_info,
      birth_date: user_data.birth_date,
      telephone: user_data.telephone,
      hide_address: user_data.hide_address,
      contact_name: user_data.contact_name,
      contact_phone: user_data.contact_phone,
      contact_phone2: user_data.contact_phone2,
      contact_email: user_data.contact_email,
      company_description: user_data.company_description || '',
      have_website: user_data.have_website,
      segments: {
        parent: user_data.segments.parent,
        child: user_data.segments.child,
      },
      selling_intends: user_data.selling_intends,
      verification_doc: user_data.verification_doc,
      gender: user_data.gender,
      responsible_name: user_data.responsible_name,
      responsible_doc: user_data.responsible_doc,
      verify_status: user_data.verify_status,
    }));
  }, [user_data]);

  useEffect(() => {
    fetchSegmentsData();

    fetch(
      `https://prosite-api.alboompro.com/api/v1/alboompay/clients/${user_data.owner_id}/domain`,
      {
        method: 'GET',
        headers: new Headers({
          'X-Alboompay-Token':
            '7a367bd866f615528ec447d3a1cdf2b00f1323ee565fb50d673ddcc40643ba38a5c728c0de043df97b3dcf4c5ad5b16bf071b833975d347e21ae6b0e1a9c28b8',
        }),
      },
    )
      .then(response => response.json())
      .then(json => {
        if (json.client.domain) {
          setPrositeDomain(json.client.domain);
        }
      })
      .catch(err => console.log(err));
  }, [alprotoken]);

  async function fetchSegmentsData() {
    const request = await Segments.list(alprotoken);

    if (Object.keys(request).length) {
      let segmentsmap = request.map(item => ({
        label: item.parent,
        value: item.parent,
        options: item.children.map(child => ({ label: child, value: child })),
      }));
      setSegmentsOptions(segmentsmap);

      if (user_data.segments.parent) {
        const segment = segmentsmap.find(obj => obj.label === user_data.segments.parent);
        setSubSegmentsOptions(segment.options);
      }
    }
  }

  function maskDob(value) {
    if (!value) return value;
    const onlyNums = value.replace(/[^\d]/g, '');
    if (onlyNums.length <= 2) {
      if (onlyNums > 31 || onlyNums < 0) return 31;
      return onlyNums;
    }

    let months = onlyNums.slice(2, 4);
    if (onlyNums.length <= 4) {
      if (months > 12 || months < 0 || (months.length === 2 && months < 1)) months = 12;
      return `${onlyNums.slice(0, 2)}/${months}`;
    }

    if (onlyNums.length === 6) {
      let years = onlyNums.slice(4, 6);
      if (years < 19 || years > 20) years = 19;
      return `${onlyNums.slice(0, 2)}/${months}/${years}`;
    }

    if (onlyNums.length === 8) {
      let years = onlyNums.slice(4, 8);
      const currentDate = moment(new Date()).format('YYYY');
      if (years < 1900 || years > currentDate) years = currentDate;
      return `${onlyNums.slice(0, 2)}/${months}/${years}`;
    }

    return `${onlyNums.slice(0, 2)}/${onlyNums.slice(2, 4)}/${onlyNums.slice(4, 8)}`;
  }

  function maskInitialDob(value) {
    if (!value) return value;
    const onlyNums = value.replace(/[^\d]/g, '');

    return `${onlyNums.slice(6, 8)}/${onlyNums.slice(4, 6)}/${onlyNums.slice(0, 4)}`;
  }

  const handleScroll = () => {
    const wrapper = document.getElementById('stickyWrapper');
    const container = document.getElementById('formContainer');

    const wrpRelative = wrapper.offsetTop - container.offsetTop - 60;
    const formHeight = container.offsetHeight;

    formHeight > wrpRelative
      ? (wrapper.style.boxShadow = '0px -2px 6px rgba(0, 0, 0, 0.13)')
      : (wrapper.style.boxShadow = 'none');
  };

  const getAccountFiles = async () => {
    await axios.get('/account_files').then(({ data: { data } }) => setAccountFiles(data));
  };

  useEffect(() => {
    getAccountFiles();
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const deleteFile = async _id => {
    if(data.verify_status !== "verifying"){
      const response = await axios.delete(`/account_files/${_id}`);
      if (response.status === 204) {
        const newAccountFiles = accountFiles.filter(file => file._id !== _id);
        setAccountFiles(newAccountFiles);
      }
    }else{
      toast.error('Não é possível deletar arquivos com verificação em andamento');
    }
  };

  return (
    <>
      {accountsLoading && <AbsoluteSpinner Zindex={7} />}
      <Form onSubmit={handleSubmit} schema={configAccount}>
        <div id="formContainer">
          <AvatarInput onChange={changeAvatar} />
          <SubCategory style={{ fontWeight: '400', fontSize: '16px', marginBottom: '0px' }}>
            Tipo de conta
          </SubCategory>

          <div className="row justify-content-start">
            <div>
              <AccountType>
                <div className="row centered" style={{ margin: '0 15px' }}>
                  <div>
                    <RadioInput
                      name="doc_type_pj"
                      type="radio"
                      value="pj"
                      label="  "
                      checked={false}
                    >
                      <RadioLabel>
                        <RadioTitle>Pessoa Física (CPF)</RadioTitle>
                        <RadioSubtitle>Sem CNPJ</RadioSubtitle>
                      </RadioLabel>
                    </RadioInput>
                  </div>

                  <div>
                    <RadioInput
                      name="doc_type_pj"
                      type="radio"
                      value="pj"
                      label="  "
                      checked={true}
                    >
                      <RadioLabel>
                        <RadioTitle>Pessoa Jurídica (CNPJ)</RadioTitle>
                        <RadioSubtitle>MEI, LTDA, S/A, SS</RadioSubtitle>
                      </RadioLabel>
                    </RadioInput>
                  </div>
                </div>
              </AccountType>
            </div>
          </div>

          <hr />

          <SubCategory className="text-uppercase">Dados do negócio</SubCategory>
          <div style={{ marginTop: '40px' }}>
            <Input
              name="publicName"
              label={
                <InfoDescription
                  tooltip="O nome do negócio, ou nome fantasia, será a principal maneira como seu negócio será mostrado."
                  width="325px"
                >
                  {' '}
                  Nome do negócio (Nome Fantasia)&nbsp;{' '}
                </InfoDescription>
              }
              placeholder="Este nome aparecerá para seus clientes."
              value={display_name}
              onChange={e => setData({ ...data, display_name: e.target.value })}
              maxLength="100"
            />
            <div
              className="row"
              style={{ paddingLeft: '15px', display: 'flex'}}
            >
              <div>
                <InfoDescription
                  tooltip="O site ou página online do seu negócio ajuda a fortalecer sua marca online e é um importante fator para vender mais e receber mais rápido seus pagamentos."
                  width="300px"
                >
                  Site&nbsp;
                </InfoDescription>
              </div>
              <div style={{ padding: '5px 15px 0px 0px', width: '100%' }}>
                <Input
                  name="website"
                  value={url}
                  label=""
                  disabled={!have_website}
                  onChange={e => {
                    setURL(e.target.value);
                    setData({
                      ...data,
                      website: e.target.value ? protocol.concat(e.target.value) : '',
                    });
                  }}
                />
              </div>
            </div>
            <div className="optionsWebsite">
              <div className="col-12 col-sm-6 pl own_website" style={{ padding: '0px' }}>
                <Checkbox
                  name="have_website"
                  label="  "
                  checked={!have_website}
                  value={have_website}
                  onChange={() => {
                    setURL('');
                    setData({ ...data, have_website: !have_website });
                  }}
                >
                  <CheckboxLabel>Meu negócio não possui site</CheckboxLabel>
                </Checkbox>
              </div>
              <div
                className="col-12 col-sm-6 pr copyurl_website"
                style={{
                  marginBottom: '10px',
                  padding: '0px',
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                {prositeDomain ? (
                  <CopyLink
                    onClick={() => {
                      setData({
                        ...data,
                        website: protocol.concat(prositeDomain),
                        have_website: true,
                      });
                      setURL(prositeDomain);
                    }}
                  >
                    Copiar do meu Prosite
                  </CopyLink>
                ) : (
                  <CopyLink onClick={() => window.open('http://prosite.alboompro.com/', '_blank')}>
                    Criar site grátis
                  </CopyLink>
                )}
              </div>
            </div>

            <Textarea
              name="company_description"
              label="Descrição do negócio"
              value={company_description}
              maxlength="250"
              onChange={e => setData({ ...data, company_description: e.target.value })}
              inlineStyle={{ marginBottom: '0' }}
            />
          </div>

          <SubCategory style={{ fontWeight: '400', fontSize: '16px' }}>
            Endereço do negócio
          </SubCategory>
          <AddressWrap className="addressWrap">
            {loading && (
              <div className="loading">
                <Spinner size={40} />
              </div>
            )}
            <div className="row justify-content-center inputs-wrapper">
              <div className="col-12 col-lg-5 pr">
                <SelectLabel className="label country">País</SelectLabel>
                <Select
                  options={countries}
                  initialValue="brasil"
                  className="CountrySelectWizard"
                  isDisabled
                />
              </div>
              <div className="col-12 col-lg-7 pl">
                <Input name="zipcode" value={postal_code} label="Cep" onChange={handleZipCode} />
              </div>
            </div>

            <div className="row justify-content-center">
              <div className="col-12 col-xl-9 street pr">
                <Input name="street" label="Rua" disabled placeholder={address} />
              </div>
              <div className="col-12 col-xl-3 number pl">
                <Input
                  name="number"
                  label="Número"
                  value={number}
                  onChange={e => setData({ ...data, number: e.target.value })}
                  maxLength="10"
                />
              </div>
            </div>

            <div className="row justify-content-center">
              <div className="col-lg-12">
                <Input
                  name="complement"
                  label="Complemento"
                  value={complement}
                  onChange={e => setData({ ...data, complement: e.target.value })}
                  maxLength="100"
                />
              </div>

              <div className="col-12 col-lg-6 pr">
                <Input name="city" label="Cidade" disabled placeholder={city.name} />
              </div>
              <div className="col-12 col-lg-6 state pl">
                <Input name="state" label="Estado" disabled placeholder={city.state_name} />
              </div>
            </div>

            <Checkbox
              name="hide_address"
              label="  "
              checked={hide_address}
              value={hide_address}
              onChange={() => {
                setData({ ...data, hide_address: !hide_address });
              }}
            >
              <CheckboxLabel>
                <InfoDescription
                  tooltip="Ao marcar esta opção, ocultaremos o endereço das suas solicitações e links de pagamento."
                  width="325px"
                >
                  Não exibir endereço para meus clientes&nbsp;
                </InfoDescription>
              </CheckboxLabel>
            </Checkbox>
          </AddressWrap>

          <hr />

          <SubCategory className="text-uppercase">responsável do negócio</SubCategory>
          <div style={{ marginTop: '40px' }}>
            <Input
              name="fullname"
              label="Nome completo do responsável"
              value={responsible_name}
              onChange={e => setData({ ...data, responsible_name: e.target.value })}
              required
            />
            <div className="row justify-content-center">
              <div className="col-12 col-lg-6 pr">
                <Input name="cellphone" label="Celular" disabled placeholder={phone} />
              </div>
              <div className="col-12 col-lg-6 state pl">
                <Input
                  name="phone"
                  label="Telefone"
                  value={telephone}
                  onChange={e => setData({ ...data, telephone: maskPhone(e.target.value) })}
                  placeholder="( ) _____-____"
                  required
                />
              </div>
            </div>

            <Input
              name="email"
              label="E-mail"
              value={email}
              onChange={e => setData({ ...data, email: e.target.value })}
              required
            />

            <div className="row justify-content-center">
              <div className="col-12 col-xl-6 pr">
                <Input
                  name="cpf"
                  label="CPF"
                  value={maskSsn(responsible_doc)}
                  onChange={({ target: { value: v } }) =>
                    setData(d => ({ ...d, responsible_doc: maskSsn(v) }))
                  }
                  required
                />
              </div>
              <div className="col-12 col-xl-6 state pl">
                <Input
                  name="rg"
                  label="RG"
                  value={rg}
                  onChange={e => setData({ ...data, rg: e.target.value.replace(/[^0-9./-]/g, '') })}
                />
              </div>
            </div>

            <div className="row justify-content-center">
              <div className="col-12 col-xl-6 pr">
                <Input
                  name="birth_date"
                  label="Data de nascimento"
                  value={maskedDate}
                  onChange={e => {
                    setMaskedDate(maskDob(e.target.value));
                    setData({
                      ...data,
                      birth_date: moment(e.target.value, 'DD/MM/YYYY').toISOString(),
                    });
                  }}
                />
              </div>
              <div className="col-12 col-xl-6 pl">
                <SelectLabel className="label country">Gênero/Sexo</SelectLabel>
                <Select
                  options={genders}
                  initialValue={gender}
                  className="CountrySelectWizard"
                  onChange={obj => {
                    setData({ ...data, gender: obj.value });
                  }}
                />
              </div>
            </div>
          </div>

          {accountFiles.length === 0 ? (
            <div style={{ margin: '50px 0px 36px' }}>
              <SubCategory>Documentos exigidos para a verificação de conta</SubCategory>
              <CategorySubtitle>Cópia frente e verso do seu CPF e/ou CNH.</CategorySubtitle>
              <CategorySubtitle>Uma foto sua (selfie), segurando um documento com foto.</CategorySubtitle>

              <DocumentInput canBeOpened={data.verify_status !== "verifying" ? true : false } onChange={changeDocument} />

              <DocumentCard>
                <div className="title">Qual documento será aceito?</div>
                <ul>
                  <li>
                    <p>RG + CPF.</p>
                  </li>
                  <li>
                    <p>CNH.</p>
                  </li>
                  <li>
                    <p>As imagens deverão ser legíveis, sem cortes, coloridas e frente e verso.</p>
                  </li>
                </ul>
              </DocumentCard>
            </div>
          ) : (
            <div style={{ margin: '50px 0px 36px' }}>
              <SubCategory>Documentos exigidos para a verificação de conta</SubCategory>
              <CategorySubtitle>Cópia frente e verso do seu CPF e/ou CNH</CategorySubtitle>
              <SubCategory style={{ marginBottom: '22px', marginTop: '43px' }}>
                Documentos enviados
              </SubCategory>

              <FilesUploaded>
                {accountFiles.map(({ _id, file_id, file_name, file_url }) => (
                  <File key={file_id}>
                    <div>
                      {file_name.endsWith('pdf') ? <PDFIcon /> : <JPGIcon />}
                      {file_name}
                    </div>
                    <div className="uploadedOptions">
                      <a className="exclude" onClick={() => deleteFile(_id)}>
                        <BinSVG />
                        <span>Excluir</span>
                      </a>
                    </div>
                  </File>
                ))}
              </FilesUploaded>

              <DocumentInput canBeOpened={data.verify_status !== "verifying" ? true : false } onChange={changeDocument} smaller />
              <DocumentCard style={{ marginTop: '29px' }}>
                <div className="title">Qual documento será aceito?</div>
                <ul>
                  <li>
                    <p>CPF e/ou CNH</p>
                  </li>
                  <li>
                    <p>As imagens deverão ser legíveis, sem cortes, coloridas e frente e verso.</p>
                  </li>
                </ul>
              </DocumentCard>
            </div>
          )}
          <hr />

          <SubCategory className="text-uppercase">Dados de contato para o cliente</SubCategory>
          <div style={{ margin: '40px 0px 52px' }}>
            <div style={{ marginBottom: '20px' }}>
              <Checkbox
                name="use_account_info"
                label="  "
                checked={use_account_info}
                value={use_account_info}
                onChange={() => {
                  setData({
                    ...data,
                    use_account_info: !use_account_info,
                    contact_name: fullname,
                    contact_phone: phone,
                    contact_phone2: telephone,
                    contact_email: email,
                  });
                }}
              >
                <CheckboxLabel>Utilizar a mesma informação da conta</CheckboxLabel>
              </Checkbox>
            </div>

            <Input
              name="contact_name"
              value={contact_name}
              disabled={use_account_info}
              label="Nome para contato"
              onChange={e => setData({ ...data, contact_name: e.target.value })}
            />

            <div className="row justify-content-center">
              <div className="col-12 col-lg-6 pr">
                <Input
                  name="contact_phone"
                  label="Celular/Telefone"
                  value={contact_phone}
                  onChange={e => setData({ ...data, contact_phone: maskPhone(e.target.value) })}
                  placeholder={use_account_info ? '' : '( ) _____-____'}
                  disabled={use_account_info}
                  required
                />
              </div>
              <div className="col-12 col-lg-6 state pl">
                <Input
                  name="contact_phone2"
                  label="Celular/Telefone 2"
                  value={contact_phone2}
                  onChange={e => setData({ ...data, contact_phone2: maskPhone(e.target.value) })}
                  placeholder={use_account_info ? '' : '( ) _____-____'}
                  disabled={use_account_info}
                />
              </div>
            </div>

            <Input
              name="contact_email"
              value={contact_email}
              disabled={use_account_info}
              label="E-mail"
              onChange={e => {
                setData({ ...data, contact_email: e.target.value });
              }}
              required
            />
          </div>

          <hr />

          <SubCategory className="text-uppercase">Mais informações</SubCategory>
          <div style={{ marginTop: '46px' }}>
            <SelectLabel className="label country">Qual segmento você atua?</SelectLabel>
            <Select
              options={segmentsOptions}
              className="CountrySelectWizard"
              initialValue={segments.parent}
              onChange={obj => {
                const segmentsmap = segmentsOptions
                  .filter(segment => segment.value === obj.value)
                  .shift();
                setSubSegmentsOptions(segmentsmap.options);
                setData({ ...data, segments: { child: null, parent: obj.value } });
              }}
            />

            <div style={{ marginTop: '38px' }}>
              <SelectLabel className="label country">
                O que melhor representa seu negócio?
              </SelectLabel>
              <Select
                options={subSegmentsOptions}
                className="CountrySelectWizard"
                initialValue={segments.child}
                onChange={obj => {
                  setData({ ...data, segments: { ...data.segments, child: obj.value } });
                }}
              />
            </div>

            <div className="row justify-content-start" style={{ marginBottom: '60px' }}>
              <div className="col-12">
                <RadioCategory>O que você vende?</RadioCategory>
                <div className="row centered">
                  <div className="col-12 col-xl-4">
                    <RadioInput
                      name="doc_type_pf"
                      type="radio"
                      value="pf"
                      label="  "
                      checked={selling_intends === 'products'}
                      onClick={e => setData({ ...data, selling_intends: 'products' })}
                    >
                      <RadioLabel>
                        <RadioText>Produtos</RadioText>
                      </RadioLabel>
                    </RadioInput>
                  </div>

                  <div className="col-12 col-xl-4">
                    <RadioInput
                      name="selling_intends_services"
                      type="radio"
                      value="pj"
                      label="  "
                      checked={selling_intends === 'services'}
                      onClick={e => setData({ ...data, selling_intends: 'services' })}
                    >
                      <RadioLabel>
                        <RadioText>Serviços</RadioText>
                      </RadioLabel>
                    </RadioInput>
                  </div>

                  <div className="col-12 col-xl-4">
                    <RadioInput
                      name="selling_intends_all"
                      type="radio"
                      value="pj"
                      label="  "
                      checked={selling_intends === 'all'}
                      onClick={e => setData({ ...data, selling_intends: 'all' })}
                    >
                      <RadioLabel>
                        <RadioText>Produtos e Serviços</RadioText>
                      </RadioLabel>
                    </RadioInput>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <StickyButtons id="stickyWrapper">
          <div className={dataChanged ? 'row mt-4' : 'row mt-4 disabled'}>
            <div className="col-lg-6 col-md-6">
              <Button className="buttonDefault buttonLarge" onClick={handleCancel}>
                Cancelar alterações
              </Button>
            </div>
            <div className="col-lg-6 col-md-6">
              <Button
                className="buttonBlue buttonLarge"
                htmlType="submit"
                disabled={accountsLoading}
              >
                Salvar Alterações
              </Button>
            </div>
          </div>
        </StickyButtons>
      </Form>
    </>
  );
}
