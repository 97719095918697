import React from "react"
import Skeleton from '@alboompro/boom-components/feedback/skeleton'
import { formatToBRL, formatToLongDate } from "../utils"

import { 
  PeriodSummaryWrapper,
  TotalsWrapper,
  SummaryCard,
} from "./styles"

const { Line } = Skeleton



export default function Summary({
  periodBalance,
  dateRange,
  isLoading,
}) {
  const { incomes, outcomes } = periodBalance || {}
  const { startDate, endDate } = dateRange || {}

  return (
    <PeriodSummaryWrapper>
      <TotalsWrapper>
        <SummaryCard>
          <span>Total de entradas:</span>
          {isLoading ? (
            <span className="skeleton">
              <Line width="100%" height="100%"/>
            </span>
          ) : (
            <span className="income">
              {formatToBRL(incomes || 0, true)}
            </span>
          )}
        </SummaryCard>
        <SummaryCard>
          <span>Total de saídas:</span>
          {isLoading ? (
            <span className="skeleton">
              <Line width="100%" height="100%"/>
            </span>
          ) : (
            <span className="outcome">
              {formatToBRL(outcomes || 0, true)}
            </span>
          )}
        </SummaryCard>
      </TotalsWrapper>
      <p>{formatToLongDate(startDate)} a {formatToLongDate(endDate)}</p>
    </PeriodSummaryWrapper>
  )
}