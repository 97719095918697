import axios from "axios";

export default {
  get: async zipcode => {
    if( !zipcode ) return false;

    const checkuser = await axios.get(`https://viacep.com.br/ws/${zipcode}/json/`)
      .then( response => { return response })
      .catch( error => { return false; });

    return checkuser;
  }
};
