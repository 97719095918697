import React, { useEffect } from 'react';
import {
  WizardContainer,
  WrapperLogo,
  Logo,
  Title,
  WelcomeMessage,
  Subtitle,
  ContainerSteps,
  Step,
} from './styles';
// import Button from '../../components/Button';
import history from '../../services/history';

export default function Wizard0() {
  useEffect(() => {
    history.push('/');
  }, []);

  return (
    <>
      <div className="container">
        <WizardContainer>
          <WrapperLogo>
            <Logo
              src="https://bifrost.alboompro.com/products/alboompay/AlboomPay2022-full.svg"
              alt="Logo Alboom Pay"
            />
          </WrapperLogo>
          <Title>Bem-vindo(a) a configuração da sua conta Alboom Pay</Title>
          <WelcomeMessage>Você está a um passo de vender e receber pagamentos online.</WelcomeMessage>
          <WelcomeMessage>O Alboom Pay é uma ferramenta totalmente grátis da Alboom que vai tornar possível a venda de serviços e fotos de maneira fácil, rápida e segura.</WelcomeMessage>

          {/* <Subtitle>
            Você está a um passo de vender e receber pagamentos online.
          </Subtitle> */}

          {/* <ContainerSteps>
            <Step className="step-1">
              <img
                src="https://bifrost.alboompro.com/products/alboompay/steps/step-1.svg"
                alt="Controle nas finanças"
              />
              <p className="title">Receba em até 2 dias úteis</p>
            </Step>
            <Step className="step-2">
              <img
                src="https://bifrost.alboompro.com/products/alboompay/steps/step-2.svg"
                alt="Gera link de pagamento"
              />
              <p className="title">Parcele seus recebimentos em até 12x e receba tudo antes</p>
            </Step>
            <Step className="step-3">
              <img
                src="https://bifrost.alboompro.com/products/alboompay/steps/step-3.svg"
                alt="Personalize taxas"
              />
              <p className="title">Taxas a partir de 2,99%</p>
            </Step>
          </ContainerSteps> */}

          {/* <div className="row justify-content-center text-center">
            <div className="col-lg-12">
              <Button
                className="buttonBlue"
                style={{ margin: 'auto', width: 475, height: 70 }}
                textStyle={{ fontSize: '30px' }}
                onClick={() => {
                  history.push('/create');
                }}
                type="primary"
              >
                Crie sua conta Alboom Pay
              </Button>
            </div>
          </div>
          <Info>Serviço grátis, sem adesão e mensalidade.</Info> */}
        </WizardContainer>
      </div>
    </>
  );
}
