/* eslint-disable no-undef */
import React, { Component } from 'react';

import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  CardInfoRow,
  CardInfoLabel,
  CardInfoValue,
} from '../styles';

class PayerDetails extends Component {
  render() {
    const { payer, address } = this.props;
    
    return (
      <Card>
        <CardHeader>
          <CardTitle>Pagamento realizado por</CardTitle>
        </CardHeader>
        <CardBody>

          <CardInfoRow>
            <CardInfoLabel>Nome:</CardInfoLabel>
            <CardInfoValue>{payer.name}</CardInfoValue>
          </CardInfoRow>

          {payer.documents.length && (
          <CardInfoRow>
            <CardInfoLabel>CPF/CNPJ:</CardInfoLabel>
            <CardInfoValue>{payer.documents[0].number}</CardInfoValue>
          </CardInfoRow>
          )}

          <CardInfoRow>
            <CardInfoLabel>E-mail:</CardInfoLabel>
            <CardInfoValue>{payer.email}</CardInfoValue>
          </CardInfoRow>

          <CardInfoRow>
            <CardInfoLabel>Telefone:</CardInfoLabel>
            <CardInfoValue>{payer.phone_numbers[0]}</CardInfoValue>
          </CardInfoRow>

          <CardInfoRow>
            <CardInfoLabel>Endereço:</CardInfoLabel>
            <CardInfoValue>
              {address.street}, {address.street_number} - CEP: {address.zipcode}
            </CardInfoValue>
          </CardInfoRow>

          <CardInfoRow>
            <CardInfoLabel>Cidade/Estado:</CardInfoLabel>
            <CardInfoValue>
              {address.city}/{address.state}
            </CardInfoValue>
          </CardInfoRow>

        </CardBody>
      </Card>
    );
  }
}

PayerDetails.propTypes = {};

export default PayerDetails;
