/* eslint-disable no-undef */
import React, { Component } from 'react';

import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  CardInfoRow,
  CardInfoLabel,
  CardInfoValue,
} from '../styles';

class ShippingAddress extends Component {
  render() {
    const { title, address } = this.props;
    
    return (
      <Card>
        <CardHeader>
          <CardTitle>{title}</CardTitle>
        </CardHeader>
        <CardBody>

          <CardInfoRow>
            <CardInfoLabel>Endereço:</CardInfoLabel>
            <CardInfoValue>
              {address.street}, {address.street_number} - CEP: {address.zipcode}
            </CardInfoValue>
          </CardInfoRow>

          <CardInfoRow>
            <CardInfoLabel>Cidade/Estado:</CardInfoLabel>
            <CardInfoValue>
              {address.city}/{address.state}
            </CardInfoValue>
          </CardInfoRow>

        </CardBody>
      </Card>
    );
  }
}

ShippingAddress.propTypes = {};

export default ShippingAddress;
