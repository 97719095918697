import React from "react";
import propTypes from "prop-types";
import Button from "@alboompro/boom-components/general/button";

export default function ButtonComponent({
  className,
  disabled,
  htmlType,
  children,
  size,
  onClick,
  type,
  ...rest
}) {
  return (
    <Button
      className={className}
      disabled={disabled}
      htmlType={htmlType}
      children={children}
      size={size}
      onClick={onClick}
      type={type}
      {...rest}
    >
      {children && <span>{children}</span>}
    </Button>
  );
}

ButtonComponent.defaultProps = {
  disabled: false,
  size: "default",
  type: "main",
  children: "",
  className: "",
  htmlType: "button",
  onClick: () => {}
};

ButtonComponent.propTypes = {
  className: propTypes.string,
  disabled: propTypes.bool,
  size: propTypes.oneOf(["small", "default", "large"]),
  type: propTypes.oneOf(['main', 'primary']),
  children: propTypes.oneOfType([
    propTypes.string,
    propTypes.element
  ]),
  htmlType: propTypes.oneOf(["button", "reset", "submit"]),
  onClick: propTypes.func
};
