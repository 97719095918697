import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import Icon from '@alboompro/boom-components/general/icon';
import Button from '@alboompro/boom-components/general/button';
import Dropdown from '@alboompro/boom-components/data-display/dropdown/Dropdown';

import LinkPayment from '../Modal/LinkPayment';
import Social from '../Modal/Social';
import history from '../../services/history';
import Delete from '../Modal/Delete';
import Clone from '../Modal/LinkPayment/Clone';
import ButtonDropdown from '../Button/ButtonDropdown';
import Spinner from '../Spinner/Absolute';

import {
  paymentLinksRequest,
  paymentLinksPut,
  paymentLinksDelete,
} from '../../store/modules/paymentLinks/actions';

import { modalOpen, modalClose } from '../../store/modules/modal/actions';

import { currencyFormat } from '../../utils';

import searchIcon from '../../assets/static/images/icons/search@3x.png';
import { TableTitle } from '../../styles/global';
import {
  Container,
  Header,
  Wrapper,
  SearchBar,
  ReportTable,
  TRTable,
  THTable,
  TDTable,
  ShareIconContainer,
  DropdownItem,
  PageStatusContent,
  StatusOptionItem,
  BulletStatusOnline,
  BulletStatusOffline,
  StatusDesc,
  StatusTitle,
  StatusText,
  PageStatusContainer,
  Empty,
} from './styles';
import EmptyTable from '../EmptyTable';

function Options({ order, handleLinkPayment, handleClone, handleRemove }) {
  const [visible, setVisible] = useState(false);

  return (
    <ButtonDropdown
      primaryButton={{ text: 'Editar', handleClick: () => handleLinkPayment(order), width: 100 }}
      visible={visible}
      handleVisible={visible => setVisible(visible)}
    >
      <DropdownItem onClick={() => handleLinkPayment(order)}>
        <Icon
          kind="bold"
          group="interface-essential"
          category="edit"
          file="pencil-1.svg"
          size="12"
          color="#414141"
        />
        Editar
      </DropdownItem>
      <DropdownItem onClick={() => handleClone(order.id, order.name)}>
        <Icon
          kind="bold"
          group="files-folders"
          category="common-files"
          file="common-file-double-1-alternate.svg"
          size="13"
          color="#414141"
        />
        Duplicar
      </DropdownItem>
       <DropdownItem onClick={() => {history.push(`/payments?search=${order.slug}`)}}>
        <Icon
          kind="bold"
          group="money-payments-finance"
          category="accounting-billing"
          file="accounting-invoice.svg"
          size="14"
          color="#414141"
        />
        Ver pagamentos do link
      </DropdownItem> 
      <DropdownItem onClick={() => handleRemove(order.id)}>
      <Icon
          kind="regular"
          group="interface-essential"
          category="delete"
          file="bin-1.svg"
          size="13"
          color="#EF5D5D"
        />
        <span style={{ color: '#EF5D5D' }}>Excluir</span>
      </DropdownItem>
    </ButtonDropdown>
  );
}

function StatusLink({ order, handleStatusLinkPayment }) {
  const [visible, setVisible] = useState(false);
  const status = order.active;

  return (
    <Dropdown
      visible={visible}
      onVisibleChange={visible => setVisible(visible)}
      trigger="click"
      showArrow={false}
      overlayStyle={{ padding: 0 }}
      align="center"
      overlay={
        <PageStatusContent onClick={() => setVisible(false)}>
          <StatusOptionItem status={status} onClick={() => handleStatusLinkPayment(order, true)}>
            <BulletStatusOnline status={status} />
            <StatusDesc>
              <StatusTitle>Ativar</StatusTitle>
              <StatusText>Este link ficará disponível para pagamento.</StatusText>
            </StatusDesc>
          </StatusOptionItem>
          <StatusOptionItem status={status} onClick={() => handleStatusLinkPayment(order, false)}>
            <BulletStatusOffline status={status} />
            <StatusDesc>
              <StatusTitle>Desativar</StatusTitle>
              <StatusText>Este link não será mais acessível.</StatusText>
            </StatusDesc>
          </StatusOptionItem>
        </PageStatusContent>
      }
    >
      <PageStatusContainer status={status}>{status ? 'Ativo' : 'Desativado'}</PageStatusContainer>
    </Dropdown>
  );
}

export default function MyCheckout() {
  const dispatch = useDispatch();
  const { orders, getPending, deletePending } = useSelector(state => state.paymentLinks);
  const { username } = useSelector(state => state.accounts.user_data);
  const { visible, data: newLink } = useSelector(state => state.modal);

  const [socialVisible, setSocialVisible] = useState(false);
  const [cloneVisible, setCloneVisible] = useState(false);
  const [removeVisible, setRemoveVisible] = useState(false);

  const [urlShare, setUrlShare] = useState('');
  const [order, setOrder] = useState(false);
  const [nameClone, setNameClone] = useState('');
  const [search, setSearch] = useState('');
  const [idOrder, setIdOrder] = useState(null);

  useEffect(() => {
    dispatch(paymentLinksRequest());
  }, []);

  useEffect(() => {
    if (newLink) {
      setOrder(false);
    }

    !visible && dispatch(paymentLinksRequest({ search }));
  }, [visible]);

  function handleLinkPayment(order = false) {
    setOrder(order);
    dispatch(modalOpen({ type: 'Checkout', data: false }));
  }

  function handleSocial(url) {
    setSocialVisible(!socialVisible);
    setUrlShare(url);
  }

  function handleClone(id, name) {
    setCloneVisible(!cloneVisible);
    setNameClone(`Copia de ${name}`);
    setIdOrder(id);
  }

  function handleRemove(id) {
    setRemoveVisible(!removeVisible);
    setIdOrder(id);
  }

  function handleStatusLinkPayment(order, active) {
    dispatch(paymentLinksPut({ ...order, active }));
  }

  function handleSearch() {
    dispatch(paymentLinksRequest({ search }));
  }

  return (
    <Container>
      {getPending && <Spinner />}
      <Header>
        <Wrapper className="row aligm-items-center">
          <TableTitle className="col-lg-4"></TableTitle>
          <div className="col-sm-12 d-flex align-items-center filters">
            <SearchBar className="col search">
              <input
                type="text"
                placeholder="Buscar"
                onChange={e => setSearch(e.target.value)}
                onKeyDown={e =>
                  e.key === 'Enter' ||
                  (e.key === 'Backspace' && e.target.value === '' && !getPending)
                    ? handleSearch()
                    : null
                }
              />
              <button>
                <img src="https://icons.alboompro.com/v1/bold/interface-essential/search/bdbdbd/search-alternate.svg" alt="" onClick={handleSearch} />
              </button>
            </SearchBar>
          </div>
        </Wrapper>
      </Header>

      <ReportTable>
        <div className="wrap">
          <table>
            <thead>
              <tr>
                <THTable>Nome</THTable>
                <THTable>Descrição</THTable>
                <THTable>Status</THTable>
                <THTable>Valor</THTable>
                <THTable>Ações</THTable>
              </tr>
            </thead>
            {
              <tbody className={orders.length ? "" : "empty"}>
                {orders.length ? orders.map(order => {
                  const url = `alboompay.com/${username}/${order.slug}`;

                  return (
                    <TRTable key={order.id}>
                      <TDTable>
                        <div>
                          <p>{order.name}</p>
                          <div style={{ position: 'relative' }}>
                            <a target="_blank" href={`https://www.${url}`}>
                              {url}
                            </a>
                            <ShareIconContainer onClick={() => handleSocial(`https://www.${url}`)}>
                              <Icon
                                kind="bold"
                                group="interface-essential"
                                category="share"
                                file="share.svg"
                                size="14"
                                color="#8D8D8D"
                              />
                            </ShareIconContainer>
                          </div>
                        </div>
                      </TDTable>
                      <TDTable>
                        <p>{order.description}</p>
                      </TDTable>
                      <TDTable>
                        <StatusLink
                          order={order}
                          handleStatusLinkPayment={handleStatusLinkPayment}
                        />
                      </TDTable>
                      <TDTable>
                        <p>{currencyFormat(order.amountTotal / 100)}</p>
                      </TDTable>
                      <TDTable>
                        <div>
                          <Options
                            order={order}
                            handleLinkPayment={handleLinkPayment}
                            handleClone={handleClone}
                            handleRemove={handleRemove}
                          />
                        </div>
                      </TDTable>
                    </TRTable>
                  );
                }):(
                  <EmptyTable search={search} text={"Nenhum link de pagamento"} />
                )
                }
              </tbody>
            }
          </table>
        </div>
      </ReportTable>
      {visible && (
        <LinkPayment order={order} visible={visible} onClose={() => dispatch(modalClose())} />
      )}
      <Social
        title="Compartilhar link de pagamento"
        description="Link de pagamento"
        visible={socialVisible}
        slug={urlShare}
        onClose={handleSocial}
      />

      <Clone visible={cloneVisible} id={idOrder} name={nameClone} onClose={handleClone} />
      <Delete
        visible={removeVisible}
        title="Excluir Link de Pagamento"
        description="Tem certeza que deseja excluir esse Link de Pagamento?"
        loading={deletePending}
        onClose={handleRemove}
        onConfirm={() => dispatch(paymentLinksDelete(idOrder))}
      />
    </Container>
  );
}
