import axios from '../../axios';
import { toast } from 'react-toastify';
export default {
  get: async () => {
    return await axios.get('/accounts/default-fees');
  },
  put: async ({ fee_id, antecipation_automatic }) => {
    try {
      return await axios.post('/accounts/update/fees', { fee_id, antecipation_automatic });
    } catch (e) {
      const message = e.message || 'Não foi possível atualizar a taxa, tente novamente mais tarde';
      toast.error(message);
      return Promise.reject(e);
    }
  },
};
