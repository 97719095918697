import { takeLatest, put, call, all } from 'redux-saga/effects';
import {
  putAccountsRequestSuccess,
  putAccountsRequestFailure,
  getAccountsRequestSuccess,
  getAccountsRequestFailure,
  generateSMSCodeSuccess,
  generateSMSCodeFailure,
  insertPhoneAndBankRequestSuccess,
  insertPhoneAndBankRequestFailure
} from './actions';
import Accounts from '../../../services/core/http/accounts';
import { toast } from 'react-toastify';
import { bankAccountsRequest } from '../bankAccounts/actions';

export function* putRequest({ id, payload }) {
  try {
    const response = yield call(Accounts.put, id, payload);
    toast.success('Configurações alteradas');
    yield put(putAccountsRequestSuccess(response));
  } catch (error) {
    console.log(error);
    toast.error(
      'Não foi possível atualizar as configurações, por favor tente novamente mais tarde.',
    );
    yield put(putAccountsRequestFailure());
  }
}

export function* getRequest({ id }) {
  try {
    const response = yield call(Accounts.get, id);
    yield put(getAccountsRequestSuccess(response));
  } catch (error) {
    console.log(error);
    toast.error(
      'Não foi possível capturar os dados da conta, por favor tente novamente mais tarde.',
    );
    yield put(getAccountsRequestFailure());
  }
}

export function* generateSMSCode(payload){
  try {
    const response = yield call(Accounts.generateSMSCode, payload.payload);
    yield put(generateSMSCodeSuccess(response));
    toast.success('Código de verificação enviado');
  } catch (error) {
    console.log(error);
    toast.error(
      'Não foi possível capturar os dados da conta, por favor tente novamente mais tarde.',
    );
    yield put(generateSMSCodeFailure());
  }
}


export function* insertPhoneAndBank({ payload, bankId}) {
  try {
    yield call(Accounts.insertPhoneAndBank, { ...payload, bank_account_id: bankId});
    yield put(insertPhoneAndBankRequestSuccess());
    yield put(bankAccountsRequest());
    toast.success("Conta bancária atualizada");
  } catch (error) {
    toast.error(error.response.data.data.message)
    yield put(insertPhoneAndBankRequestFailure());
  }
}

export default all([
  takeLatest('@accounts/PUT_REQUEST', putRequest),
  takeLatest('@accounts/GET_REQUEST', getRequest),
  takeLatest('@accounts/PUT_GENERATE_SMS', generateSMSCode),
  takeLatest('@accounts/INSERT_PHONE_BANK_REQUEST', insertPhoneAndBank),
]);
