import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 768px) {
    overflow-x: scroll;
  }

  div {
    flex: 1;
    border-radius: 4%;
    border-left: 1px solid #eaeaea;
    border-right: 1px solid #eaeaea;
    border-bottom: 1px solid #eaeaea;

    & + div {
      margin-left: 12px;
    }

    @media (max-width: 768px) {
      flex: 3 0 0;
      min-width: 90%;
      align-self: stretch;
    }
  }
`;

export const Header = styled.header`
  width: 100%;
  padding: 10.5px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  background-color: ${props => (props.status === 'complete' ? '#37A9FB' : '#EAEAEA')};
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 12px;
  letter-spacing: 0em;
  text-align: center;
  color: ${props => (props.status === 'complete' ? '#ffffff' : '#212121')};
`;

export const Content = styled.article`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 15px;
  color: #252525;
  margin-bottom: 5px;
  flex: 1;

  @media (max-width: 768px) {
    min-height: 160px;
  }

  img {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    border: 2px solid #ffffff;
    background-color: #ffffff;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.15));
  }

  h1 {
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: 14px;
    letter-spacing: 0em;
    text-align: center;
    margin-top: 15px;

    @media (max-width: 768px) {
      font-size: 12px;
    }
  }

  p {
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: center;
    margin-top: 8px;
    margin-bottom: 0px;
    max-width: 220px;

    @media (max-width: 768px) {
      font-size: 12px;
    }
  }
`;

export const Footer = styled.footer`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 82px;
  padding: 14px;
  background-color: ${props => (props.status === 'complete' ? '#E9F6FF' : '#EAEAEA')};
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  color: #212121;

  h1 {
    font-size: 11px;
    font-style: normal;
    font-weight: 500;
    line-height: 12px;
    letter-spacing: 0em;
    text-align: center;
  }

  p {
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    line-height: 12px;
    letter-spacing: 0em;
    text-align: center;
    margin-bottom: 0px;

    a {
      color: #35a7f8 !important;
      text-decoration: underline !important;
      cursor: pointer;
    }

    &:first-child {
      margin-top: 6px;
    }

    &:last-child {
      margin-top: 2px;
      margin-bottom: 0;
    }
  }
`;

export const Alert = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  padding: 13px 22px;
  background-color: #f2f6f9;
  border-radius: 10px;

  img {
    width: 20px;
    height: 20px;
    background-color: #ffffff;

    @media (max-width: 768px) {
      align-self: flex-start;
    }
  }

  p {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 1.3;
    text-align: left;
    margin: 0 0 0 14px;

    a {
      color: #35a7f8 !important;
      text-decoration: underline !important;
      cursor: pointer;
    }
  }
`;
