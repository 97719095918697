import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { useSelector } from 'react-redux';
import ProductsMenu from '@alboom/products-menu';
import history from '../../services/history';
import { Container } from './styles';
window.SAWPOPUP = false;

export default function Menu() {
  const { pathname } = history.location;
  const selected = pathname.substr(-1) === '/' ? pathname.slice(0, -1) : pathname;
  const IconsSrc = 'https://icons.alboompro.com/v1';

  const {
    user_data: { avatar_url, doc, fullname, username, verify_status: status, verified_at, raffle_code },
    core_user: { beta_tests },
    loaded,
  } = useSelector(state => state.accounts);

  // Formatting data (Before Menu)
  const firstInitial = fullname ? fullname.split(' ')[0].substr(0, 1) : '..';
  const secondInitial = fullname ? fullname.split(' ')[1].substr(0, 1) : '.';
  const initials = (firstInitial + secondInitial).toUpperCase();
  const docFormatted = doc
    ? doc.length === 11
      ? `${doc.substr(0, 3)}.${doc.substr(3, 3)}.${doc.substr(6, 3)}-${doc.substr(9, 2)}`
      : `${doc.substr(0, 2)}.${doc.substr(2, 3)}.${doc.substr(5, 3)}/${doc.substr(
          8,
          4,
        )}-${doc.substr(12, 2)}`
    : 'Carregando...';

  useEffect(() => {
    if (loaded) {
      setTimeout(() => {
        const popup = document.getElementById('popup');
        if (popup) {
          if (!window.SAWPOPUP) {
            popup.style.display = 'block';
            popup.classList.add('fadeAnimation');
            setTimeout(() => {
              window.SAWPOPUP = true;
              popup.style.display = 'none';
              popup.classList.remove('fadeAnimation');
            }, 4500);
          }
        }
      }, 200);
    }
  }, [loaded]);

  const beforeMenu = `<div class="menuBefore">
    <div class="userAvatar">
      ${
        !avatar_url
          ? `<div onClick="window.reactHistory.push('/account-alboom-pay')" class="noAvatar">${initials}</div>`
          : `<img onClick="window.reactHistory.push('/account-alboom-pay')" src="${avatar_url}" alt=${
              fullname ? `Avatar de ${fullname}` : 'Carregando...'
            } />`
      }
      <div
        class="badge"
        style="background-color: ${status === 'verified' ? '#1b9dfa' : '#FF6E6E'}"
        onmouseover="getElementById('popup').style.display = 'block'"
      >
      ${
        status === 'verified'
          ? `<img
            class="verifiedIcon"
            src="https://icons.alboompro.com/v1/bold/interface-essential/form-validation/FFFFFF/check.svg"
            alt="Conta verificada"
          />`
          : `
          <img
            src="https://icons.alboompro.com/v1/bold/interface-essential/alerts/FF6E6E/alert-circle.svg"
            alt="Conta não verificada"
          />
        `
      }

        ${
          status === 'verified'
            ? `
          <div class="accountStatus noAnimation">
            <div>
              <span class="verifiedSpan">
                <img
                  class="verifiedIcon"
                  src="https://icons.alboompro.com/v1/bold/interface-essential/form-validation/1b9dfa/check.svg"
                  alt="Conta verificada"
                />
              </span>
              <h3>Conta verificada</h3>
            </div>
            <p>Desde ${moment(verified_at).format('DD/MMMM/YYYY')}</p>
          `
            : `<div id="popup" class="accountStatus">
              <div>
                <span>
                  <img
                    class="verifiedIcon"
                    src="https://icons.alboompro.com/v1/bold/interface-essential/alerts/FFFFFF/alert-circle.svg"
                    alt="Conta não verificada"
                  />
                </span>
                <h3>Conta não verificada</h3>

                <div id="closeButton" class="closeButton" onClick="getElementById('popup').style.display = 'none'; window.SAWPOPUP = true">
                  <img
                      src="https://icons.alboompro.com/v1/bold/interface-essential/form-validation/FFFFFF/close.svg"
                      alt="Fechar pop-up"
                    />
                </div>
              </div>
              <p>
                Verifique sua conta e torne a sua experiência no Alboom Pay ainda melhor e mais
                segura.
              </p>
              <a onClick="window.reactHistory.push('/account-alboom-pay')">Iniciar verificação</a>
          `
        }
        </div>
      </div>
    </div>
    <div onClick="window.reactHistory.push('/account-alboom-pay')" class="userInfo">
      <h3 title="${fullname || 'Carregando...'}">${fullname || 'Carregando...'}</h3>
      <p>${docFormatted}</p>
      <p>${username ? `@${username}` : 'Carregando...'}</p>
      <a onClick="window.reactHistory.push('/account-alboom-pay')">
        <img
          class="verifiedIcon"
          src="https://icons.alboompro.com/v1/bold/interface-essential/edit/1b9dfa/pencil-1.svg"
          alt="Editar dados"
        />
      </a>
    </div>
  </div>`;
  
  let afterMenu = `<div class="menuAfter">
  <div class="menuAfter-Wrapper">
    <div class="menuAfter-IconWrapper">
      <img width="13" height="13" src="https://icons.alboompro.com/v1/bold/interface-essential/alerts/646464/information-circle.svg" />
    </div>
    <div class="menuAfter-textWrapper">
      <a onClick="window.reactHistory.push('/tax-receipt')">Tarifas e Taxas</a>
    </div>
  </div>
</div>`;

  // if(raffle_code){
  //   afterMenu = afterMenu + `<div class="globalBoxRaffle">
  //   <h3>Campanha MacbookPro M1</h3>
  //   <p>Seu número da Sorte</p>
  //   <h2>${raffle_code}</h2>
  //   <p>Data do sorteio: 05/12/2022</p>
  //   <a href="https://lp.alboompro.com/sorteiomacbook/regulamento" target="_blank">Regulamento</p>
  //   </div>`;
  // }
  let menuConfig = {
    header: {
      logo: 'https://bifrost.alboompro.com/products/alboompay/v2/AlboomPay-blue-v23.svg',
      logoMobile: 'https://bifrost.alboompro.com/products/alboompay/AlboomPay-faviconFull2.svg',
      logoLink: '/',
      label: {
        color: '#44ae70',
        text: '',
      },
      desc: 'Receba pagamentos online',
    },
    before: beforeMenu,
    after: afterMenu,
    contacts_beta: beta_tests.includes('contacts'),
    fotto_panel: beta_tests.includes('fotto_panel'),
    selected,
    active_product: 'pay',
    country: 'BR',
    language: 'pt-BR',
    menuProps: [
      {
        colorTheme: '#1B9DFA',
        title: 'Meu dinheiro',
        items: [
          {
            icon: `${IconsSrc}/regular/money-payments-finance/accounting-billing/212121/accounting-document.svg`,
            label: 'Saldo e atividades',
            action: () => history.push('/dashboard'),
            key: '/dashboard',
          },
          {
            icon: `${IconsSrc}/light/money-payments-finance/cash-payments/212121/cash-payment-bills.svg`,
            label: 'Meus Pagamentos',
            action: () => history.push('/payments'),
            key: '/payments',
          },
          {
            icon: `${IconsSrc}/regular/money-payments-finance/accounting-billing/212121/accounting-calculator.svg`,
            label: 'Calculadora',
            action: () => history.push('/payment-simulate'),
            key: '/payment-simulate',
          },
          {
            icon: `${IconsSrc}/bold/content/notes/212121/notes-paper-text.svg`,
            label: 'Extrato da conta',
            action: () => history.push('/account-statement'),
            key: '/account-statement',
          },
          // {
          //   icon: `${IconsSrc}/regular/money-payments-finance/accounting-billing/212121/accounting-calculator.svg`,
          //   label: 'Contatos',
          //   action: () => window.alb("contact", "openModalContacts", {}, {}),
          //   key: '/contacts',
          // },
        ],
      },
      {
        colorTheme: '#796dbb',
        title: 'Cobrança',
        items: [
          {
            icon: `${IconsSrc}/light/phones-mobile-devices/content-actions/212121/phone-action-cash-1.svg`,
            label: 'Solicitar pagamento',
            action: () => history.push('/payment-request'),
            key: '/payment-request',
          },
        ],
      },
      {
        colorTheme: '#44ae70',
        title: 'Venda Online',
        items: [
          {
            icon: `${IconsSrc}/bold/interface-essential/link-unlink/212121/hyperlink-2.svg`,
            label: 'Links de pagamento',
            action: () => history.push('/checkout'),
            key: '/checkout',
          },
          {
            icon: `${IconsSrc}/regular/interface-essential/select/212121/cursor-double-click-2.svg`,
            label: 'Botão de pagame..',
            action: () => history.push('/payment-button'),
            key: '/payment-button',
            // flag: {
            //   label: 'NOVO',
            //   color: '#1c9dfa'
            // },
          }
        ],
      },
      {
        colorTheme: '#A86D16',
        title: 'Configurações',
        items: [
          {
            icon: `${IconsSrc}/regular/users/geomertic-close-up-single-user-actions-neutral/212121/single-neutral-actions.svg`,
            label: 'Conta Alboom Pay',
            action: () => history.push('/account-alboom-pay'),
            key: '/account-alboom-pay',
          },
          {
            icon: `${IconsSrc}/regular/money-payments-finance/finance-savings/212121/saving-bank.svg`,
            label: 'Contas bancárias',
            action: () => history.push('/bank-accounts'),
            key: '/bank-accounts',
          },
          {
            icon: `${IconsSrc}/regular/money-payments-finance/credit-card-payments/212121/credit-card-1.svg`,
            label: 'Métodos de pagamento',
            action: () => history.push('/config-payments'),
            key: '/config-payments',
          },
          {
            icon: `${IconsSrc}/regular/money-payments-finance/cash-payments/212121/cash-payment-wallet.svg`,
            label: 'Antecipação',
            action: () => history.push('/config-anticipation'),
            key: '/config-anticipation',
          },
        ],
      },
    ],
  };

  // if(beta_tests.includes('payment_button')) {
  //   menuConfig.menuProps[2].items.push({
  //     icon: `${IconsSrc}/regular/interface-essential/select/000000/cursor-double-click-2.svg`,
  //     label: 'Botão de pagamento',
  //     action: () => history.push('/payment-button'),
  //     key: '/payment-button',
  //   },)
  // }

  useEffect(() => {
    ProductsMenu.init('#menu-alboom-pay', menuConfig);
  }, [menuConfig, pathname]);

  return <Container id="menu-alboom-pay" />;
}
