import React, { memo, useRef, useEffect } from "react";
import { Container } from "./styles";

const ContactPicker = ({ onSelect }) => {
  const inputRef = useRef();

  useEffect(() => {
    if (inputRef.current && window.alb) {
      window.alb(
        "contact",
        "picker",
        { target: inputRef.current },
        {
          onSelect: (contact) => {
            if (onSelect) onSelect(contact);
          },
        }
      );
    }
  }, [inputRef, onSelect]);

  return (
    <Container>
      <div className="picker" ref={inputRef}></div>
    </Container>
  );
};

export default memo(ContactPicker);