import styled from 'styled-components';

export const Methods = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  align-items: center;
  margin-bottom: 30px;

  .method {
    margin-left: 40px;
  }

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;

    .method {
      margin-left: 0;
    }
  }

`;

export const Settings = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const SelectContainer = styled.div`
  width: 47%;
  
  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const Label = styled.p`
  width: 100%;
  font-size: 15px;
  margin-bottom: 10px;
  padding
`;
