import styled from 'styled-components';

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-content: center;
  text-align: left;
  margin: 0 0 10px 0;
  background-color: #f8f8f8;
  border-radius: 3px;
  padding: 10px 15px;

  @media screen and (max-width: 767px) {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }

  strong {
    font-size: 16px;
  }

  .CustomerInfo {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 10px;
  }

  .CustomerName {
    font-weight: 400;
    font-size: 18px;
    color: #282828;
    margin-bottom: 5px;

    max-width: 28vw;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    @media screen and (max-width: 768px) {
      max-width: 50vw;
    }
  }

  .CustomerEmail {
    font-size: 15px;
    color: #616161;

    max-width: 28vw;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    @media screen and (max-width: 768px) {
      max-width: 50vw;
    }
  }

  .name {
    display: grid;
    grid-gap: 5px;
  }
  .buttons > a,
  .buttons > button {
    margin: 0 5px;
    background: #ededed;
    border-radius: 50px;
    display: inline-block;
    padding: 6px;
    border: none;
  }
  .buttons > button {
    padding: 8px;
  }
  a.WhatsApp {
    background: #46ae70;
  }
`;

export const Container = styled.div`
  font-size: 14px;
  padding: 20px 0;

  > img {
    margin-bottom: 30px;
  }

  h2 {
    color: #282828;
    font-size: 26px;
    font-weight: 400;
    margin-bottom: 10px;
    text-align: center;
  }
  h3 {
    color: #282828;
    font-weight: 400;
    font-size: 16px;
    margin-bottom: 40px;
    text-align: center;
  }
  > p {
    margin-bottom: 15px;
    text-align: left;
    line-height: 1.3;
  }

  .done {
    background: #46ae70;
    border-radius: 50%;
    padding: 10px;
    margin: 30px;
  }
`;

export const RequestAnotherButton = styled.button`
  background-color: #50aff4;
  color: #ffffff;
  padding: 13px 40px;
  border-radius: 3px;
  text-decoration: none;
  font-size: 16px;
  margin-top: 40px;
  border: 0;
  &:hover {
    opacity: 0.8;
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  margin-top: 10px;
`;

export const Button = styled.a`
  text-align: center;
  margin-left: 10px;
  padding: 6px;
  border-radius: 3px;
  text-decoration: none;
  cursor: pointer;

  &:hover {
    background-color: #efefef;
    text-decoration: none;
  }

  > img {
    margin-bottom: 7px;
  }
`;

export const ButtonText = styled.p`
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  color: #282828;
  margin-bottom: 0;
`;

export const BackToPayments = styled.a`
  text-align: center;
  font-size: 15px;
  font-weight: 400;
  color: #282828;
  margin-top: 25px;
  text-decoration: none;
  display: block;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;
