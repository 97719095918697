import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  display: flex;
  border-radius: 10px;
  flex-direction: column;
  background: #fff;
  position: relative;
  border: 1px solid #ebebeb;
`;

export const Header = styled.div`
  padding: 20px 30px;
  img {
    width: 16px;
    height: 16px;
  }
  input {
    width: 100%;
    border: none;
    background: none;
  }
  > * {
    flex-basis: 1;
    flex-grow: 0;
  }
`;

export const Wrapper = styled.div`
  justify-content: space-between;
  align-items: center;
  .filters {
    max-width: 369px;
    padding: 0;

    @media (max-width: 768px) {
      flex-direction: column;
      align-items: stretch;
    }
  }
`;

export const SearchBar = styled.div`
  position: relative;
  padding-right: 10px;
  width: 100%;
  color: #373636;
  display: flex;
  align-items: center;

  @media screen and (max-width: 767px) {
    &.search {
      width: 100%;
      padding: 0;
    }
  }

  button {
    position: absolute;
    right: 26px;
    background: transparent;
    border: none;
    outline-color: transparent;
  }
  input {
    width: 100%;
    padding: 10px 22px;
    background-color: #ffffff;
    border: 1px solid #dedede;
    border-radius: 3px;
    &:focus {
      box-shadow: 0 0 2px 1px #50aff4;
    }
  }
`;

export const ReportTable = styled.div`
  position: relative;
  padding: 10px 30px;

  .wrap {
    /* height: calc(100vh - 320px); */
    min-height: 400px;
    overflow: auto;
  }
  .empty {
    position: absolute;
    left: 50%; 
    top: 50%;
    transform: translate(-50%, -50%);
  }

  table {
    width: 100%;
  }
  thead {
    border-bottom: 1px solid #eee;
    background: #fff;
  }
`;

export const THTable = styled.th`
  color: #262626;
  font-size: 12px;
  font-weight: 500;
  text-transform: uppercase;
  text-align: left;
  height: 30px;
  position: sticky;
  background: #fff;
  top: 0;
  z-index: 999;

  @media (max-width: 768px) {
    z-index: 2;
  }

  &:first-child {
    padding-left: 0;
  }

  &:last-child {
    padding-right: 0;
    text-align: center;
  }

  &:nth-child(3) {
    text-align: center;
  }

  &:nth-child(4) {
    text-align: right;
  }

  &:nth-child(5) {
    text-align: right;
    /* padding-right: 20px; */
  }
`;

export const TRTable = styled.tr`
  height: 75px;
`;

export const TDTable = styled.td`
  padding: 10px 0;
  border-bottom: 1px solid #eee;

  p {
    margin: 0;
  }

  &:nth-child(1) {
    max-width: 100%;
    width: 450px !important;

    > div {
      display: flex;
      flex-direction: column;

      > div {
        display: flex;
        align-items: center;
        margin-top: -10px;
      }

      p {
        font-size: 16px;
        color: #262626;
        margin-bottom: 5px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        max-width: 380px;
      }

      a {
        font-size: 14px;
        color: #44adf8;
        text-decoration: underline;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        max-width: 340px;
      }
    }
  }

  &:nth-child(2) {
    max-width: 100%;
    width: 280px !important;

    p {
      font-size: 15px;
      color: #262626;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      max-width: 100%;
      width: 250px !important;
    }
  }

  &:nth-child(3) {
    max-width: 100%;
    width: 155px !important;
    text-align: center;

    p {
      margin: 0;
    }
  }

  &:nth-child(4) {
    max-width: 100%;
    width: 130px !important;
    text-align: right;
  }

  &:nth-child(5) {
    padding-left: 20px;
    /* padding-right: 20px; */

    > div {
      display: flex;
      justify-content: flex-end;
    }
  }
`;

export const ShareIconContainer = styled.div`
  padding: 7px;
  border-radius: 3px;
  margin-left: 10px;
  margin-top: 2px;

  cursor: pointer;

  &:hover {
    background-color: #efefef;
  }
`;

export const Icon = styled.div`
  width: 54px;
  height: 54px;
  border-radius: 50%;
  background: #f6f5f6;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 27px;
    height: 27px;
  }
`;

export const IconWrapper = styled.span`
  border-radius: 3px;
  padding: 8px;
  display: inline-block;
  &:hover {
    background-color: #eaf6ff;
  }
`;

export const Empty = styled.div`
  font-size: 18px;
  color: #5e5e5e;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

// Button
export const WrapperPublish = styled.div`
  display: flex;
  margin-left: 10px;

  > button {
    background-color: transparent;
    color: #44adf8;
    border: 1px solid #50aff4;
    height: 40px;

    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    margin-right: 1px;
    width: 165px;
    font-size: 16px;
  }

  > div button {
    background-color: transparent;
    color: #44adf8;
    border: 1px solid #50aff4;
    margin-left: -2px;
    height: 40px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    width: 40px;
    padding: 0;

    img {
      max-width: none;
    }
  }
`;

export const ListDropdown = styled.div`
  .dropdown-placement {
    > div {
      &:before {
        display: none;
      }
    }
  }
`;

// Options
export const DropdownContent = styled.ul`
  display: flex;
  flex-direction: column;
  padding: 5px;
  width: 240px;

  @media (max-width: 768px) {
    width: calc(100vw - 75px);
    max-width: 280px;
  }
`;

export const DropdownItem = styled.div`
  display: flex;
  align-items: center;
  padding: 7px 10px;
  color: #414141;
  font-size: 14px;
  transition: 0.2s;
  cursor: pointer;
  border-radius: 3px;
  position: relative;
  height: 36px;

  img {
    margin-right: 12px;
  }

  ${props =>
    props.disabled &&
    `
    cursor: not-allowed;
    opacity: 0.31;
  `}

  &:last-child {
    margin-top: 10px;

    &::before {
      content: '';
      display: block;
      width: 100%;
      height: 1px;
      background-color: #e4e4e4;
      position: absolute;
      top: -5px;
      left: 0;
    }

    &:hover {
      background-color: #FFD7D0;
    }
  }

  ${props => props.active && 'font-weight: 500;'}

  &:hover {
    background-color: #f1f9ff;
  }
`;

// Status Dropdown
export const PageStatusContent = styled.div`
  background-color: #fff;
`;

export const StatusOptionItem = styled.div`
  padding: 10px 15px 15px 20px;
  display: flex;
  cursor: pointer;
  &:hover {
    background-color: #f2f6f9;
  }
  label {
    display: flex;
    cursor: pointer;
  }
`;

export const BulletStatus = styled.div`
  position: relative;
  padding-right: 15px;
  &:before,
  &:after {
    content: '';
    display: block;
    border-radius: 50%;
  }
  &:before {
    position: absolute;
    z-index: 2;
    width: 8px;
    height: 8px;
    background-color: #3bd078;
    top: 2px;
    left: 2px;
  }
  &:after {
    z-index: 1;
    position: relative;
    width: 12px;
    height: 12px;
    border: 1px solid #b7b7b7;
  }
`;

export const BulletStatusOnline = styled(BulletStatus)`
  &:before {
    background-color: ${props => (props.status ? '#3bd078' : 'transparent')};
  }
  &:after {
    border: 1px solid #b7b7b7;
  }
`;

export const BulletStatusOffline = styled(BulletStatus)`
  &:before {
    background-color: ${props => (!props.status ? '#FF5252' : 'transparent')};
  }
  &:after {
    border: 1px solid #ff8686;
  }
`;

export const StatusDesc = styled.div`
  text-align: left;
`;

export const StatusTitle = styled.div`
  margin-bottom: 5px;
  font-size: 14px;
  font-weight: 500;
  color: #212121;
`;

export const StatusText = styled.div`
  font-size: 12px;
  font-weight: 400;
  color: #5e5e5e;
`;

export const MenuItem = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  &:not(:last-child) {
    padding: 0 25px;
  }

  &:last-child {
    button {
      padding: 16px 35px 18px;
      border: none;
      cursor: pointer;
      &:first-child {
        color: #4b4b4b;
      }
      &:last-child {
        background-color: #45ae70;
        color: #fff;
      }
    }
  }
`;

export const triangle = `
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 6px solid black;
`;

export const PageStatusContainer = styled(MenuItem)`
  user-select: none;
  padding: 7px 20px;
  border-radius: 2px;

  &:hover {
    background-color: #f2f6f9;
  }

  &:before {
    content: '';
    display: block;
    width: 13px;
    height: 13px;
    background-color: ${props => (props.status ? '#3bd078' : '#FF5252')};

    margin-right: 12px;
    border-radius: 50%;
  }
  &:nth-child(1) {
    display: flex;
    align-items: center;
    cursor: pointer;
    height: 32px;
    &:after {
      content: '';
      display: block;
      ${triangle};
      margin-left: 12px;
    }
  }
  &:nth-child(2) {
    top: 100%;
    right: 15px;
    margin-top: 10px;
    z-index: 1;
    box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.15);
  }
`;
