export function balanceRequest() {
  return {
    type: "@balance/BALANCE_REQUEST",
  };
}

export function balanceDetailsRequest() {
  return {
    type: "@balance/BALANCE_DETAILS_REQUEST",
  };
}

export function balanceSuccess(data) {
  return {
    type: "@balance/BALANCE_SUCCESS",
    payload: data
  };
}

export function balanceDetailsSuccess(data) {
  return {
    type: "@balance/BALANCE_DETAILS_SUCCESS",
    payload: data
  };
}

export function balanceError() {
  return {
    type: "@balance/BALANCE_REQUEST_FAIL",
  };
}

export function payablesRequest(date) {
  return {
    type: "@balance/PAYABLES_REQUEST",
    date
  };
}

export function payablesSuccess(data) {
  return {
    type: "@balance/PAYABLES_SUCCESS",
    payload: data
  };
}

export function payablesError() {
  return {
    type: "@balance/PAYABLES_REQUEST_FAIL",
  };
}

export function payablesDetailsRequest(payload) {
  return {
    type: "@balance/PAYABLES_DETAILS_REQUEST",
    payload
  };
}

export function payablesDetailsSuccess(payload) {
  return {
    type: "@balance/PAYABLES_DETAILS_SUCCESS",
    payload
  };
}

export function payablesDetailsError() {
  return {
    type: "@balance/PAYABLES_DETAILS_REQUEST_FAIL",
  };
}
