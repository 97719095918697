import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction"; // needed for dayClick
import { Container } from "./styles";
import { currencyFormat } from "../../utils";
import Spinner from "../Spinner/Absolute";
import moment from "moment";
import { payablesDetailsRequest } from "../../store/modules/balance/actions";

// must manually import the stylesheets for each plugin
import "@fullcalendar/core/main.css";
import "@fullcalendar/daygrid/main.css";

export default function () {
  const calendarComponentRef = useRef();
  const dispatch = useDispatch();
  const { loading, date, data: payables } = useSelector(state => state.balance.payables)
  const [data, setData] = useState({
    calendarWeekends: true,
    calendarEvents: [
      // initial event data
      { title: "Event Now", start: new Date() }
    ],
    backgroundEvents:
    {
      start: moment(new Date()).format('YYYY-MM-DD'),
      end: moment(new Date()).format('YYYY-MM-DD'),
      rendering: 'background'
    }

  });

  const handleDateClick = ({ date }) => {
    setData({
      ...data, backgroundEvents: {
        start: date,
        allDay: true,
        rendering: 'background',
        classNames: ['background']
      }
    })
    const payments = payables.filter(p => moment(p.payment_date).format('YYYY-MM-DD') === moment(date).format('YYYY-MM-DD')).map( e => e.transaction_id && e.transaction_id);
    dispatch(payablesDetailsRequest(payments));
  }

  useEffect(() => {
    setData(d => ({
      ...d, calendarEvents: payables.map(p => ({
        title: currencyFormat(p.amount / 100),
        start: new Date(p.payment_date)
      }))
    }))
  }, [payables])

  useEffect(() => {
    calendarComponentRef.current.getApi().gotoDate(date)
  }, [date])

  return (
    <Container>
      {loading && <Spinner />}
      <FullCalendar
        defaultView="dayGridMonth"
        locale="pt-br"
        header={{
          left: "",
          center: "",
          right: ""
        }}
        eventTextColor="black"
        eventBackgroundColor="transparent"
        eventBorderColor="transparent"
        height={340}
        aspectRatio={5}
        displayEventTime={false}
        plugins={[dayGridPlugin, interactionPlugin]}
        ref={calendarComponentRef}
        weekends={data.calendarWeekends}
        events={[...data.calendarEvents, data.backgroundEvents]}
        dateClick={handleDateClick}
        // eventClick={handleDateClick}
        columnHeaderFormat={{ weekday: 'long' }}
        showNonCurrentDates={false}
      />
    </Container>
  );
}
