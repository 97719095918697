import { all } from 'redux-saga/effects';
import orders from './orders/sagas';
import auth from './auth/sagas';
import balance from './balance/sagas';
import activities from './activities/sagas';
import bankAccounts from './bankAccounts/sagas';
import banks from './banks/sagas';
import accounts from './accounts/sagas';
import withdraw from './withdraw/sagas';
import paymentLinks from './paymentLinks/sagas';
import paymentButtons from './paymentButtons/sagas';

export default function* rootSaga() {
  return yield all([
    orders,
    auth,
    balance,
    activities,
    bankAccounts,
    banks,
    accounts,
    withdraw,
    paymentLinks,
    paymentButtons
  ]);
}
