import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { toast } from 'react-toastify';
import Modal from "@alboompro/boom-components/data-display/modal";
import Button from "@alboompro/boom-components/general/button";
import Input from "../../Input";
import Select from "../../Select";
import { ContentBox, FooterWrapper, StyledForm, Col, Row } from "./styles";
import { modalClose } from "../../../store/modules/modal/actions";
import { banksRequest } from "../../../store/modules/banks/actions";
import { getAccountsRequest } from "../../../store/modules/accounts/actions";
import { bankAccountsPost, bankAccountsPut, bankAccountsCodeStore } from "../../../store/modules/bankAccounts/actions";
import { insertPhoneAndBankRequest } from "../../../store/modules/accounts/actions";
import Spinner from '../../Spinner/Absolute';
import { maskSsn, maskRn } from '../../../utils';
import Checkbox from '../../Checkbox';
import { Verify } from './Verify';

export default function () {
  const inputRef = useRef();
  const [isVerify, setIsVerify] = useState();
  const [isVerifingPhone, setIsVerifingPhone] = useState(false);
  const [verification, setVerification] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState();
  const [verifyingMessage, setVerifyingMessage] = useState(false);
  const { data: m, visible } = useSelector(state => state.modal);
  const modalType = useSelector(state => state.modal.type);
  var { list: banks, loading } = useSelector(state => state.banks)
  var { user_data, account_id } = useSelector(state => state.accounts)
  banks = !!banks.length ? banks.map(b => ({ value: b.bank_code, label: <span><img src={b.icon} alt={b.name} width="25" style={{ marginRight: "10px" }} />{b.name}</span> })) : [];
  const dispatch = useDispatch();
  const [data, setData] = useState({
    default: false,
    bank_code: '',
    agency: '',
    agency_dv: '',
    account: '',
    account_dv: '',
    legal_name: '',
    document_number: null,
    documentFormatted: '',
    type: 'conta_corrente'
  });
  const {
    default: isDefault,
    agency,
    agency_dv,
    account,
    account_dv,
    legal_name,
    documentFormatted,
    bank_code,
    document_number,
    type
  } = data;

  const bankAccountTypes = [
    { value: "conta_corrente", label: 'Conta corrente' },
    { value: "conta_corrente_conjunta", label: 'Conta corrente conjunta' },
    { value: "conta_poupanca", label: 'Conta poupança' },
    { value: "conta_poupanca_conjunta", label: 'Conta poupança conjunta' }
  ];

  const handleClickButton = () => {
    setVerification(true);
    if(phoneNumber){
      dispatch(bankAccountsCodeStore({}))
    }
  }

  const submitBankAccount = async () => {
    try {
      const toSubmit = {
        default: isDefault,
        bank_code,
        agency,
        agency_dv: agency_dv ? agency_dv : "0",
        account,
        account_dv,
        legal_name,
        document_number: document_number.toString(),
        type,
        phone: phoneNumber || null,
        phone_code: parseInt(inputRef.current.value)
      }
      if (m) {
        if(isVerifingPhone){
          dispatch(insertPhoneAndBankRequest(toSubmit, m.id));
        }
        else{
          dispatch(bankAccountsPut(toSubmit, m.id));
        }
        setVerification(false);
        setIsVerify(true);
        return dispatch(modalClose())
      }
      
      if(isVerifingPhone){
        dispatch(insertPhoneAndBankRequest(toSubmit));
      }
      else{
        dispatch(bankAccountsPost(toSubmit));
      } 

      setVerifyingMessage(false);
      setVerification(false);
      setIsVerify(true);
      dispatch(modalClose());
    } catch {
      setIsVerify(false);
      setVerifyingMessage(false);
      toast.error("Falha ao verificar conta bancária");
    }
  }

  useEffect(() => {
    if (!banks) dispatch(banksRequest())
  }, [banks, dispatch])

  useEffect(() => {
    if (!user_data.username) dispatch(getAccountsRequest(account_id))
  }, [user_data, dispatch, account_id])

  useEffect(() => {
    if (user_data.phone) {
      setPhoneNumber(user_data.phone);
    }
  }, [user_data])

  useEffect(() => {
    if (m) {
      setData({
        default: m.default || false,
        bank_code: m.bank_code || '',
        agency: m.agency || '',
        agency_dv: m.agency_dv || '',
        account: m.account || '',
        account_dv: m.account_dv || '',
        legal_name: user_data.fullname || '',
        document_number: user_data.type === "person" ? user_data.doc : user_data.company_doc || '',
        documentFormatted: user_data.type === "person" ? maskSsn(user_data.doc) : maskRn(user_data.company_doc) || '',
        type: m.type || 'conta_corrente',
      })
    }
  }, [m, user_data])

  function getTitle() {
    if(isVerifingPhone){
      return "Verificação de código";
    }
    return !!m ? "Editar Conta bancária" : "Adicionar Conta bancária"
  }

  return (
    <Modal
      title={getTitle()}
      visible={modalType === "bankAccounts" && visible}
      width={550}
      backdropStyle={{ backgroundColor: "#000000b8" }}
      backdropClosable={false}
      background={"#FFF"}
      bodyStyle={{ padding: "40px 25px", backgroundColor: "#f6fafe" }}
      onClose={() => dispatch(modalClose())}
      closeButton={true}
      zIndex={999}
    >
      <div>
        {verification ?
          <Verify
            isVerify={isVerify}
            handleClickButton={handleClickButton}
            submitBankAccount={submitBankAccount}
            phoneNumber={phoneNumber}
            setPhoneNumber={setPhoneNumber}
            inputRef={inputRef}
            verifyingMessage={verifyingMessage}
            isVerifingPhone={isVerifingPhone}
            setIsVerifingPhone={setIsVerifingPhone}
            setVerification={setVerification}
          />
          :
          <ContentBox>
            {loading ? <Spinner /> :
              <StyledForm onSubmit={handleClickButton}>
                <Row>
                  <Col width="100%" mb="30px">
                    <span className="label custom">Banco</span>
                    <Select options={banks} required initialValue={bank_code} onChange={(obj) => setData({ ...data, bank: obj, bank_code: obj.value })} />
                  </Col>
                </Row>
                <Row>
                  <Col width="100%">
                    <Input required name="name" label="Nome do titular" disabled maxLength="30" value={legal_name} readOnly />
                  </Col>
                </Row>
                <Row>
                  <Col width="100%">
                    <Input required name="ssn" label="CPF/CNPJ" disabled value={documentFormatted} readOnly />
                  </Col>
                </Row>
                <Row>
                  <Col width="68%" mr="2%">
                    <Input required name="agency" label="Número da agência" maxLength="4" value={agency.toString()} onChange={(e) => setData({ ...data, agency: e.target.value })} />
                  </Col>
                  <Col width="30%">
                    <Input name="agency_dv" label="Dígito (se houver)" maxLength="1" value={agency_dv.toString()} onChange={(e) => setData({ ...data, agency_dv: e.target.value })} />
                  </Col>
                </Row>
                <Row>
                  <Col width="68%" mr="2%">
                    <Input required name="account" label="Número da conta" minLength="4" maxLength="11" value={account.toString()} onChange={(e) => setData({ ...data, account: e.target.value })} />
                  </Col>
                  <Col width="30%">
                    <Input required name="account_dv" label="Dígito" maxLength="1" value={account_dv.toString()} onChange={(e) => setData({ ...data, account_dv: e.target.value })} />
                  </Col>
                </Row>
                <Row>
                  <Col width="100%" mb="30px">
                    <span className="label custom">Tipo</span>
                    <Select
                      options={bankAccountTypes}
                      required
                      disabled
                      initialValue={type}
                      onChange={(obj) => setData({ ...data, type: obj.value })}
                    />
                  </Col>
                </Row>
                <Row mb="30px">
                  <Col>
                    <Checkbox
                      name="isDefault"
                      label="  "
                      checked={isDefault}
                      value={isDefault}
                      onChange={() => setData({ ...data, default: !isDefault })}
                    >
                      <span>Definir como banco principal</span>
                    </Checkbox>
                    <FooterWrapper>
                      <Button onClick={() => dispatch(modalClose())} className="buttonDefault">
                        Cancelar
                      </Button>
                      <Button htmlType="submit" className="buttonBlue">
                        Continuar
                      </Button>
                    </FooterWrapper>
                  </Col>
                </Row>
              </StyledForm>
            }
          </ContentBox>
        }
      </div>
    </Modal>
  )
}
