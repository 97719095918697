import styled from 'styled-components';

export const DropdownContent = styled.div`
  background-color: #fff;
  border-radius: 10px;
  padding: 7px;
`;

export const DropdownItem = styled.div`
  padding: 10px 12px;
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 13px;
  border-radius: 3px;
  margin-bottom: 2px;
  position: relative;
  opacity: ${props => (props.inactive_since ? '0.7' : '1')};

  cursor: ${props => props.inactive_since && 'not-allowed'};

  img {
    margin-right: 10px;
  }

  &:nth-child(2) {
    margin-bottom: 6px;
  }

  &:nth-child(3) {
    color: #EF5D5D;
    margin-bottom: 0;

    &:before {
      content: '';
      display: block;
      width: 100%;
      height: 1px;
      background-color: #e4e4e4;
      position: absolute;
      top: -3px;
      left: 0;
    }

    &:hover {
      background-color: ${props => (props.inactive_since ? 'transparent' : '#fae7e4')};
    }
  }

  &:hover {
    background-color: #f1f9ff;
  }
  label {
    display: flex;
    cursor: pointer;
  }
`;

export const DropdownIconWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;
  cursor: pointer;
  border-radius: 2px;
  padding: 10px;
  background-color: transparent;

  > img {
    max-width: 16px;
  }

  &:hover {
    background-color: #d6e9f6;
  }

  > div {
    &:nth-child(2) {
      top: 100%;
      right: 15px;
      margin-top: 10px;
      z-index: 1;
    }
  }

  @media screen and (max-width: 768px) {
    padding: 5px 4px;
  }
`;