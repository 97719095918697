import React, { useRef, useEffect } from "react";
import PropTypes from "prop-types";

import { useField } from "@rocketseat/unform";
import * as FontAwesome from "react-icons/md";

export default function RadioInput({
  label,
  type,
  customMessage,
  infoSize,
  value,
  info,
  name,
  icon,
  size,
  iconStyle,
  children,
  options,
  required,
  onChange,
  ...rest
}) {
  const ref = useRef(null);
  const { fieldName, registerField, defaultValue, error } = useField(name);

  const InfoIcon = FontAwesome[info];

  useEffect(() => {
    if (!ref.current) return;
    registerField({
      name: fieldName,
      ref: ref.current,
      path: "value"
    });
  }, [ref.current, fieldName]); //eslint-disable-line

  return (
    <label className="radio-custom" htmlFor={fieldName} icon={icon}>
      {error && <span>{error}</span>}
      <input
        onChange={onChange}
        value={value}
        name={fieldName}
        type={type}
        ref={ref}
        id={fieldName}
        aria-label={fieldName}
        defaultValue={defaultValue}
        hidden
        {...rest}
      />
      <div className="radioContainer">
        <span className="checkmark" />
      </div>
      <span className="label">
        {children[0]}
        {children}
        <div>
          {info && <InfoIcon size={infoSize} />}
          <div className="message">{customMessage}</div>
        </div>
      </span>
    </label>
  );
}

RadioInput.propTypes = {
  onChange: PropTypes.func,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  icon: PropTypes.string,
  info: PropTypes.string,
  type: PropTypes.string,
  customMessage: PropTypes.string,
  size: PropTypes.number,
  infoSize: PropTypes.number,
  iconStyle: PropTypes.objectOf(PropTypes.any),
  children: PropTypes.element,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

RadioInput.defaultProps = ({ name }) => ({
  icon: "",
  customMessage: "Hey Brother!",
  label: "",
  size: null,
  iconStyle: null,
  children: null
});
