import styled from 'styled-components';
import media from '../../styles/media';

export const Container = styled.div`
  width: ${props => props.width};
  display: inline-block;
  margin-right: ${props => (props.mr ? props.mr : '0px')};

  @media ${media.small} {
    width: ${props => (props.width_mb ? props.width_mb : '100%')};
    max-width: ${props => (props.width_mb ? props.width_mb : '100%')};
    display: ${props => (props.display_mb ? props.display_mb : 'inline-block')};
    white-space: nowrap;

  }
`;
