import React from 'react'
import { set, get } from '../../../utils/cookies.js';

import { Container, Content, ContentLeft, Title, Button, CloseButton, Strong, Stripe, BreakMobileLine } from './styles'

class PromoBlackHold extends React.PureComponent {
  state = {
    visible: true,
    image: null,
    title: null,
    list: false,
    subtitle: null,
    validate: null,
    button: null,
    link: null,
    rules: null,
    load: null,
  }

  handleCloseButton = () => {
    this.setState({ visible: false })
    set('pay-black-hold', 'disabled', 4)
  }

  render() {
    const { visible } = this.state

    return (
      visible &&
      !get('pay-black-hold') && (
        <Stripe>
          <Container>
            <Content>
              <ContentLeft>
                <Title>
                  <BreakMobileLine>
                    <Strong>Novidade:</Strong>Os brindes da
                    <Strong style={{ marginLeft: '4px' }}>Black Hold</Strong>
                  </BreakMobileLine>
                  <BreakMobileLine>voltaram por tempo limitado ⏰</BreakMobileLine>
                </Title>
              </ContentLeft>
              <Button href="https://lp.alboompro.com/promo-outubro?utm_campaign=promo-outubro-tarja-painel" target="_blank">
                Saiba Mais
              </Button>
            </Content>
            <CloseButton onClick={this.handleCloseButton}>&#10005;</CloseButton>
          </Container>
        </Stripe>
      )
    )
  }
}

export default PromoBlackHold
