import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getDateToParts } from '../../utils';
import { balanceDetailsRequest, balanceRequest } from '../../store/modules/balance/actions';
import { modalOpen } from '../../store/modules/modal/actions';
import ModalWithdraw from '../../components/Modal/Withdraw';
import Icon from '@alboompro/boom-components/general/icon';
import moment from 'moment';
import { Container, Card, MoreInfo, SeeMoreInfo, Loading } from './styles';

export default function({ hideAmount }) {
  const [thisMonthYear, setThisMonthYear] = useState({});
  const [lastMonthYear, setLastMonthYear] = useState({});
  const [displaySkeleton, setDisplaySkeleton] = useState(true);
  const [isOpened, setIsOpened] = useState(false);

  useEffect(() => {
    setThisMonthYear(getDateToParts());
    setLastMonthYear(getDateToParts(-1));
    setTimeout(() => {
      setDisplaySkeleton(false);
    }, 1500);
  }, []);

  const dispatch = useDispatch();
  const {
    loaded,
    loadingDetails,
    balanceAvailable,
    balanceAwaiting,
    balanceTotal,
    received,
    pending,
    transactions,
  } = useSelector(state => state.balance);
  const { created_at } = useSelector(state => state.auth);

  function handleWithdraw() {
    //if (!balanceAvailable) return toast.error("Você deve ter saldo disponível para solicitar o saque.");
    dispatch(modalOpen({ type: 'withdraw' }));
  }

  useEffect(() => {
    dispatch(balanceRequest());
    dispatch(balanceDetailsRequest());
  }, [dispatch]);

  return (
    <Container>
      <div className="row">
        <div className="col-lg-4 col-md-12">
          <Card>
            <main>
              {loaded && !displaySkeleton ? (
                <p>Saldo Disponível</p>
              ) : (
                <Loading width="80%" height="28px" />
              )}
              {loaded && !displaySkeleton ? (
                <strong className={hideAmount && 'noBefore'}>
                  {hideAmount
                    ? '*****'
                    : balanceAvailable &&
                      balanceAvailable.toLocaleString('pt-br', {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}
                </strong>
              ) : (
                <Loading width="90%" height="48px" mt="20px" />
              )}
              {loaded && !displaySkeleton ? (
                <button type="button" onClick={handleWithdraw}>
                  Realizar saque
                </button>
              ) : (
                <Loading width="70%" height="48px" mt="20px" />
              )}
            </main>
            <div>
              {loaded && !displaySkeleton ? (
                <p>Saldo a liberar</p>
              ) : (
                <Loading width="40%" height="18px" />
              )}
              {loaded && !displaySkeleton ? (
                <strong>
                  {hideAmount
                    ? '*****'
                    : `R$ ${balanceAwaiting &&
                        balanceAwaiting.toLocaleString('pt-br', {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}`}
                </strong>
              ) : (
                <Loading width="40%" height="18px" />
              )}
            </div>
            <div>
              {loaded && !displaySkeleton ? (
                <p>Total em conta</p>
              ) : (
                <Loading width="40%" height="18px" />
              )}
              {loaded && !displaySkeleton ? (
                <strong>
                  {hideAmount
                    ? '*****'
                    : `R$ ${balanceTotal &&
                        balanceTotal.toLocaleString('pt-br', {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}`}
                </strong>
              ) : (
                <Loading width="40%" height="18px" />
              )}
            </div>
          </Card>
        </div>
        <div className="col-lg-4 col-md-12">
          <Card className="remove">
            <main>
              {!loadingDetails && !displaySkeleton ? (
                <p>
                  Recebidos este mês{' '}
                  <span>
                    ({thisMonthYear.month}/{thisMonthYear.year})
                  </span>
                </p>
              ) : (
                <Loading width="90%" height="28px" />
              )}

              {!loadingDetails && !displaySkeleton ? (
                <strong className={hideAmount && 'noBefore'}>
                  {hideAmount
                    ? '*****'
                    : received.this_month &&
                      received.this_month.toLocaleString('pt-br', {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}
                </strong>
              ) : (
                <Loading width="90%" height="48px" mt="28px" />
              )}

              {!loadingDetails && !displaySkeleton ? (
                <span>
                  {transactions.last_month_count ? transactions.last_month_count : 0}{' '}
                  {transactions.last_month_count === 1 ? 'transação' : 'transações'}
                </span>
              ) : (
                <Loading width="50%" height="19px" mt="40px" />
              )}
            </main>
            <div>
              {!loadingDetails && !displaySkeleton ? (
                <p>
                  Mês anterior{' '}
                  <span>
                    ({lastMonthYear.month}/{lastMonthYear.year})
                  </span>
                </p>
              ) : (
                <Loading width="40%" height="18px" />
              )}

              {!loadingDetails && !displaySkeleton ? (
                <strong>
                  {hideAmount
                    ? '*****'
                    : `R$ ${received.last_month &&
                        received.last_month.toLocaleString('pt-br', {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}`}
                </strong>
              ) : (
                <Loading width="40%" height="18px" />
              )}
            </div>
            <div>
              {!loadingDetails && !displaySkeleton ? (
                <p>Recebidos no ano ({moment().format('YYYY')})</p>
              ) : (
                <Loading width="40%" height="18px" />
              )}

              {!loadingDetails && !displaySkeleton ? (
                <strong>
                  {hideAmount
                    ? '*****'
                    : `R$ ${received.last_year &&
                        received.last_year.toLocaleString('pt-br', {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}`}
                </strong>
              ) : (
                <Loading width="40%" height="18px" />
              )}
            </div>
          </Card>
        </div>
        <div className="col-lg-4 col-md-12">
          <Card className="remove">
            <main style={{ padding: '30px 30px 20px' }}>
              {!loadingDetails && !displaySkeleton ? (
                <p>Pendente de recebimento </p>
              ) : (
                <Loading width="90%" height="28px" />
              )}
              {!loadingDetails && !displaySkeleton ? (
                <strong className={hideAmount && 'noBefore'}>
                  {hideAmount
                    ? '*****'
                    : pending.total &&
                      pending.total.toLocaleString('pt-br', {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}
                </strong>
              ) : (
                <Loading width="85%" height="48px" mt="30px" style={{ marginBottom: '12px' }} />
              )}
            </main>
            <div>
              {!loadingDetails && !displaySkeleton ? (
                <p>Solicitações pendentes </p>
              ) : (
                <Loading width="60%" height="18px" />
              )}
              {!loadingDetails && !displaySkeleton ? (
                <strong>{pending.count}</strong>
              ) : (
                <Loading width="20%" height="18px" />
              )}
            </div>
          </Card>
          <Card style={{ marginTop: '8px' }} className="remove">
            <section>
              {!loadingDetails && !displaySkeleton ? (
                <p>
                  Total recebido desde o ínicio <span>({moment(created_at).format('MMM/YY')})</span>
                </p>
              ) : (
                <Loading width="80%" height="19px" />
              )}
              {!loadingDetails && !displaySkeleton ? (
                <strong>
                  {hideAmount
                    ? '*****'
                    : `R$ ${received.all_time &&
                        received.all_time.toLocaleString('pt-br', {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}`}
                </strong>
              ) : (
                <Loading width="50%" height="19px" mt="20px" />
              )}
            </section>
          </Card>
        </div>
        <div className="col-lg-4 col-md-12">
          {isOpened && (
            <>
              <MoreInfo>
                <div>
                  <section>
                    {!loadingDetails && !displaySkeleton ? (
                      <p>
                        Recebidos este mês{' '}
                        <span>
                          ({thisMonthYear.month}/{thisMonthYear.year})
                        </span>
                      </p>
                    ) : (
                      <Loading width="50%" height="19px" />
                    )}

                    {!loadingDetails && !displaySkeleton ? (
                      <strong>
                        {hideAmount
                          ? '*****'
                          : `R$ ${transactions.this_month &&
                              transactions.this_month.toLocaleString('pt-br', {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })}`}
                      </strong>
                    ) : (
                      <Loading width="30%" height="19px" />
                    )}
                  </section>

                  <section>
                    {!loadingDetails && !displaySkeleton ? (
                      <p>
                        Recebidos mês anterior{' '}
                        <span>
                          ({lastMonthYear.month}/{lastMonthYear.year})
                        </span>
                      </p>
                    ) : (
                      <Loading width="50%" height="19px" />
                    )}

                    {!loadingDetails && !displaySkeleton ? (
                      <strong>
                        {hideAmount
                          ? '*****'
                          : `R$ ${transactions.last_month &&
                              transactions.last_month.toLocaleString('pt-br', {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })}`}
                      </strong>
                    ) : (
                      <Loading width="30%" height="19px" />
                    )}
                  </section>

                  <section>
                    {!loadingDetails && !displaySkeleton ? (
                      <p>Recebidos no ano ({moment().format('YYYY')})</p>
                    ) : (
                      <Loading width="50%" height="19px" />
                    )}

                    {!loadingDetails && !displaySkeleton ? (
                      <strong>
                        {hideAmount
                          ? '*****'
                          : `R$ ${received.last_year &&
                              received.last_year.toLocaleString('pt-br', {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })}`}
                      </strong>
                    ) : (
                      <Loading width="30%" height="19px" />
                    )}
                  </section>

                  <section>
                    {!loadingDetails && !displaySkeleton ? (
                      <p>Recebimentos esse mês</p>
                    ) : (
                      <Loading width="50%" height="19px" />
                    )}

                    {loaded && !displaySkeleton ? (
                      <strong>
                        {transactions.last_month_count ? transactions.last_month_count : 0}
                      </strong>
                    ) : (
                      <Loading width="30%" height="19px" />
                    )}
                  </section>
                </div>
              </MoreInfo>
              <MoreInfo style={{ marginTop: '10px' }}>
                <div>
                  <section>
                    {!loadingDetails && !displaySkeleton ? (
                      <p>Pendente de recebimento</p>
                    ) : (
                      <Loading width="50%" height="19px" />
                    )}

                    {!loadingDetails && !displaySkeleton ? (
                      <strong>
                        {hideAmount
                          ? '*****'
                          : `R$ ${pending.total &&
                              pending.total.toLocaleString('pt-br', {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })}`}
                      </strong>
                    ) : (
                      <Loading width="30%" height="19px" />
                    )}
                  </section>

                  <section>
                    {!loadingDetails && !displaySkeleton ? (
                      <p>Solicitações pendentes</p>
                    ) : (
                      <Loading width="50%" height="19px" />
                    )}

                    {!loadingDetails && !displaySkeleton ? (
                      <strong>{pending.count}</strong>
                    ) : (
                      <Loading width="30%" height="19px" />
                    )}
                  </section>
                </div>
              </MoreInfo>
              <MoreInfo style={{ marginTop: '10px' }}>
                <main>
                  {!loadingDetails && !displaySkeleton ? (
                    <p>
                      Total recebido desde o início{' '}
                      <span>({moment(created_at).format('MMM/YY')})</span>
                    </p>
                  ) : (
                    <Loading width="85%" height="19px" />
                  )}

                  {!loadingDetails && !displaySkeleton ? (
                    <strong>{hideAmount ? '*****' : `R$ ${received.all_time}`}</strong>
                  ) : (
                    <Loading width="50%" height="19px" mt="15px" />
                  )}
                </main>
              </MoreInfo>
            </>
          )}
          <SeeMoreInfo isOpened={isOpened} onClick={() => setIsOpened(!isOpened)}>
            <p>{isOpened ? 'Ver menos informações' : 'Ver mais informações'}</p>
            <button className={isOpened && 'reverse'}>
              <Icon
                kind="bold"
                group="arrows-diagrams"
                category="arrows"
                file="arrow-down-1.svg"
                size="12"
                color="#494949"
              />
            </button>
          </SeeMoreInfo>
        </div>
      </div>
      <ModalWithdraw />
    </Container>
  );
}
