export function bankAccountsRequest(data) {
  return {
    type: "@bankAccounts/REQUEST",
    payload: { data }
  };
}

export function bankAccountsSuccess(payload) {
  return {
    type: "@bankAccounts/SUCCESS",
    payload
  };
}

export function bankAccountsFailure() {
  return {
    type: "@bankAccounts/FAILURE"
  };
}

export function bankAccountsPost(payload) {
  return {
    type: "@bankAccounts/POST",
    payload
  };
}

export function bankAccountsPostSuccess() {
  return {
    type: "@bankAccounts/POST_SUCCESS",
  };
}

export function bankAccountsPostFailure() {
  return {
    type: "@bankAccounts/POST_FAILURE",
  };
}

export function bankAccountsPut(payload, id) {
  return {
    type: "@bankAccounts/PUT",
    payload,
    id
  };
}

export function bankAccountsPutAndWithdraw(payload, id) {
  return {
    type: "@bankAccounts/PUT_AND_WITHDRAW",
    payload,
    id
  };
}

export function bankAccountsPutAndWithdrawSuccess() {
  return {
    type: "@bankAccounts/PUT_AND_WITHDRAW_SUCCESS",
  };
}

export function bankAccountsPutAndWithdrawFailure() {
  return {
    type: "@bankAccounts/PUT_AND_WITHDRAW_FAILURE",
  };
}

export function bankAccountsPutSuccess() {
  return {
    type: "@bankAccounts/PUT_SUCCESS",
  };
}

export function bankAccountsPutFailure() {
  return {
    type: "@bankAccounts/PUT_FAILURE",
  };
}

export function bankAccountsDelete(id) {
  return {
    type: "@bankAccounts/DELETE",
    id
  };
}

export function bankAccountsDeleteSuccess() {
  return {
    type: "@bankAccounts/DELETE_SUCCESS",
  };
}

export function bankAccountsDeleteFailure() {
  return {
    type: "@bankAccounts/DELETE_FAILURE",
  };
}

export function bankAccountsCodeStore(payload) {
  return {
    type: "@bankAccounts/CODE_STORE",
    payload
  };
}
