import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  EditImage,
  UserInfo,
  Name,
  Document,
  Username,
  SocialReason,
  Button,
  Initials,
} from './styles';
import Icon from '@alboompro/boom-components/general/icon';
import Skeleton from '@alboompro/boom-components/feedback/skeleton';
import '../../scripts/avatar';
import { maskSsn, maskRn } from '../../utils';

export default function AvatarInput({ onChange }) {
  const {
    loading,
    user_data: { avatar_url, fullname, doc, type, username },
  } = useSelector(state => state.accounts);
  const { Line, Circle } = Skeleton;

  const [avatar, setAvatar] = useState('');

  useEffect(() => {
    setAvatar(avatar_url);
  }, [avatar_url]);

  async function handleChange() {
    console.log("chamou cloud");
    window.alb(
      'cloud',
      'open',
      {
        multipleSelect: false,
      },
      {
        onFinish: e => {
          const data = e.shift();
          let obj = data.versions.filter(v => v.alias === 'thumb');
          obj = !!obj.length ? obj.shift().url : data.versions[0].url;
          onChange(obj);
          setAvatar(obj);
        },
      },
    );
  }

  const firstInitial = fullname ? fullname.split(' ')[0].substr(0, 1) : '';
  const secondInitial = fullname ? fullname.split(' ')[1].substr(0, 1) : '';
  const initials = (firstInitial + secondInitial).toUpperCase();

  return (
    <div className="avatarInput">
      {loading ? (
        <>
          <EditImage>
            <Circle animated size={110} />
          </EditImage>
          <UserInfo>
            <Line animated width={360} height={31} />
            <Line animated width={153} height={18} />
            <Line animated width={153} height={18} />
          </UserInfo>
        </>
      ) : (
        <EditImage>
          <label htmlFor="avatar">
            {loading ? (
              ''
            ) : avatar ? (
              <img src={avatar} alt={`Foto de perfil de ${fullname}`} onClick={handleChange} />
            ) : (
              <Initials onClick={handleChange}>{initials} </Initials>
            )}
            <Button type="button" onClick={handleChange}>
              Trocar
            </Button>
          </label>
        </EditImage>
      )}
      {loading ? (
        ''
      ) : (
        <UserInfo>
          {type === 'person' ? (
            <>
              <Name>{fullname}</Name>
              <Document>CPF: {maskSsn(doc)}</Document>
              <Username>@{username}</Username>
            </>
          ) : (
            <>
              <SocialReason>Razão Social</SocialReason>
              <Name>
                {fullname}
              </Name>
              <Document>CNPJ: {maskRn(doc)}</Document>
              <Username>@{username}</Username>
            </>
          )}
        </UserInfo>
      )}
    </div>
  );
}
