import React, { useEffect } from 'react';
import { Switch, Route, Router, Redirect } from 'react-router-dom';
import { ToastContainer, Slide } from 'react-toastify';
import history from './services/history';
import { Initial, Full } from './layouts';
import { useSelector, useDispatch } from 'react-redux';

import { getAccountsRequest } from './store/modules/accounts/actions';

import Icon from '@alboompro/boom-components/general/icon';

//Pages
import Auth from './pages/Auth';
import CreateAccount from './pages/CreateAccount';
import CreateAccountSuccess from './pages/CreateAccountSuccess';
import CreateAccountFail from './pages/CreateAccountFail';
import Checkout from './pages/Checkout';
import PaymentRequest from './pages/PaymentRequest';
import Dashboard from './pages/Dashboard';
import BankAccounts from './pages/BankAccounts';
import TaxReceipt from './pages/TaxReceipt';
import NotFound from './pages/NotFound';
import Error from './pages/Error';
import Payments from './pages/Payments';
import Activities from './pages/Activities';
import AccountAlboomPay from './pages/AlboomAccount';
import PaymentConfig from './pages/PaymentConfig';
import Anticipation from './pages/Anticipation';
import Balance from './pages/Balance';
import Calculator from './pages/Calculator';
import AccountStatement from './pages/AccountStatement';
import PaymentButton from './pages/PaymentButton';

const GetUserData = () => {
  const dispatch = useDispatch();
  const { account_id } = useSelector(state => state.accounts);

  useEffect(() => {
    if (account_id) {
      dispatch(getAccountsRequest(account_id));
    }
  }, [account_id, dispatch]);
};

/** Alboom Pro private route for new customers */
const AlProRoute = ({ component: Component, layout: Layout, ...rest }) => {
  const { alprotoken, alpaytoken, alpaynewcustomer } = useSelector(state => state.auth);
  GetUserData();

  return (
    <>
      {alpaytoken ? (
        <Redirect to="/dashboard" />
      ) : !alprotoken ? (
        <Auth />
      ) : (
        alpaynewcustomer && (
          <Route
            {...rest}
            render={props => (
              <Layout>
                <Component {...props} />
              </Layout>
            )}
          />
        )
      )}
    </>
  );
};

/** Alboom Pay private route */
const AlPayRoute = ({ component: Component, layout: Layout, ...rest }) => {
  const { alpaytoken, alpaynewcustomer } = useSelector(state => state.auth);
  GetUserData();

  return (
    <>
      {alpaynewcustomer ? (
        <Redirect to="/create" />
      ) : !alpaytoken ? (
        <Auth />
      ) : (
        <Route
          {...rest}
          render={props => (
            <Layout {...rest}>
              <Component {...props} />
            </Layout>
          )}
        />
      )}
    </>
  );
};

export default function Routes() {
  return (
    <Router history={history}>
      <Switch>
        <AlProRoute path="/" exact component={CreateAccount} layout={Initial} />
        <AlProRoute path="/create" exact component={CreateAccount} layout={Initial} />
        <AlProRoute path="/create/failed" exact component={CreateAccountFail} layout={Initial} />
        <AlProRoute
          path="/create/success"
          exact
          component={CreateAccountSuccess}
          layout={Initial}
        />
        <AlPayRoute path="/checkout" exact component={Checkout} layout={Full} />
        <AlPayRoute path="/payment-request" exact component={PaymentRequest} layout={Full} />
        <AlPayRoute path="/payment-request/:contactEncode" exact component={PaymentRequest} layout={Full} />
        <AlPayRoute path="/dashboard" exact component={Dashboard} layout={Full} />
        <AlPayRoute path="/tax-receipt" exact component={TaxReceipt} layout={Full} />
        <AlPayRoute path="/payments" exact component={Payments} layout={Full} />
        <AlPayRoute path="/account-statement" exact component={AccountStatement} layout={Full} />
        <AlPayRoute path="/activities" exact component={Activities} layout={Full} />
        <AlPayRoute path="/bank-accounts" exact component={BankAccounts} layout={Full} />
        <AlPayRoute path="/account-alboom-pay" exact component={AccountAlboomPay} layout={Full} />
        <AlPayRoute path="/config-payments" exact component={PaymentConfig} layout={Full} />
        <AlPayRoute path="/config-anticipation" exact component={Anticipation} layout={Full} />
        <AlPayRoute path="/balance" exact component={Balance} layout={Full} />
        <AlPayRoute path="/payment-simulate" exact component={Calculator} layout={Full} />
        <AlPayRoute path="/payment-button" exact component={PaymentButton} layout={Full} />
        <Route path="/session-expired" exact component={Error} />
        <Route component={NotFound} />
      </Switch>
      <ToastContainer
        autoClose={3000}
        style={{ fontSize: 15, top: '60px', minHeight: '48px' }}
        draggablePercent={30}
        hideProgressBar={true}
        transition={Slide}
        toastClassName="tostify-custom-class"
        progressClassName="custom-progress-bar"
        bodyClassName="tostify-custom-body"
        closeButton={
          <Icon
            kind="bold"
            group="interface-essential"
            category="form-validation"
            file="close.svg"
            size="11"
            color="#ffffff"
          />
        }
      />
    </Router>
  );
}
