import styled from 'styled-components';

// Button
export const WrapperPublish = styled.div`
  display: flex;
  margin-left: 10px;
  justify-content: flex-end;

  > button {
    background-color: transparent;
    color: #44adf8;
    border: 1px solid #50aff4;
    height: 40px;

    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    margin-right: 1px;
    width: 165px;
    font-size: 16px;
    padding: 0;

    &:hover {
      background-color: #e9f2f8;
    }
  }

  > div button {
    background-color: transparent;
    color: #44adf8;
    border: 1px solid #50aff4;
    margin-left: -2px;
    height: 40px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    width: 40px;
    padding: 0;

    &:hover {
      background-color: #e9f2f8;
    }

    img {
      max-width: none;
    }
  }

  @media (max-width: 768px) {
    justify-content: flex-start;
    margin-left: 0;
  }

`;

export const ListDropdown = styled.div`
  .dropdown-placement {
    > div {
      &:before {
        display: none;
      }
    }
  }
`;

// Options
export const DropdownContent = styled.ul`
  display: flex;
  flex-direction: column;
  padding: 5px;
  width: 240px;

  @media (max-width: 768px) {
    width: calc(100vw - 75px);
    max-width: 280px;
  }
`;

export const DropdownItem = styled.div`
  display: flex;
  align-items: center;
  padding: 7px 10px;
  color: #414141;
  font-size: 15px;
  transition: 0.2s;
  cursor: pointer;
  border-radius: 3px;
  position: relative;
  height: 36px;

  img {
    margin-right: 15px;
  }

  ${props =>
    props.disabled &&
    `
    cursor: not-allowed;
    opacity: 0.31;
  `}

  &:last-child {
    margin-top: 10px;

    &::before {
      content: '';
      display: block;
      width: 100%;
      height: 1px;
      background-color: #e4e4e4;
      position: absolute;
      top: -5px;
      left: 0;
    }

    &:hover {
      background-color: ${props => (props.inactive_since ? 'transparent' : '#fae7e4')};
    }

    opacity: ${props => (props.inactive_since ? '0.7' : '1')};

    cursor: ${props => props.inactive_since && 'not-allowed'};
  }

  ${props => props.active && 'font-weight: 500;'}

  &:hover {
    background-color: #f1f9ff;
  }
`;
