import produce from 'immer';

const INITIAL_STATE = {
  loading: false,
  loaded: false,
  account_id: null,
  payments_settings: {
    invoice: {
      enabled: null,
      expiration_in_days: null,
    },
    credit_card: {
      enabled: null,
      installments_no_fees: null,
      installments_fees_payer: null,
    },
    pix: {
      enabled: null,
    },
  },
  user_data: {
    owner_id: null,
    fullname: null,
    email: null,
    doc: null,
    company_doc: null,
    type: null,
    address: null,
    neighborhood: null,
    postal_code: null,
    complement: null,
    number: null,
    raffle_code: null,
    city: {
      name: null,
      state_name: null,
      country_name: null,
      ibge: null,
      gia: null,
    },
    display_name: null,
    username: null,
    phone: null,
    verification_request_at: null,
    verified_at: null,
    verified_by: null,
    verify_status: 'not_verified',
    cc_total_transactions: null,
    last_cc_transaction_at: null,
    exchange_url: null,
    have_exchange_policy: false,
    privacy_url: null,
    policy_url: null,
    facebook: null,
    instagram: null,
    google_business: null,
    linkedin: null,
    twitter: null,
    reclameaqui: null,
    telephone: null,
    rg: null,
    gender: null,
    company_description: null,
    hide_address: false,
    contact_name: null,
    contact_phone: null,
    contact_phone2: null,
    contact_email: null,
    website: null,
    verification_doc: null,
    selling_intends: null,
    segments: {
      parent: null,
      child: null,
    },
    fees: {
      antecipation_automatic: null,
      antecipation_days: null,
      antecipation_fee_monthly: null,
      antecipation_fees: null,
      credit_fee: null,
      credit_installments_fee: null,
      debit_fee: null,
      fee_id: null,
      invoice_fee: null,
      transaction_fee: null,
    },
    birth_date: null,
    have_website: false,
    use_account_info: false,
    responsible_name: null,
    responsible_doc: null,
    fees: null,
    allow_antecipation: null,
  },
  core_user: {
    id: null,
    email: null,
    token_alboom: null,
    beta_tests: [],
  },
};

export default function core(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case '@accounts/AFTER_SIGNIN': {
        draft.payments_settings = action.payload.payments_settings;
        draft.account_id = action.payload.account_id;
        draft.core_user = action.payload.user;
        break;
      }
      case '@accounts/PUT_REQUEST':
        draft.loading = true;
        break;
      case '@accounts/GET_REQUEST':
        draft.loading = true;
        break;
      case '@accounts/PUT_FAILURE':
        draft.loading = false;
        break;
      case '@accounts/INSERT_PHONE_BANK_REQUEST':
        draft.loading = true;
        break;
      case '@accounts/PUT_GENERATE_SMS':
        draft.status = action.payload ? action.payload.status : null;;
        break;
      case '@accounts/PUT_SUCCESS':
        let _u = action.payload.data.data.account;
        draft.loading = false;
        draft.payments_settings = _u.payments_settings;
        draft.user_data = {
          owner_id: _u.owner_id,
          fullname: _u.fullname,
          email: _u.email,
          doc: _u.doc,
          company_doc: _u.company_doc,
          type: _u.type,
          address: _u.address,
          neighborhood: _u.neighborhood,
          postal_code: _u.postal_code,
          complement: _u.complement,
          number: _u.number,
          city: {
            name: _u.city.name,
            state_name: _u.city.state_name,
            country_name: _u.city.country_name,
            ibge: _u.city.ibge,
            gia: _u.city.gia,
          },
          display_name: _u.display_name,
          username: _u.username,
          phone: _u.phone,
          avatar_url: _u.avatar_url,
          exchange_url: _u.exchange_url,
          have_exchange_policy: _u.have_exchange_policy,
          privacy_url: _u.privacy_url,
          policy_url: _u.policy_url,
          facebook: _u.facebook,
          instagram: _u.instagram,
          whatsapp: _u.whatsapp,
          google_business: _u.google_business,
          linkedin: _u.linkedin,
          twitter: _u.twitter,
          reclameaqui: _u.reclameaqui,
          verified_at: _u.verified_at,
          verified_by: _u.verified_by,
          verify_status: _u.verify_status,
          telephone: _u.telephone,
          rg: _u.rg,
          gender: _u.gender,
          company_description: _u.company_description,
          hide_address: _u.hide_address,
          contact_name: _u.contact_name,
          contact_phone: _u.contact_phone,
          contact_phone2: _u.contact_phone2,
          contact_email: _u.contact_email,
          website: _u.website,
          selling_intends: _u.selling_intends,
          segments: {
            parent: _u.segments.parent,
            child: _u.segments.child,
          },
          birth_date: _u.birth_date,
          have_website: _u.have_website,
          use_account_info: _u.use_account_info,
          responsible_name: _u.responsible_name,
          responsible_doc: _u.responsible_doc,
        };
        break;
      case '@accounts/GET_SUCCESS':
        draft.loading = false;
        draft.loaded = true;
        let user_data = action.payload.data.data.accounts;
        draft.user_data = {
          fullname: user_data.fullname,
          email: user_data.email,
          owner_id: user_data.owner_id,
          doc: user_data.doc,
          company_doc: user_data.company_doc,
          type: user_data.type,
          address: user_data.address,
          neighborhood: user_data.neighborhood,
          postal_code: user_data.postal_code,
          complement: user_data.complement,
          number: user_data.number,
          raffle_code: user_data.raffle_code,
          city: {
            name: user_data.city.name,
            state_name: user_data.city.state_name,
            country_name: user_data.city.country_name,
            ibge: user_data.city.ibge,
            gia: user_data.city.gia,
          },
          display_name: user_data.display_name,
          username: user_data.username,
          phone: user_data.phone,
          verification_request_at: user_data.verification_request_at,
          verified_at: user_data.verified_at,
          verified_by: user_data.verified_by,
          verify_status: user_data.verify_status,
          avatar_url: user_data.avatar_url,
          cc_total_transactions: user_data.cc_total_transactions,
          last_cc_transaction_at: user_data.last_cc_transaction_at,
          exchange_url: user_data.exchange_url,
          have_exchange_policy: user_data.have_exchange_policy,
          privacy_url: user_data.privacy_url,
          policy_url: user_data.policy_url,
          facebook: user_data.facebook,
          instagram: user_data.instagram,
          whatsapp: user_data.whatsapp,
          google_business: user_data.google_business,
          linkedin: user_data.linkedin,
          twitter: user_data.twitter,
          reclameaqui: user_data.reclameaqui,
          telephone: user_data.telephone,
          rg: user_data.rg,
          gender: user_data.gender,
          company_description: user_data.company_description,
          hide_address: user_data.hide_address,
          contact_name: user_data.contact_name,
          contact_phone: user_data.contact_phone,
          contact_phone2: user_data.contact_phone2,
          contact_email: user_data.contact_email,
          website: user_data.website,
          verification_doc: user_data.verification_doc,
          selling_intends: user_data.selling_intends,
          segments: {
            parent: user_data.segments ? user_data.segments.parent : '',
            child: user_data.segments ? user_data.segments.child : '',
          },
          fees: {
            antecipation_automatic: user_data.fees ? user_data.fees.antecipation_automatic : '',
            antecipation_days: user_data.fees ? user_data.fees.antecipation_days : null,
            antecipation_fee_monthly: user_data.fees
              ? user_data.fees.antecipation_fee_monthly
              : null,
            antecipation_fees: user_data.fees ? user_data.fees.antecipation_fees : null,
            credit_fee: user_data.fees ? user_data.fees.credit_fee : null,
            credit_installments_fee: user_data.fees ? user_data.fees.credit_installments_fee : null,
            debit_fee: user_data.fees ? user_data.fees.debit_fee : null,
            fee_id: user_data.fees ? user_data.fees.fee_id : null,
            invoice_fee: user_data.fees ? user_data.fees.invoice_fee : null,
            transaction_fee: user_data.fees ? user_data.fees.transaction_fee : null,
          },
          birth_date: user_data.birth_date,
          have_website: user_data.have_website,
          use_account_info: user_data.use_account_info,
          responsible_name: user_data.responsible_name,
          responsible_doc: user_data.responsible_doc,
          allow_antecipation: user_data.allow_antecipation,
        };
        break;
      default:
    }
  });
}
