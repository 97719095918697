import React, { useEffect, useState } from 'react';
import { Form } from '@rocketseat/unform';
import Input from '../../components/Input';
import { Container, WrapperLogo, Logo } from './styles';
import Button from '../../components/Button';

export default function CreateAccountSuccess(props) {
  const [username, setUsername] = useState('');
  const [iframe, setIframe] = useState(false);
  const [user, setUser] = useState({})

  useEffect(() => {
    let data = props.location.state;
    if (data) {
      setUsername(data.username || 'usuario');
      setIframe(data.iframe);
      setUser(data.user_data)
    }
  }, [props]);

  if (iframe) window.parent.postMessage({ user, source: 'alboompay', status: 'created' }, '*');

  return (
    <>
      <Container>
        <WrapperLogo>
          <Logo
            src="https://bifrost.alboompro.com/products/alboompay/AlboomPay2022-full.svg"
            alt="Logo Alboom Pay"
          />
        </WrapperLogo>
        <div className="container">
          <div className="row justify-content-center text-center">
            <div className="col-lg-8">
              <h2>Sua conta Alboom Pay foi configurada com sucesso!</h2>
            </div>
            <div className="col-lg-12 text-center">
              <h5>Este é o seu identificador:</h5>
            </div>
          </div>
          <Form>
            <div className="row justify-content-center">
              <div className="col-lg-6 text-center">
                <Input name="nickname" label=" " disabled placeholder={`@${username}`} />

                <p className="text-center">
                  Seus links de pagamento começarão com: &nbsp;&nbsp;
                  <span className="link-gray">alboom.pay/{username}</span>
                </p>
              </div>
            </div>
            <div className="row justify-content-center">
            {!iframe &&
              <div className="col-lg-4 text-center">
                <Button
                  className="buttonLarge buttonBlue mb-3"
                  onClick={() => window.location.reload()}
                >
                  <span>Solicitar meu primeiro pagamento</span>
                </Button>

                <p>
                  <Button className="link-gray" onClick={() => window.location.reload()}>
                    Ir para resumo da conta
                  </Button>
                </p>
              </div>
            }
            </div>
          </Form>
        </div>
      </Container>
    </>
  );
}
