/* eslint-disable no-undef */
import React, { Component } from 'react';
import Dropdown from '@alboompro/boom-components/data-display/dropdown/Dropdown';
import Icon from '@alboompro/boom-components/general/icon';

import {
  PageSettingsContainer,
  SettingsOptionItem,
  SettingsTitle,
  PageSettingsContent,
} from './styles';

class Actions extends Component {
  state = {
    active: false,
    visible: false,
  };

  render() {
    const { defaultBank, handleEdit, handleDelete } = this.props;

    return (
      <div>
        <Dropdown
          visible={this.state.visible}
          onVisibleChange={visible => this.setState({ visible: visible })}
          trigger="click"
          overlayStyle={{ padding: 0, width: '215px' }}
          align="right"
          overlay={
            <PageSettingsContent onClick={() => this.setState({ visible: false })}>
              <SettingsOptionItem onClick={() => handleEdit()}>
                <Icon
                  kind="bold"
                  group="interface-essential"
                  category="edit"
                  file="pencil-1.svg"
                  size="13"
                  color="#4F4F4F"
                />
                <SettingsTitle>Editar</SettingsTitle>
              </SettingsOptionItem>
              <SettingsOptionItem
                disabled={defaultBank}
                onClick={() => {
                  if (defaultBank) return;
                  handleDelete();
                }}
              >
                <Icon
                  kind="regular"
                  group="interface-essential"
                  category="delete"
                  file="bin.svg"
                  size="13"
                  color="#E83535"
                />
                <SettingsTitle>Excluir conta</SettingsTitle>
              </SettingsOptionItem>
            </PageSettingsContent>
          }
        >
          <PageSettingsContainer>
            <Icon
              kind="bold"
              group="interface-essential"
              category="menu"
              file="navigation-menu-vertical.svg"
              size="15"
              color="#535353"
            />
          </PageSettingsContainer>
        </Dropdown>
      </div>
    );
  }
}

Actions.propTypes = {};

export default Actions;
