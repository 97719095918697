import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import DateRangePicker from 'react-bootstrap-daterangepicker';

import SpinnerButton from '../../Spinner';

import { FiltersWrapper, DateRangeSelect, ExportToCsv } from './styles';

import moment from 'moment';
import 'moment/locale/pt-br';
moment.locale('pt-br');

export default function FiltersPanel({ initialDateRange, handleDateRangeUpdate, exportToCsv }) {
  const [rangeLabel, setRangeLabel] = useState('Últimos 30 dias');
  const { created_at } = useSelector(state => state.auth);
  const {
    core_user: { beta_tests },
  } = useSelector(state => state.accounts);
  const [loadCSV, setLoadCSV] = useState(false)

  const initialSettings = {
    startDate: initialDateRange.start.toDate(),
    endDate: initialDateRange.end.toDate(),
    ranges: {
      'Últimos 7 dias': [
        moment()
          .subtract(6, 'days')
          .toDate(),
        moment().toDate(),
      ],
      'Últimos 30 dias': [
        moment()
          .subtract(29, 'days')
          .toDate(),
        moment().toDate(),
      ],
      'Este mês': [
        moment()
          .startOf('month')
          .toDate(),
        moment().toDate(),
      ],
      'Mês anterior': [
        moment()
          .subtract(1, 'months')
          .date(1)
          .toDate(),
        moment()
          .startOf('month')
          .date(0)
          .toDate(),
      ],
      'Últimos 6 meses': [
        moment()
          .subtract(179, 'days')
          .toDate(),
        moment().toDate(),
      ],
      'Últimos 12 meses': [
        moment()
          .subtract(365, 'days')
          .toDate(),
        moment().toDate(),
      ],
      'Todo o período': [
        moment()
          .subtract(moment().diff(moment(new Date(created_at)), 'days') + 1, 'days')
          .toDate(),
        moment().toDate(),
      ],
    },
    autoApply: true,
    locale: { customRangeLabel: 'Personalizado' },
  };

  const handleDateChange = (start, end) => {
    const startDate = start.format();
    const endDate = end.format();

    handleDateRangeUpdate(startDate, endDate);

    if (
      start.format('DD/MM/YYYY') ===
        moment()
          .subtract(6, 'days')
          .format('DD/MM/YYYY') &&
      end.format('DD/MM/YYYY') === moment().format('DD/MM/YYYY')
    ) {
      setRangeLabel('Últimos 7 dias');
    } else if (
      start.format('DD/MM/YYYY') ===
        moment()
          .subtract(29, 'days')
          .format('DD/MM/YYYY') &&
      end.format('DD/MM/YYYY') === moment().format('DD/MM/YYYY')
    ) {
      setRangeLabel('Últimos 30 dias');
    } else if (
      start.format('DD/MM/YYYY') ===
        moment()
          .startOf('month')
          .format('DD/MM/YYYY') &&
      end.format('DD/MM/YYYY') === moment().format('DD/MM/YYYY')
    ) {
      setRangeLabel('Este mês');
    } else if (
      start.format('DD/MM/YYYY') ===
        moment()
          .subtract(1, 'months')
          .date(1)
          .format('DD/MM/YYYY') &&
      end.format('DD/MM/YYYY') ===
        moment()
          .startOf('month')
          .date(0)
          .format('DD/MM/YYYY')
    ) {
      setRangeLabel('Mês anterior');
    } else if (
      start.format('DD/MM/YYYY') ===
        moment()
          .subtract(179, 'days')
          .format('DD/MM/YYYY') &&
      end.format('DD/MM/YYYY') === moment().format('DD/MM/YYYY')
    ) {
      setRangeLabel('Últimos 6 meses');
    } else if (
      start.format('DD/MM/YYYY') ===
        moment()
          .subtract(365, 'days')
          .format('DD/MM/YYYY') &&
      end.format('DD/MM/YYYY') === moment().format('DD/MM/YYYY')
    ) {
      setRangeLabel('Últimos 12 meses');
    } else if (
      start.format('DD/MM/YYYY') ===
        moment()
          .subtract(moment().diff(moment(new Date(created_at)), 'days') + 1, 'days')
          .format('DD/MM/YYYY') &&
      end.format('DD/MM/YYYY') === moment().format('DD/MM/YYYY')
    ) {
      setRangeLabel('Todo o período');
    } else {
      setRangeLabel(start.format('DD/MM/YYYY') + ' - ' + end.format('DD/MM/YYYY'));
    }
  };

  const getExportToCSV = async () => {
    setLoadCSV(true);
    await exportToCsv()
    setLoadCSV(false)
  }

  return (
    <FiltersWrapper>
      <ExportToCsv onClick={getExportToCSV}>
        {loadCSV ? (
          <SpinnerButton size={16} />
        ) : (
          <img
            src="https://icons.alboompro.com/v1/bold/internet-networks-servers/upload-download/6F6F6F/download-bottom.svg"
            style={{ width: '16px', height: '16px' }}
          />
        )}
        <div className="tooltip">Baixar em formato .csv</div>
      </ExportToCsv>
      <DateRangePicker initialSettings={initialSettings} onCallback={handleDateChange}>
        <DateRangeSelect id="reportrange">
          <img src="https://icons.alboompro.com/v1/bold/interface-essential/date-calendar/6F6F6F/calendar-3.svg" />
          <span>{rangeLabel}</span>
          <img
            src="https://icons.alboompro.com/v1/bold/arrows-diagrams/arrows/6F6F6F/arrow-button-down.svg"
            style={{ marginLeft: '10px', width: '10px', height: '10px' }}
          />
        </DateRangeSelect>
      </DateRangePicker>
    </FiltersWrapper>
  );
}
