import React, { useState } from 'react';
import { Option, OptionContainer } from '../styles';
import Simple from '../Simple';
import Advanced from '../Advanced';
import RadioInput from '../../../components/RadioInput';
import { Container, ContainerInner } from '../../../styles/global';
import PageHeader from '../../../components/PageHeader';

export default function() {
  const [check, setCheck] = useState('ss');

  return (
    <>
      <PageHeader
        title="Solicitar pagamento"
        subtitle="Solicite pagamentos online com o Alboom Pay e receba mais rápido."
      />
      <ContainerInner>
        <OptionContainer>
          <Option onClick={() => setCheck('ss')} checked={check === 'ss'}>
            <RadioInput
              className="RequestPaymentRadio"
              name="other"
              type="radio"
              value="ss"
              label="  "
              checked={check === 'ss'}
            >
              <h3>Solicitação rápida</h3>
            </RadioInput>
          </Option>
          <Option onClick={() => setCheck('sa')} checked={check === 'sa'}>
            <RadioInput
              className="RequestPaymentRadio"
              name="other name"
              type="radio"
              value="sa"
              label="  "
              checked={check === 'sa'}
            >
              <h3>Solicitação Avançada</h3>
            </RadioInput>
          </Option>
        </OptionContainer>
        {check === 'ss' ? <Simple /> : <Advanced />}
      </ContainerInner>
    </>
  );
}
