import styled from 'styled-components';

import { Form } from '@rocketseat/unform';
import media from '../../../styles/media';

export const StyledForm = styled(Form)`
  display: flex;
  flex-direction: column;
  margin-top: 2em;
  @media ${media.small} {
    padding: 0 5px;
  }
  p.description {
    // font-size: 1.75em;
    text-align: left;
    margin-top: 1em;
  }

  .checkbox {
    p {
      font-size: 14px;
    }
    .checkmark:after {
      top: auto;
    }
  }
  .input-text {
    margin: 1em 0;
    font-size: 14px;
  }
`;
export const Receiver = styled.div`
  text-align: left;
  margin: 5px 0 20px;

  button.icons {
    background: transparent;
    border: none;
    margin-left: 7px;

    img {
      width: 15px;
      margin-bottom: 5px;
    }
  }

  p {
    &.description {
      // font-size: 1.75em;
      margin-top: 1em;
      text-align: left;
    }

    &.name {
      // font-size: 1.75em;
      text-transform: uppercase;
      text-align: left;
    }
    &.value {
      // font-size: 1.75em;
      text-transform: uppercase;
      text-align: right;

      @media ${media.small} {
        text-align: left;
      }
    }
    &.info {
      text-transform: none;
      // font-size: 1.5em;
      line-height: 1.2em;
      text-align: left;

      span {
        display: block;
      }
    }
  }
`;
export const SettingsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  label {
    align-self: center;
    // border-bottom: 1px solid #ccc;
    color: #727272;
    // font-size: 1.9em;

    img {
      padding: 0 5px;
    }
  }

  input {
    display: none;
  }
`;

export const ItemWrapper = styled.div`
  padding: 5px 0;

  div p {
    text-align: center;
  }
  div:first-child p {
    text-align: left;
  }
`;

export const Table = styled.div`
  p {
    margin-bottom: 0;
    color: #383838;
    // font-size: 1.5em;
  }
`;
export const Title = styled.div`
  padding: 0.8em 0;

  p {
    text-transform: uppercase;
    color: #595959;
    font-size: 12px;
    font-weight: 500;
  }

  div p {
    text-align: center;
  }
  div:first-child p {
    text-align: left;
  }
`;
export const Anchor = styled.span`
  color: #1b9dfa;
  text-decoration: underline;
  cursor: pointer;
`;

export const Discounts = styled.div`
  display: none;
  position: absolute;
  background: #fff;
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.1);
  padding: 10px 15px;
`;

export const Body = styled.div`
  padding: 0.8em 0;
  border-top: 1px solid #eee;
  text-align: left;

  button.icons {
    background: transparent;
    border: none;
    padding: 6px;
    border-radius: 3px;

    &:hover {
      background-color: #ebebeb;
    }

    &:last-child {
      margin-left: 5px;

      &:hover {
        background-color: #f3dfdb;
      }
    }
  }

  button {
    width: auto;
  }

  .noitens {
    padding: 10px;
    color: #3e3e3e;
    text-align: center;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 40px;

  button {
    width: 300px;
    height: 46px;

    @media screen and (max-width: 767px) {
      width: 100%;
      align-items: center;
    }

    @media ${media.small} {
      margin-top: 1rem !important;
      margin-bottom: 0;
    }
  }

  @media ${media.small} {
    flex-direction: column;
  }
`;

export const Footer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 30px;

  .removeDiscount {
    background: none;
    border: none;
    padding: 0 5px;
    margin: 0 7px;
    color: #727272;
    outline: none;
    transition: 0.1s;
    border-radius: 50%;
    &:hover {
      color: #000;
    }
  }
  .discounts {
    &:hover + ${Discounts} {
      display: block;
    }
  }
  div.info {
    margin-right: 10px;
    p {
      text-align: right;
      text-transform: uppercase;
    }
  }
  div.values {
    p {
      text-align: left;
    }
  }
  p {
    margin-bottom: 0.6em;

    &.total {
      color: #373636;
    }
  }
`;

export const FooterRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  font-size: ${props => (props.total ? '16px' : '15px')};
  color: #323232;
  font-weight: ${props => (props.total ? '500' : '400')};
  margin-bottom: 15px;

  @media screen and (max-width: 767px) {
    justify-content: flex-start;
  }
`;

export const FooterKey = styled.div`
  text-align: right;
  margin-right: 10px;
`;

export const FooterValue = styled.div`
  width: 170px;
  text-align: left;
`;

export const Discount = styled.div`
  display: flex;
  align-items: center;

  > span {
    margin-right: 4px;
    margin-top: 2px;
    min-width: 65px;
  }

  button {
    background: transparent;
    border: none;
    padding: 4px;
    border-radius: 2px;

    &:first-child {
      margin-left: 5px;
    }

    &:hover {
      background-color: #ebebeb;
    }

    &:last-child {
      margin-left: 0px;

      &:hover {
        background-color: #f3dfdb;
      }
    }
  }
`;

export const QuantityMobile = styled.span`
  display: none;
  @media screen and (max-width: 767px) {
    display: inline-flex;
    color: #9c9c9c;
  }
`;
export const DescriptionItem = styled.span`
  text-overflow: ellipsis;
  overflow: hidden;
  display: inline-block;
  line-height: 0.8em;
  max-width: 240px;

  @media screen and (max-width: 767px){
    max-width: 60px;
  }
`;

export const ContainerPaymentType = styled.div`
  width: 100%;
  margin: 30px 0 0;
`;
