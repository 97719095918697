import { takeLatest, call, put, all } from "redux-saga/effects";
import { 
  balanceSuccess, 
  balanceDetailsSuccess, 
  balanceError, 
  payablesSuccess, 
  payablesError, 
  payablesDetailsSuccess, 
  payablesDetailsError 
} from "./actions";
import Accounts from '../../../services/core/http/accounts';

export function* getBalance() {
  try {
    const response = yield call(Accounts.balance);
    yield put(balanceSuccess(response));
  } catch (error) {
    yield put(balanceError());
  }
}

export function* getBalanceDetails() {
  try {
    const response = yield call(Accounts.balanceDetails);
    yield put(balanceDetailsSuccess(response));
  } catch (error) {
    yield put(balanceError());
  }
}

export function* getPayables({ date }) {
  try {
    const response = yield call(Accounts.getPayables, date);
    yield put(payablesSuccess(response));
  } catch (error) {
    yield put(payablesError());
  }
}

export function* getPayablesDetails({ payload }) {
  try {
    const response = yield call(Accounts.getPayablesDetails, payload);
    yield put(payablesDetailsSuccess(response));
  } catch (error) {
    yield put(payablesDetailsError());
  }
}

export default all([
  takeLatest("@balance/BALANCE_REQUEST", getBalance),
  takeLatest("@balance/BALANCE_DETAILS_REQUEST", getBalanceDetails),
  takeLatest("@balance/PAYABLES_REQUEST", getPayables),
  takeLatest("@balance/PAYABLES_DETAILS_REQUEST", getPayablesDetails),
]);
