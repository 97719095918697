import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Success from "./Success";
import Create from './Create'
import { ordersClear } from '../../store/modules/orders/actions';

export default function () {
  const orders = useSelector(state => state.orders.data);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(ordersClear());
  }, [dispatch])

  return (
    <>
      {
        !orders.length ?
        <Create /> :
        <Success />
      }
    </>
  )
}
