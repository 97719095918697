import styled from 'styled-components';

export const Wrapper = styled.div`
  display: block;
  margin-bottom: 30px;
`;

export const TextareaField = styled.textarea`
  display: block;
  width: 100%;
  border: 1px solid #e8e8e8;
  border-radius: 3px;
  font-size: 15px;
  line-height: 1.2;
  padding: 15px;
  color: #373636;
  resize: none;
  height: 100px;
  transition: 180ms ease-in-out;

  @media screen and (max-width: 767px) {
    height: 180px;
  }

  &:focus {
    box-shadow: 0 0 2px 1px #50aff4;
  }
`;

export const Label = styled.label`
  display: block;
  margin-bottom: 8px;
  color: #282828;
  font-size: 15px;
  font-weight: 400;
`;

export const MaxLength = styled.span`
  display: block;
  text-align: right;
  padding: 5px 10px 0 0;
  font-size: 12px;
  color: #535353;
  font-weight: 400;
`;
