import React from 'react';
import { Container } from '../../styles/global';
import Mypayments from '../../components/MyPayments';
import PageHeader from '../../components/PageHeader';
import Button from '@alboompro/boom-components/general/button';
import history from '../../services/history';

export default function Payments() {
  return (
    <>
      <PageHeader
        title="Meus pagamentos"
        subtitle="Acompanhe o status de cada pagamento. Você pode reenviar os links dos pagamentos pendentes caso precise."
        actions={
          <Button
            onClick={() => history.push('/payment-request')}
            style={{ height: 35, backgroundColor: '#50AFF4', whiteSpace: 'nowrap' }}
            type="primary"
          >
            Solicitar pagamento
          </Button>
        }
      />
      <Mypayments />
    </>
  );
}
