import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  .spinner {
    position: relative;
    top: 50%;
    left: 50%;
  }
`;
