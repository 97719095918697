import React, { useState, useEffect, useCallback } from "react";
import PropTypes from 'prop-types';
import Modal from "@alboompro/boom-components/data-display/modal";
import Button from "@alboompro/boom-components/general/button";
import Input from "../../Input";
import { ContentBox, FooterWrapper, StyledForm, Col, Row } from "./styles";
import { currencyFormat } from '../../../utils';
import { paymentRequestItem } from "../../../validators/schema";
export default function ItemModal({ onSubmit, closeAction, title, initialData }) {
  const limit = process.env.REACT_APP_REQUESTS_MAX_ITENS;
  const [modal, setModal] = useState(true);
  const { amount:a, total:t, quantity:q, id:i, item:d } = initialData;
  const [data, setData] = useState({
    item: d || '',
    amount: a || 0,
    total: t || 0,
    quantity: q ? q.toString() : '1',
    id: i || 0,
    amountFormatted: t ? currencyFormat(initialData.amount) : '',
  })
  const { item, amountFormatted, total, quantity, id, amount, totalFormatted } = data;

  function changeModalStatus() {
    setModal(false);
    closeAction()
  }

  function handleSubmit(data) {
    setModal(false);
    onSubmit({
      ...data,
      id,
      amount,
      total,
      quantity: parseInt(quantity),
      totalFormatted
    });
  }

  function handleQty({ target: { value: v } }) {
    const quantity = parseInt(v) > 0 ? v : 1;
    setData({ ...data, quantity });
  }

  function handleValue({ target: { value: v } }) {
    var amount = parseInt(v.replace(/[^\d]/g, ""));
    setData({...data, amount })
  }

  const calculate = useCallback( (quantity, value) => {
    const cents = (value > parseInt(limit) ? parseInt(limit) : value);
    const amount = (cents / 100).toFixed(2);
    const total = parseInt(cents * quantity);

    return setData( d => ({
      ...d,
      amount: cents,
      total: total,
      amountFormatted: currencyFormat(amount),
      totalFormatted: currencyFormat((total / 100).toFixed(2))
    }));
  }, [limit])

  useEffect(() => {
    calculate(quantity, amount);
  }, [quantity, amount, calculate])

  return (
    <Modal
      title="Adicionar item"
      visible={modal}
      width={550}
      backdropStyle={{ backgroundColor: "#000000b8" }}
      backdropClosable={false}
      background={"#FFF"}
      bodyStyle={{ padding: "40px 25px", backgroundColor: "#f6fafe" }}
      onClose={changeModalStatus}
      closeButton={true}
      zIndex={999}
    >
      <div>
        <ContentBox>
          <h1>{title}</h1>
          <StyledForm onSubmit={handleSubmit} schema={paymentRequestItem}>
            <Input name="item" value={item} onChange={e => setData({ ...data, item: e.target.value })} label="Descrição" />
            <Row>
              <Col width="20%" mr="2%">
                <Input name="quantity" value={quantity} onChange={handleQty} label="Quantidade" min="1" type="number" />
              </Col>
              <Col width="78%">
                <Input name="amountFormatted" value={amount ? amountFormatted : ""} onChange={handleValue} label="Valor unitário" placeholder="R$ 0,00" />
              </Col>
            </Row>
            <FooterWrapper>
              <Button onClick={changeModalStatus} className="buttonDefault">
                Cancelar
              </Button>
              <Button htmlType="submit" className="buttonBlue">
                { initialData.amount ? "Editar" : "Adicionar" }
              </Button>
            </FooterWrapper>
          </StyledForm>
        </ContentBox>
      </div>
    </Modal>
  );
}

ItemModal.propTypes = {
  title: PropTypes.string,
  initialData: PropTypes.shape({
    item: PropTypes.string,
    amount: PropTypes.number,
    total: PropTypes.number,
    amountFormatted: PropTypes.string,
    totalFormatted: PropTypes.string,
    quantity: PropTypes.number,
  })
}
