import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  background: #fff;
`;

export const Title = styled.div`
  font-weight: 500;
  font-size: 18px;
  text-transform: uppercase;
  line-height: 2.5;
`;

export const Header = styled.div`
  padding: 5px 1em;
  > * {
    flex-basis: 1;
    flex-grow: 0;
  }
`;

export const Footer = styled.div`
  padding: 1em;
  > * {
    flex-basis: 1;
    flex-grow: 0;
  }
  span {
    line-height: 2.5;
  }
`;

export const Wrapper = styled.div`
  justify-content: space-between;
  align-items: center;
`;

export const ReportTable = styled.div`
  position: relative;
  .wrap {
    min-height: 320px;
    overflow:auto;
  }
  table {
    width: 100%;
    border-top: 1px solid #eee;
  }
  tbody td {
    padding: 15px 0;
    // border-bottom: 1px solid #eee;
    strong {
      text-transform: uppercase;
      margin-bottom: 3px;
    }
    small {
      text-transform: uppercase;
    }
    p {
      margin: 0;
    }
  }
  tbody td:first-child {
    padding-left: 20px;
  }
  tbody td:last-child {
    text-align: right;
    padding-right: 20px;
  }
  tbody tr:last-child td {
    padding: 5em 0;
  }
`;
