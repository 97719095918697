import produce from "immer";

const INITIAL_STATE = {
  data: [],
  loading: false,
  dailyOrders: null,
  list: []
};

export default function orders(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case "@order/ORDERS_REQUEST": {
        draft.loading = true;
        break;
      }
      case "@order/ORDERS_SUCCESS": {
        draft.data = action.payload.data;
        draft.loading = false;
        break;
      }
      case "@order/ORDERS_FAILURE": {
        draft.loading = false;
        break;
      }
      case "@order/ORDERS_CLEAR":
        draft.data = INITIAL_STATE.data;
        draft.loading = INITIAL_STATE.loading;
        break;
      case "@order/ORDERS_GET_DAILY_SUCCESS":
        draft.dailyOrders = action.payload.data.data.orders;
        draft.loading = false;
        break;
      case "@order/ORDERS_GET_LIST":
        draft.loading = true;
        break;
      case "@order/ORDERS_GET_LIST_SUCCESS":
        draft.list = action.payload.data.data.orders.filter(order => order !== null);
        draft.loading = false;
        break;
      default:
        break;
    }
  });
}
