import styled from 'styled-components';
import InfoDescription from '../../../InfoDescription';

export const PaymentWrapper = styled.div``;

export const Header = styled.div`
  background-color: #f5fbff;
  border-radius: 3px 3px 0 0;
  padding: 10px 30px;
  margin-bottom: 10px;

  @media screen and (max-width: 767px) {
    padding: 40px 20px 20px;
  }
`;

export const HeaderRow = styled.div`
  display: flex;

  @media screen and (max-width: 767px) {
    flex-direction: column;
  }
`;

export const ModalTitle = styled.span`
  position: absolute;
  top: -25px;
  left: 0;
  font-size: 15px;
  font-weight: 500;
  color: #4e4e4e;

  @media screen and (max-width: 767px) {
    top: -15px;
  }
`;

export const PaymentCardHalf = styled.div`
  position: relative;

  &:first-child {
    margin-right: 10px;
    width: 60%;

    @media screen and (max-width: 767px) {
      width: 100%;
      margin-right: 0;
    }
  }
  &:last-child {
    text-align: right;
    margin-left: 10px;
    width: 40%;

    @media screen and (max-width: 767px) {
      width: 100%;
      margin-left: 0;
    }
  }
`;

export const WarningFees = styled.span`
  font-size: 12px;
  color: #545454;
  font-weight: 400;
  margin-left: 5px;
`;

export const PayerName = styled.h4`
  font-size: 24px;
  font-weight: 500;
  color: #3e3e3e;
  margin-bottom: 10px;
  line-height: 1;

  @media screen and (max-width: 767px) {
    padding: 8px 30px 8px 0px;
  }
`;

export const PaymentAmount = styled.span`
  font-size: 24px;
  font-weight: 500;
  color: #3e3e3e;
  margin-bottom: 8px;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  @media screen and (max-width: 767px) {
    justify-content: flex-start;
  }
`;

export const PaymentType = styled.h4`
  font-size: 14px;
  font-weight: 400;
  color: #363636;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 320px;

  > div {
    display: flex;
  }

  img {
    margin-right: 6px;
  }

  a {
    color: #44adf8;
    text-decoration: underline;
    margin-left: 3px;

    &:hover {
      color: #44adf8;
    }
  }
`;

export const PaymentCode = styled.div`
  font-size: 13px;
  font-weight: 400;
  font-weight: 500;
  color: #737373;
  text-transform: uppercase;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  @media screen and (max-width: 767px) {
    justify-content: flex-start;
  }
`;

export const CopyCodeTooltip = styled.span`
  position: absolute;
  background: #454545;
  color: #ffffff;
  display: block;
  width: auto;
  left: 50%;
  transform: translateX(-50%);
  opacity: 0;
  bottom: -31px;
  padding: 5px 15px;
  border-radius: 2px;
  white-space: nowrap;
  text-transform: capitalize;
  font-weight: 325;
  font-size: 11px;
  transition: 0.2s all cubic-bezier(0.55, 0.3, 0.5, 0.9);
  pointer-events: none;
`;

export const CopyCode = styled.span`
  position: relative;

  &:hover ${CopyCodeTooltip} {
    opacity: 1;
    bottom: -28px;
  }

  cursor: ${props => (props.isCopied ? 'default' : 'pointer')};
  margin-right: 5px;
  padding: 4px;
  border-radius: 2px;

  ${props =>
    !props.isCopied &&
    `
    &:hover {
      background-color: #e0f0fb;
    }
  `}
`;

export const CloseWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;

  @media screen and (max-width: 767px) {
    margin-bottom: 0;
    align-items: center;
    justify-content: center;

    position: absolute;
    right: 10px;
    width: 32px;
    height: 32px;
    top: 15px;
  }
`;

export const CloseIcon = styled.button`
  cursor: pointer;
  margin-right: -8px;
  padding: 8px;
  border-radius: 2px;
  outline: 0;
  border: 0;
  background-color: transparent;

  @media screen and (max-width: 767px) {
    margin-right: 0;
  }

  &:hover,
  &:focus {
    background-color: #e0f0fb;
    outline: 0;
    border: 0;
  }
`;

export const CardLabel = styled.div`
  font-weight: 500;
  font-size: 11px;
  text-transform: uppercase;
  color: #575757;
  margin-bottom: 5px;
`;

export const CardContent = styled.div`
  color: #262626;
  font-size: 16px;
`;

export const PaymentStatus = styled.span`
  border-radius: 20px;
  padding: 4px 12px;
  font-size: 11px;
  font-weight: 500;
  text-transform: uppercase;
  color: #ffffff;
  margin-right: 10px;
  background-color: #989898;

  &.paid {
    background-color: #45ae70;
  }
  &.waiting_payment {
    background-color: #e3d12f;
    color: #363636;
  }
  &.canceled {
    background: #eb413d;
    color: #ffffff;
  }
  &.chargedback {
    background: #eb413d;
    color: #ffffff;
  }
  &.desactived {
    background: #eb413d;
    color: #ffffff;
  }
`;

export const Card = styled.div`
  background-color: #ffffff;
  border-radius: 10px;
  flex: 1;
  margin-bottom: 25px;
`;

export const CardHeader = styled.div`
  padding: 5px 45px;
  flex: 1;
  margin-bottom: 5px;

  @media screen and (max-width: 767px) {
    padding: 10px 20px;
  }
`;

export const CardBody = styled.div`
  padding: 0 20px;
`;

export const CardTitle = styled.h4`
  font-size: 16px;
  font-weight: 400;
  line-height: 1.2;
  color: #282828;
  margin: 0;
  width: 270px;
  text-align: ${props => (props.align ? props.align : 'right')};

  @media screen and (max-width: 767px) {
    text-align: left;
  }
`;

export const CardInfoRow = styled.div`
  font-size: 15px;
  font-weight: 500;
  line-height: 1.2;
  margin-bottom: 4px;
  color: #474747;

  @media screen and (max-width: 767px) {
    display: flex;
    flex-direction: column;
  }
`;

export const CardInfoLabel = styled.span`
  width: 270px;
  font-weight: 400;
  display: inline-block;
  text-align: right;
  margin-right: 10px;

  @media screen and (max-width: 767px) {
    width: 100%;
    margin-right: 0;
    text-align: left;
  }
`;

export const CardInfoValue = styled.span`
  @media screen and (max-width: 767px) {
    display: flex;
    align-items: center;
  }

  strong {
    font-weight: 500;
  }
`;

export const FeeDetailsCard = styled.div`
  background-color: #F5F5F5;
  border-radius: 3px;
  padding-bottom: 10px;
  display: ${props => (props.opened ? 'block' : 'none')};
  /* padding-bottom: ${props => (props.opened ? '10px' : '0px')};
  max-height: ${props => (props.opened ? '300px' : '0')};
  overflow: hidden;
  transition: max-height .2s ease-in-out;  */
  @media screen and (max-width: 767px) {
    padding: 10px;
  }
`;

export const FeeDetailsTitle = styled.h6`
  margin-top: 10px;
  padding-top: 10px;
  margin-left: -116px;
  font-weight: 500;
  font-size: 10px;
  text-transform: uppercase;
  text-align: center;
  color: #696969;
`;

export const FeePercent = styled.span`
  margin-left: 10px;
  font-size: 11px;
  color: #7c7c7c;
  font-weight: 500;
`;

export const EmptyInfos = styled.div`
  height: ${props => (props.height ? props.height : '180')}px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
  background-color: #f6fafe;
  color: #bababa;
  font-size: 14px;
  font-weight: 500;
  width: 80%;
  margin: 0 auto;

  @media screen and (max-width: 767px) {
    width: calc(100% - 40px);
  }
`;

export const FeeDetails = styled.span`
  margin-left: 10px;
  font-size: 12px;
  color: #787878;
  cursor: pointer;
  font-weight: 400;
  text-decoration: underline;

  img {
    transition: transform 0.2s ease-in-out;
    margin-left: 4px;
    transform: ${props => (props.opened ? 'rotate(180deg);' : 'rotate(0);')};
  }
`;

export const OrderTable = styled.div`
  display: flex;
  flex-direction: column;

  background: #ffffff;
  border: 1px solid #e0e0e0;
  box-sizing: border-box;
  border-radius: 3px;
  margin: 0 25px;

  @media screen and (max-width: 767px) {
    margin: 0;
  }
`;

export const OrderHeader = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 15px 20px;
  border-bottom: 1px solid #dfdfdf;

  > span {
    display: block !important;
    font-weight: 500;
    font-size: 11px;
    display: flex;
    align-items: center;
    text-transform: uppercase;
    color: #676767;
    width: 20%;
    text-align: center;
    line-height: 110%;

    &:first-child {
      width: 60%;
      text-align: left;

      @media screen and (max-width: 767px) {
        width: 45%;
      }
    }

    &:last-child {
      text-align: right;

      @media screen and (max-width: 767px) {
        width: 45%;
      }
    }
  }
`;

export const OrderBody = styled.div`
  display: flex;
  flex-direction: column;
`;

export const OrderItem = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 15px 20px;
  border-bottom: 1px solid #dfdfdf;

  > span {
    display: block !important;
    font-size: 14px;
    display: flex;
    align-items: center;
    color: #454545;

    width: 15%;
    text-align: center;

    &:first-child {
      width: 60%;
      text-align: left;

      @media screen and (max-width: 767px) {
        width: 45%;
      }
    }

    &:last-child {
      text-align: right;

      @media screen and (max-width: 767px) {
        width: 45%;
      }
    }
  }
`;

export const OrderFooter = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 15px 20px;
`;

export const OrderText = styled.div`
  display: flex;
  justify-content: space-between;
  width: 200px;

  > p {
    width: 100%;
    text-align: right;
    margin: 0 0 10px;

    font-size: 14px;
    color: #454545;
  }

  &:last-child {
    > p {
      margin-bottom: 0;
      font-weight: 500;
      font-size: 15px;
    }
  }
`;

export const DaysToRelease = styled.span`
  font-size: 12px;
  color: #545454;
  margin-left: 10px;
  font-weight: 400;
`;

export const Tooltip = styled(InfoDescription)`
  display: inline-block;
  margin-right: 8px;
  transform: translateY(-1px);

  &.icon-tooltip {
  }
  &.text-tooltip {
  }
`;
