import styled from 'styled-components';
import 'react-toastify/dist/ReactToastify.min.css';

export const FullContainer = styled.div`
  padding-left: 285px;
  @media (max-width: 768px) {
    padding-left: 60px;
  }

  .minified-select {
    min-width: 150px;
    z-index: 5;
    @media screen and (max-width: 767px) {
    z-index: 0;
    }
    .react-select__control {
      border: none;
      height: auto;
      box-shadow: none;
    }
    .react-select__single-value {
      // position: relative;
      top: auto;
      transform: none;
    }
    .react-select__value-container {
      padding: 0;
    }
  }
  .react-select__menu:hover,
  .react-select__control:hover {
    cursor: pointer;
  }
`;

export const Container = styled.div`
  margin: 40px auto;
  max-width: ${props => (props.small ? '740px;' : '1080px;')};

  @media screen and (max-width: 1600px) {
    margin: 40px auto;
    padding: 0 40px;
  }

  @media screen and (max-width: 767px) {
    margin: 20px;
    padding: 0;
  }

  .react-select__menu:hover,
  .react-select__control:hover {
    cursor: pointer;
  }

  .CountrySelectWizard {
    .react-select__control {
      min-height: 50px;
    }
  }
`;

export const ContainerInner = styled.div`
  background-color: #fff;
  border-radius: 10px;
  border: ${props => (props.noBorder ? 'unset' : '1px solid #ebebeb')};
  padding: ${props => (props.padding ? props.padding : '50px')};

  @media screen and (max-width: 767px) {
    padding: 10px;
  }
`;

export const Title = styled.h5`
  font-weight: 500;
  margin: 0;
  font-size: 20px;
  text-transform: none;
  color: #212121;
`;

export const TableTitle = styled.h5`
  font-weight: 500;
  font-size: 18px;
  color: #262626;
  margin: 0 0 5px 0;
`;
