import styled from 'styled-components';
import { Form } from '@rocketseat/unform';
import Input from '../../../components/Input';
import media from '../../../styles/media';

export const AmountWrapper = styled.div``;

export const StyledForm = styled(Form)`
  display: flex;
  padding: 0;
  flex-direction: column;

  .input-text > input {
    color: #373636;
  }

  @media ${media.small} {
    padding: 0 5px;
  }

  p,
  #description {
    font-size: 14px;
  }
`;

export const Title = styled.div`
  color: #373737;
  font-size: 2em;
  line-height: 2em;
  border-bottom: 1px solid;
  border-color: #e2e2e2;
`;

export const SubTitle = styled.div`
  color: #282828;
  font-size: 15px;
  font-weight: 400;
  margin: 40px 0 2px 0;

  @media screen and (max-width: 767px) {
    margin-top: 20px;
  }
`;

export const StyledValue = styled(Input).attrs()`
  text-align: center;
  color: #414141 !important;
  font-size: 38px !important;
  padding: 15px !important;
  font-weight: 500;
  border: 1px solid #e8e8e8;
  border-radius: 5px;
  display: inline-block;
  margin: 0 auto;
  height: auto !important;

  @media screen and (max-width: 767px) {
    padding: 10px !important;
    font-size: 32px !important;
  }

  &:focus {
    box-shadow: 0 0 2px 1px #45ae70;
  }

  ::placeholder {
    color: #d2cdcd;
  }
`;

export const SettingsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 3em 0 0;
  text-decoration: underline;

  label {
    cursor: pointer;
    align-self: center;
    color: #727272;

    img {
      padding: 0 5px;
    }
  }

  input {
    display: none;
  }

  [for='advancedSettings'] {
    @media ${media.small} {
      text-align: center;
    }
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 40px;

  @media screen and (max-width: 767px) {
    margin-top: 20px;
  }

  button {
    width: 300px;
    height: 46px;

    @media screen and (max-width: 767px) {
      width: 100%;
    }

    @media ${media.small} {
      margin-top: 1rem !important;
      margin-bottom: 0;
    }
  }

  @media ${media.small} {
    flex-direction: column;
  }
`;

export const Payer = styled.div`
  margin: 10px 0;
`;

export const ContainerPaymentType = styled.div`
  width: 100%;
  margin: 30px 0 0;
`;
