import axios from "../../axios";
// import { toast } from 'react-toastify';
export default {
  get: async ({ search = null }) => {
    return await axios.get("/bank-accounts", {
      params: {
        phrase: search
      }
    });
  },
  post: async (data) => {
    return await axios.post("/bank-accounts", data);
  },
  put: async (data) => {
    return await axios.put(`/bank-accounts/${data.id}/`, data);
  },
  delete: async (id) => {
    return await axios.delete(`/bank-accounts/${id}/`);
  },
  codeStore: async (data) => {
    return await axios.get(`sms-verification/create-bank-account-code`, data);
  }
};
