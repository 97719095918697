import React from "react";
import Modal from '@alboompro/boom-components/data-display/modal';
import Icon from '@alboompro/boom-components/general/icon';

import { formatToBRL, formatToDate } from "../utils";

import arrowReturnUpSvg from "../../../assets/static/images/icons/arrow-return-up.svg"

import { DataHeader,
  ContentWrapper,
  DataWrapper,
  DataRow,
  DateWrapper,
  TotalWrapper
} from "./styles";

export default function AnticipationGroupModal({
  movement,
  onClose
}) {
  const { date, installments, totalAmount } = movement
  const orderedInstallments = installments.sort((a, b) => a.installment - b.installment)

  return (
    <Modal
      title="Detalhes da antecipação"
      visible={true}
      width={550}
      backdropStyle={{ backgroundColor: '#000000b8' }}
      backdropClosable={true}
      bodyStyle={{
        padding: '56px 20px',
        backgroundColor: '#F6FAFE',
        height: '95%',
        maxHeight: '95vh',
        position: 'relative',
        borderRadius: '3px',
      }}
      onClose={onClose}
      closeButton={true}
    >
      <ContentWrapper>
        <DataHeader>
          <span>Parcela</span>
          <span className="anticipation">Antecipação</span>
          <span>Valor (R$)</span>
        </DataHeader>
        <DataWrapper>
          {orderedInstallments.map(({ installment, amount, fee, anticipated_from }) => (
            <DataRow key={installment}>
              <span className="installment">{installment}</span>
              <DateWrapper>
                <span className="original-date">
                  {formatToDate(anticipated_from)}
                </span>
                <Icon
                  kind="bold"
                  group="interface-essential"
                  category="keyboard"
                  file="keyboard-arrow-right.svg"
                  size="12"
                  color="#A0A0A0"
                />
                <span>{formatToDate(date)}</span>
                <img src={arrowReturnUpSvg} className="arrow-return-up" alt="" />
              </DateWrapper>
              <span>{formatToBRL(amount - fee)}</span>
            </DataRow>
          ))}
        </DataWrapper>
        <TotalWrapper>
          <span>Total:</span>
          <span>{formatToBRL(totalAmount, true)}</span>
        </TotalWrapper>
      </ContentWrapper>
    </Modal>
  )
}