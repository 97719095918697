export const BUTTON_PAYMENT_GET = '@paymentButtons/BUTTON_PAYMENT_GET';
export const BUTTON_PAYMENT_GET_SUCCESS = '@paymentButtons/BUTTON_PAYMENT_GET_SUCCESS';

export const BUTTON_PAYMENT_POST = '@paymentButtons/BUTTON_PAYMENT_POST';
export const BUTTON_PAYMENT_POST_SUCCESS = '@paymentButtons/BUTTON_PAYMENT_POST_SUCCESS';

export const BUTTON_PAYMENT_PUT = '@paymentButtons/BUTTON_PAYMENT_PUT';
export const BUTTON_PAYMENT_PUT_SUCCESS = '@paymentButtons/BUTTON_PAYMENT_PUT_SUCCESS';

export const BUTTON_PAYMENT_DELETE = '@paymentButtons/BUTTON_PAYMENT_DELETE';
export const BUTTON_PAYMENT_DELETE_SUCCESS = '@paymentButtons/BUTTON_PAYMENT_DELETE_SUCCESS';

export const BUTTON_PAYMENT_FAILURE = '@paymentButtons/BUTTON_PAYMENT_FAILURE';

export const BUTTON_PAYMENT_DUPLICATE = '@paymentButtons/BUTTON_PAYMENT_DUPLICATE';
export const BUTTON_PAYMENT_DUPLICATE_SUCCESS = '@paymentButtons/BUTTON_PAYMENT_DUPLICATE_SUCCESS';

export function paymentButtonsRequest(payload) {
  return {
    type: BUTTON_PAYMENT_GET,
    payload,
  };
}

export function paymentButtonsRequestSuccess(payload) {
  return {
    type: BUTTON_PAYMENT_GET_SUCCESS,
    payload,
  };
}

export function paymentButtonsPost(payload) {
  return {
    type: BUTTON_PAYMENT_POST,
    payload,
  };
}

export function buttonPaymentPostSuccess(payload) {
  return {
    type: BUTTON_PAYMENT_POST_SUCCESS,
    payload,
  };
}

export function paymentButtonsPut(payload) {
  return {
    type: BUTTON_PAYMENT_PUT,
    payload,
  };
}

export function buttonPaymentPutSuccess(payload) {
  return {
    type: BUTTON_PAYMENT_PUT_SUCCESS,
    payload,
  };
}

export function paymentButtonsDelete(payload) {
  return {
    type: BUTTON_PAYMENT_DELETE,
    payload,
  };
}

export function buttonPaymentDeleteSuccess(payload) {
  return {
    type: BUTTON_PAYMENT_DELETE_SUCCESS,
    payload,
  };
}
export function paymentButtonsRequestFailure() {
  return {
    type: BUTTON_PAYMENT_FAILURE,
  };
}

export function paymentButtonsDuplicate(payload) {
  return {
    type: BUTTON_PAYMENT_DUPLICATE,
    payload,
  };
}

export function paymentButtonsDuplicateSuccess(payload) {
  return {
    type: BUTTON_PAYMENT_DUPLICATE_SUCCESS,
    payload,
  };
}