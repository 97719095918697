import React, { useState, useEffect } from 'react';
import Wizard from '../../components/Wizard';
import { WrapperLogo, Logo, Title } from './styles';
import { Field } from 'react-final-form';
import { ReactComponent as PhoneActionCheck } from '../../assets/static/images/icons/phone-action-check-1.svg';
import Select from '@alboompro/boom-components/data-entry/select';

export default function Wizard4() {
  const [countryCode, setCountryCode] = useState('+55');

  function maskPhoneNumber(value) {
    if (!value) return value;
    const onlyNums = value.replace(/[^\d]/g, '');
    window.setFormValue('area_code', countryCode); // Temporary - Setting countryCode on area code value
    if (onlyNums.length <= 2) return `(${onlyNums.slice(0, 2)}`;

    if (onlyNums.length <= 7) return `(${onlyNums.slice(0, 2)}) ${onlyNums.slice(2, 7)}`;

    if (onlyNums.length <= 11)
      return `(${onlyNums.slice(0, 2)}) ${onlyNums.slice(2, 7)}-${onlyNums.slice(7, 11)}`;

    return `(${onlyNums.slice(0, 2)}) ${onlyNums.slice(2, 7)}-${onlyNums.slice(7, 11)}`;
  }

  function validatePhoneNumber(value) {
    return /^\([1-9]{2}\) (?:[2-8]|9[1-9])[0-9]{3}\-[0-9]{4}$/.test(value)
      ? undefined
      : 'Número de celular inválido.';
  }

  function Error({ name }) {
    return (
      <Field
        name={name}
        subscribe={{ touched: true, error: true }}
        render={({ meta: { touched, error } }) => (touched && error ? <span>{error}</span> : null)}
      />
    );
  }

  const options = [
    {
      value: '+55',
      label: '+55',
    },
  ];

  return (
    <Wizard.Page>
      <WrapperLogo>
        <Logo
          src="https://bifrost.alboompro.com/products/alboompay/AlboomPay2022-full.svg"
          alt="Logo Alboom Pay"
        />
      </WrapperLogo>
      <div className="row justify-content-center title-pages">
        <div className="col-lg-12">
          <Title>Cadastre seu celular para contato</Title>
        </div>
      </div>

      <div style={{ marginTop: '48px', textAlign: 'center' }}>
        <PhoneActionCheck className="phone-action-check" />
        <p
          style={{
            fontSize: '15px',
            lineHeight: '18px',
            display: 'block',
            textAlign: 'center',
            marginTop: '50px',
          }}
        >
          Enviaremos um código de confirmação SMS para prosseguir.
        </p>
        {/* <p
          style={{
            fontSize: '15px',
            lineHeight: '18px',
            display: 'block',
            textAlign: 'center',
            marginBottom: '40px',
          }}
        >
          Você poderá alterar o número a qualquer momento.
        </p> */}
      </div>

      <div className="row justify-content-between col-lg-7 phone-area">
        <div className="country-code">
          <label className="input-text">
            <Select
              name="area_code"
              className="disabledCountryArea"
              value={countryCode}
              options={options}
              onChange={e => {
                setCountryCode(e.value);
              }}
              disabled
              dropdownStyle={{
                maxHeight: 250,
                overflow: 'hidden',
              }}
              selectStyle={{
                height: 50,
                border: '1px solid #E8E8E8',
                borderRadius: 3,
                marginBottom: '10px',
                backgroundColor: '#FFFFFF',
              }}
            />
            <span className="label">País</span>
          </label>
        </div>
        <div className="phone-number">
          <label className="input-text">
            <Error name="phone_number" />
            <Field
              name="phone_number"
              component="input"
              type="text"
              parse={maskPhoneNumber}
              maxLength="15"
              required
              placeholder=""
              validate={validatePhoneNumber}
              className="input-field"
            />
            <span className="label">Número de celular</span>
          </label>
        </div>
        <p className="phone-info">
          Ao prosseguir, você autoriza a Alboom a entrar em contato por meio de chamadas,
          automatizadas ou não, sobre qualquer tema relacionado ao Alboom Pay e demais serviços da Alboom.
        </p>
      </div>
    </Wizard.Page>
  );
}
