import styled from "styled-components";

export const PanelWrapper = styled.div`
  align-self: flex-end;
  display: flex;
  align-items: center;
  gap: 8px;
`

export const ChangePageButton = styled.button`
  background-color: transparent;
  padding: ${({ action }) => action === "previous" ? "5px 4px": "5px 3px 5px 5px"}; 
  color: #A8A8A8;
  border: 1px solid #A8A8A8;
  border-radius: 2px;

  display: flex;
  justify-content: center;
  align-items: center;

  :disabled {
    cursor: not-allowed;
    color: #D7D7D7;
    border: 1px solid #D7D7D7;
  }
`

export const CurrentPageBox = styled.div`
  height: 26px;
  width: 24px;

  background-color: transparent;
  color: #50AFF4;
  border: 1px solid #50AFF4;
  border-radius: 2px;
  cursor: default;

  display: flex;
  justify-content: center;
  align-items: center;
`