import React from 'react';
import propTypes from 'prop-types';
import Modal from '@alboompro/boom-components/data-display/modal';
export default function ModalComponent({
  className,
  visible,
  floatingStyle,
  headerStyle,
  width,
  title,
  backdropStyle,
  backdropClosable,
  background,
  onClose,
  bodyStyle,
  footer,
  children,
}) {
  return (
    <Modal
      className={className}
      title={title}
      visible={visible}
      floatingStyle={floatingStyle}
      width={width}
      backdropStyle={backdropStyle}
      backdropClosable={backdropClosable}
      background={background}
      bodyStyle={bodyStyle}
      onClose={onClose}
      closeButton={true}
      zIndex={999}
      footer={footer}
      headerStyle={headerStyle}
    >
      {children}
    </Modal>
  );
}

ModalComponent.defaultProps = {
  visible: false,
  backdropStyle: { backgroundColor: '#000000b8' },
  backdropClosable: true,
  background: '#FFF',
  bodyStyle: { padding: '25px', backgroundColor: '#FFF' },
};

ModalComponent.propTypes = {
  className: propTypes.string,
  visible: propTypes.bool,
  floatingStyle: propTypes.object, //style of floating layer which is used for adjusting its position
  width: propTypes.string,
  backdropStyle: propTypes.object,
  backdropClosable: propTypes.bool,
  title: propTypes.string,
  closeAction: propTypes.func,
  onClose: propTypes.func,
  children: propTypes.oneOfType([propTypes.element, propTypes.string]),
};
