export const PAYMENT_LINKS_GET = '@paymentLinks/PAYMENT_LINKS_GET';
export const PAYMENT_LINKS_GET_SUCCESS = '@paymentLinks/PAYMENT_LINKS_GET_SUCCESS';

export const PAYMENT_LINKS_POST = '@paymentLinks/PAYMENT_LINKS_POST';
export const PAYMENT_LINKS_POST_SUCCESS = '@paymentLinks/PAYMENT_LINKS_POST_SUCCESS';

export const PAYMENT_LINKS_PUT = '@paymentLinks/PAYMENT_LINKS_PUT';
export const PAYMENT_LINKS_PUT_SUCCESS = '@paymentLinks/PAYMENT_LINKS_PUT_SUCCESS';

export const PAYMENT_LINKS_DELETE = '@paymentLinks/PAYMENT_LINKS_DELETE';
export const PAYMENT_LINKS_DELETE_SUCCESS = '@paymentLinks/PAYMENT_LINKS_DELETE_SUCCESS';

export const PAYMENT_LINKS_DUPLICATE = '@paymentLinks/PAYMENT_LINKS_DUPLICATE';
export const PAYMENT_LINKS_DUPLICATE_SUCCESS = '@paymentLinks/PAYMENT_LINKS_DUPLICATE_SUCCESS';

export const PAYMENT_LINKS_FAILURE = '@paymentLinks/PAYMENT_LINKS_FAILURE';

export function paymentLinksRequest(payload) {
  return {
    type: PAYMENT_LINKS_GET,
    payload,
  };
}

export function paymentLinksRequestSuccess(payload) {
  return {
    type: PAYMENT_LINKS_GET_SUCCESS,
    payload,
  };
}

export function paymentLinksPost(payload) {
  return {
    type: PAYMENT_LINKS_POST,
    payload,
  };
}

export function paymentLinksPostSuccess(payload) {
  return {
    type: PAYMENT_LINKS_POST_SUCCESS,
    payload,
  };
}

export function paymentLinksPut(payload) {
  return {
    type: PAYMENT_LINKS_PUT,
    payload,
  };
}

export function paymentLinksPutSuccess(payload) {
  return {
    type: PAYMENT_LINKS_PUT_SUCCESS,
    payload,
  };
}

export function paymentLinksDelete(payload) {
  return {
    type: PAYMENT_LINKS_DELETE,
    payload,
  };
}

export function paymentLinksDeleteSuccess(payload) {
  return {
    type: PAYMENT_LINKS_DELETE_SUCCESS,
    payload,
  };
}

export function paymentLinksDuplicate(payload) {
  return {
    type: PAYMENT_LINKS_DUPLICATE,
    payload,
  };
}

export function paymentLinksDuplicateSuccess(payload) {
  return {
    type: PAYMENT_LINKS_DUPLICATE_SUCCESS,
    payload,
  };
}

export function paymentLinksFailure() {
  return {
    type: PAYMENT_LINKS_FAILURE,
  };
}
