import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Modal from '@alboompro/boom-components/data-display/modal';
import Button from '@alboompro/boom-components/general/button';
import Input from '../../Input';
import { currencyFormat, maskSsn, maskRn } from '../../../utils';
import { modalClose } from '../../../store/modules/modal/actions';
import Select from '../../Select';
import Icon from '@alboompro/boom-components/general/icon';
import { toast } from 'react-toastify';
import { MdDone } from 'react-icons/md';
import { bankAccountsRequest } from '../../../store/modules/bankAccounts/actions';
import {
  activitiesRequest,
  typesRequest,
  setWithdraw,
} from '../../../store/modules/activities/actions';
import { balanceDetailsRequest, balanceRequest } from '../../../store/modules/balance/actions';
import { postWithdraw } from '../../../store/modules/withdraw/actions';
import { banksRequest } from '../../../store/modules/banks/actions';
import Spinner from '../../Spinner/Absolute';
import { bankAccountsPutAndWithdraw } from '../../../store/modules/bankAccounts/actions';
import {
  ContentBox,
  FooterWrapper,
  StyledForm,
  Row,
  Option,
  BankName,
  SelectWrapper,
  AvailableAmount,
  PayoutFeesLabel,
  PayoutFees,
  PayoutFeesItem,
  ConfirmationTitle,
  ConfirmationSection,
  SectionTitle,
  SectionItem,
  MessageLimitWithdraw,
  FirstBoxLimitWithdraw,
} from './styles';

export default function Withdraw() {
  const {
    user_data: { verify_status },
  } = useSelector(state => state.accounts);

  const { visible, type, balanceAvailable } = useSelector(state => ({
    ...state.modal,
    ...state.balance,
  }));
  const {
    REACT_APP_WITHDRAW_TRANSFER_DAYS_TO_BE_AVAILABLE: days,
    REACT_APP_WITHDRAW_MIN_AMOUNT: minAmount,
  } = process.env;
  const { list: bankAccounts, loading: l2 } = useSelector(state => state.bankAccounts);
  const { list: banks, loading: l1 } = useSelector(state => state.banks);
  const { loading: l3 } = useSelector(state => state.withdraw);
  const { withdraw } = useSelector(state => state.activities);

  const dispatch = useDispatch();
  const titles = {
    1: 'Transferência para a conta bancária',
    2: 'Confirmar transferência',
    3: 'Transferência solicitada',
  };
  const [data, setData] = useState({
    amount: 0,
    amountFormatted: '',
    options: [],
    option: {},
    step: 1,
    title: titles[1],
  });
  const { step, title, amountFormatted, amount, option, options } = data;

  function handleSubmit() {
    if (amount < minAmount)
      return toast.error(`O valor mínimo para saques é de ${currencyFormat(minAmount / 100)}`);
    if (step === 1) {
      if (!amount && !option)
        return toast.error('Você deve inserir um valor e/ou escolher o banco para prosseguir.');
      setData(d => ({ ...d, step: 2, title: titles[2] }));
    }
    /* Solicita o Saque v */
    if (step === 2) {
      dispatch(postWithdraw({ amount, bank_account_id: option.id }));
      setData(d => ({ ...d, step: 3, title: titles[3] }));
    }
  }

  function handleAmount({ target: { value: v } }) {
    var amount = parseInt(v.replace(/[^\d]/g, ''));
    if (verify_status !== 'verified' && amount > 50000) amount = 50000;
    var minAvailable = minWithdrawAmount(balanceAvailable, option);
    if (amount / 100 > minAvailable) amount = minAvailable * 100;
    setData({ ...data, amount, amountFormatted: currencyFormat(amount / 100) });
  }

  function minWithdrawAmount(balanceAvailable, bank) {
    var minAvailable = balanceAvailable - 0;
    minAvailable = minAvailable < 0 ? 0 : minAvailable;
    // eslint-disable-next-line
    return bank.bank_code == 237 ? balanceAvailable : minAvailable;
  }

  const close = () => {
    dispatch(modalClose());
    setData({ ...data, step: 1, title: titles[1] });
    if (step === 3) {
      dispatch(activitiesRequest());
      dispatch(balanceRequest());
      dispatch(balanceDetailsRequest());
      dispatch(typesRequest());
    }
  };

  useEffect(() => {
    if (!bankAccounts.length) dispatch(bankAccountsRequest());
  }, [bankAccounts, dispatch]);

  useEffect(() => {
    !banks.length && dispatch(banksRequest());
  }, [banks, dispatch]);

  function setBank(obj) {
    const ba = bankAccounts.filter(b => obj.value === b.id).shift();
    const bank = banks.filter(b => b.bank_code === ba.bank_code).shift();
    setData(d => ({ ...d, option: { bank_id: bank.id, ...bank, ...ba } }));
  }

  useEffect(() => {
    if (bankAccounts.length && banks.length) {
      const options = bankAccounts.map(ba => {
        const bank = banks.filter(b => b.bank_code === ba.bank_code).shift();
        ba.default && setData(d => ({ ...d, option: { bank_id: bank.id, ...bank, ...ba } }));
        return {
          value: ba.id,
          label: (
            <Option>
              <img src={bank.icon} alt={bank.name} />
              <BankName>{bank.name}</BankName>
              <small>
                Ag: {ba.agency}-{ba.agency_dv} | Cc: {ba.account}-{ba.account_dv}
              </small>
            </Option>
          ),
        };
      });
      setData(d => ({ ...d, options }));
    }
  }, [banks, bankAccounts]);

  return (
    <Modal
      title={title}
      visible={!!visible && type === 'withdraw'}
      width={550}
      backdropStyle={{ backgroundColor: '#000000b8' }}
      backdropClosable={false}
      background={'#FFF'}
      bodyStyle={{ padding: '40px 25px', backgroundColor: '#f6fafe' }}
      onClose={close}
      closeButton={true}
      zIndex={999}
    >
      <div style={{ position: 'relative' }}>
        <ContentBox>
          {(step === 1 || step === 2) && (
            <StyledForm onSubmit={handleSubmit}>
              {(l1 || l2) && <Spinner size={20} />}
              {step === 1 && (
                <>
                  <SelectWrapper>
                    <label className="label custom">Selecione a conta bancária</label>
                    <Select
                      label="Selecione a conta bancária"
                      onChange={setBank}
                      options={options}
                      initialValue={option.id}
                    />
                  </SelectWrapper>
                  <Input
                    label="Valor da transferência"
                    required
                    name="amount"
                    placeholder="R$ 0,00"
                    value={amountFormatted}
                    onChange={handleAmount}
                  />
                  <AvailableAmount>
                    Disponível: {currencyFormat(minWithdrawAmount(balanceAvailable, option))}.
                  </AvailableAmount>
                  <PayoutFeesLabel>Taxa para transferência: R$ 0,00</PayoutFeesLabel>
                  {verify_status !== 'verified' && (
                    <MessageLimitWithdraw>
                      <FirstBoxLimitWithdraw>
                        <Icon
                          kind="bold"
                          group="interface-essential"
                          category="alerts"
                          file="alert-circle.svg"
                          color="#1B9DFA"
                          width="16px"
                        />
                        <p>Contas não verificadas tem um limite de saque diário de R$ 500,00.</p>
                      </FirstBoxLimitWithdraw>
                      <a href="/account-alboom-pay">Solicitar verificação</a>
                    </MessageLimitWithdraw>
                  )}
                  {/* <PayoutFees>
                    <PayoutFeesItem>Bradesco: R$ 0,00</PayoutFeesItem>
                    <PayoutFeesItem>Demais bancos: R$ 0,00</PayoutFeesItem>
                  </PayoutFees> */}
                </>
              )}

              {step === 2 && (
                <>
                  <ConfirmationTitle>
                    Revise os dados antes de confirmar a operação
                  </ConfirmationTitle>

                  <ConfirmationSection>
                    <SectionTitle>Conta bancária</SectionTitle>
                    <SectionItem>{option.legal_name}</SectionItem>
                    <SectionItem>
                      {option.document_type === 'cpf'
                        ? `CPF: ${maskSsn(option.document_number)}`
                        : `CNPJ: ${maskRn(option.document_number)}`}
                    </SectionItem>
                    <SectionItem>
                      <img src={option.icon} alt={option.bank_code} /> {option.name} | Ag.{' '}
                      {option.agency}-{option.agency_dv} | Cc. {option.account}-{option.account_dv}
                    </SectionItem>
                  </ConfirmationSection>

                  <ConfirmationSection>
                    <SectionTitle>Valor da transferência</SectionTitle>
                    <SectionItem>{amountFormatted}</SectionItem>
                  </ConfirmationSection>

                  <ConfirmationSection>
                    <SectionTitle>Taxa da transferência</SectionTitle>
                    <SectionItem>
                      {//eslint-disable-next-line
                      option.bank_code == 237 ? 'R$ 0,00' : 'R$ 0,00'}
                    </SectionItem>
                  </ConfirmationSection>

                  <Row>
                    <Option>
                      <p className="mt-1 mb-4"> </p>
                    </Option>
                  </Row>
                </>
              )}
              <FooterWrapper>
                <Button onClick={close} className="buttonDefault">
                  Cancelar
                </Button>
                <Button
                  htmlType="submit"
                  className="buttonBlue"
                  disabled={Boolean(!balanceAvailable) || !withdraw}
                >
                  {step === 2 ? 'Solicitar Saque' : 'Continuar'}
                </Button>
              </FooterWrapper>
            </StyledForm>
          )}
          {step === 3 && (
            <>
              {!!l2 || !!l3 ? (
                <Spinner />
              ) : (
                <div className="text-center">
                  <MdDone size={62} className="done" color="#fff" />
                  <h4>Transação solicitada com sucesso!</h4>
                  <Row>
                    <p className="m-4">
                      O valor transferido estará disponível em sua conta em até {days} dias úteis.
                    </p>
                  </Row>
                </div>
              )}
            </>
          )}
        </ContentBox>
      </div>
    </Modal>
  );
}
