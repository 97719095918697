import React from "react";
import { Container, Wrapper, AlboomPayLogo } from "./styles";
import Icon from '@alboompro/boom-components/general/icon';

export default function NotFound() {
  return (
    <>
      <Container>
        <Wrapper>
          <div className="container">
            <Icon kind="regular" group="computers-devices-electronics" category="power-supply" file="charger.svg" size="74" color="#9A9A9A" />
            <h1>Sessão expirada</h1>
            <h2>Para sua segurança, sua sessão expirou.<br />
            Clique no botão abaixo para entrar novamente.</h2>
            <a href="/">Entrar</a>
          </div>
          <AlboomPayLogo>
            <img src="https://bifrost.alboompro.com/products/alboompay/AlboomPay2022-full.svg" alt="Alboom Pay"/>
          </AlboomPayLogo>
        </Wrapper>
      </Container>
    </>
  );
}
