import React, { useEffect, useState } from 'react';
import { Container } from './styles';
import Icon from '@alboompro/boom-components/general/icon';

export default function EmptyTable({ search, text = "Nenhum resultado" }) {
  return (
    <>
      <Container>
        <Icon kind="bold" group="files-folders" category="folders" file="folder-empty-alternate.svg" size="48" color="#D9D9D9" />
        <div className="text" style={{paddingBottom: search ? "" : "17px"}}>{search === '' ? text : `${text} para a busca:`}</div>
        {<div>{search && search}</div>}
      </Container>
    </>
  );
}
