import React from 'react';
import axios from '../../services/axios';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Form } from 'react-final-form';
import { Container, Info } from './styles';
import Button from '@alboompro/boom-components/general/button';

class Wizard extends React.Component {
  static propTypes = {
    onSubmit: PropTypes.func.isRequired,
  };
  static Page = ({ children }) => children;

  constructor(props) {
    super(props);
    this.state = {
      page: 0,
      values: props.initialValues || {},
      disableButton: props.disableButton,
    };
  }

  componentDidMount() {
    if (window.location.pathname === '/create') {
      this.setState({ page: 1 });
    }
  }

  next = values => {
    this.setState(state => ({
      page: Math.min(state.page + 1, this.props.children.length - 1),
      values,
    }));

    document.documentElement.scrollTop = 0;
  };

  previous = () => {
    this.setState(state => ({
      page: Math.max(state.page - 1, 0),
    }));

    this.props.setDisableButton(false);
    document.documentElement.scrollTop = 0;
  };

  /**
   * NOTE: Both validate and handleSubmit switching are implemented
   * here because 🏁 Redux Final Form does not accept changes to those
   * functions once the form has been defined.
   */

  validate = values => {
    const activePage = React.Children.toArray(this.props.children)[this.state.page];
    return activePage.props.validate ? activePage.props.validate(values) : {};
  };

  handleSubmit = values => {
    const { children, onSubmit } = this.props;
    const { page } = this.state;
    const isLastPage = page === React.Children.count(children) - 1;
    const isSMSPage = page === React.Children.count(children) - 2;

    if (isLastPage) {
      return onSubmit(values);
    } else if (isSMSPage) {
      this.next(values);
    } else {
      this.next(values);
    }
  };

  validateSMSCode = () => {
    const { alprotoken } = this.props.auth;
    this.props.setIsSMSCorrect(null);
    this.props.setSendingMessage(true);

    axios
      .post(
        '/sms-verification/verify',
        { code: window.getFormValues['sms_code'] },
        { headers: { Authorization: alprotoken } },
      )
      .then(
        ({
          data: {
            data: { success },
          },
        }) => {
          this.props.setSendingMessage(false);
          if (success) {
            this.props.setIsSMSCorrect(true);
            setTimeout(() => {
              this.next(window.getFormValues);
            }, 1000);
          } else this.props.setIsSMSCorrect(false);
        },
      )
      .catch(() => {
        this.props.setSendingMessage(false);
        this.props.setIsSMSCorrect(false);
      });
  };

  render() {
    const { children, submitMessage, disableButton, verifyMessage } = this.props;
    const { page, values } = this.state;
    const isFirstPage = page === 0;
    const activePage = React.Children.toArray(children)[page];
    const isLastPage = page === React.Children.count(children) - 1;
    const isSMSPage = page === React.Children.count(children) - 2;

    return (
      <Container>
        <Form
          initialValues={values}
          validate={this.validate}
          onSubmit={this.handleSubmit}
          mutators={{
            setValue: ([field, value], state, { changeValue }) => {
              changeValue(state, field, () => value);
            },
          }}
        >
          {({ handleSubmit, submitting, values, form }) => {
            if (!window.setFormValue) window.setFormValue = form.mutators.setValue;
            window.getFormValues = values;
            return (
              <form onSubmit={handleSubmit} style={{ width: '100%' }}>
                {activePage}

                <div className="row justify-content-center mt-2">
                  <div className="col-lg-7 col-12">
                    {isFirstPage && !isLastPage && (
                      <>
                        <Button
                          type="primary"
                          className="buttonBlue"
                          htmlType="submit"
                          style={{ margin: 'auto', maxWidth: 390, width: '100%', height: 52 }}
                          disabled={submitting}
                          loading={submitting}
                          textStyle={{ fontSize: '20px' }}
                        >
                          Iniciar configuração
                        </Button>
                        <Info>Serviço grátis, sem adesão e mensalidade.</Info>
                      </>
                    )}

                    <div className="row justify-content-center mt-2">
                      <div className="col-12 col-lg-6">
                        {page > 0 && (
                          <Button
                            type="primary"
                            className="button buttonGray"
                            onClick={this.previous}
                          >
                            Voltar
                          </Button>
                        )}
                      </div>

                      <div className="col-12 col-lg-6">
                        {!isFirstPage && !isSMSPage && (
                          <Button
                            type="primary"
                            className="buttonBlue"
                            htmlType="submit"
                            disabled={isSMSPage ? disableButton : submitting}
                            loading={submitting}
                          >
                            {!isLastPage && !isFirstPage ? 'Próximo' : null}
                            {isLastPage && (submitMessage || 'Enviar')}
                          </Button>
                        )}
                        {isSMSPage && (
                          <Button
                            type="primary"
                            className="buttonBlue"
                            disabled={disableButton}
                            loading={submitting}
                            onClick={this.validateSMSCode}
                          >
                            {verifyMessage}
                          </Button>
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                {/* Debug */}
                {/* <pre>{JSON.stringify(values, 0, 2)}</pre> */}
              </form>
            );
          }}
        </Form>
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(Wizard);
