import React from 'react';
import { useSelector } from 'react-redux';
import AnticipationTable from '../../components/AnticipationTable';
import PageHeader from '../../components/PageHeader';
import Steps from '../../components/Steps';
import { ContainerInner } from '../../styles/global';
import { CCTitle } from './styles';

const Anticipation = () => {
  const { created_at } = useSelector(state => state.auth);
  const {
    user_data: {
      verify_status,
      verification_request_at,
      verified_at,
      cc_total_transactions,
      last_cc_transaction_at,
      allow_antecipation,
    },
    loaded,
  } = useSelector(state => state.accounts);

  return (
    <>
      <PageHeader
        title="Antecipação"
        subtitle="Configure a antecipação para vendas no cartão de crédito."
      />
      <ContainerInner padding="45px">
        <Steps
          createdAt={created_at}
          verifyStatus={verify_status}
          verificationRequestedAt={verification_request_at}
          verifiedAt={verified_at}
          totalTransactions={cc_total_transactions}
          lastTransactionAt={last_cc_transaction_at}
          allowAntecipation={allow_antecipation}
        />
        <CCTitle>Selecione a configuração de antecipação desejada</CCTitle>
        <AnticipationTable
          verifyStatus={verify_status}
          totalTransactions={cc_total_transactions}
          createdAt={created_at}
          loadedData={loaded}
          allowAntecipation={allow_antecipation}
        />
      </ContainerInner>
    </>
  );
};

export default Anticipation;
