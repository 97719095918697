import { toast } from 'react-toastify';
import moment from 'moment';

export const { format: currencyFormat } = new Intl.NumberFormat('pt-BR', {
  style: 'currency',
  currency: 'BRL',
  minimumFractionDigits: 2,
});

export function maskPhoneNumber(value) {
  if (!value) return value;
  const onlyNums = value.replace(/[^\d]/g, '');

  if (onlyNums.length <= 2) return `(${onlyNums.slice(0, 2)}`;

  if (onlyNums.length <= 7) return `(${onlyNums.slice(0, 2)}) ${onlyNums.slice(2, 7)}`;

  if (onlyNums.length <= 11)
    return `(${onlyNums.slice(0, 2)}) ${onlyNums.slice(2, 7)}-${onlyNums.slice(7, 11)}`;

  return `(${onlyNums.slice(0, 2)}) ${onlyNums.slice(2, 7)}-${onlyNums.slice(7, 11)}`;
}

export const currencyFormatToParts = value => {
  const formattedValue = new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
    minimumFractionDigits: 2,
  }).formatToParts(value);

  const minusSign = formattedValue.some(el => el.type === 'minusSign');
  const integerValue = formattedValue
    .filter(part => part.type === 'integer')
    .map(part => part.value)
    .join('');
  const formattedIntegerValue = formattedValue
    .filter(part => part.type === 'integer' || part.type === 'group')
    .map(part => part.value)
    .join('');
  const decimalValue = formattedValue.find(part => part.type === 'fraction').value;

  const currencySymbol = formattedValue.find(part => part.type === 'currency').value;
  const decimalSymbol = formattedValue.find(part => part.type === 'decimal').value;

  return {
    minusSign,
    integerValue,
    formattedIntegerValue,
    decimalValue,
    currencySymbol,
    decimalSymbol,
  };
};

export const getDateToParts = (diffMonth = 0) => {
  const date = new Date(Date.now());
  date.setMonth(date.getMonth() + diffMonth);

  const month = new Intl.DateTimeFormat('pt-BR', { month: 'short' }).format(date.valueOf());
  const year = new Intl.DateTimeFormat('pt-BR', { year: 'numeric' }).format(date.valueOf());

  return { month: month.replace(/\./g, ''), year: `${year}`.substr(2) };
};

export const { format: percentFormat } = new Intl.NumberFormat('pt-BR', {
  style: 'percent',
  minimumFractionDigits: 2,
});

export const statusPagarme = {
  pending: 'Pendente',
  //processing: 'Processando',
  //authorized: 'Autorizado',
  paid: 'Pago',
  refunded: 'Estornado',
  waiting_payment: 'Boleto gerado',
  waiting_pix: 'Pix gerado',
  //pending_refund: 'Aguardando Estorno',
  refused: 'Recusado',
  chargedback: 'Chargedback',
};

export const orderType = {
  advanced: 'Solicitação Avançada',
  simple: 'Solicitação Simples',
};

export const paymentType = {
  boleto: 'Boleto',
  credit_card: 'Cartão de Crédito',
};

export const maskRn = value => {
  if (!value) return value;
  const onlyNums = value.replace(/[^\d]/g, '');
  if (onlyNums.length <= 2) return onlyNums;

  if (onlyNums.length <= 5) return `${onlyNums.slice(0, 2)}.${onlyNums.slice(2, 5)}`;

  if (onlyNums.length <= 8)
    return `${onlyNums.slice(0, 2)}.${onlyNums.slice(2, 5)}.${onlyNums.slice(5, 8)}`;

  if (onlyNums.length <= 12)
    return `${onlyNums.slice(0, 2)}.${onlyNums.slice(2, 5)}.${onlyNums.slice(
      5,
      8,
    )}/${onlyNums.slice(8, 12)}`;

  return `${onlyNums.slice(0, 2)}.${onlyNums.slice(2, 5)}.${onlyNums.slice(5, 8)}/${onlyNums.slice(
    8,
    12,
  )}-${onlyNums.slice(12, 14)}`;
};

export const maskUsername = value => {
  const regex = value.replace(/[^A-Za-z_]/g, '');
  return regex;
};

export const maskSsn = value => {
  if (!value) return value;
  const onlyNums = value.replace(/[^\d]/g, '');
  if (onlyNums.length <= 3) return onlyNums;

  if (onlyNums.length <= 6) return `${onlyNums.slice(0, 3)}.${onlyNums.slice(3, 6)}`;

  if (onlyNums.length <= 9)
    return `${onlyNums.slice(0, 3)}.${onlyNums.slice(3, 6)}.${onlyNums.slice(6, 9)}`;

  return `${onlyNums.slice(0, 3)}.${onlyNums.slice(3, 6)}.${onlyNums.slice(6, 9)}-${onlyNums.slice(
    9,
    11,
  )}`;
};

export const maskPhone = value => {
  if (!value) return value;
  const onlyNums = value.replace(/[^\d]/g, '');

  if (onlyNums.length <= 2) return onlyNums;

  if (onlyNums.length <= 6) return `(${onlyNums.slice(0, 2)})${onlyNums.slice(2, 6)}`;

  if (onlyNums.length <= 10)
    return `(${onlyNums.slice(0, 2)})${onlyNums.slice(2, 6)}-${onlyNums.slice(6, 10)}`;

  return `(${onlyNums.slice(0, 2)})${onlyNums.slice(2, 7)}-${onlyNums.slice(7, 11)}`;
};

export const maskZipCode = value => {
  if (!value) return value;
  const onlyNums = value.replace(/[^\d]/g, '');

  if (onlyNums.length <= 5) return onlyNums;

  if (onlyNums.length <= 8) return `${onlyNums.slice(0, 5)}-${onlyNums.slice(5, 8)}`;

  return `${onlyNums.slice(0, 5)}-${onlyNums.slice(5, 8)}`;
};

export const copyClipboard = (value, toastSuccess) => {
  var dummy = document.createElement('textarea');
  document.body.appendChild(dummy);
  dummy.value = value;
  dummy.select();
  document.execCommand('copy');
  document.body.removeChild(dummy);
  toast.success(toastSuccess);
};

export const validateSearch = value => {
  // const minLength = 3
  // if(value.length < minLength) {
  //   toast.error(`Você deve inserir pelo menos ${minLength} caracteres para pesquisar.`)
  //   return false;
  // }
  // return value;
  return true;
};

function nextBusinessDay(date) {
  if (moment(date).day() === 0) {
    return moment(date).add(1, 'days');
  } else if (moment(date).day() === 6) {
    return moment(date).add(2, 'days');
  }

  return date;
}

export const estimatedPaidRealease = (paid_at, payment_method, fees, type = 'qtd_days') => {
  const today = moment().format('DD/MM/YYYY');
  let daysToRelease = 2;
  let releaseDate = 0;

  //PIX IS instantly
  if (payment_method == 'pix') return false;

  if (payment_method == 'credit_card') {
    if (!fees.antecipation_automatic) return false;

    daysToRelease = fees.antecipation_days;
  }

  releaseDate = moment(paid_at).add(daysToRelease, 'days');

  releaseDate = nextBusinessDay(releaseDate);

  if (type == 'qtd_days') {
    daysToRelease = releaseDate.diff(moment(), 'days');

    if (daysToRelease >= 1) return daysToRelease;
  } else {
    return moment(releaseDate).format('DD/MM/YYYY');
  }

  return false;
};
