import React, { useEffect } from 'react';
import Wizard from '../../components/Wizard';
import { Field } from 'react-final-form';
import { WrapperLogo, Logo, Title, Subtitle, Prefix } from './styles';

export default function Wizard6() {
  const [username, setUsername] = React.useState('');

  function maskUsername(value) {
    const to_response = value.replace(/[^\d._A-Za-z]/g, '');
    setUsername(to_response);
    return to_response;
  }

  useEffect(() => {
    const fullname = window.getFormValues && window.getFormValues['fullname'];

    if (fullname) {
      const splitName = fullname.split(' ');
      const lengthName = splitName.length;

      const generateUsername = (splitName.length > 1
        ? `${splitName[0]} ${splitName[lengthName - 1]}`
        : splitName[0]
      ).toLowerCase();

      maskUsername(generateUsername);
    }
  }, []);

  useEffect(() => {
    window.setFormValue('username', username);
  }, [username]);

  return (
    <Wizard.Page>
      <WrapperLogo>
        <Logo
          src="https://bifrost.alboompro.com/products/alboompay/AlboomPay2022-full.svg"
          alt="Logo Alboom Pay"
        />
      </WrapperLogo>
      <div className="container">
        <div className="row justify-content-center title-pages">
          <div className="col-lg-6">
            <Title>Estamos quase lá...</Title>
          </div>
          <div className="col-lg-12 text-center">
            <Subtitle>Personalize o seu usuário Alboom Pay.</Subtitle>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-lg-7 text-center">
            <label className="input-text username">
              {/* <Error name="username" /> */}
              <Prefix>@</Prefix>
              <Field
                name="username"
                component="input"
                type="text"
                parse={maskUsername}
                placeholder="usuario.exemplo"
                maxLength="20"
                required
                autoComplete="off"
                className="input-field-username"
              />
            </label>

            <p className="comment">
              Seu link Alboom Pay comecará com alboompay.com/
              {username.toLowerCase() || window.getFormValues['username']}
              {!username && !window.getFormValues['username'] && (
                <span className="example">usuario</span>
              )}
            </p>
          </div>
        </div>
      </div>
    </Wizard.Page>
  );
}
