import styled from "styled-components";

export const FiltersWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;

  @media (max-width: 767px) {
    flex-direction: column;
    width: 100%;
    gap: 8px;
  }
`

export const DateRangeSelect = styled.div`
  background: #fff;
  cursor: pointer;
  padding: 5px 15px;
  min-width: 183px;
  min-height: 36px;
  line-height: 1.8;
  border: 1px solid #ebebeb;
  border-radius: 3px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  span {
    margin-left: 8px;
  }

  img {
    width: 16px;
    height: 16px;
  }

  @media (max-width: 767px) {
    order: 1;
  }
`

export const ExportToCsv = styled.button`
  position: relative;
  border: 1px solid #ebebeb;
  border-radius: 3px;
  padding: 10px;
  background-color: transparent;
  cursor: pointer;
  outline: 0;

  .tooltip {
    position: absolute;
    left: 0;
    transform: translate(-30%, 75%);
    background-color: #595959;
    border-radius: 3px;
    text-align: center;
    padding: 4px 16px;
    color: #ffffff;
    font-size: 0.625rem; // 10px
    letter-spacing: 0.4px;
    font-weight: 400;
    opacity: 0;
    pointer-events: none;
    z-index: 99;
    white-space: nowrap;
  }

  &:hover {
    .tooltip {
      opacity: 1;
      transition: 0.2s opacity 0.3s cubic-bezier(0.55, 0.3, 0.5, 0.9);
    }
    background-color: #f7f7f7;
  }

  &:focus {
    outline: 0;
  }

  img {
    min-width: 16px;
    min-height: 16px;
  }

  @media (max-width: 767px) {
    order: 2;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;

    .tooltip {
      display: none;
    }

    ::after {
      content: "Baixar em formato .csv"
    }
  }
`