import React from 'react';
import moment from 'moment';
import { useSelector } from 'react-redux';
import history from '../../services/history';
import Button from '@alboompro/boom-components/general/button';
import Icon from '@alboompro/boom-components/general/icon';
import { Container, Alert } from './styles';
import notebookCover from '../../assets/static/images/not-verified/notebook-cover.png';

import PromoMacBook from '../Stripes/PromoMacBook';
import PromoBlackHold from '../Stripes/PromoBlackHold';

const GlobalContainer = ({ small, children }) => {
  const {
    user_data: { username, verify_status, raffle_code },
  } = useSelector(state => state.accounts);
  const now = moment();
  return (
    <>
      {/* {now.isBefore('2022-12-01 00:00:00') && !raffle_code && <PromoMacBook />} */}
      {now.isBefore('2022-11-15 00:00:00') && <PromoBlackHold />}

      {username && verify_status === 'not_verified' && (
        <Alert>
          <img src={notebookCover} alt="Conta não verificada" />
          <div>
            <h1>Verifique sua conta</h1>
            <p>
              Ter a sua conta no Alboom Pay verificada é importante para desbloquear funções como a
              antecipação das vendas parceladas no cartão.
            </p>
            <Button
              onClick={() => history.push('/account-alboom-pay')}
              style={{ height: 35, backgroundColor: '#50AFF4' }}
              type="primary"
            >
              Iniciar Verificação
            </Button>
            <div className="floatIcon">
              <Icon
                kind="bold"
                group="interface-essential"
                category="form-validation"
                file="check.svg"
                size="36"
                color="#FFFFFF"
              />
            </div>
          </div>
        </Alert>
      )}
      <Container small={small}>{children}</Container>
    </>
  );
};

export default GlobalContainer;
