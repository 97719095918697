import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';

// ========================================================
// Global values
// ========================================================

// window.APP_CODE = 'alboom-pay';
// window.USER_TOKEN = '';
// window.USER_DATA = {};

// if (process.env.NODE_ENV === 'development') {
//   window.AUTH_URL = 'https://services.alboompro.com';
//   window.ACCOUNTS_URL = 'https://accounts.alboompro.com';
//   window.ENV = 'development';
//   // window.devToolsExtension && window.devToolsExtension.open();
// } else {
//   window.AUTH_URL = 'https://services.alboompro.com';
//   window.ACCOUNTS_URL = 'https://accounts.alboompro.com';
//   window.ENV = 'production';
// }

if (window.location.protocol !== 'https:' && process.env.NODE_ENV === 'production') {
  window.location.href = 'https:' + window.location.href.substring(window.location.protocol.length)
}

ReactDOM.render(<App />, document.getElementById('root'));
