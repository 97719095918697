import format from "date-fns/format"
import { ptBR } from "date-fns/locale"

export function formatToBRL(cents, currency) {
  let options = currency ? { style: "currency", currency: "BRL" } : {}

  return new Intl.NumberFormat("pt-BR", {
    ...options,
    minimumFractionDigits: 2,
  }).format(cents / 100)
}

export function formatToDate(strDate) {
  return format(new Date(strDate), "P", { locale: ptBR })
}

export function formatToDateAndTime(strDate) {
  return format(new Date(strDate), "Pp", { locale: ptBR })
}

export function formatToMobileDate(strDate) {
  return format(new Date(strDate), "dd LLL yy, HH:mm", {
    locale: ptBR
  }).toLocaleUpperCase()
}

export function formatToLongDate(strDate) {
  return format(new Date(strDate), "dd 'de' LLL 'de' yyyy", {
    locale: ptBR
  })
}