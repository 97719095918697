import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Modal from '@alboompro/boom-components/data-display/modal';
import Button from '@alboompro/boom-components/general/button';
import Input from '../../Input';
import { ContentBox, FooterWrapper, StyledForm } from './styles';
import { currencyFormat } from '../../../utils';
import Select from '../../Select';

export default function DiscountModal({ onSubmit: childOnSubmit, onClose: childOnClose }) {
  const [modal, setModal] = useState(true);
  const [data, setData] = useState({
    amount: '',
    amountFormatted: currencyFormat(0),
    use_percent: 2,
    name: '',
  });
  const { amountFormatted, use_percent, amount, name } = data;
  const options = [
    { label: 'Porcentagem', value: 1 },
    { label: 'Valor', value: 2 },
  ];

  function onClose() {
    setModal(false);
    childOnClose();
  }

  function onSubmit() {
    data.use_percent = use_percent === 1 ? true : false;
    childOnSubmit(data);
  }

  function handleValue({ target: { value } }) {
    let onlyNums = value.replace(/[^\d]/g, '');
    if (onlyNums > 10000000) onlyNums = 10000000;
    const centValue = (onlyNums / 100).toFixed(2);
    const amount = Math.round(centValue * 100);
    return setData({
      ...data,
      amount: amount,
      amountFormatted: currencyFormat(centValue),
    });
  }

  function handlePercent({ target: { value } }) {
    let onlyNums = value.replace(/[^\d]/g, '');
    if (onlyNums > 10000) onlyNums = 10000;
    const percentValue = (onlyNums / 100).toFixed(2);
    return setData({
      ...data,
      amount: parseFloat(percentValue),
      amountFormatted: percentValue.toString(),
    });
  }

  function handleSelect(e) {
    const use_percent = e.value;
    let amountFormatted = currencyFormat(0);
    if (use_percent === 1) {
      amountFormatted = parseFloat(0).toString();
    }
    setData(d => ({ ...d, amount: 0, use_percent, amountFormatted }));
  }

  return (
    <Modal
      title="Desconto"
      visible={modal}
      width={550}
      backdropStyle={{ backgroundColor: '#000000b8' }}
      backdropClosable={true}
      background={'#FFF'}
      bodyStyle={{ padding: '40px 25px', backgroundColor: '#f6fafe' }}
      onClose={onClose}
      closeButton={true}
      zIndex={999}
    >
      <div>
        <ContentBox>
          <StyledForm onSubmit={onSubmit}>
            <span className="label text-uppercase">Tipo de desconto</span>
            <Select
              required
              initialValue={use_percent || 2}
              onChange={handleSelect}
              className="mb-3 mt-3 select-full"
              options={options}
            />
            {use_percent === 1 ? (
              <Input
                required
                name="amountFormatted"
                placeholder={amountFormatted}
                value={amount ? amountFormatted : ''}
                onChange={handlePercent}
                label="Porcentagem de desconto"
              />
            ) : (
              <Input
                required
                name="amountFormatted"
                placeholder={amountFormatted}
                value={amount ? amountFormatted : ''}
                onChange={handleValue}
                label="Valor de desconto"
              />
            )}
            <FooterWrapper>
              <Button onClick={onClose} className="buttonDefault">
                Cancelar
              </Button>
              <Button htmlType="submit" className="buttonBlue">
                Adicionar
              </Button>
            </FooterWrapper>
          </StyledForm>
        </ContentBox>
      </div>
    </Modal>
  );
}

DiscountModal.propTypes = {
  initialData: PropTypes.number,
};

DiscountModal.defaultProps = {
  initialData: 0,
};
