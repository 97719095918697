import styled from 'styled-components';

export const WrapperLogo = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 40px;
`;

export const Logo = styled.img`
  margin: 0 auto;
  text-align: center;
  max-width: 210px;
  height: 75px;
`;

export const Title = styled.h2`
  font-size: 28px;
  font-weight: 400;
  strong {
    font-weight: 500;
  }
`;

export const Subtitle = styled.h2`
  font-size: 24px;
  font-weight: 400;
  line-height: 1.4;
  max-width: 580px;
  text-align: center;
  margin: 0px auto 10px;
`;

export const WelcomeMessage = styled.h3`
  font-size: 20px;
  font-weight: 400;
  text-align: center;
  color: #212121;
  margin-top: 30px;
  margin-bottom: 0px;
  line-height: 1.4;
  max-width: 800px;
`;

export const Container = styled.div`
  .CountrySelectWizard {
    .react-select__control {
      min-height: 53px;
    }
  }
  .submit-error ~ label {
    color: blue;
  }
  .dropdown {
    width: 100%;
    border: 1px solid #eeeeee;
    // height: 1.4rem;
    color: #373636;
    padding: 1.6rem;
    padding-right: 1.6rem;
    font-size: 1.9em;
  }

  .react-select__menu,
  .react-select__control:hover {
    cursor: pointer;
  }

  .submit-error {
    border: 1px solid #e83535;
    background: #fde0e0;
  }

  .example {
    color: #aaa;
    text-decoration: outline;
  }

  .radio label {
    margin: 0 12px;
  }

  .disabled {
    background: #eee;
  }

  .radio-group {
    height: 40px;
    display: flex;
    margin: 0 0 15px;

    div {
      text-transform: initial;
    }
  }

  .username input {
    padding-left: 70px;
  }

  .comment {
    margin-bottom: 10px;
  }

  .label {
    // margin-bottom: 0.3rem;
    /* font-weight: 500; */
    // line-height: 1.2;
  }

  .radio-custom {
    margin: 0;
    text-transform: none;
  }

  .react-date-picker__wrapper {
    border: none;
  }

  .datepicker {
    display: block;
    font-size: 1.3rem;
    margin-bottom: 0.3rem;

    .picker {
      border: 1px solid #eeeeee;
      background: #fff;
      padding: 10px;
      margin-top: -5px;
      width: 100%;
      height: 52px;
      input {
        background: #fff;
      }
    }
  }

  margin-top: 5em;
  margin-bottom: 5em;

  .phone-action-check {
    width: 75px;
    height: 75px;
  }

  div.pl {
    padding-left: 25px;
  }
  div.pr {
    padding-right: 25px;
  }

  div.number {
    width: calc(100% - 175px);
    margin-left: 15px;
  }
  div.digit {
    width: 135px;
    margin-right: 15px;
  }

  div.phone-area {
    margin: 0 auto;

    p.phone-info {
      margin-top: 20px;
      font-size: 15px;
      line-height: 18px;
      text-align: center;
      padding: 16px 36px;
      color: #6e6e6e;
      background-color: #f0f0f0;
      border-radius: 6px;
      margin-bottom: 25px;
    }
  }

  div.country-code {
    width: 93px;
    margin-right: 34px;

    .disabledCountryArea div div[disabled] {
      background-color: white;
      opacity: 100%;
    }

    .disabledCountryArea div div[disabled]::before {
      content: 'BR';
      width: 24px;
      height: 24px;
      background-color: #1b9dfa;
      display: inline-block;
      border-radius: 3px;
      color: white;
      text-align: center;
      line-height: 24px;
      margin-right: 6px;
      font-weight: bold;
    }
  }

  div.phone-number {
    width: calc(100% - 127px);
  }

  div.country-code input::before {
    content: 'a';
    display: inline-block;
    width: 20px;
    height: 20px;
    -moz-appearance: textfield;
    appearance: textfield;
    -webkit-appearance: none;
  }

  div.sms-confirmation {
    width: 266px;
    margin: 0 auto;
    font-size: 16px;
    line-height: 16px;

    input {
      padding: 17px 0 17px 48px;
      font-size: 16px;
      line-height: 16px;
      letter-spacing: 44px;
      text-shadow: 0px 0px 1px #000;

      &.sms-code-full {
        color: transparent;
      }
    }

    .sms-code-label {
      text-align: center;
      display: block;
      position: relative;
    }

    a {
      text-decoration: none;
      color: #44adf8;
      display: block;
      margin-top: -20px;
      margin-bottom: 30px;
      cursor: pointer;

      &:hover {
        text-decoration: underline;
        color: #44adf8;
      }

      @media (max-width: 768px) {
        margin-top: -5px;
      }
    }

    .loader {
      position: absolute;
      right: -40px;
      top: 42px;
      border: 3px solid #44adf8;
      border-top: 3px solid #f7f7f7;
      border-radius: 50%;
      width: 23px;
      height: 23px;
      animation: spin 2s linear infinite;

      @keyframes spin {
        0% {
          transform: rotate(0deg);
        }
        100% {
          transform: rotate(360deg);
        }
      }
    }

    .icon-validate {
      display: flex;
      align-items: center;
      position: absolute;
      right: -150px;
      top: 42px;
      color: #44adf8;

      @media (max-width: 768px) {
        & {
          right: -40px;
        }
      }

      &.incorrect {
        right: -188px;
        color: #dc624b;

        @media (max-width: 768px) {
          & {
            right: -40px;
          }
        }
      }

      span {
        font-size: 18px;
        line-height: 18px;
        margin-left: 18px;

        @media (max-width: 768px) {
          & {
            display: none;
          }
        }
      }
    }
  }

  input {
    color: #373737;
    border-radius: 3px;
    appearance: textfield;
  }
`;

export const AccountType = styled.div`
  .centered {
    text-align: center;
  }
  margin-bottom: 40px;
  h3 {
    margin: 0 0 0 4px;
    line-height: 1;
    display: inline;
  }
  h5 {
    margin: 0;
    font-weight: 400;
  }
`;
export const SubCategory = styled.div`
  // font-size: 1.8em;
  color: #434343;
  // margin-bottom: 1.2em;
  // margin-top: 1.2em;
  line-height: 4;
  margin-bottom: 10px;
`;

export const Footer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;

  label {
    display: flex;
    align-items: center;
    margin-bottom: 2.3rem;
  }
`;

export const WizardContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 5em;
  margin-bottom: 5em;
`;

export const ContainerSteps = styled.div`
  display: flex;
  align-items: baseline;
  width: 100%;
  justify-content: space-between;
  margin-top: 80px;

  @media screen and (max-width: 930px) {
    flex-direction: column;
    align-items: center;
  }
`;

export const Step = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  &.step-1 {
    max-width: 300px;
    width: 100%;

    img {
      margin-bottom: 55px;
      max-width: 200px;
    }

    .title {
      max-width: 200px;
      width: 100%;
      text-align: center;
      font-size: 22px;
      font-weight: 500;
      color: #000;
    }
  }

  &.step-2 {
    max-width: 300px;
    width: 100%;

    img {
      margin-bottom: 55px;
      max-width: 200px;
    }

    .title {
      width: 100%;
      text-align: center;
      font-size: 22px;
      font-weight: 500;
      color: #000;
    }
  }

  &.step-3 {
    max-width: 300px;
    width: 100%;

    img {
      margin-bottom: 55px;
      max-width: 200px;
    }

    .title {
      max-width: 150px;
      width: 100%;
      text-align: center;
      font-size: 22px;
      font-weight: 500;
      color: #000;
    }
  }
`;

export const Info = styled.p`
  font-size: 16px;
  color: #474747;
  margin-top: 20px;
`;

export const TypeTitle = styled.p`
  display: flex;
  align-items: center;
  position: relative;
  margin-bottom: 8px !important;
  color: #282828;
  font-size: 15px;
  font-weight: 400;
`;

export const Prefix = styled.div`
  position: absolute;
  top: 1px;
  left: 1px;
  height: 48px;
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f5f5f5;
  color: #4a4a4a;
  font-size: 20px;
`;
