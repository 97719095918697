import styled from 'styled-components';

export const Container = styled.button`
  padding: 8px 10px;
  background-color: transparent;
  border-radius: 3px;
  border: none;

  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;

  &:hover {
    background-color: #e9e9e9;
  }

  &:hover span {
    opacity: 1;
    transform: translate(0px, calc(100% + 8px));
  }

  &:focus {
    outline: none;
  }
`;

export const Tooltip = styled.span`
  background-color: #5a5a5a;
  border-radius: 2px;

  color: #fff;
  font-size: 12px;
  font-weight: 400;

  padding: 5px 18px;
  position: absolute;

  opacity: 0;
  transform: translate(0px, calc(100% + 11px));
  transition: 0.3s all cubic-bezier(0.55, 0.3, 0.5, 0.9);

  pointer-events: none;
  white-space: nowrap;
`;
