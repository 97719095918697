import React, { useState } from "react";
import PropTypes from 'prop-types';
import Modal from "@alboompro/boom-components/data-display/modal";
import Button from "@alboompro/boom-components/general/button";
import Input from "../../Input";
import { ContentBox, FooterWrapper, StyledForm } from "./styles";
import { currencyFormat } from '../../../utils';

export default function FreightModal({ onSubmit: childOnSubmit, onClose: childOnClose, initialData }) {
  const [modal, setModal] = useState(true);
  const [data, setData] = useState({
    freight: initialData ? initialData / 100 : '',
    freightFormatted: currencyFormat(initialData ? initialData / 100 : 0),
  })
  const { freightFormatted, freight } = data;
  const { REACT_APP_REQUESTS_MAX_FREIGHT: freightLimit } = process.env;

  function onClose() {
    setModal(false);
    childOnClose()
  }

  function onSubmit(data) {
    setModal(false);
    data.freight = freight;
    childOnSubmit(data);
  }

  function handleValue({ target: { value: v } }) {
    const value = parseInt(v.replace(/[^\d]/g, ""));
    const freight = (value > parseInt(freightLimit) ? parseInt(freightLimit) : value);
    const onlyNums = (freight / 100).toFixed(2);
    return setData({ ...data, freight, freightFormatted: currencyFormat(onlyNums) });
  }

  return (
    <Modal
      title="Frete"
      visible={modal}
      width={550}
      backdropStyle={{ backgroundColor: "#000000b8" }}
      backdropClosable={false}
      background={"#FFF"}
      bodyStyle={{ padding: "40px 25px", backgroundColor: "#f6fafe" }}
      onClose={onClose}
      closeButton={true}
      zIndex={999}
    >
      <div>
        <ContentBox>
          <StyledForm onSubmit={onSubmit}>
            <Input required name="freightFormatted" value={freightFormatted} onChange={handleValue} label="Valor do frete" />
            <FooterWrapper>
              <Button onClick={onClose} className="buttonDefault">
                Cancelar
              </Button>
              <Button htmlType="submit" className="buttonBlue">
                Salvar
              </Button>
            </FooterWrapper>
          </StyledForm>
        </ContentBox>
      </div>
    </Modal>
  );
}

FreightModal.propTypes = {
  initialData: PropTypes.number,
}

FreightModal.defaultProps = {
  initialData: '0'
}
