import styled from 'styled-components';
import { Form } from '@rocketseat/unform';
import media from '../../../styles/media';

export const ContentBox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  h1 {
    font-size: 2em;
    color: #242424;
  }
  h5 {
    font-weight: 400;
    font-size: 1.15em;
  }
  .amount {
    font-size: 30px;
    font-weight: 500;
    border: none;
    color: #727272;
    border: 1px solid #cccccc;
    padding: 10px 10px;
    ::placeholder {
      color: #d2cdcd;
    }
  }
  .amount-step2 {
    font-size: 48px;
  }
  h4 {
    font-weight: 400;
  }
  .done {
    background: #50aff4;
    border-radius: 50%;
    padding: 10px;
    margin: 0px 0 30px;
  }
`;
export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;

  button {
    width: 182px;
    margin-bottom: 0px !important;

    &:first-child {
      margin-right: 18px;
    }
  }
`;
export const StyledForm = styled(Form)`
  width: 100%;
  display: flex;
  flex-direction: column;
`;
export const Row = styled.div`
  display: inline-flex;
  > div {
    width: 100%;
  }
`;
export const Col = styled.div`
  width: ${props => props.width};
  display: inline-block;
  margin-right: ${props => (props.mr ? props.mr : '0px')};

  @media ${media.small} {
    width: ${props => (props.width_mb ? props.width_mb : '100%')};
    display: inline-block;
  }
`;

export const Option = styled.div`
  display: flex;
  align-items: center;
  img {
    width: 20px;
  }
  > * {
    margin: 0;
    padding: 0;
    margin-right: 5px;
  }
`;

export const BankName = styled.span`
  font-weight: 500;
`;
export const SelectWrapper = styled.div`
  margin-bottom: 30px;
`;

export const MessageLimitWithdraw = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  margin-bottom: 80px;
  align-items: self-start;
  padding: 10px;
  border: 1px solid #50aff4;
  border-radius: 3px;

  a {
    margin-left: 26px;
    font-weight: 500;
  }
`;

export const FirstBoxLimitWithdraw = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  p {
    margin-left: 9px;
    font-size: 14px;
    font-weight: 500;
  }
`;

export const AvailableAmount = styled.p`
  font-size: 14px;
  margin-top: -20px;
  margin-bottom: 20px;

  @media screen and (max-width: 767px) {
    margin-top: -10px;
  }
`;

export const PayoutFeesLabel = styled.p`
  font-size: 14px;
  margin-bottom: 45px;
`;

export const PayoutFees = styled.ul`
  font-size: 15px;
  color: #383838;
  list-style: disc;
  padding-left: 15px;
  margin-bottom: 50px;
`;
export const PayoutFeesItem = styled.li`
  font-size: 14px;
  color: #383838;
  margin-bottom: 5px;
`;

export const ConfirmationTitle = styled.h4`
  font-size: 18px;
  color: #212121;
  margin-bottom: 40px;
`;

export const ConfirmationSection = styled.div`
  margin-bottom: 30px;
`;

export const SectionTitle = styled.div`
  font-size: 16px;
  color: #212121;
  font-weight: 500;
  margin-bottom: 10px;
`;

export const SectionItem = styled.div`
  font-size: 16px;
  color: #212121;
  margin-bottom: 7px;

  > img {
    max-width: 25px;
    margin-right: 4px;
  }
`;

export const FooterWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  background-color: #fff;
  padding: 20px;

  position: absolute;
  bottom: -40px;
  right: -25px;
  left: -25px;

  border-top: 1px solid #eaeaea;

  button {
    width: 182px;
    margin-bottom: 0px !important;

    &:first-child {
      margin-right: 18px;
    }
  }
`;
