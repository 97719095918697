import styled from 'styled-components';

export const ColWrapper = styled.div`
  display: flex;

  @media screen and (max-width: 767px) {
    flex-direction: column;
    padding-top: 20px;
  }
`;

export const Col = styled.div`
  width: 50%;

  &:first-child {
    padding-right: 50px;
    border-right: 1px solid #f0f0f0;
  }
  &:last-child {
    padding-left: 50px;
  }

  @media screen and (max-width: 767px) {
    width: 100%;

    &:first-child {
      padding-right: 10px;
      padding-left: 10px;
      border-right: 0;
    }
    &:last-child {
      padding-right: 10px;
      padding-left: 10px;
    }
  }
`;

export const FieldWrapper = styled.div`
  margin-bottom: 30px;

  opacity: ${props => (props.disabled ? '0.5' : '1')};

  > span.label {
    display: flex;
    align-items: center;
    position: relative;
    margin-bottom: 8px;
    color: #282828;
    font-size: 14px;
    font-weight: 400;
  }

  @media screen and (max-width: 767px) {
    margin-bottom: 15px;
    padding-top: 10px;
  }
`;

export const RadioWrapper = styled.div`
  display: flex;
  align-items: center;
  padding-top: 10px;

  .radio-custom .radioContainer .checkmark {
    margin-right: 10px;
  }

  > label.radio-custom {
    margin-right: 40px;
  }

  > label.radio-custom:last-child {
    margin-right: 0px;
  }

  @media screen and (max-width: 767px) {
    padding-top: 10px;
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const AmountWrapper = styled.div`
  text-align: center;
  background-color: #f4faff;
  padding: 20px 0;
  margin-bottom: 20px;

  h4 {
    font-size: 16px;
    color: #363636;
    font-weight: 400;
  }
`;

export const Amount = styled.div`
  font-size: 38px;
  color: #212121;
  margin-top: 10px;
`;

export const FeeDetails = styled.div`
  font-size: 15px;
  background-color: #fbfbfb;
  border-radius: 3px;
  padding: 30px 0;
  min-height: 218px;
`;

export const InfoRow = styled.div`
  font-size: 16px;
  font-weight: ${props => (props.bold ? '500' : '400')};
  line-height: 1.2;
  margin-bottom: 10px;
  color: #474747;

  @media screen and (max-width: 767px) {
    display: flex;
  }
`;

export const InfoLabel = styled.span`
  width: 220px;
  display: inline-block;
  text-align: right;
  margin-right: 10px;

  @media screen and (max-width: 767px) {
    display: flex;
    margin-right: 0;
    text-align: left;
  }
`;

export const InfoValue = styled.span`
  @media screen and (max-width: 767px) {
    display: flex;
    align-items: center;
  }

  strong {
    font-weight: 500;
  }
`;

export const DetailsTitle = styled.div`
  text-align: center;
  margin-bottom: 15px;
  font-size: 17px;
  color: #212121;
`;

export const FeePercent = styled.span`
  margin-left: 10px;
  font-size: 11px;
  color: #7c7c7c;
  font-weight: 500;

  @media screen and (max-width: 767px) {
    display: none;
  }
`;
