import React, { useState } from 'react';
import Icon from '@alboompro/boom-components/general/icon';

import {
  GeneralContainer,
  Descriptions,
  DescriptionsHeader,
  ScriptContainer,
  TitleDescription,
  IconSuggestion,
  LinkHelpCenter,
  StyledCode,
  FooterDescription,
  ButtonCopy,
  CopyButtonContent
} from '../UseManually/styles';

export default function UseManually({ paymentButtonId }) {
  const [copyButtonClicked, setCopyButtonClicked] = useState(false);
  const script = `<script src="https://bifrost.alboompro.com/products/alboompay/buttons/dist/index.min.js" data-bid="${paymentButtonId}"></script>`

  const handleCopyURL = () => {
    navigator.clipboard.writeText(script)    
    setCopyButtonClicked(true)
    setTimeout(() => {
      setCopyButtonClicked(false)
    }
    , 3000)
  }

  return (
    <GeneralContainer>
      <DescriptionsHeader>Copie o código abaixo e cole no local indicado da sua landing page.</DescriptionsHeader>
      <ScriptContainer>
        <StyledCode>{script}</StyledCode>  
          <ButtonCopy onClick={handleCopyURL}>
            <CopyButtonContent onClick={handleCopyURL}>
              {copyButtonClicked ? (
                <>
                <Icon
                  kind="bold"
                  group="interface-essential"
                  category="form-validation"
                  file="check-circle-1.svg"
                  size="12"
                  color="#50AFF4"
                />
                  <span>Copiado</span>
                </>
              ) : (
                <>
                  <Icon
                    kind="bold"
                    group="files-folders"
                    category="common-files"
                    file="common-file-double-1-alternate.svg"
                    size="12"
                    color="#50AFF4"
                  />
                  <span>Copiar</span>
                </>
              )}
            </CopyButtonContent>
          </ButtonCopy>
      </ScriptContainer>

      <TitleDescription>Passo a passo</TitleDescription>
      <Descriptions>1. No editor da página procure pala opção de incorporar código;</Descriptions>
      <Descriptions>2. Cole o código acima ná área desejada;</Descriptions>
      <Descriptions>3. Publique as alterações feitas;</Descriptions>
      <Descriptions>4. Abra a página que inseriu o botão e faça o teste para certificar que ele está funcionando corretamente.</Descriptions>
      <FooterDescription>
        <IconSuggestion src="https://icons.alboompro.com/v1/bold/interface-essential/alerts/000000/information-circle.svg" />
        Para mais detalhes de como incorporar, acesse nossa <LinkHelpCenter href="https://suporte.alboom.com.br/hc/pt-br">Central de ajuda</LinkHelpCenter> ou entre em contato conosco.
      </FooterDescription>
   </GeneralContainer>
  );
}