import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  position: relative;
  .background {
    background: #ddd !important;
  }
  // .fc-bg tbody tr td:not(.fc-disabled-day) {
  //   cursor: pointer !important;
  // }
  td {
    cursor: pointer !important;
  }
  th.fc-day-header {
    padding: 14px 0;
    margin: 0;
    text-transform: capitalize;
  }
  .fc-toolbar.fc-header-toolbar {
    display: none;
  }
  .fc-row .fc-bg {
    z-index: 5;
  }
`;
