import styled from "styled-components";

export const PeriodSummaryWrapper = styled.div`
  padding: 0 16px;
  
  display: flex;
  flex-direction: column;
  gap: 8px;

  font-size: 0.8125rem; // 13px

  p {
    color: #818181;
    margin-bottom: 0;
  }

  @media screen and (max-width: 767px) {
    width: 100%;
    padding: 0;
    gap: 12px;

    font-size: 0.75rem; // 12px
  }
`

export const TotalsWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 55px;

  @media screen and (max-width: 767px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
  }
`

export const SummaryCard = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  
  font-weight: 500;

  div {
    margin-bottom: 0;
  }

  .skeleton {
    width: 90px;
    height: 14px;
  }

  span {
    &.income {
      color: #44AE70;
    }

    &.outcome {
      color: #262626;
    }
  }

  @media screen and (max-width: 767px) {
    width: 100%;
    justify-content: space-between;
    font-size: 0.75rem; // 12px

    .skeleton {
      width: 82px;
      height: 13px;
    }
  }
`