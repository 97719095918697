import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

import InitZendesk from '../utils/zendesk';

import Header from '../components/Header';
import Menu from '../components/Menu';
import PageFooter from '../components/PageFooter';
import UserReportButton from '../components/UserReportButton';
import { FullContainer } from '../styles/global';
import GlobalContainer from '../components/GlobalContainer';

const { REACT_APP_APP_CODE: appCode } = process.env;

const Zendesk = () => {
  const {
    user_data: { fullname, email },
  } = useSelector(state => state.accounts);

  if (fullname && email) {
    InitZendesk({ name: fullname, email });
  }
};

export const Full = ({ children, path }) => {

  const {
    user_data: { owner_id, },
    core_user: { beta_tests },
  } = useSelector(state => state.accounts);

  // console.log(useSelector(state => state.accounts));
  
  const {
    alprotoken: AuthUserToken
  } = useSelector(state => state.auth);

  Zendesk();

  useEffect(() => {
    if (owner_id && AuthUserToken) {
      (function(i, s, o, g, r, a, m) {
        i[r] =
          i[r] ||
          function() {
            (i[r].q = i[r].q || []).push(arguments);
          };
        i[r].i = 1 * new Date();
        if (!s.getElementById('sdk-' + r)) {
          a = s.createElement(o);
          m = s.getElementsByTagName(o)[0];
          a.id = 'sdk-' + r;
          a.async = 1;
          a.src = g;
          m.parentNode.insertBefore(a, m);
        }
      })(window, document, 'script', 'https://bifrost.alboompro.com/libs/alb/alb-1.1.2.js', 'alb');

      beta_tests.includes('contacts') && window.alb('contact', 'auth', owner_id, AuthUserToken, appCode, { staging: true })

      window.alb('cloud', 'auth', owner_id, 'wqLKYRNvrZ4RjD8sHP9Yr', AuthUserToken, appCode);
    }
  }, [owner_id, AuthUserToken, appCode]);

  const smallContainer = ['/payment-request', '/tax-receipt', '/payment-request/:contactEncode'];

  return (
    <FullContainer>
      <Header />
      <Menu />
      <GlobalContainer small={smallContainer.includes(path) ? true : false}>
        {children}
      </GlobalContainer>
      <PageFooter path={path} />
      <UserReportButton />
    </FullContainer>
  );
};

export const Initial = ({ children }) => {
  return children;
};
