import styled from 'styled-components';

export const Footer = styled.footer`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 80px 40px 0;

  border-top: 1px solid #ebebeb;
  padding: 20px 0;

  @media screen and (max-width: 767px) {
    flex-direction: column;
    align-items: flex-start;
  }

  @media screen and (min-height: 900px) {
    &.fixed {
      position: absolute;
      left: 285px;
      right: 0;
      bottom: 0;
    }
  }
`;

export const LegalList = styled.div`
  @media screen and (max-width: 767px) {   
    order: 1;
    margin-bottom: 20px;
  }

  @media screen and (max-width: 1180px) {
    display: flex;
    flex-direction: column;
    margin-left: 0;
  }
`;

export const LegalListItem = styled.a`
  font-size: 13px;
  color: #484848;

  &:hover {
    text-decoration: underline;
    color: #484848;
  }

  &:last-child {
    margin-left: 30px;
  }

  @media screen and (max-width: 767px) {
    &:last-child {
      margin-left: 0;
      margin-top: 5px;
    }
  }

  @media screen and (max-width: 1180px) {
    &:last-child {
      margin-left: 0;
      margin-top: 5px;
    }
  }
`;

export const LeftContent = styled.div`
  display: flex;
  align-items: center;

  @media screen and (max-width: 767px) {
    flex-direction: column;
    align-items: flex-start;
    order: 2;
  }

  @media screen and (max-width: 1180px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const LeftText = styled.p`
  font-size: 13px;
  color: #484848;
  margin: 0;

  @media screen and (max-width: 767px) {
    margin-bottom: 20px;
  }
`;

export const MethodsList = styled.div`
  display: flex;
  align-items: center;
  margin-left: 10px;

  img {
    height: 24px;
    margin-left: 5px;
  }

  @media screen and (max-width: 767px) {
    margin-left: 0;

    img:first-child {
      margin-left: 0px;
    }
  }

  @media screen and (max-width: 1180px) {
    margin-left: 0;
    margin-top: 5px;

    img:first-child {
      margin-left: 0;
    }
  }
`;

export const MethodsPlus = styled.div`
  background: rgb(233, 233, 233);
  border-radius: 2px;
  width: 36px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 5px;
  font-size: 11px;
  color: rgb(101, 101, 101);
`;
