import React from 'react';

import {
  Footer,
  LegalList,
  LegalListItem,
  LeftContent,
  LeftText,
  MethodsList,
  MethodsPlus,
} from './styles';

var currentTime = new Date();
var year = currentTime.getFullYear();

export default function PageFooter({ path }) {
  const footerFixed = ['/payment-simulate'];
  return (
    <Footer className={footerFixed.includes(path) ? 'fixed' : ''}>
      <LeftContent>
        <LeftText>{year} © Alboom Pay | Receba pagamentos até 3x mais rápido.</LeftText>
        <MethodsList>
          <img
            src="https://bifrost.alboompro.com/static/alboompay/icons/flags/boleto_bancario.svg"
            alt=""
          />
          <img
            src="https://bifrost.alboompro.com/static/alboompay/icons/flags/mastercard.svg"
            alt=""
          />
          <img src="https://bifrost.alboompro.com/static/alboompay/icons/flags/visa.svg" alt="" />
          <img src="https://bifrost.alboompro.com/static/alboompay/icons/flags/elo.svg" alt="" />
          <img src="https://bifrost.alboompro.com/static/alboompay/icons/flags/amex.svg" alt="" />
          <MethodsPlus>+5</MethodsPlus>
        </MethodsList>
      </LeftContent>
      <LegalList>
        <LegalListItem href="https://www.alboompro.com/terms-of-use/" target="_blank">
          Termos de uso
        </LegalListItem>
        <LegalListItem href="https://www.alboompro.com/privacy-policy/" target="_blank">
          Política de privacidade
        </LegalListItem>
      </LegalList>
    </Footer>
  );
}
