import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import Modal from '@alboompro/boom-components/data-display/modal';
import Tabs from '../../../components/Tabs';

import UseMyProsite from '../../../components/Modal/PaymentButton/UseMyProsite';
import UseManually from '../../../components/Modal/PaymentButton/UseManually';  

import IconProsite from '../../../components/Modal/PaymentButton/assets/images/IconProsite.svg';  

import {
  ContainerGeneral,
} from './styles';

export default function ModalPaymentButtons({ paymentButtonId, onClose }) {
  const { visible } = useSelector(state => state.modal);
  const [visibleOn, setVisibleOn] = useState(false);

  useEffect(() => { 
    setVisibleOn(visible);
  }, [visible]);

  function handleClose() {
    onClose();
  }

  const tabs = [
    {
      label: (
        <span>
          <img src={IconProsite} alt="IconProsite" style={{marginRight: '8px'} }/>
          Usar no meu Prosite
        </span>
      ),
      component: <UseMyProsite />
    },
    {
      label: 'Usar manualmente',
      component: <UseManually paymentButtonId={paymentButtonId} />,
    }
  ];

  return (
    <Modal
      title={'Incorporar botão de pagamento'}
      visible={visible}
      width={600}
      headerStyle={{borderRadius: '5px 5px 0 0'}}
      bodyStyle={{ padding: '0px 25px', backgroundColor: '#F6FAFE', borderRadius: '0 0 5px 5px'}}
      onClose={() => handleClose()}
    >
      <ContainerGeneral>
        <Tabs
          tabs={tabs}
          componentStyle={{ padding: '10px 0 0 0', borderWidth: '1px 0 0 0', borderRadius: 0, backgroundColor: '#F6FAFE'}}
        />
      </ContainerGeneral>
    </Modal>
  );
}