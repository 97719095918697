import produce from "immer";

const INITIAL_STATE = {
  list: [],
  loading: false
};

export default function bankAccounts(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case "@bankAccounts/REQUEST":
      case "@bankAccounts/POST":
      case "@bankAccounts/PUT":
      case "@bankAccounts/PUT_AND_WITHDRAW":
        draft.loading = true;
        break;
      case "@bankAccounts/SUCCESS": {
        draft.list = action.payload.data.data.bank_accounts;
        draft.loading = false;
        break;
      }
      case "@bankAccounts/POST_SUCCESS":
      case "@bankAccounts/PUT_SUCCESS":
      case "@bankAccounts/FAILURE":
      case "@bankAccounts/POST_FAILURE":
      case "@bankAccounts/PUT_FAILURE":
      case "@bankAccounts/DELETE_SUCCESS":
      case "@bankAccounts/DELETE_FAILURE":
      case "@bankAccounts/PUT_AND_WITHDRAW_SUCCESS":
      case "@bankAccounts/PUT_AND_WITHDRAW_FAILURE":
        draft.loading = false;
        break;
      case "@bankAccounts/CODE_STORE":
      default:
        break;
    }
  });
}
