import styled from 'styled-components';

export const Wrapper = styled.div`
  margin-bottom: 30px;
  padding: 20px;
  display: flex;
  width: 100%;
  background-color: ${props => (props.warning ? 'rgba(233, 194, 55, 0.08);' : '#F6F2FA')};
  border: 1px solid ${props => (props.warning ? '#E2DB32' : '#EBEBEB')};
  border-radius: 3px;
`;

export const IconWrapper = styled.div`
  margin-right: 15px;

  > img {
    width: 24px;
    height: 24px;
    max-width: 24px;
  }
`;

export const AlertMessage = styled.div`
  font-weight: 400;
`;

export const AlertTitle = styled.h4`
  font-size: 16px;
  color: #EE358E;
  margin-bottom: 10px;
`;

export const AlertDescription = styled.div`
  font-size: 15px;
  color: #4A4A4A;
  line-height: 1.4;
  b, strong {
    font-weight: 600;
  }
  p {
    margin-bottom: 10px;
  }
  ul {
    list-style: disc;
    padding-left: 20px;
    margin-bottom: 30px;
  }
  li {
    margin-bottom: 10px;
  }
  a {
    color: #3295dc;
    text-decoration: underline;
  }
  a:hover {
    color: #3295dc;
    text-decoration: underline;
  }
`;