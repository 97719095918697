import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { getAccountsRequest, putAccountsRequest } from '../../../store/modules/accounts/actions';

import { Form } from '@rocketseat/unform';

import Input from '../../Input';
import Checkbox from '../../Checkbox';
import Button from '../../Button';

import { Container, Title, Header, CheckboxLabel, Advise } from './styles';
import { configAccount } from '../../../validators/schema';

export default function TermsAndPolicies() {
  const { account_id, user_data, loading: accountsLoading } = useSelector(state => state.accounts);
  const dispatch = useDispatch();
  const initialData = {
    exchange_url: '',
    have_exchange_policy: false,
    privacy_url: '',
    policy_url: '',
  };
  const [data, setData] = useState(initialData);
  const { exchange_url, have_exchange_policy, privacy_url, policy_url } = data;

  const handleSubmit = () => {
    dispatch(putAccountsRequest(account_id, data));
  };

  const handleCancel = () => {
    setData(d => ({
      ...d,
      exchange_url: user_data.exchange_url,
      have_exchange_policy: user_data.have_exchange_policy,
      privacy_url: user_data.privacy_url,
      policy_url: user_data.policy_url,
    }));
  };
  useEffect(() => {
    !!account_id && dispatch(getAccountsRequest(account_id));
  }, [account_id, dispatch]);

  useEffect(() => {
    setData(d => ({
      ...d,
      exchange_url: user_data.exchange_url,
      have_exchange_policy: user_data.have_exchange_policy,
      privacy_url: user_data.privacy_url,
      policy_url: user_data.policy_url,
    }));
  }, [user_data]);

  return (
    <Container>
      <Header>
        <Title className="text-uppercase">Termos e políticas para venda online</Title>
        Adicione seus termos e políticas no perfil da sua conta para melhorar sua conversão.
      </Header>
      <Form onSubmit={handleSubmit} schema={configAccount}>
        <Input
          name="exchange_url"
          label="Política de troca e devoluções"
          placeholder="Endereço URL"
          value={have_exchange_policy ? exchange_url : ''}
          disabled={!have_exchange_policy}
          onChange={e => setData({ ...data, exchange_url: e.target.value })}
        />
        <div style={{ margin: '-10px 0px 30px 0px' }}>
          <Checkbox
            name="have_exchange_policy"
            label="  "
            checked={!have_exchange_policy}
            value={have_exchange_policy}
            onChange={() => {
              setData({ ...data, have_exchange_policy: !have_exchange_policy });
            }}
          >
            <CheckboxLabel>Meu negócio não possui Política de troca e devoluções</CheckboxLabel>
          </Checkbox>
          <Advise>
            Para vendas de produtos físicos e digitais é altamente recomendado exibir sua Política
            de troca e devoluções.
          </Advise>
        </div>
        <Input
          name="privacy_url"
          label="Política de privacidade"
          placeholder="Endereço URL"
          value={privacy_url}
          onChange={e => setData({ ...data, privacy_url: e.target.value })}
        />
        <Input
          name="policy_url"
          label="Termos gerais"
          placeholder="Endereço URL"
          value={policy_url}
          onChange={e => setData({ ...data, policy_url: e.target.value })}
        />

        <div style={{ paddingTop: '192px' }} className="row mt-4">
          <div className="col-lg-6 col-md-6">
            <Button className="buttonDefault buttonLarge" onClick={handleCancel}>
              Restaurar
            </Button>
          </div>
          <div className="col-lg-6 col-md-6">
            <Button className="buttonBlue buttonLarge" onClick={handleSubmit} disabled={accountsLoading}>
              Salvar
            </Button>
          </div>
        </div>
      </Form>
    </Container>
  );
}
