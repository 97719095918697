import React, { useState, useEffect } from 'react';
import axios from '../../services/axios';
import { useSelector, useDispatch } from 'react-redux';
import { putAccountsRequestSuccess } from '../../store/modules/accounts/actions';
import { toast } from 'react-toastify';
import moment from 'moment';
import Button from '@alboompro/boom-components/general/button';
import PageHeader from '../../components/PageHeader';
import Modal from '../../components/Modal';
import Tabs from '../../components/Tabs';
import PersonProfile from '../../components/MyProfile/PersonProfile';
import CompanyProfile from '../../components/MyProfile/CompanyProfile';
import TermsAndPolicies from '../../components/MyProfile/TermsAndPolicies';
import SocialMedia from '../../components/MyProfile//SocialMedia';
import { ContentArea, AccountStatus, ModalContent } from './styles';
import Icon from '@alboompro/boom-components/general/icon';

export default function AlboomAccount() {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [verificationErrors, setVerificationErrors] = useState([]);

  const dispatch = useDispatch();
  const { REACT_APP_APP_CODE: appCode } = process.env;

  const {
    account_id,
    user_data: {
      type,
      verified_at,
      verify_status,
      website,
      company_description,
      exchange_url,
      have_exchange_policy,
      policy_url,
      privacy_url,
      have_website,
      facebook,
      instagram,
      whatsapp,
      google_business,
      linkedin,
      twitter,
      reclameaqui,
      owner_id,
    },
  } = useSelector(state => state.accounts);
  const { created_at, alprotoken: AuthUserToken } = useSelector(state => state.auth);

  const verifyRequirements = () => {
    setButtonLoading(true);
    axios({
      method: 'post',
      url: `/accounts/${account_id}/request-verification`,
      data: {
        data: {
          company_description,
          website,
          exchange_url,
          have_exchange_policy,
          policy_url,
          privacy_url,
          have_website,
          social_media: {
            facebook,
            instagram,
            whatsapp,
            google_business,
            linkedin,
            twitter,
            reclameaqui,
          },
        },
      },
    })
      .then(
        ({
          data: {
            data: { account },
          },
        }) => {
          const formattedAccount = {
            data: {
              data: {
                account: account[0],
              },
            },
          };
          dispatch(putAccountsRequestSuccess(formattedAccount));
          toast.success('Verificação solicitada com sucesso');
          setButtonLoading(false);
        },
      )
      .catch(
        ({
          response: {
            data: {
              data: { errors },
            },
          },
        }) => {
          setVerificationErrors(errors);
          setButtonLoading(false);
          setIsModalVisible(true);
        },
      );
  };

  const tabs = [
    {
      label: 'Meu perfil',
      component: type === 'person' ? <PersonProfile /> : <CompanyProfile />,
    },
    {
      label: 'Termos e políticas',
      component: <TermsAndPolicies />,
    },
    {
      label: 'Redes sociais',
      component: <SocialMedia />,
    },
  ];

  // useEffect(() => {
  //   if (owner_id && AuthUserToken) {
  //     (function(i, s, o, g, r, a, m) {
  //       i[r] =
  //         i[r] ||
  //         function() {
  //           (i[r].q = i[r].q || []).push(arguments);
  //         };
  //       i[r].i = 1 * new Date();
  //       if (!s.getElementById('sdk-' + r)) {
  //         a = s.createElement(o);
  //         m = s.getElementsByTagName(o)[0];
  //         a.id = 'sdk-' + r;
  //         a.async = 1;
  //         a.src = g;
  //         m.parentNode.insertBefore(a, m);
  //       }
  //     })(window, document, 'script', 'https://bifrost.alboompro.com/libs/alb/alb-1.1.0.js', 'alb');
  //     window.alb('cloud', 'auth', owner_id, 'wqLKYRNvrZ4RjD8sHP9Yr', AuthUserToken, appCode);
  //   }
  // }, [owner_id, AuthUserToken, appCode]);

  return (
    <>
      <PageHeader
        title="Minha conta Alboom Pay"
        subtitle={
          verify_status === 'not_verified'
            ? `Para verificar sua conta, preencha todas as informações abaixo e pressione "Solicitar verificação".`
            : 'Personalize as informações do seu negócio'
        }
        label={
          verify_status === 'not_verified'
            ? 'Conta não verificada'
            : verify_status === 'verifying'
            ? 'Conta em verificação'
            : ''
        }
        labelIcon={
          verify_status === 'not_verified' ? (
            <Icon
              kind="bold"
              group="interface-essential"
              category="alerts"
              file="alert-circle.svg"
              size="18"
              color="#FF6E6E"
            />
          ) : verify_status === 'verifying' ? (
            <Icon
              kind="bold"
              group="interface-essential"
              category="time"
              file="time-clock-circle.svg"
              size="18"
              color="#E9D737"
            />
          ) : (
            ''
          )
        }
      />
      <ContentArea>
        <Tabs
          tabs={tabs}
          containerStyle={{ marginLeft: '0', flex: 1, marginRight: '50px', position: 'relative' }}
          componentStyle={{ width: '100%' }}
        />
        <AccountStatus
          style={{
            height:
              verify_status === 'verified'
                && '204px'
          }}
        >
          <div className="top">
            {verify_status === 'verified' && (
              <div
                className="iconStatus"
                style={{ backgroundColor: verify_status === 'verified' ? '#1b9dfa' : '#1b9dfa' }}
              >
                <img
                  class="verifiedIcon"
                  src="https://icons.alboompro.com/v1/bold/interface-essential/form-validation/FFFFFF/check.svg"
                  alt="Conta verificada"
                />
              </div>
            )}
            {verify_status === 'not_verified' && (
              <img
                src="https://icons.alboompro.com/v1/bold/interface-essential/alerts/FF6E6E/alert-circle.svg"
                alt="Conta não verificada"
                className="nonVerifiedIcon"
              />
            )}
            {verify_status === 'verifying' && (
              <img
                src="https://icons.alboompro.com/v1/bold/interface-essential/time/E9D737/time-clock-circle.svg"
                alt="Em processo de verificação"
                className="nonVerifiedIcon"
              />
            )}
          </div>
          <div className="status">
            {verify_status === 'verified' && (
              <>
                <strong>Conta verificada</strong>
                <p>Conta Alboom Pay criada em {moment(created_at).format('DD/MMM/YYYY')}</p>
                <p>Verificada em {moment(verified_at).format('DD/MMM/YYYY')}</p>
              </>
            )}
            {verify_status === 'not_verified' && (
              <>
                <strong>Como verificar a sua conta</strong>
                <p style={{ textAlign: 'center ' }}>
                  Para verificar a sua conta você precisará atualizar os seguintes dados do negócio:
                </p>
                <ul>
                  <li>Foto do documento CNH e/ou CPF do titular da conta.</li>
                  <li>Website</li>
                  <li>Termos e políticas</li>
                  <li>Descrição do negócio</li>
                </ul>
                <p style={{ maxWidth: '219px', paddingLeft: '16px' }}>
                  Após atualizar, clique no botão Salvar e depois no botão abaixo "Solicitar
                  Verificação".
                </p>
                <Button
                  onClick={() => verifyRequirements()}
                  style={{ height: '44px', backgroundColor: '#50AFF4' }}
                  type="primary"
                  loading={buttonLoading}
                >
                  Solicitar Verificação
                </Button>
                <p style={{ marginTop: '12px' }}>
                  O processo de verificação pode levar de 2 a 5 dias úteis.
                </p>
              </>
            )}
            {verify_status === 'verifying' && (
              <>
                <strong>Em processo de verificação</strong>
                <p
                  style={{
                    textAlign: 'center',
                    color: '#3A3A3A',
                    fontSize: '11px',
                    lineHeight: '15.4px',
                  }}
                >
                  Obrigado, sua conta está em processo de verificação. Você recebera um aviso assim
                  que o processo for finalizado.
                </p>
              </>
            )}
          </div>
        </AccountStatus>
      </ContentArea>

      {/* Verification Modal */}
      <Modal
        title="Solicitar Verificação"
        onClose={() => setIsModalVisible(false)}
        visible={isModalVisible}
        headerStyle={{ boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.26)', height: '48px' }}
        bodyStyle={{ backgroundColor: '#F7F7F7', padding: '20px 40px' }}
        footer={
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button
              onClick={() => setIsModalVisible(false)}
              style={{ height: '35px', backgroundColor: '#E4E4E4', color: '#000' }}
            >
              Fechar
            </Button>
          </div>
        }
      >
        <ModalContent>
          <img
            src="https://icons.alboompro.com/v1/bold/interface-essential/alerts/FF6E6E/alert-circle.svg"
            alt="Conta não verificada"
            style={{}}
          />
          <strong>
            Você ainda não preencheu todos os campos necessários para verificarmos sua conta.
          </strong>

          <ul>
            <p>Revise os itens que faltam:</p>
            {verificationErrors.map(error => (
              <li>{error}</li>
            ))}
          </ul>
        </ModalContent>
      </Modal>
    </>
  );
}
