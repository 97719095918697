import React from "react";
import { useSelector, useDispatch } from 'react-redux';
import { ordersClear } from '../../../store/modules/orders/actions';
import { toast } from 'react-toastify';
import Icon from '@alboompro/boom-components/general/icon';
import history from '../../../services/history';

import {
  Row,
  Container,
  RequestAnotherButton,
  ButtonWrapper,
  Button,
  ButtonText,
  BackToPayments
} from "./styles";
import icon from "@alboompro/boom-components/general/icon";

export default function () {
  const url = process.env.REACT_APP_PAYMENT_LINK_URL;
  const orders = useSelector(state=>state.orders.data);
  const dispatch = useDispatch();

  function WhatsappLink(username,id) {
    const message = `Aqui está a solicitação de pagamento conforme combinamos.\n Fique a vontade para escolher a forma de pagamento que melhor te atende.\n Muito obrigado! \n\n ${url}/${username}/${id}`;
    return `https://api.whatsapp.com/send?text=${message}`
  }

  function copyToClipboard(username,id) {
    var dummy = document.createElement("textarea");
    // to avoid breaking orgain page when copying more words
    // cant copy when adding below this code
    // dummy.style.display = 'none'
    document.body.appendChild(dummy);
    //Be careful if you use texarea. setAttribute('value', value), which works with "input" does not work with "textarea". – Eduard
    dummy.value = `${url}/${username}/${id}`;
    dummy.select();
    document.execCommand("copy");
    document.body.removeChild(dummy);
    toast.success("Link copiado com sucesso");
  }

  function clear(){
    dispatch(ordersClear());
  }

  return (
    <div className="container-flex">
      <div className="content">
        <Container>
          <Icon kind="bold" group="interface-essential" category="form-validation" file="check-circle-1.svg" size="60" color="#50AFF4" />
          <h2>Solicitação enviada com sucesso!</h2>
          <h3>As solicitações foram criadas e enviadas por e-mail.</h3>
          <p>Dica: Aproveite agora para enviar diretamente o link de pagamento via whatsapp ou rede social, assim seu cliente sente ainda mais segurança.</p>
          {
            orders.map(({account,id,payers:{name,email}}) =>
              <Row key={id}>
                <div className="CustomerInfo">
                  <div className="CustomerName">{name}</div>
                  <span className="CustomerEmail">{email}</span>
                </div>
                <ButtonWrapper>
                  <Button href={WhatsappLink(account.username,id)} target="blank">
                    <Icon kind="regular" group="logos" category="messaging-video-meetings" file="messaging-whatsapp.svg" size="22" color="#494949" />
                    <ButtonText>Enviar link</ButtonText>
                  </Button>
                  <Button onClick={() => copyToClipboard(account.username,id)}>
                  <Icon kind="bold" group="files-folders" category="common-files" file="common-file-double-1-alternate.svg" size="22" color="#494949" />
                    <ButtonText>Copiar link</ButtonText>
                  </Button>

{/* 
                  <a href={WhatsappLink(account.username,id)} className="WhatsApp" target="blank">
                    <FaWhatsapp size={20} color="#fff" />
                  </a>
                  <button href={`${url}/${account.username}/${id}`} onClick={() => copyToClipboard(account.username,id)}>
                    <FaCopy size={18} />
                  </button> */}
                </ButtonWrapper>
              </Row>
            )
          }
          <RequestAnotherButton onClick={clear}>Solicitar outro pagamento</RequestAnotherButton>
          <BackToPayments onClick={() => history.push('/payments')}>Ir para meus pagamentos</BackToPayments>
        </Container>
      </div>
    </div>
  );
}
