import React from "react"
import Icon from '@alboompro/boom-components/general/icon';

import { 
  formatToBRL,
  formatToDateAndTime,
  formatToMobileDate,
} from "../utils";

import yellowWarningSvg from "../../../assets/static/images/icons/yellow-warning.svg"

import { 
  DataWrapper, 
  DateCard, 
  DescriptionCard, 
  PaymentWrapper, 
  ValueCard,
  InstallmentsDetailsButton,
  PaymentDetailsButton,
  MobileDateCard,
} from "./styles";

export default function DataRow({ 
  operation, 
  tableColumns, 
  handleOperationDetailsOpen,
  handleAnticipationMovementOpen,
}) {
  const { 
    amount,
    fee, 
    type: operationType,
    date, 
    movement: {
      status: movementStatus,
      installments,
      type: movementType,
      payment_method,
      bank_response,
      bank_account,
      client_name,
      total_installments,
      metadata,
    },
    alboom_pay_payment_id,
  } = operation

  const {
    agencia,
    bank_name,
    conta,
    conta_dv,
  } = bank_account || {}

  const [
    dateCol,
    _,
    valueCol
  ] = tableColumns

  const [operationDate, operationTime] = formatToDateAndTime(date).split(", ")

  const isAnticipationGroup = installments?.length > 1

  const descriptionMessages = {
    credit: {
      pix: "Pagamento no PIX",
      boleto: "Pagamento no boleto",
    },
    refund: {
      credit_card: "Estorno de pagamento",
      pix: "Estorno de pagamento",
      boleto: "Estorno de pagamento",
    },
    chargeback: {
      credit_card: "Chargeback",
    },
  }
  
  let paymentDescription = ""
  let paymentDetails = ""
  let bank_description = ""
  if(operationType === "transfer") {
    if(movementType === "inter_recipient") {
      paymentDescription = "Transferência Alboom"
      bank_description = metadata.obs
    }
    else if(movementStatus === "failed" && amount > 0) {
      paymentDescription = "Estorno por falha na transferência"
    } else {
      const hiddenAgencia = new Array(agencia.length).join("*") + agencia.slice(-1)
      const hiddenCc = new Array(conta.length - 2).join("*") + conta.slice(-3)
      bank_description = `${bank_name} ag. ${hiddenAgencia} cc. ${hiddenCc}-${conta_dv}`
      paymentDescription = "Transferência efetuada"
    }
  } else if (movementType === "credit" && payment_method === "credit_card") {
    if(total_installments === 1) {
      if(fee > 0) {
        paymentDescription = "Pagamento no cartão de crédito"
        paymentDetails = "Antecipado"
      } else {
        paymentDescription = "Pagamento no cartão de crédito"
      }
    } else if(installments.length > 1) {
      paymentDescription = "Pagamento no cartão de crédito"
      paymentDetails = "Antecipado"
    } else if(installments.length === 1 && fee === 0) {
      paymentDescription = "Pagamento no cartão de crédito"
      paymentDetails = `Parcela ${installments[0].installment}`
    } else {
      paymentDescription = "Pagamento no cartão de crédito"
    }
  } else if (operationType === "fee_collection" && movementType === "contract_adjustment") {
    paymentDescription = "Liquidação residual"
  } else {
    try {
      paymentDescription = descriptionMessages[movementType][payment_method]
    } catch (error) {
      console.log('error', error)
    }
  }

  const [mobileDate, mobileTime] = formatToMobileDate(date).split(', ')
  const transferFailed = operationType === "transfer" && movementStatus === "failed" && amount >= 0
  const netBalance = amount - fee

  return (
    <DataWrapper>
      <MobileDateCard>
        <span>{mobileDate}</span>
        <span className="time">{mobileTime}</span>
      </MobileDateCard>
      <DateCard colWidth={dateCol.styles.width}>
        <span className="date">{operationDate}</span>
        <span className="time">{operationTime}</span>
      </DateCard>
      <DescriptionCard>
        <div className="descriptionWrapper">
          <PaymentWrapper>
            <span style={{ display: 'flex', alignItems: 'center' }}>
              <span>{paymentDescription}</span>
              {paymentDetails && (
                <span className="paymentDetails">
                  <span>{paymentDetails}</span>
                </span>
              )}
              {transferFailed && (
                <span className="iconWrapper">
                  <img src={yellowWarningSvg}/>
                  <span className="tooltip">{bank_response}</span>
                </span>
              )}
            </span>
          </PaymentWrapper>
          <span className="client-name">
            {operationType === "transfer" ? bank_description : client_name}
          </span>
        </div>
        {(isAnticipationGroup || alboom_pay_payment_id) && (
          <div className="buttonsWrapper">
            {isAnticipationGroup && (
              <InstallmentsDetailsButton
                onClick={() => handleAnticipationMovementOpen({
                  date,
                  installments,
                  totalAmount: netBalance
                })}
              >
                Ver parcelas
              </InstallmentsDetailsButton>
            )}
            {alboom_pay_payment_id && (
              <PaymentDetailsButton 
                onClick={() => handleOperationDetailsOpen(alboom_pay_payment_id)}
              >
                <Icon
                  kind="bold"
                  group="interface-essential"
                  category="view"
                  file="view-1.svg"
                  size="16"
                  color="#50AFF4"
                />
                <span>Ver pagamento</span>
              </PaymentDetailsButton>
            )}
          </div>
        )}
      </DescriptionCard>
      <ValueCard colWidth={valueCol.styles.width} profit={netBalance >= 0}>
        <span>{formatToBRL(Math.abs(netBalance))}</span>
        <Icon
          kind="bold"
          group="arrows-diagrams"
          category="arrows"
          file={netBalance >= 0 ? "arrow-down.svg" : "arrow-up.svg "}
          size="12"
          color={netBalance >= 0 ? "#44AE70" : "#262626"}
        />
      </ValueCard>
    </DataWrapper>
  )
}