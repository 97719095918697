import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useField } from '@rocketseat/unform';
import * as FontAwesome from 'react-icons/fi';
import { ReactComponent as FacebookIcon } from '../../assets/static/images/icons/facebook.svg';
import { ReactComponent as InstagramIcon } from '../../assets/static/images/icons/instagram.svg';
import { ReactComponent as WhatsAppIcon } from '../../assets/static/images/icons/whatsapp.svg';
import { ReactComponent as GoogleBusinessIcon } from '../../assets/static/images/icons/google-business.svg';
import { ReactComponent as LinkedInIcon } from '../../assets/static/images/icons/linkedin.svg';
import { ReactComponent as TwitterIcon } from '../../assets/static/images/icons/twitter.svg';
import { ReactComponent as NoIcon } from '../../assets/static/images/icons/no-icon.svg';
import { ReactComponent as ReclameAquiIcon } from '../../assets/static/images/icons/reclame-aqui.svg';

export default function Input({
  label,
  type,
  customMessage,
  infoSize,
  info,
  name,
  icon,
  size,
  fill,
  required,
  autocomplete,
  iconStyle,
  infoStyle,
  children,
  // defaultValue,
  value,
  onChange,
  maxlength,
  ...rest
}) {
  const ref = useRef(null);
  const { fieldName, registerField, error } = useField(name);

  const renderLabel = label;
  const InfoIcon = FontAwesome[info];

  const Icons = {
    facebook: <FacebookIcon />,
    instagram: <InstagramIcon />,
    whatsApp: <WhatsAppIcon />,
    googleBusiness: <GoogleBusinessIcon />,
    linkedIn: <LinkedInIcon />,
    twitter: <TwitterIcon />,
    noIcon: <NoIcon />,
    reclameAqui: <ReclameAquiIcon />,
  };

  const Icon = useEffect(() => {
    if (!ref.current) return;
    registerField({
      name: fieldName,
      ref: ref.current,
      path: 'value',
    });
  }, [ref.current, fieldName]); //eslint-disable-line

  return (
    <label className={`input-text ${error ? 'error' : ''}`} htmlFor={fieldName} icon={icon}>
      {error && <span className="error-msg">{error}</span>}
      {maxlength && (
        <span className="input-maxlength">
          {value ? value.length : 0}/{maxlength}
        </span>
      )}
      <input
        name={fieldName}
        type={type ? type : 'text'}
        ref={ref}
        id={fieldName}
        aria-label={fieldName}
        value={value}
        // defaultValue={defaultValue ? defaultValue : ''}
        required={required}
        autoComplete={autocomplete ? 'on' : 'no'}
        onChange={onChange}
        maxLength={maxlength}
        style={{ padding: icon ? '15px 15px 15px 51px' : '15px' }}
        {...rest}
      />
      <span className="label">
        {renderLabel}{' '}
        <div>
          {info && <InfoIcon size={infoSize} style={infoStyle} />}
          <div className="message">{customMessage}</div>
        </div>
      </span>
      {icon && Icons[icon]}
      {children}
    </label>
  );
}

Input.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  icon: PropTypes.string,
  info: PropTypes.string,
  autocomplete: PropTypes.bool,
  required: PropTypes.bool,
  type: PropTypes.string,
  customMessage: PropTypes.string,
  size: PropTypes.number,
  infoSize: PropTypes.number,
  iconStyle: PropTypes.objectOf(PropTypes.any),
  infoStyle: PropTypes.objectOf(PropTypes.any),
  children: PropTypes.element,
  value: PropTypes.string,
  onChange: PropTypes.func,
  maxlength: PropTypes.string,
};

Input.defaultProps = ({ name }) => ({
  icon: '',
  customMessage: 'Hey Brother!',
  label: '',
  size: null,
  iconStyle: null,
  infoStyle: null,
  children: null,
  onChange: () => {},
});
