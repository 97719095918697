export const set = (cname, cvalue, exdays) => {
  cvalue = window.btoa(cvalue)
  let expires = 'expires='
  if (exdays > 0) {
    let d = new Date()
    d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000))
    expires += d.toUTCString()
  } else {
    expires += '0'
  }
  
  document.cookie = `${cname}=${cvalue};${expires};path=/`
}

export const get = (cname, decode=true) => {
  let name = cname + '='
  let ca = document.cookie.split(';')
  for(let i = 0; i < ca.length; i++) {
    let c = ca[i]
    while (c.charAt(0) == ' ') {
      c = c.substring(1)
    }
    if (c.indexOf(name) == 0) {
      let content = c.substring(name.length, c.length)
      return decode ? window.atob(content) : content
    }
  }
  return ''
}

export default {
  set,
  get
}
