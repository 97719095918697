import { takeLatest, call, put, all } from "redux-saga/effects";
import { banksSuccess, banksFailure } from "./actions";
import Banks from '../../../services/core/http/banks';

export function* getBanks() {
  try {
    const response = yield call(Banks.get);
    yield put(banksSuccess(response));
  } catch (error) {
    console.log(error)
    yield put(banksFailure());
  }
}
export default all([
  takeLatest("@banks/REQUEST", getBanks),
]);
