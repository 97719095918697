import React, { useRef, useState } from "react";
import { useDispatch } from "react-redux";
import cx from 'classnames';

import { generateSMSCode } from "../../../../store/modules/accounts/actions";
import { verificationContent } from "./ModalContent";
import { maskPhoneNumber } from "../../../../utils";

export function Verify({ isVerifingPhone, setIsVerifingPhone, setPhoneNumber, isVerify, phoneNumber, submitBankAccount, handleClickButton, setVerification, inputRef, verifyingMessage }) {
  const Ref = useRef(null);
  const [cursor, setCursor] = useState(true)
  const [disableButton, setDisableButton] = useState(true);
  const [reSend, setReSend] = useState(true);
  const [timer, setTimer] = useState();
  const dispatch = useDispatch();

  const handleCreateCode = async () => {
    let phoneValue =  phoneNumber;

    if(!phoneValue) {
      phoneValue = inputRef.current.value;
    }
    setPhoneNumber(phoneValue);
    dispatch(generateSMSCode(phoneValue));
    setIsVerifingPhone(true);
  }

  const handleSubmitPhone = async () => {
    submitBankAccount();
    setVerification(false);
    setIsVerifingPhone(false);
    setReSend(false);
    onClickReset();
  }

  function maskCode(value) {
    setCursor(true);

    if (!value) return value;
    const onlyNums = value.replace(/[^\d]/g, '');

    if (onlyNums.length <= 1) {
      setDisableButton(true);
      return `${onlyNums.slice(0, 1)}`;
    }
    if (onlyNums.length <= 2) {
      setDisableButton(true);
      return `${onlyNums.slice(0, 2)}`;
    }
    if (onlyNums.length <= 3) {
      setDisableButton(true);
      return `${onlyNums.slice(0, 3)}`;
    }
    if (onlyNums.length === 4) {
      setDisableButton(false);
      setCursor(false);
      return `${onlyNums.slice(0, 4)}`;
    }
  }

  const getTimeRemaining = (e) => {
    const total = Date.parse(e) - Date.parse(new Date());
    const seconds = Math.floor((total / 1000) % 60);
    const minutes = Math.floor((total / 1000 / 60) % 60);
    return {
      total, minutes, seconds
    };
  }

  const startTimer = (e) => {
    let { total, minutes, seconds }
      = getTimeRemaining(e);
    if (total >= 0) {
      setTimer(
        (minutes > 9 ? minutes : '0' + minutes) + ':'
        + (seconds > 9 ? seconds : '0' + seconds)
      )
    }
  }

  const clearTimer = (e) => {
    setTimer('03:00');

    if (Ref.current) clearInterval(Ref.current);
    const id = setInterval(() => {
      startTimer(e);
    }, 1000)
    Ref.current = id;
  }

  const getDeadTime = () => {
    let deadline = new Date();

    deadline.setSeconds(deadline.getSeconds() + 59);
    deadline.setMinutes(deadline.getMinutes() + 2);
    return deadline;
  }

  const onClickReset = () => {
    clearTimer(getDeadTime());
  }

  const handleResend = () => {
    if(isVerifingPhone){
      handleCreateCode()
    } else {
      handleClickButton()
    }

    setReSend(false);
    onClickReset();
  }


  function handleBackButtonClick () {
    if(isVerifingPhone){
      setPhoneNumber(null);
      setIsVerifingPhone(false);
      return
    }

    setVerification(false);
  }

  return (
    <div>
      {!isVerifingPhone && !phoneNumber ?
      verificationContent({
        titleContent: "Verificamos que você ainda não tem seu celular cadastrado no Alboom Pay!",
        descriptionContent: "Antes de continuar, precisamos que cadastre seu telefone:",
        submitFunction: handleCreateCode,
        placeholder: "( ) _____-____",
        className: "container-phone",
        mask: maskPhoneNumber,
        fieldName: "phone_input",
        lengthInput: 15,
        spanContent: "",
        inputRef: inputRef,
        setVerification: setVerification,
        handleBackButtonClick: handleBackButtonClick,
      }) :
      verificationContent({
        titleContent: "Confirme a alteração",
        descriptionContent: "Enviamos seu código de verificação de 4 dígitos por SMS para o número ",
        phoneNumber: phoneNumber,
        submitFunction: isVerifingPhone ? handleSubmitPhone : submitBankAccount,
        placeholder: "____",
        className: cx("code-confirmation", { "sms-code-full": !cursor }, { "sms-code-error": isVerify === false }),
        mask: maskCode,
        fieldName: "sms_code",
        spanContent: "Código de confirmação",
        inputRef: inputRef,
        setVerification: setVerification,
        timer: timer,
        handleResend: handleResend,
        handleBackButtonClick: handleBackButtonClick,
    })}
    </div>
  );
} 
