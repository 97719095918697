import React, { useEffect } from 'react';
import Spinner from '../../components/Spinner';
import { useDispatch } from 'react-redux';
import { AlproRequest } from '../../store/modules/auth/actions';
import { Container } from './styles';

export default function () {
  const dispatch = useDispatch();

  const verifyLogin = () => {
    dispatch(AlproRequest());
  };

  useEffect(verifyLogin, []);

  return <Container><Spinner size={40} className="spinner" /></Container>
}
