import styled from 'styled-components';

export const WrapperLogo = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 60px;
`;

export const Logo = styled.img`
  margin: 0 auto;
  text-align: center;
  max-width: 280px;
  height: 75px;
`;

export const Container = styled.div`
  margin-top: 5em;
  margin-bottom: 5em;

  .message-error {
    margin: 0;
    font-size: 12px;
    color: red;
  }

  .link-gray {
    background: none;
    text-decoration: underline;
    margin: auto;
  }

  p {
    // font-size: 1.8em;
    margin-bottom: 2em;
  }

  h2 {
    font-size: 49.5px;
    font-weight: 400;
    margin-bottom: 0.8em;
  }

  h5 {
    font-size: 24px;
    font-weight: 400;
    margin-bottom: 24px;
  }

  .label {
    margin: 0 !important;
  }

  [for='nickname']:after {
    width: 10px;
    height: 16px;
    border: solid #aaa;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
    content: '';
    display: block;
    left: calc(100% - 35px);
    position: absolute;
    top: 35%;
  }
`;
