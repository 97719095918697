import React from 'react';
import { Container } from './styles';
import Activity from '../../components/Activity';

export default function Dashboard() {
  return (
    <Container>
      <Activity title="Todas as Atividades"/>
    </Container>
  );
}
