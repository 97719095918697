import React from 'react';
import Dropdown from '@alboompro/boom-components/data-display/dropdown/Dropdown';
import Button from '@alboompro/boom-components/general/button';
import Icon from '@alboompro/boom-components/general/icon';

import { WrapperPublish, ListDropdown, DropdownContent, DropdownItem } from './styles';

export default function ButtonDropdown({ primaryButton, visible, handleVisible, children }) {
  return (
    <WrapperPublish>
      {primaryButton && (
      <Button
        onClick={() => primaryButton.handleClick()}
        style={{ height: 34, width: primaryButton.width, padding: '0 20px', fontSize: '13px' }}
        textStyle={{ color: '#44ADF8', fontSize: 14 }}
      >
        {primaryButton.text}
      </Button>
      )}
      <ListDropdown>
        <Dropdown
          showArrow={false}
          trigger="click"
          overlayStyle={{ padding: 0, width: 'auto' }}
          visible={visible}
          onVisibleChange={visible => handleVisible(visible)}
          align="right"
          overlay={
            <DropdownContent onClick={() => handleVisible(false)}>{children}</DropdownContent>
          }
        >
          <Button style={{ height: 34 }}>
            <Icon
              kind="bold"
              group="arrows-diagrams"
              category="arrows"
              file="arrow-down-1.svg"
              size="12"
              color="#44ADF8"
            />
          </Button>
        </Dropdown>
      </ListDropdown>
    </WrapperPublish>
  );
}
