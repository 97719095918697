import React, { useEffect, useCallback } from 'react';
import { Container, Content, Wrapper, Footer } from './styles';
import { useDispatch, useSelector } from "react-redux";
import { payablesRequest } from "../../store/modules/balance/actions";
import BalanceControl from '../../components/BalanceControl';
import Select from '../../components/Select';
import { Title } from '../../styles/global';
import DailyPayments from '../../components/DailyPayments'
import Calendar from '../../components/Calendar'
import moment from "moment";
import { currencyFormat } from "../../utils"


export default function () {
  const currentMonth = moment(new Date());
  const { date, data } = useSelector(state => state.balance.payables)
  const options = [
    { value: currentMonth.format("YYYY-MM-01"), label: currentMonth.format("MMMM YYYY") },
    { value: currentMonth.add(1, "M").format("YYYY-MM-01"), label: currentMonth.format("MMMM YYYY") },
    { value: currentMonth.add(1, "M").format("YYYY-MM-01"), label: currentMonth.format("MMMM YYYY") },
    { value: currentMonth.add(1, "M").format("YYYY-MM-01"), label: currentMonth.format("MMMM YYYY") },
    { value: currentMonth.add(1, "M").format("YYYY-MM-01"), label: currentMonth.format("MMMM YYYY") },
    { value: currentMonth.add(1, "M").format("YYYY-MM-01"), label: currentMonth.format("MMMM YYYY") },
  ];
  const dispatch = useDispatch();

  const getPayables = useCallback(({ value: date }) => {
    dispatch(payablesRequest(date))
  },[dispatch])

  useEffect(() => {
    getPayables({ value: moment(new Date()).format("YYYY-MM-01") })
  }, [getPayables])

  const Total = () => data.length > 1 ? currencyFormat(data.length ? data.reduce((a, b) => parseInt(a.amount || a) + parseInt(b.amount)) / 100 : 0) : data.length ? currencyFormat(data[0].amount / 100 | 0) : 0;

  return (
    <Container>
      <BalanceControl />

      <div className="row">
        <div className="col-lg-7">
          <Content>
            <div className="d-flex justify-content-center align-items-center">
              <Title className="col p-3">Próximas Liberações</Title>
              <Select className="minified-select col" options={options} initialValue={date} onChange={getPayables} />
            </div>
            <Calendar />
            <Footer>
              <Wrapper className="row">
                <span className="col text-right">
                  <strong>Total do mês:</strong> <Total />
                </span>
              </Wrapper>
            </Footer>
          </Content>
        </div>
        <div className="col-lg-5">
          <Content>
            <DailyPayments />
          </Content>
        </div>
      </div>
    </Container>
  );
}
