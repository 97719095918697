import React, { useState, useEffect } from 'react';
import Select from '../Select';
import Icon from '@alboompro/boom-components/general/icon/Icon';
import iconFilter from '../../assets/static/images/icons/filter@3x.png';
import Orders from '../../services/core/http/orders';
import { currencyFormat, statusPagarme, validateSearch, estimatedPaidRealease } from '../../utils';
import { useLocation } from 'react-router-dom';
import moment from 'moment';
import PaymentsDetailsModal from '../Modal/PaymentDetails/General';
import { TableTitle } from '../../styles/global';
import { ordersListRequest } from '../../store/modules/orders/actions';
import { modalOpen } from '../../store/modules/modal/actions';
import ProofLogo from '../Icons/ProofLogo';
import { useSelector, useDispatch } from 'react-redux';
import Spinner from '../Spinner/Absolute';
import SpinnerButton from '../Spinner';
import Actions from './Actions';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-daterangepicker/daterangepicker.css';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import './MyPayments.scss';
import axios from '../../services/axios';
import {
  Container,
  Header,
  Wrapper,
  ReportTable,
  Status,
  StatusLeft,
  StatusRight,
  StatusCircle,
  PaymentMethod,
  PaymentMethodIcon,
  PaymentMethodInstallments,
  ClientWrapper,
  ClientName,
  ClientEmail,
  PaymentValue,
  TooltipFee,
  AlertFee,
  PaymentType,
  PaymentTypeIconWrapper,
  PaymentTypeCol,
  PaymentTypeTooltip,
  PaymentTypeName,
  PaymentTypeCode,
  Tooltip,
  iconInfo,
  WrapperActions,
  PaidAt,
  EstimatedDate,
  ExportToCsv,
  HeaderMobile,
  BodyMobile,
  ContainerMobile,
  RowMobile,
  LabelMobile,
} from './styles';
import SearchBar from '../SearchBar';
import EmptyTable from '../EmptyTable';

const MyPayments = () => {
  const [loadCSV, setLoadCSV] = useState(false);
  const { list, loading } = useSelector(state => state.orders);
  const { created_at } = useSelector(state => state.auth);
  const {
    user_data: { username, fees },
    core_user: { beta_tests },
  } = useSelector(state => state.accounts);

  const [searchtable, setSearchTable] = useState('');
  const dispatch = useDispatch();

  const statusToFilters = Object.keys(statusPagarme).map(status => ({
    value: status,
    label: statusPagarme[status],
  }));

  const location = useLocation();
  const { search: slug } = location.search
    .slice(1)
    .split('&')
    .map(p => p.split('='))
    .reduce((obj, [key, value]) => ({ ...obj, [key]: value?.replace('/?expires', '') }), {});

  const [data, setData] = useState({
    startDate: moment()
      .subtract(29, 'days')
      .format('YYYY-MM-DDT23:59:59-03:00'),
    endDate: moment(new Date()).format('YYYY-MM-DDT23:59:59-03:00'),
    filterBy: '',
    show: null,
    search: slug ?? '',
    submit: true, //get data on component init
  });

  const { startDate, endDate, search, filterBy, submit } = data;
  const statusList = [{ value: '', label: ' Todos ' }, ...statusToFilters];

  const desactivateLink = async order => {
    await Orders.desactivateLink(order.id);
    setData(d => ({ ...d, submit: true }));
  };

  const handleFilter = f => setData(d => ({ ...d, filterBy: f.value, submit: true }));

  const handleSearch = value => {
    setSearchTable(value);
    validateSearch(value) && setData(d => ({ ...d, search: value, submit: true }));
  };

  const handleDetails = order =>
    dispatch(
      modalOpen({
        type: order.type === 'simple' ? 'paymentDetails' : 'paymentDetails',
        data: order,
      }),
    );

  useEffect(() => {
    if (submit) {
      dispatch(
        ordersListRequest({
          search,
          startDate,
          endDate,
          filterBy: !!filterBy ? filterBy : null,
        }),
      );
      setData(d => ({ ...d, submit: false }));
    }
  }, [submit, search, startDate, endDate, filterBy, dispatch]);

  async function handleCSV() {
    let formatedStartDate = new Date(startDate);
    let formatedEndDate = new Date(endDate);

    formatedStartDate = moment(formatedStartDate).format('DD-MM-YYYY');
    formatedEndDate = moment(formatedEndDate).format('DD-MM-YYYY');

    setLoadCSV(true);
    await axios
      .get('/payments', {
        params: {
          status: filterBy ? filterBy : null,
          startDate,
          endDate,
          exportsToCSV: 'true',
        },
        responseType: 'blob',
      })
      .then(res => {
        setLoadCSV(false);
        const href = URL.createObjectURL(res.data);
        const link = document.createElement('a');
        link.href = href;
        link.setAttribute(
          'download',
          'AlboomPay_Pagamentos_de_' + formatedStartDate + '_ate_' + formatedEndDate + '.csv',
        );
        document.body.appendChild(link);
        link.click();
      });
  }

  function orderName(order) {
    if (order.kind == 'paymentLink') return order.name;
    if (order.type == 'simple') return 'Solicitação simples';
    return 'Solicitação avançada';
  }

  const [newDates, setNewDates] = useState({
    start: moment().subtract(29, 'days'),
    end: moment(),
  });

  const { start, end } = newDates;

  const [label, setLabel] = useState('Últimos 30 dias');

  const handleCallback = (start, end) => {
    const startDate = start.format(),
      endDate = end.format();
    setData(d => ({ ...d, startDate, endDate, submit: true }));

    if (
      start.format('DD/MM/YYYY') ===
        moment()
          .subtract(6, 'days')
          .format('DD/MM/YYYY') &&
      end.format('DD/MM/YYYY') === moment().format('DD/MM/YYYY')
    ) {
      setLabel('Últimos 7 dias');
    } else if (
      start.format('DD/MM/YYYY') ===
        moment()
          .subtract(29, 'days')
          .format('DD/MM/YYYY') &&
      end.format('DD/MM/YYYY') === moment().format('DD/MM/YYYY')
    ) {
      setLabel('Últimos 30 dias');
    } else if (
      start.format('DD/MM/YYYY') ===
        moment()
          .startOf('month')
          .format('DD/MM/YYYY') &&
      end.format('DD/MM/YYYY') === moment().format('DD/MM/YYYY')
    ) {
      setLabel('Este mês');
    } else if (
      start.format('DD/MM/YYYY') ===
        moment()
          .subtract(1, 'months')
          .date(1)
          .format('DD/MM/YYYY') &&
      end.format('DD/MM/YYYY') ===
        moment()
          .startOf('month')
          .date(0)
          .format('DD/MM/YYYY')
    ) {
      setLabel('Mês anterior');
    } else if (
      start.format('DD/MM/YYYY') ===
        moment()
          .subtract(179, 'days')
          .format('DD/MM/YYYY') &&
      end.format('DD/MM/YYYY') === moment().format('DD/MM/YYYY')
    ) {
      setLabel('Últimos 6 meses');
    } else if (
      start.format('DD/MM/YYYY') ===
        moment()
          .subtract(365, 'days')
          .format('DD/MM/YYYY') &&
      end.format('DD/MM/YYYY') === moment().format('DD/MM/YYYY')
    ) {
      setLabel('Últimos 12 meses');
    } else if (
      start.format('DD/MM/YYYY') ===
        moment()
          .subtract(moment().diff(moment(new Date(created_at)), 'days') + 1, 'days')
          .format('DD/MM/YYYY') &&
      end.format('DD/MM/YYYY') === moment().format('DD/MM/YYYY')
    ) {
      setLabel('Todo o período');
    } else {
      setLabel(start.format('DD/MM/YYYY') + ' - ' + end.format('DD/MM/YYYY'));
    }
  };

  const [initialSettings, setInitialSettings] = useState({
    startDate: start.toDate(),
    endDate: end.toDate(),
    ranges: {
      'Últimos 7 dias': [
        moment()
          .subtract(6, 'days')
          .toDate(),
        moment().toDate(),
      ],
      'Últimos 30 dias': [
        moment()
          .subtract(29, 'days')
          .toDate(),
        moment().toDate(),
      ],
      'Este mês': [
        moment()
          .startOf('month')
          .toDate(),
        moment().toDate(),
      ],
      'Mês anterior': [
        moment()
          .subtract(1, 'months')
          .date(1)
          .toDate(),
        moment()
          .startOf('month')
          .date(0)
          .toDate(),
      ],
      'Últimos 6 meses': [
        moment()
          .subtract(179, 'days')
          .toDate(),
        moment().toDate(),
      ],
      'Últimos 12 meses': [
        moment()
          .subtract(365, 'days')
          .toDate(),
        moment().toDate(),
      ],
      'Todo o período': [
        moment()
          .subtract(moment().diff(moment(new Date(created_at)), 'days') + 1, 'days')
          .toDate(),
        moment().toDate(),
      ],
    },
    autoApply: true,
    locale: { customRangeLabel: 'Personalizado' },
  });

  const renderPaymentType = order => {
    if (order.origin == 'proof') {
      return <ProofLogo />;
    }

    if (order.kind == 'paymentLink') {
      return (
        <img
          src="https://icons.alboompro.com/v1/bold/interface-essential/link-unlink/818181/hyperlink-2.svg"
          width="18"
        />
      );
    }

    if (order.kind == 'paymentRequest') {
      return (
        <img
          src="https://icons.alboompro.com/v1/regular/money-payments-finance/cash-payments/818181/cash-payment-bills-1.svg"
          width="18"
        />
      );
    }

    if (order.kind == 'paymentButton') {
      return (
        <img
          src="https://icons.alboompro.com/v1/regular/interface-essential/form-validation/818181/check-button.svg"
          width="18"
        />
      );
    }

    return (
      <img
        src="https://icons.alboompro.com/v1/bold/interface-essential/tags-bookmarks/818181/tags-1.svg"
        width="18"
      />
    );
  };

  const renderPaymentTypeTooltip = order => {
    // TODO: Colocar ID dinamico
    if (order.origin == 'proof') {
      return `Pedido #${order.provider_id} feito pelo Proof`;
    }

    if (order.kind == 'paymentLink') {
      return 'Link de pagamento';
    }

    if (order.kind == 'paymentRequest') {
      return 'Solicitação de pagamento';
    }

    if (order.kind == 'paymentButton') {
      return 'Botão de pagamento';
    }

    return 'Pedido personalizado';
  };

  const renderPaymentMethod = order => {
    switch (order.payment_method) {
      case 'credit_card':
        return (
          <PaymentMethodIcon>
            <PaymentMethodInstallments>{order.installments}x</PaymentMethodInstallments>
            <img
              src="https://icons.alboompro.com/v1/bold/money-payments-finance/credit-card-payments/6A6A6A/credit-card-1-alternate.svg"
              width= {window.innerWidth <= 768 ? '18' : '22'}
            />
            <Tooltip>Cartão de crédito em {order.installments}x</Tooltip>
          </PaymentMethodIcon>
        );
      case 'boleto':
        return (
          <PaymentMethodIcon>
            <img
              src="https://bifrost.alboompro.com/static/alboompay/icons/bar_code2.svg"
              width="22"
            />
            <Tooltip>Boleto bancário</Tooltip>
          </PaymentMethodIcon>
        );
      case 'pix':
        return (
          <PaymentMethodIcon>
            <img
              src="https://bifrost.alboompro.com/static/alboompay/icons/logo_PIX_pb.svg"
              width="20"
              style={{ opacity: '0.5' }}
            />
            <Tooltip>Pix</Tooltip>
          </PaymentMethodIcon>
        );
    }
  };

  const renderMobileRow = order => {
    return (
      <ContainerMobile>
        <HeaderMobile>
          <PaymentType>
            <PaymentTypeCol>
              <PaymentTypeIconWrapper>
                {renderPaymentType(order)}
                <PaymentTypeTooltip>{renderPaymentTypeTooltip(order)}</PaymentTypeTooltip>
              </PaymentTypeIconWrapper>
            </PaymentTypeCol>
          </PaymentType>
          <ClientWrapper>
            <ClientName>{order.payers && order.payers.name ? order.payers.name : '-'}</ClientName>
            <ClientEmail>
              {order.payers && order.payers.email ? order.payers.email : '-'}
            </ClientEmail>
          </ClientWrapper>
          <WrapperActions>
            <Actions
              order={order}
              username={username}
              handleDetails={handleDetails}
              desactivateLink={desactivateLink}
            />
          </WrapperActions>
        </HeaderMobile>
        <BodyMobile>
          <RowMobile>
            <LabelMobile>STATUS</LabelMobile>
            <Status>
              <StatusLeft>
                <StatusCircle
                  className={`${order.inactive_since && `desactived`} ${order.status}`}
                />
                <p>
                  {order.inactive_since
                    ? 'Cancelado'
                    : statusPagarme[
                        order.payment_method === 'pix' && order.status === 'waiting_payment'
                          ? 'waiting_pix'
                          : order.status
                      ]}
                </p>
              </StatusLeft>
            </Status>
          </RowMobile>
          <RowMobile>
            <LabelMobile>VALOR BRUTO</LabelMobile>
            <PaymentValue>
              {order.payment_method && (
                <StatusRight>
                  <PaymentMethod>{renderPaymentMethod(order)}</PaymentMethod>
                </StatusRight>
              )}
              {order.paymentFees &&
                order.paymentFees.total_value &&
                order.paymentFees.total_value > order.amountTotal && (
                  <AlertFee>
                    {/* <p style={{ marginRight: '8px' }}>*</p> */}
                    <Icon
                      kind="bold"
                      group="interface-essential"
                      category="alerts"
                      file="information-circle.svg"
                      size="14"
                      color="#44ADF8"
                    />
                    <TooltipFee>Pago com acréscimo</TooltipFee>
                  </AlertFee>
                )}
              <p>
                {order.paymentFees && order.paymentFees.total_value
                  ? currencyFormat(order.paymentFees.total_value / 100)
                  : currencyFormat(order.amountTotal / 100)}
              </p>
            </PaymentValue>
          </RowMobile>
          <RowMobile>
            <LabelMobile>CRIADO EM</LabelMobile>
            <p>{moment(order.created_at).format('DD/MM/YYYY')}</p>
          </RowMobile>
          <RowMobile>
            <LabelMobile>PAGO EM</LabelMobile>
            {order.paid_transaction_at ? (
              <PaidAt>
                {moment(order.paid_transaction_at).format('DD/MM/YYYY')}
                {estimatedPaidRealease(order.paid_transaction_at, order.payment_method, fees) && (
                  <EstimatedDate>
                    a liberar em ~
                    {estimatedPaidRealease(order.paid_transaction_at, order.payment_method, fees)}{' '}
                    dias
                  </EstimatedDate>
                )}
              </PaidAt>
            ) : (
              <span>-</span>
            )}
          </RowMobile>
        </BodyMobile>
      </ContainerMobile>
    );
  };

  const renderRow = order => {
    return (
      <tr key={order?.payment_id ?? order?.id} onClick={() => handleDetails(order)}>
        <td>
          <PaymentType>
            <PaymentTypeCol>
              <PaymentTypeIconWrapper>
                {renderPaymentType(order)}
                <PaymentTypeTooltip>{renderPaymentTypeTooltip(order)}</PaymentTypeTooltip>
              </PaymentTypeIconWrapper>
            </PaymentTypeCol>
            {/* <PaymentTypeCol>
              <PaymentTypeName title={orderName(order)}>{orderName(order)}</PaymentTypeName>
              <PaymentTypeCode>{order?.payment_id ?? order?.id}</PaymentTypeCode>
            </PaymentTypeCol> */}
          </PaymentType>
        </td>
        <td>
          <ClientWrapper>
            <ClientName>{order.payers && order.payers.name ? order.payers.name : '-'}</ClientName>
            <ClientEmail>
              {order.payers && order.payers.email ? order.payers.email : '-'}
            </ClientEmail>
          </ClientWrapper>
        </td>
        <td>
          <Status>
            <StatusLeft>
              <StatusCircle className={`${order.inactive_since && `desactived`} ${order.status}`} />
              <p>
                {order.inactive_since
                  ? 'Cancelado'
                  : statusPagarme[
                      order.payment_method === 'pix' && order.status === 'waiting_payment'
                        ? 'waiting_pix'
                        : order.status
                    ]}
              </p>
            </StatusLeft>
            <StatusRight>
              {order.payment_method && <PaymentMethod>{renderPaymentMethod(order)}</PaymentMethod>}
            </StatusRight>
          </Status>
        </td>
        <td>
          <PaymentValue>
            <p>
              {order.paymentFees && order.paymentFees.total_value
                ? currencyFormat(order.paymentFees.total_value / 100)
                : currencyFormat(order.amountTotal / 100)}
            </p>

            {order.paymentFees &&
              order.paymentFees.total_value &&
              order.paymentFees.total_value > order.amountTotal && (
                <AlertFee>
                  <p style={{ marginRight: '8px' }}>*</p>
                  <Icon
                    kind="bold"
                    group="interface-essential"
                    category="alerts"
                    file="information-circle.svg"
                    size="13"
                    color="#44ADF8"
                  />
                  <TooltipFee>Pago com acréscimo</TooltipFee>
                </AlertFee>
              )}
          </PaymentValue>
        </td>
        <td>
          <p>{moment(order.created_at).format('DD/MM/YYYY')}</p>
        </td>
        <td>
          {order.paid_transaction_at ? (
            <PaidAt>
              {moment(order.paid_transaction_at).format('DD/MM/YYYY')}
              {estimatedPaidRealease(order.paid_transaction_at, order.payment_method, fees) && (
                <EstimatedDate>
                  a liberar em ~
                  {estimatedPaidRealease(order.paid_transaction_at, order.payment_method, fees)}{' '}
                  dias
                </EstimatedDate>
              )}
            </PaidAt>
          ) : (
            <span>-</span>
          )}
        </td>
        <td>
          <WrapperActions>
            <img
              src="https://icons.alboompro.com/v1/bold/interface-essential/view/44ADF8/view-1.svg"
              width="20"
            />
            <Actions
              order={order}
              username={username}
              handleDetails={handleDetails}
              desactivateLink={desactivateLink}
            />
          </WrapperActions>
        </td>
      </tr>
    );
  };

  return (
    <Container>
      {loading && <Spinner />}
      <Header>
        <Wrapper className="row aligm-items-center">
          <TableTitle className="col-lg-4"></TableTitle>

          <ExportToCsv onClick={handleCSV} className="disabled">
            {loadCSV ? (
              <SpinnerButton size={16} />
            ) : (
              <img
                className="download-icon"
                src={`https://icons.alboompro.com/v1/bold/internet-networks-servers/upload-download/${
                  window.innerWidth > 768 ? '6F6F6F' : '50AFF4'
                }/download-bottom.svg`}
              />
            )}
            <div className="tooltip">Baixar em formato .csv</div>
          </ExportToCsv>

          <DateRangePicker initialSettings={initialSettings} onCallback={handleCallback}>
            <div id="reportrange">
              <div>
                <img
                  src="https://icons.alboompro.com/v1/bold/interface-essential/date-calendar/6F6F6F/calendar-3.svg"
                  style={{ width: '16px', height: '16px' }}
                />
                <span style={{ marginLeft: '8px' }}>{label}</span>
              </div>
              <img
                src="https://icons.alboompro.com/v1/bold/arrows-diagrams/arrows/6F6F6F/arrow-button-down.svg"
                style={{ marginLeft: '10px', width: '10px', height: '10px' }}
              />
            </div>
          </DateRangePicker>
          <Select
            className="minified-select col"
            placeholder="Filtros"
            initialValue={filterBy}
            components={{
              DropdownIndicator: () => <img src={iconFilter} alt="icon" />,
            }}
            options={statusList}
            onChange={handleFilter}
            containerStyle={{ marginLeft: '15px' }}
          />
          <SearchBar
            slug={slug}
            loading={loading}
            className="col search"
            handleSearch={handleSearch}
            validateSearch={validateSearch}
          />
        </Wrapper>
      </Header>

      {window.innerWidth > 768 ? (
        <ReportTable>
          <div className="wrap">
            <table>
              <thead>
                <tr>
                  <th>Tipo</th>
                  <th>Cliente</th>
                  <th>Status</th>
                  <th>Valor Bruto</th>
                  <th>Criado em</th>
                  <th>Pago em</th>
                  <th>Ações</th>
                </tr>
              </thead>
              <tbody className={list.length ? '' : 'empty'}>
                {list.length && fees ? (
                  list.map(order => renderRow(order))
                ) : (
                  <EmptyTable search={searchtable} text={'Nenhum pagamento neste período'} />
                )}
              </tbody>
            </table>
          </div>
        </ReportTable>
      ) : (
        <div className={list.length ? 'list-mobile' : 'empty'}>
          {list.length && fees ? (
            list.map(order => renderMobileRow(order))
          ) : (
            <EmptyTable search={searchtable} text={'Nenhum pagamento neste período'} />
          )}
        </div>
      )}
      <PaymentsDetailsModal />
    </Container>
  );
};

export default MyPayments;
