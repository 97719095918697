import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  display: flex;
  border-radius: 10px;
  border: 1px solid #ebebeb;
  flex-direction: column;
  background: #fff;
  position: relative;
`;

export const Button = styled.button`
  display: flex;
  background: none;
  border: none;
  border-radius: 3px;
  background-color: #50aff4;
  color: #ffffff;
  justify-content: center;
  line-height: 1.3;
  padding: 10px 30px;
  margin: 0 15px;
  :focus {
    outline: 0;
  }

  @media screen and (max-width: 767px) {
    margin: 0;
    width: 100%;
  }
`;

export const Label = styled.span`
  background-color: #46ae70;
  border-radius: 30px;
  font-size: 10px;
  text-transform: uppercase;
  font-weight: 500;
  color: #ffffff;
  padding: 3px 10px;
  text-align: center;
`;

export const TableActions = styled.div`
  display: flex;
  justify-content: center;

  @media screen and (max-width: 767px) {
    flex-direction: column;
    align-items: center;
  }
`;

export const Header = styled.div`
  padding: 20px 30px;

  @media screen and (max-width: 767px) {
    padding: 15px;

    > div {
      justify-content: center;
    }
  }

  img {
    width: 16px;
    height: 16px;
  }
  input {
    width: 100%;
    border: none;
    background: none;
  }
  > * {
    flex-basis: 1;
    flex-grow: 0;
  }
`;

export const Wrapper = styled.div`
  justify-content: space-between;
  align-items: center;
`;

export const SearchBar = styled.div`
  position: relative;
  padding-right: 10px;
  width: 300px;
  color: #373636;
  display: flex;
  align-items: center;

  @media screen and (max-width: 767px) {
    width: 100%;
    padding: 0;
    margin-bottom: 10px;
  }

  button {
    position: absolute;
    right: 26px;
    background: transparent;
    border: none;
    outline-color: transparent;
  }
  input {
    width: 100%;
    padding: 10px 22px;
    background-color: #ffffff;
    border: 1px solid #dedede;
    border-radius: 3px;
    &:focus {
      box-shadow: 0 0 2px 1px #50aff4;
    }
  }
`;

export const ReportTable = styled.div`
  position: relative;
  padding: 10px 30px;

  .wrap {
    /* height: calc(100vh - 180px); */
    min-height: 400px;
    overflow: auto;
  }
  table {
    width: 100%;
  }
  thead {
    border-bottom: 1px solid #eee;
    background: #fff;
  }
  thead th {
    color: #262626;
    font-size: 12px;
    font-weight: 500;
    text-transform: uppercase;
    text-align: left;
    height: 30px;
    position: sticky;
    background: #fff;
    top: 0;
    z-index: 2;

    &:first-child {
      padding-left: 0;
    }
    &:last-child {
      padding-right: 0;
      text-align: center;
    }
  }
  tbody td {
    padding: 18px 0;
    border-bottom: 1px solid #eee;
    font-size: 15px;

    &:first-child {
      padding-left: 0;
      font-size: 15px;
      .flags {
        width: 30px;
        height: 30px;
      }
    }

    strong {
      text-transform: uppercase;
      margin-bottom: 3px;
    }
    small {
      text-transform: uppercase;
    }
    p {
      margin: 0;
    }
  }

  @media screen and (max-width: 767px) {
    tbody td {
      min-width: 100px;
    }

    tbody td:nth-child(1),
    tbody td:nth-child(2),
    tbody td:nth-child(3) {
      min-width: 200px;
    }
  }

  .banks > * {
    margin: 0 5px;
  }
  tbody td:last-child {
    text-align: center;

    .show-options {
      display: block;
    }
  }
  tbody td:nth-child(2) div {
    display: flex;
    flex-direction: column;
  }
  .options {
    position: absolute;
    background: #fff;
    box-shadow: 0 0 7px 0 rgba(6, 6, 6, 0.08);
    margin-left: calc(-136px + 50%);
    z-index: 9;
    display: none;
    div {
      button {
        line-height: 3em;
        display: flex;
        align-items: center;
        background: none;
        border: none;
        color: #aaa;
        width: max-content;
        outline: none;
        width: 100%;
        padding-right: 15px;
        padding-left: 15px;
        &:hover {
          color: #242424;
          img {
            opacity: 1;
          }
        }
      }
    }
    img {
      width: auto !important;
      height: auto !important;
      margin-right: 10px;
      opacity: 0.6;
    }
  }
`;

export const Icon = styled.div`
  width: 54px;
  height: 54px;
  border-radius: 50%;
  background: #f6f5f6;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 27px;
    height: 27px;
  }
`;

export const Status = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  div {
    margin-right: 5px;
  }
`;

export const StatusCircle = styled.div`
  height: 8px;
  width: 8px;
  background: #fb5b5b;
  border-radius: 4px;
`;
