import React from 'react';
import Spinner from '../index';
import { Container } from './styles'

export default function ({Zindex, ...rest}) {
  return (
    <Container>
      <Spinner size={40} className="center" zindex={Zindex} {...rest} />
    </Container>
  )
}
