import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  display: flex;
  border-radius: 10px;
  flex-direction: column;
  background: #fff;
  position: relative;
  border: 1px solid #ebebeb;
`;

export const Header = styled.div`
  padding: 20px 30px;
  img {
    width: 16px;
    height: 16px;
  }
  input {
    width: 100%;
    border: none;
    background: none;
  }
  > * {
    flex-basis: 1;
    flex-grow: 0;
  }
`;

export const Wrapper = styled.div`
  justify-content: space-between;
  align-items: center;
  font-size: 12px;

  .filters {
    padding: 0;
    max-width: 340px;

    @media (max-width: 767px) {
      flex-direction: column;
      align-items: stretch;
      max-width: 100%;

    }
  }
`;

export const SearchBar = styled.div`
  position: relative;
  padding-right: 10px;
  width: 340px;
  display: flex;
  align-items: center;
  font-size: 14px;;

  @media screen and (max-width: 767px) {
    &.search {
      width: 100%;
      padding: 0;
    }
  }

  button {
    position: absolute;
    right: 26px;
    background: transparent;
    border: none;
    outline-color: transparent;
  }
  input {
    width: 100%;
    padding: 10px 22px;
    background-color: #ffffff;
    border: 1px solid #dedede;
    border-radius: 3px;
    &:focus {
      box-shadow: 0 0 2px 1px #50aff4;
    }
  }
`;

export const DropdownContent = styled.ul`
  display: flex;
  flex-direction: column;
  padding: 5px;
  width: 170px;

  @media (max-width: 768px) {
    width: calc(100vw - 75px);
    max-width: 280px;
  }
`;


export const DropdownItem = styled.div`
  display: flex;
  align-items: center;
  padding: 7px 10px;
  color: #414141;
  font-size: 14px;
  transition: 0.2s;
  cursor: pointer;
  border-radius: 3px;
  position: relative;
  height: 36px;

  img {
    margin-right: 12px;
  }

  &:last-child {
    margin-top: 10px;

    &::before {
      content: '';
      display: block;
      width: 100%;
      height: 1px;
      background-color: #e4e4e4;
      position: absolute;
      top: -5px;
      left: 0;
    }

    &:hover {
      background-color: #FFD7D0;
    }
  }

  &:hover {
    background-color: #f1f9ff;
  }
`;

export const TableWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`;

export const TableHeader = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #EBEBEB;
  padding: 10px 30px;

  font-size: 12px;
  line-height: 1.3;
  font-weight: 500;
  text-transform: uppercase;
  color: #878787;
  text-align: left;

  @media screen and (max-width: 768px) {
    display: none;
  }
`;

export const TableBody  = styled.div`
  width: 100%;
  display: block;
  justify-content: space-between;
  min-height: 400px;
`;

export const TableItem = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #EBEBEB;
  align-items: center;
  padding: 10px 30px;

  font-size: 14px;
  font-weight: 400;
  color: #3E3E3E;
  line-height: 1.3;
  text-align: left;

  &:last-child {
    border-bottom: 0;
  }

  @media screen and (max-width: 768px) {
    flex-direction: column;
    padding: 14px;
  }  
`;

export const ButtonName  = styled.div`
  padding: 0 10px 0 0;
  width: 25%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;


  @media screen and (max-width: 768px) {
    font-weight: 500;
    margin-bottom: 4px;
    width: 100%;
    padding: 0px;
  }
`;

export const ButtonDescription  = styled.div`
  padding: 0 10px;
  width: 35%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  
  @media screen and (max-width: 768px) {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    white-space: initial;
    margin-bottom: 8px;
    padding: 0px;
    width: 100%;
  }
`;


export const ButtonAmount  = styled.div`
  padding: 0 15px;
  width: 13%;
  text-align: right;

  @media screen and (max-width: 768px) {
    width: 100%;
    font-size: 15px;
    text-align: left;
    padding: 0px;
    margin-bottom: 8px;
    font-weight: 500;
  }
`;


export const ButtonActions  = styled.div`
  width: 20%;

  @media screen and (max-width: 768px) {
    width: 100%;
  }
`;