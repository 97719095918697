import React, { useState, useEffect, useCallback } from 'react';
import { toast } from 'react-toastify';
import { useSelector, useDispatch } from 'react-redux';
import { ordersRequest } from '../../../store/modules/orders/actions';
import axios from '../../../services/core/http/discounts';

/* -- Componentes -- */
// import Input from '../../../components/Input';
import Textarea from '../../../components/Textarea';
import Checkbox from '../../../components/Checkbox';
import Button from '../../../components/Button';
import Col from '../../../components/Col';
import Customer from '../../../components/Customer';
import PaymentType from '../../../components/PaymentType';
import ContactPicker from '../../../components/ContactPicker';

// import Settings from '../Settings';
import Icon from '@alboompro/boom-components/general/icon';

/* -- Modais -- */
import ItemModal from '../../../components/Modal/Item';
import DiscountModal from '../../../components/Modal/Discount';
import FreightModal from '../../../components/Modal/Freight';
import CustomerModal from '../../../components/Modal/Person';

/* -- Util -- */
import { currencyFormat } from '../../../utils';
import {
  ButtonContainer,
  StyledForm,
  Table,
  Title,
  Body,
  Footer,
  ItemWrapper,
  Anchor,
  FooterRow,
  FooterKey,
  FooterValue,
  Discount,
  QuantityMobile,
  DescriptionItem,
  ContainerPaymentType,
} from './styles';

import { AddButton, Receiver } from '../styles';

export default function() {
  const { REACT_APP_REQUESTS_MAX_INSTALLMENTS: defaultInstallments } = process.env;

  const { installments_no_fees } = useSelector(
    state => state.accounts.payments_settings.credit_card,
  );

  const { payments_settings: paymentSettings, core_user: { beta_tests } } = useSelector(state => state.accounts);

  const state = {
    shipping: false,
    itens: [],
    editItem: {},
    itemModal: false,
    settings: false,
    subtotal: 0,
    subtotalFormatted: 'R$ 0,00',
    discounts: [],
    discount: 0,
    discountFormatted: 'R$ 0,00',
    discountModal: false,
    freight: 0,
    freightFormatted: 'R$ 0,00',
    freightModal: false,
    total: 0,
    totalFormatted: 'R$ 0,00',
    customers: [],
    customerModal: false,
    editCustomer: {},
    installments: installments_no_fees,
    payments_settings: paymentSettings,
    message: `Aqui está a solicitação de pagamento conforme combinamos. Muito obrigado!`,
  };
  const [data, setData] = useState(state);
  const dispatch = useDispatch();
  const loading = useSelector(state => state.orders.loading);
  const {
    itens,
    shipping,
    itemModal,
    editItem,
    subtotal,
    subtotalFormatted,
    discounts,
    discount,
    discountFormatted,
    discountModal,
    freight,
    freightFormatted,
    freightModal,
    totalFormatted,
    total,
    customers,
    customerModal,
    editCustomer,
    installments,
    payments_settings,
    message,
  } = data;

  function handleSubmit(payload) {
    if (total < 1000) {
      return toast.error('Valor mínimo de R$ 10,00');
    }

    dispatch(
      ordersRequest({
        ...payload,
        itens,
        subtotal,
        discounts,
        customers,
        shipping,
        message,
        freight,
        installments,
        payments_settings,
        type: 'advanced',
      }),
    );
  }

  function removeItem(id) {
    const temp = [...data.itens];
    temp.splice(id, 1);
    setData({ ...data, itens: temp });
  }

  function beginEditItem(id) {
    setData({ ...data, editItem: { ...itens[id], id }, itemModal: true });
  }

  function commitItem(item) {
    if (Object.keys(editItem).length) {
      itens[item.id] = item;
      return setData({ ...data, itens, itemModal: false, editItem: {} });
    }
    return setData({ ...data, itens: [...itens, item], itemModal: false });
  }

  function removeCustomer(id) {
    const customers = [...data.customers];
    customers.splice(id, 1);
    setData({ ...data, customers });
  }

  function beginEditCustomer(id) {
    return setData({ ...data, editCustomer: { ...customers[id], id }, customerModal: true });
  }

  function commitCustomer(customer) {
    if (Object.keys(editCustomer).length) {
      customers[customer.id] = customer;
      return setData({ ...data, customers, editCustomer: {}, customerModal: false });
    }
    return setData({ ...data, customers: [...customers, customer], customerModal: false });
  }

  const addCustomer = useCallback((contact) => {
    const newCustomers = customers

    newCustomers.push({
      id: newCustomers.length,
      name: contact.lastName ? contact.name+' '+contact.lastName : contact.name,
      email: contact.emails[0].email
    })

    return setData(d => ({
      ...d,
      customers: newCustomers,
    }));
  }, [data])

  const removeCustomerBeta = useCallback((id) => {
    const newCustomers = customers

    newCustomers.splice(id, 1);

    return setData(d => ({
      ...d,
      customers: newCustomers,
    }));
  }, [data])

  function Item({ item, quantity, amountFormatted, totalFormatted, id }) {
    return (
      <>
        <Col width="40%" width_mb="40%">
          <p><DescriptionItem>{item}</DescriptionItem><QuantityMobile>x{quantity}</QuantityMobile></p>
        </Col>
        <Col width="10%" width_mb="14%" display_mb="none">
          <p>{quantity}</p>
        </Col>
        <Col width="20%" width_mb="23%" display_mb="none">
          <p>{amountFormatted}</p>
        </Col>
        <Col width="20%" width_mb="42%" >
          <p>{totalFormatted}</p>
        </Col>
        <Col width="10%" width_mb="10%">
          <>
            <button className="icons" onClick={() => beginEditItem(id)}>
              <Icon
                kind="bold"
                group="interface-essential"
                category="edit"
                file="pencil-1.svg"
                size="15"
                color="#808080"
              />
            </button>
            <button className="icons" onClick={() => removeItem(id)}>
              <Icon
                kind="bold"
                group="interface-essential"
                category="delete"
                file="bin-1.svg"
                size="15"
                color="#DC624B"
              />
            </button>
          </>
        </Col>
      </>
    );
  }

  async function commitDiscount(discount) {
    let discountItens = [discount];

    // if (discountItens.reduce((a, b) => a + b.amount, 0) > subtotal) {
    //   setData(d => ({ ...d, discounts: [], discount: 0, discountFormatted: 'R$ 0,00', discountModal: false }))
    //   return toast.error("O desconto deve ser menor que o subtotal.")
    // }

    setData(d => ({ ...d, discounts: [], discount: 0, discountFormatted: currencyFormat(0) }));
    setData({ ...data, discounts: discountItens, discountModal: false });
  }

  function removeDiscounts() {
    setData(d => ({ ...d, discounts: [], discount: 0, discountFormatted: currencyFormat(0) }));
  }

  function removeFreight() {
    setData(d => ({ ...d, freight: 0, freightFormatted: currencyFormat(0) }));
  }

  const Itens = () =>
    !itens.length ? (
      <p className="noitens">Insira pelo menos um item</p>
    ) : (
      itens.map((item, key) => (
        <ItemWrapper>
          <Item {...item} key={key} id={key} />
        </ItemWrapper>
      ))
    );

  function handlePaymentType(type) {
    setData(d => ({
      ...d,
      installments: type.ccFee,
      payments_settings: type.method,
    }));
  }

  // /* Update Subtotal */
  useEffect(() => {
    const subtotal = itens && itens.reduce((a, b) => a + b.amount * b.quantity, 0);
    setData(d => ({ ...d, subtotal, subtotalFormatted: currencyFormat(subtotal / 100) }));
  }, [itens, editItem]);

  /* Update Discounts */
  useEffect(() => {
    const fetch = async () => {
      if (discounts.length) {
        const {
          data: {
            data: { discount: d },
          },
        } = await axios.get({ amount: subtotal, discounts });
        setData(data => ({
          ...data,
          discount: subtotal - d,
          discountFormatted: currencyFormat((subtotal - d) / 100),
        }));
      }
    };
    fetch();
  }, [discounts, subtotal]);

  /* Update Total */
  useEffect(() => {
    const total = subtotal + freight - discount;
    setData(d => ({ ...d, total, totalFormatted: currencyFormat(total / 100) }));
  }, [subtotal, discount, freight]);

  useEffect(() => {
    setData(d => ({ ...d, payments_settings: paymentSettings }));
  }, [paymentSettings]);

  useEffect(() => {
    setData(d => ({ ...d, installments: installments_no_fees }));
  }, [installments_no_fees]);

  return (
    <>
      <StyledForm onSubmit={handleSubmit}>
        <Table>
          <Title>
            <Col width="40%" width_mb="30%">
              <p>Descrição</p>
            </Col>
            <Col width="10%" width_mb="14%" display_mb="none">
              <p>Qtd.</p>
            </Col>
            <Col width="20%" width_mb="23%" display_mb="none">
              <p>Valor</p>
            </Col>
            <Col width="20%" width_mb="50%">
              <p>Total</p>
            </Col>
            <Col width="10%" width_mb="10%"></Col>
          </Title>

          <Body>
            <Itens />
          </Body>
          <AddButton type="button" onClick={() => setData({ ...data, itemModal: true })}>
            <img
              src="https://icons.alboompro.com/v1/bold/interface-essential/remove-add/50AFF4/add-circle.svg"
              alt=""
            />
            Adicionar item
          </AddButton>

          <Footer>
            <FooterRow>
              <FooterKey>Subtotal:</FooterKey>
              <FooterValue>{subtotalFormatted}</FooterValue>
            </FooterRow>

            <FooterRow>
              <FooterKey>Desconto:</FooterKey>
              <FooterValue>
                {discount ? (
                  <Discount>
                    <span>{discountFormatted}</span>
                    <button
                      className="icons"
                      onClick={e => {
                        e.preventDefault();
                        setData({ ...data, discountModal: true });
                      }}
                    >
                      <Icon
                        kind="bold"
                        group="interface-essential"
                        category="edit"
                        file="pencil-1.svg"
                        size="14"
                        color="#808080"
                      />
                    </button>
                    <button className="icons" onClick={removeDiscounts}>
                      <Icon
                        kind="bold"
                        group="interface-essential"
                        category="delete"
                        file="bin-1.svg"
                        size="14"
                        color="#DC624B"
                      />
                    </button>
                  </Discount>
                ) : (
                  <Anchor onClick={() => setData({ ...data, discountModal: true })}>
                    Adicionar
                  </Anchor>
                )}
              </FooterValue>
            </FooterRow>

            <FooterRow>
              <FooterKey>Frete:</FooterKey>
              <FooterValue>
                {freight ? (
                  <Discount>
                    <span>{freightFormatted}</span>
                    <button
                      className="icons"
                      onClick={e => {
                        e.preventDefault();
                        setData({ ...data, freightModal: true });
                      }}
                    >
                      <Icon
                        kind="bold"
                        group="interface-essential"
                        category="edit"
                        file="pencil-1.svg"
                        size="14"
                        color="#808080"
                      />
                    </button>
                    <button className="icons" onClick={removeFreight}>
                      <Icon
                        kind="bold"
                        group="interface-essential"
                        category="delete"
                        file="bin-1.svg"
                        size="14"
                        color="#DC624B"
                      />
                    </button>
                  </Discount>
                ) : (
                  <Anchor onClick={() => setData({ ...data, freightModal: true })}>
                    Adicionar
                  </Anchor>
                )}
              </FooterValue>
            </FooterRow>

            <FooterRow total>
              <FooterKey>Total:</FooterKey>
              <FooterValue>{totalFormatted}</FooterValue>
            </FooterRow>
          </Footer>
        </Table>

        <Receiver>
          <p className="description mt-0">Solicitação de pagamento para:</p>
          {beta_tests.includes('contacts') && (
            <ContactPicker onSelect={addCustomer}></ContactPicker>
          )}
          {customers.map((customer, id) => (
            <Customer
              {...customer}
              amount={totalFormatted}
              onRemove={beta_tests.includes('contacts') ? () => removeCustomerBeta(id) : () => removeCustomer(id)}
              onEdit={() => beginEditCustomer(id)}
              key={id}
              id={id}
            />
          ))}
          {!beta_tests.includes('contacts') && (
            <AddButton type="button" onClick={() => setData({ ...data, customerModal: true })}>
              <img
                src="https://icons.alboompro.com/v1/bold/interface-essential/remove-add/50AFF4/add-circle.svg"
                alt=""
              />
              Adicionar cliente
            </AddButton>
          )}
        </Receiver>

        <Textarea
          name="message"
          label="Sua mensagem"
          value={message}
          maxlength="255"
          onChange={e => setData({ ...data, message: e.target.value })}
          inlineStyle={{ marginBottom: '0' }}
        />

        <Checkbox
          name="shipping"
          label=" "
          value={shipping}
          onChange={() => setData({ ...data, shipping: !shipping })}
          infoSize={18}
          // left
        >
          <p> Solicitar endereço de entrega</p>
        </Checkbox>

        {installments && payments_settings.credit_card.enabled !== null && (
          <ContainerPaymentType>
            <PaymentType
              initialValues={{ method: payments_settings, ccFee: installments }}
              handlePaymentType={handlePaymentType}
            />
          </ContainerPaymentType>
        )}

        <ButtonContainer>
          <Button
            htmlType="submit"
            className="buttonBlue buttonLarge"
            disabled={loading}
            loading={loading}
          >
            Enviar solicitação
          </Button>
        </ButtonContainer>
      </StyledForm>

      {/* Modal Add/Edit Item */}
      {itemModal ? (
        <ItemModal
          initialData={editItem}
          onSubmit={commitItem}
          closeAction={() => setData({ ...data, editItem: {}, itemModal: false })}
        />
      ) : (
        ''
      )}

      {/* Modal Add/Edit Discount */}
      {discountModal ? (
        <DiscountModal
          onSubmit={commitDiscount}
          onClose={() => setData({ ...data, discountModal: false })}
        />
      ) : (
        ''
      )}

      {/* Modal Add/Edit Freight */}
      {freightModal ? (
        <FreightModal
          initialData={freight}
          onSubmit={({ freight, freightFormatted }) =>
            setData({ ...data, freight, freightFormatted, freightModal: false })
          }
          onClose={() => setData({ ...data, freightModal: false })}
        />
      ) : (
        ''
      )}

      {/* Modal Add/Edit Customer */}
      {customerModal ? (
        <CustomerModal
          onSubmit={commitCustomer}
          initialData={editCustomer}
          onClose={() => setData({ ...data, customerModal: false })}
        />
      ) : (
        ''
      )}
    </>
  );
}
