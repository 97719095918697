import styled from "styled-components";

export const GeneralContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  background-color: #F6FAFE;
  margin-top: 22px;
  overflow: hidden;

  @media screen and (max-width: 767px) {
    display: flex;
    margin-top: 8px;
    background-color: #F6FAFE;
    flex-direction: column;
    align-items: center;
  }
`;

export const ContainerImage = styled.img`
  width: 240px;
  height: auto;
  transform: translateX(-1px);
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  padding-left: 20px;

  @media screen and (max-width: 767px) { 
    width: 100%;
    margin-top: 30px;
    padding-left: 0;
  }
`;

export const TitleDescription = styled.p`
  font-weight: 500;
  font-size: 16px;
  color: #3E3E3E;
  display: block;
  align-self: flex-start;
`;

export const Descriptions = styled.p`
  font-weight: 400;
  font-size: 14px;
  color: #3E3E3E;
  background-color: #F6FAFE;
  line-height: 140%;
  margin-bottom: 10px;

  &:last-child {
    margin-top: 30px;
  }
`;

export const Icon = styled.img`
  width: 12px;
  height: 12px;
  margin-right: 6px;
`;


export const LinkHelpCenter = styled.a`
  color: #50AFF4;

  &:hover {
    text-decoration: underline
  }
`;