import styled from 'styled-components';

export const Label = styled.div`
    color: #666666;
`;

export const Title = styled.h5`
    margin: 0px;
`;

export const Container = styled.div`
    display: flex;
    flex-direction: column;
`; 