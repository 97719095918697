import React from 'react';

export default function ProofLogo() {
  return (
    <svg width="18" height="18" viewBox="0 0 24 19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4.96158 3.48813L0 8.44971L4.96158 13.4113L9.92316 8.44971L4.96158 3.48813Z"
        fill="#70C4EF"
      ></path>
      <path
        d="M9.93912 8.41L4.97754 13.3716L9.93912 18.3332L14.9007 13.3716L9.93912 8.41Z"
        fill="#602B86"
      ></path>
      <path
        d="M18.4208 0.000142278L9.96582 8.45508L14.9274 13.4167L23.3823 4.96172L18.4208 0.000142278Z"
        fill="#5CBBAA"
      ></path>
    </svg>
  );
}
