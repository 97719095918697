/* eslint-disable no-undef */
import React, { Component } from 'react';

import Icon from '@alboompro/boom-components/general/icon';
import { copyClipboard } from '../../../utils';

import Dropdown from '@alboompro/boom-components/data-display/dropdown/Dropdown';

import {
  DropdownIconWrapper,
  DropdownContent,
  DropdownItem
 } from './styles';

const url = process.env.REACT_APP_PAYMENT_LINK_URL;

class Actions extends Component {
  state = {
    active: false,
    visible: false,
  };

  render() {
    const { order, username, handleDetails, desactivateLink } = this.props;
    const { visible } = this.state;

    return (
      <div onClick={(e) => e.stopPropagation()}>
        <Dropdown
          visible={visible}
          onVisibleChange={visible => this.setState({ visible: visible })}
          trigger="click"
          overlayStyle={{ padding: 0, width: '235px' }}
          align="right"
          overlay={
            <DropdownContent onClick={() => this.setState({ visible: false })}>
              <DropdownItem onClick={() => handleDetails(order)}>
                <Icon
                  kind="bold"
                  group="money-payments-finance"
                  category="accounting-billing"
                  file="accounting-invoice.svg"
                  size="13"
                  color="#4F4F4F"
                />
                Detalhes do pagamento
              </DropdownItem>
              <DropdownItem
                onClick={() => {
                  copyClipboard(`${url}/${username}/${order.id}`, 'Link copiado');
                }}
              >
                <Icon
                  kind="bold"
                  group="interface-essential"
                  category="copy-paste"
                  file="copy-paste.svg"
                  size="13"
                  color="#4F4F4F"
                />
                Copiar link do pagamento
              </DropdownItem>
              <DropdownItem
                inactive_since={order.inactive_since || order.status !== 'pending'}
                onClick={() => {
                  if (order.inactive_since || order.status !== 'pending') return;
                  desactivateLink(order);
                }}
              >
                <Icon
                  kind="bold"
                  group="interface-essential"
                  category="delete"
                  file="delete.svg"
                  size="13"
                  color="#E83535"
                />
                Cancelar solicitação
              </DropdownItem>
            </DropdownContent>
          }
        >
          <DropdownIconWrapper>
            <Icon
              kind="bold"
              group="interface-essential"
              category="menu"
              file="navigation-menu-vertical.svg"
              size="15"
              color="#535353"
            />
          </DropdownIconWrapper>
        </Dropdown>
      </div>
    );
  }
}

Actions.propTypes = {};

export default Actions;
