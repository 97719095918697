import * as Yup from 'yup';
// import { validateCPFDocument } from './index';

export const configAccount = Yup.object().shape({
  zipcode: Yup.string()
    .required('O CEP é obrigatório')
    .matches(/\d{5}-\d{3}/, 'O CEP não é válido'),
  publicName: Yup.string()
    .required('O nome do negócio é obrigatório')
    .max(200),
  number: Yup.string()
    .required('O número é obrigatorio')
    .max(20),
});

export const paymentRequestSimple = Yup.object().shape({
  total: Yup.string()
    .transform(value => {
      const serializeTotal = Number(value.replace(/[^\d]+/g, ''));
      return serializeTotal < 1000 ? undefined : value;
    })
    .required('Valor mínimo R$ 10,00'),
  description: Yup.string().required('A descrição é obrigatória'),
});

export const paymentRequestSimpleModal = Yup.object().shape({
  name: Yup.string()
    .required('O nome é obrigatório')
    .max(200),
  email: Yup.string()
    .email('Insira um e-mail válido')
    .required('O e-mail é obrigatório'),
});

export const paymentRequestItem = Yup.object().shape({
  item: Yup.string()
    .required('O nome é obrigatório')
    .max(200),
  amountFormatted: Yup.string().required('O valor é obrigatório'),
  quantity: Yup.string().required('A quantidade é obrigatória'),
  quantity: Yup.number().max(1000,"Quantidade máx. 1000"),
});

export const paymentLinkRequestSimple = Yup.object().shape({
  name: Yup.string().required('O nome é obrigatório'),
  amount: Yup.string()
    .transform(value => {
      const serializeTotal = Number(value.replace(/[^\d]+/g, ''));
      return serializeTotal < 1000 ? undefined : value;
    })
    .required('Valor mínimo R$ 10,00'),
  description: Yup.string().required('A descrição é obrigatória'),
});
