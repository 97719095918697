import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  display: flex;
  border-radius: 10px;
  flex-direction: column;
  background: #fff;
  position: relative;
  border: 1px solid #ebebeb;

  .list-mobile {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 15px;
  }

  #reportrange {
    background: #fff;
    cursor: pointer;
    padding: 5px 15px;
    min-width: 180px;
    min-height: 38px;
    line-height: 1.8;
    border: 1px solid #ebebeb;
    border-radius: 3px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-left: 15px;

    @media screen and (max-width: 768px) {
      margin-left: 0;
      margin-bottom: 16px;
    }
  }

  @media screen and (max-width: 768px) {
    p {
      margin-bottom: 0;
      display: flex;
      align-items: flex-end;
      flex-direction: column;
    }
  }
`;
export const Header = styled.div`
  padding: 20px 30px;
  img {
    width: 16px;
    height: 16px;
  }
  input {
    width: 100%;
    border: none;
    background: none;
  }
  > * {
    flex-basis: 1;
    flex-grow: 0;
  }

  @media screen and (max-width: 768px) {
    .download-icon {
      width: 12px;
      height: 12px;
    }
  }
`;

export const Wrapper = styled.div`
  justify-content: space-between;
  align-items: center;

  .filters {
    @media (max-width: 768px) {
      flex-direction: column;
      align-items: stretch;
    }
  }
`;

export const ExportToCsv = styled.button`
  position: relative;
  border: 1px solid #ebebeb;
  border-radius: 3px;
  padding: 10px;
  background-color: transparent;
  cursor: pointer;
  outline: 0;

  .tooltip {
    position: absolute;
    left: 0;
    transform: translate(-30%, 75%);
    background-color: #595959;
    border-radius: 3px;
    text-align: center;
    padding: 4px 16px;
    color: #ffffff;
    font-size: 11px;
    font-weight: 400;
    opacity: 0;
    pointer-events: none;
    z-index: 99;
    white-space: nowrap;
  }

  &:hover {
    .tooltip {
      opacity: 1;
      transition: 0.2s opacity 0.3s cubic-bezier(0.55, 0.3, 0.5, 0.9);
    }
    background-color: #f7f7f7;
  }

  &:focus {
    outline: 0;
  }

  @media (max-width: 767px) {
    order: 2;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border: 1px solid #50aff4;
    color: #50aff4;

    .tooltip {
      display: none;
    }

    ::after {
      content: 'Baixar em formato .csv';
    }
  }
`;

export const ReportTable = styled.div`
  position: relative;
  padding: 10px 0;

  .wrap {
    /* height: calc(100vh - 180px); */
    min-height: 400px;
    /* overflow-x: auto; */
  }
  table {
    width: 100%;
  }
  thead {
    border-bottom: 1px solid #eee;
    background: #fff;
  }
  thead th {
    color: #262626;
    font-size: 12px;
    font-weight: 500;
    text-transform: uppercase;
    text-align: left;
    height: 30px;
    position: sticky;
    background: #fff;
    top: 0;
    z-index: 2;
    padding: 0 10px;

    &:first-child {
      padding-left: 20px;
      text-align: center;
      width: 80px;
    }
    &:last-child {
      padding-right: 20px;
      text-align: right;
    }

    &:nth-child(4) {
      width: 110px;
    }

    &:nth-child(6) {
      text-align: center;
    }
  }

  tbody tr {
    transition: background-color 0.06s cubic-bezier(0.02, 0.53, 0.58, 1);

    td:last-child > div > img {
      transition: opacity 0.06s cubic-bezier(0.02, 0.53, 0.58, 1);
      opacity: 0;
    }

    &:hover {
      background-color: #eff5fa;
      cursor: pointer;

      td:last-child > div > img {
        opacity: 1;
      }
    }

    &:last-child td {
      border-bottom: 0;
    }
  }

  tbody td {
    padding: 12px 10px;
    border-bottom: 1px solid #eee;
    font-size: 15px;

    &:first-child {
      padding-left: 27px;
      width: 80px;
    }

    &:last-child {
      padding-right: 20px;
    }

    &:nth-child(2) {
      width: 270px;
    }

    &:nth-child(3) {
      /* width: 175px; */
    }

    &:nth-child(4),
    &:nth-child(5),
    &:nth-child(6) {
      width: 140px;
    }

    &:nth-child(6) {
      text-align: center;
    }

    strong {
      text-transform: uppercase;
      margin-bottom: 3px;
    }
    small {
      text-transform: uppercase;
    }
    p {
      margin: 0;
    }
  }

  .empty {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  tbody td:last-child {
    text-align: center;
    padding-right: 0;
    position: relative;
    cursor: pointer;

    .show-options {
      display: block;
    }
  }

  @media screen and (max-width: 767px) {
    overflow: auto;

    td:last-child > div > img {
      opacity: 1 !important;
    }

    thead {
      white-space: nowrap;
    }

    thead th {
      z-index: 0;
    }

    tbody td:nth-child(1) {
    }
    tbody td:nth-child(2) div {
      min-width: 200px;
    }

    tbody td:nth-child(3) {
      min-width: 200px;
    }

    tbody td:nth-child(4) {
      min-width: 150px;
    }

    tbody td {
    }

    tbody td:nth-child(7) {
      min-width: 50px;
    }
  }

  tbody td:nth-child(2) div {
    display: inline-grid;
  }

  .options {
    position: absolute;
    background: #fff;
    box-shadow: 0 0 7px 0 rgba(6, 6, 6, 0.08);
    margin-left: calc(-200px + 50%);
    z-index: 999;
    display: none;
    div {
      button {
        line-height: 3em;
        display: flex;
        align-items: center;
        background: none;
        border: none;
        color: #aaa;
        width: max-content;
        outline: none;
        width: 100%;
        padding-right: 15px;
        padding-left: 15px;
        &:hover {
          color: #242424;
          img {
            opacity: 1;
          }
        }
      }
    }
    img {
      width: auto !important;
      height: auto !important;
      margin-right: 10px;
      opacity: 0.6;
    }
  }
`;

export const Icon = styled.div`
  width: 54px;
  height: 54px;
  border-radius: 50%;
  background: #f6f5f6;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 27px;
    height: 27px;
  }
`;

export const ContainerMobile = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;

  border-top: 1px solid #eee;
  padding: 24px 0;

  &:first-child {
    border-top: 0;
  }
`;

export const HeaderMobile = styled.div`
  width: 100%;
  margin-bottom: 10px;
  height: auto;
  display: flex;
  justify-content: space-between;
`;

export const BodyMobile = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
`;

export const RowMobile = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  justify-content: space-between;
  padding: 6px 0;
  align-items: baseline;
  height: 42px;

  &:last-child {
    padding: 0;
  }
`;

export const LabelMobile = styled.span`
  font-size: 12px;
  color: #878787;
  font-weight: 500;
  text-transform: uppercase;
`;

export const TooltipFee = styled.span`
  position: absolute;
  margin: 22px 0 0 20px;
  transform: translateX(-50%);
  border-radius: 2px;
  background-color: #5a5a5a;
  color: #ffffff;
  padding: 4px 15px;
  font-size: 11px;
  text-align: center;
  white-space: nowrap;
  opacity: 0;
  pointer-events: none;
  transition: 0.2s all cubic-bezier(0.55, 0.3, 0.5, 0.9);
`;

export const PaymentValue = styled.div`
  display: flex;
  width: 150px;

  @media screen and (max-width: 768px) {
    width: auto;
    align-items: center;
    gap: 6px;
  }
`;
export const AlertFee = styled.div`
  display: flex;

  &:hover ${TooltipFee} {
    opacity: 1;
  }
`;

export const WrapperActions = styled.div`
  display: flex;

  > img {
    margin-right: 10px;
  }
`;

export const IconWrapper = styled.span`
  border-radius: 3px;
  padding: 8px;
  display: inline-block;
  &:hover {
    background-color: #eaf6ff;
  }
`;

export const Status = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  position: relative;

  @media screen and (max-width: 768px) {
    align-items: baseline;
  }

  .pending.paid .StatusCircle {
    background: #45ae70;
  }
`;

export const StatusLeft = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;

  @media screen and (max-width: 768px) {
    align-items: baseline;
  }
`;

export const StatusRight = styled.div`
  padding-right: 20px;

  @media screen and (max-width: 768px) {
    padding-right: 0;
  }
`;

export const StatusCircle = styled.div`
  height: 8px;
  width: 8px;
  background: #e3d12f;
  border-radius: 4px;
  margin-right: 10px;

  &.paid {
    background: #45ae70;
  }
  &.canceled {
    background: #eb413d;
  }
  &.refused {
    background: #eb413d;
  }
  &.chargedback {
    background: #eb413d;
  }
  &.desactived {
    background: #eb413d !important;
  }
`;

export const PaymentMethod = styled.span`
  margin-left: 10px;
`;

export const Tooltip = styled.span`
  position: absolute;
  background: #5a5a5a;
  color: #ffffff;
  display: block;
  width: auto;
  left: 50%;
  transform: translateX(-50%);
  opacity: 0;
  bottom: -31px;
  padding: 5px 15px;
  border-radius: 2px;
  white-space: nowrap;
  font-weight: 400;
  font-size: 11px;
  transition: 0.2s all cubic-bezier(0.55, 0.3, 0.5, 0.9);
  pointer-events: none;

  @media screen and (max-width: 768px) {
    display: none;
  }
`;

export const PaymentMethodIcon = styled.span`
  position: relative;

  &:hover ${Tooltip} {
    opacity: 1;
    bottom: -28px;
  }
`;

export const PaymentMethodInstallments = styled.span`
  font-size: 12px;
  color: #767676;
  font-weight: 500;
  margin-right: 5px;
`;

export const ClientWrapper = styled.div`
  @media screen and (max-width: 768px) {
    max-width: 65%;
    min-width: 65%;
  }
`;

export const ClientName = styled.span`
  display: block;
  font-size: 16px;
  color: #262626;
  margin-bottom: 3px;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 210px;
  line-height: 1.3em;
  white-space: nowrap;
`;

export const ClientEmail = styled.span`
  display: block;
  font-size: 13px;
  color: #6d6d6d;
  overflow: hidden;
  line-height: 1.3em;
  text-overflow: ellipsis;
  white-space: nowrap;
  min-width: 180px;

  @media screen and (max-width: 768px) {
    min-width: 100px;
    max-width: 100%;
  }
`;

export const PaymentTypeTooltip = styled.div`
  position: absolute;
  bottom: -25px;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 2px;
  background-color: #5a5a5a;
  color: #ffffff;
  padding: 4px 15px;
  font-size: 11px;
  text-align: center;
  white-space: nowrap;
  opacity: 0;
  pointer-events: none;
  transition: 0.2s all cubic-bezier(0.55, 0.3, 0.5, 0.9);
`;

export const PaymentType = styled.div`
  display: flex;
  align-items: center;
  position: relative;

  &:hover {
    ${PaymentTypeTooltip} {
      opacity: 1;
      bottom: -22px;
    }
  }
`;

export const PaymentTypeIconWrapper = styled.div`
  background-color: #fefefe;
  border-radius: 30px;
  margin-right: 10px;
  padding: 10px;
  min-width: 40px;
  border: 1px solid #eaeaea;

  img {
    min-width: 18px;
  }

  @media screen and (max-width: 768px) {
    margin-right: 0;
  }
`;

export const PaymentTypeCol = styled.div``;
export const PaymentTypeName = styled.span`
  display: block;
  font-size: 16px;
  color: #262626;
  margin-bottom: 3px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 152px;
`;

export const PaymentTypeCode = styled.span`
  display: block;
  font-size: 10px;
  color: #6d6d6d;
  text-transform: uppercase;
`;

export const PaidAt = styled.p`
  position: relative;
`;

export const EstimatedDate = styled.span`
  color: #44adf8;
  font-size: 12px;
  display: block;
  text-align: center;
  margin-top: 3px;
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0%);
  width: 130px;

  @media screen and (max-width: 768px) {
    position: static;
    width: 100%;
    transform: translate(0, 0);
    margin-top: 8px;
  }
`;
