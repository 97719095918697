import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { validateSearch, maskSsn, maskRn } from '../../utils';
import { TableTitle } from '../../styles/global';
import Spinner from '../Spinner/Absolute';
import BankModal from '../Modal/bankAccount';
import BankModalDelete from '../Modal/bankAccount/Delete';
import { bankAccountsRequest } from '../../store/modules/bankAccounts/actions';
import { banksRequest } from '../../store/modules/banks/actions';
import { modalOpen } from '../../store/modules/modal/actions';
import Actions from './Actions';

import { Container, Header, Wrapper, SearchBar, ReportTable, TableActions, Label } from './styles';

export default function BankAccounts() {
  const { list, loading } = useSelector(state => state.bankAccounts);
  const { list: banks } = useSelector(state => state.banks);
  const dispatch = useDispatch();
  const [data, setData] = useState({
    search: '',
    submit: true, //get data on component init
    show: null,
  });
  const { search, submit } = data;

  const handleSearch = () => validateSearch(search) && setData(d => ({ ...d, submit: true }));

  useEffect(() => {
    !banks.length && dispatch(banksRequest());
  }, [banks, dispatch]);

  useEffect(() => {
    if (submit) {
      dispatch(
        bankAccountsRequest({
          search,
        }),
      );
      setData(d => ({ ...d, submit: false }));
    }
  }, [submit, search, dispatch]);

  const wrapperRef = useRef(null);
  useEffect(() => {
    const handleClickOutside = () => setData(d => ({ ...d, show: false }));
    document.addEventListener('mouseup', handleClickOutside);
    return () => {
      document.removeEventListener('mouseup', handleClickOutside);
    };
  }, [wrapperRef]);

  return (
    <Container>
      {loading && <Spinner />}
      <Header>
        <Wrapper className="row">
          <TableTitle></TableTitle>
          <TableActions>
            <SearchBar>
              <input
                type="text"
                value={search}
                onKeyDown={e =>
                  e.key === 'Enter' || (e.key === 'Backspace' && e.target.value === '' && !loading)
                    ? handleSearch()
                    : null
                }
                onChange={e => setData({ ...data, search: e.target.value })}
                placeholder="Pesquisar"
              />
              <button onClick={handleSearch}>
                <img src="https://icons.alboompro.com/v1/bold/interface-essential/search/bdbdbd/search-alternate.svg" alt="" />
              </button>
            </SearchBar>
          </TableActions>
        </Wrapper>
      </Header>

      <ReportTable>
        <div className="wrap">
          <table>
            <thead>
              <tr>
                <th>BANCO</th>
                <th>NOME DO TITULAR</th>
                <th>CPF/CNPJ</th>
                <th>AGÊNCIA</th>
                <th>CONTA</th>
                <th>AÇÕES</th>
              </tr>
            </thead>
            <tbody>
              {list.map(bankAccount => {
                const bank = banks.filter(b => b.bank_code === bankAccount.bank_code).shift();
                return (
                  <tr key={bankAccount.id}>
                    <td>
                      {!!bank && (
                        <div className="d-flex align-items-center banks">
                          <img src={bank.icon} alt="bank-icon" className="flags" />
                          <p>{bank.name}</p>
                          {bankAccount.default && <Label>Principal</Label>}
                        </div>
                      )}
                    </td>
                    <td>
                      <p>{bankAccount.legal_name}</p>
                    </td>
                    <td>
                      <p>
                        {bankAccount.document_type === 'cpf' &&
                          maskSsn(bankAccount.document_number)}
                        {bankAccount.document_type === 'cnpj' &&
                          maskRn(bankAccount.document_number)}
                      </p>
                    </td>
                    <td>
                      <p>
                        {`${bankAccount.agency}`}
                        {bankAccount.agency_dv != 0 && `${bankAccount.agency_dv}`}
                      </p>
                    </td>
                    <td>
                      <p>{`${bankAccount.account}-${bankAccount.account_dv}`}</p>
                    </td>
                    <td>
                      <Actions
                        defaultBank={bankAccount.default}
                        handleEdit={() =>
                          dispatch(modalOpen({ type: 'bankAccounts', data: bankAccount }))
                        }
                        handleDelete={() =>
                          dispatch(
                            modalOpen({ type: 'bankAccountsDelete', data: { id: bankAccount.id } }),
                          )
                        }
                      />
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </ReportTable>
      {!!banks.length && <BankModal />}
      {!!banks.length && <BankModalDelete />}
    </Container>
  );
}
