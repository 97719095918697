import produce from 'immer';
import {
  PAYMENT_LINKS_GET,
  PAYMENT_LINKS_GET_SUCCESS,
  PAYMENT_LINKS_POST,
  PAYMENT_LINKS_POST_SUCCESS,
  PAYMENT_LINKS_PUT,
  PAYMENT_LINKS_PUT_SUCCESS,
  PAYMENT_LINKS_DELETE,
  PAYMENT_LINKS_DELETE_SUCCESS,
  PAYMENT_LINKS_DUPLICATE,
  PAYMENT_LINKS_DUPLICATE_SUCCESS,
  PAYMENT_LINKS_FAILURE,
} from './actions';

const INITIAL_STATE = {
  data: [],
  getPending: false,
  postPending: false,
  deletePending: false,
  duplicatePending: false,
  orders: [],
};

export default function paymentLinks(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case PAYMENT_LINKS_GET:
        draft.getPending = true;
        break;
      case PAYMENT_LINKS_GET_SUCCESS:
        draft.orders = action.payload.data.data.orders;
        draft.getPending = false;
        break;
      case PAYMENT_LINKS_POST:
        draft.postPending = true;
        break;
      case PAYMENT_LINKS_POST_SUCCESS:
        draft.orders = [action.payload.data.data.order, ...state.orders];
        draft.postPending = false;
        break;
      case PAYMENT_LINKS_PUT:
        draft.postPending = true;
        break;
      case PAYMENT_LINKS_PUT_SUCCESS:
        draft.orders = [
          ...state.orders.map(order =>
            order.id === action.payload.data.data.order.id ? action.payload.data.data.order : order,
          ),
        ];
        draft.postPending = false;
        break;
      case PAYMENT_LINKS_DELETE:
        draft.deletePending = true;
        break;
      case PAYMENT_LINKS_DELETE_SUCCESS:
        draft.orders = [...state.orders.filter(order => order.id !== action.payload.id)];
        draft.deletePending = false;
        break;
      case PAYMENT_LINKS_DUPLICATE:
        draft.duplicatePending = true;
        break;
      case PAYMENT_LINKS_DUPLICATE_SUCCESS:
        draft.orders = [action.payload.data.data.order, ...state.orders];
        draft.duplicatePending = false;
        break;
      case PAYMENT_LINKS_FAILURE:
        draft.getPending = false;
        draft.postPending = false;
        draft.deletePending = false;
        draft.duplicatePending = false;
        break;
      default:
        break;
    }
  });
}
