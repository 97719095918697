import styled from 'styled-components';
import { Form } from '@rocketseat/unform';
import media from '../../../styles/media';

export const ContentBox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  height: 160px;

  h1 {
    font-size: 2em;
    color: #242424;
    margin-bottom: 2em;
  }
`;
export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;

  button {
    width: 182px;
    margin-bottom: 0px !important;

    &:first-child {
      margin-right: 18px;
    }
  }
`;

export const FooterWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  background-color: #fff;
  padding: 20px;

  position: absolute;
  bottom: -40px;
  right: -25px;
  left: -25px;

  border-top: 1px solid #eaeaea;

  button {
    width: 182px;
    margin-bottom: 0px !important;

    &:first-child {
      margin-right: 18px;
    }
  }
`;

export const StyledForm = styled(Form)`
  width: 100%;
  display: flex;
  flex-direction: column;
`;
export const Row = styled.div`
  display: inline-flex;
`;
export const Col = styled.div`
  width: ${props => props.width};
  display: inline-block;
  margin-right: ${props => (props.mr ? props.mr : '0px')};

  @media ${media.small} {
    width: ${props => (props.width_mb ? props.width_mb : '100%')};
    display: inline-block;
  }
`;
