import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  padding-right: 10px;
  width: 100%;
  color: #373636;
  display: flex;
  align-items: center;

  @media screen and (max-width: 767px) {
    &.search {
      width: 100%;
      padding: 0;
      margin-bottom: 16px;
    }
  }
`;

export const SearchInput = styled.input`
  width: 100%;
  padding: 8px 22px;
  font-size: 13px;
  background-color: #ffffff;
  border: 1px solid #e8e8e8 !important;
  border-radius: 3px;
  &:focus {
    box-shadow: 0 0 2px 1px #50aff4;
  }
`;

export const SearchButton = styled.button`
  position: absolute;
  right: 26px;
  background: transparent;
  border: none;
  outline-color: transparent;
`;
