import styled from 'styled-components';
import media from '../../styles/media';
import { Form } from '@rocketseat/unform';

export const FullScreen = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: ${p => (p.open === 1 ? 'flex' : 'none')};
  justify-content: center;
  align-items: center;
  z-index: 9999;
  cursor: pointer;
  animation: fadeIn 380ms ease-in-out 1;

  @keyframes fadeIn {
    from {
      background-color: rgba(0, 0, 0, 0);
    }

    to {
      background-color: rgba(0, 0, 0, 0.8);
    }
  }

  > div {
    width: 100%;
    padding-bottom: 2rem;
    min-height: 220px;
    margin: 3.2rem;
    background-color: #fff;
    border-radius: 4px;
    position: relative;
    cursor: default;
    animation: slideIn 350ms cubic-bezier(0.42, 0, 0.21, 1) 1;

    @keyframes slideIn {
      from {
        transform: translateY(-120px);
        opacity: 0;
      }

      20% {
        opacity: 0;
      }

      to {
        transform: translateY(0);
        opacity: 1;
      }
    }

    header {
      padding: 2.3rem 5rem 2.3rem 2.3rem;
      display: flex;
      flex-direction: column;
      text-transform: uppercase;

      h3 {
        font-size: 1.5rem;
        white-space: pre-wrap;
        color: #1f1f1f;
      }
    }

    > button:last-child {
      background: none;
      position: absolute;
      top: 4px;
      right: 0;
      transition: 180ms ease-in-out;

      svg {
        fill: #1f1f1f;
      }
    }

    > section {
      padding: 3.2rem 2.3rem;
      border-top: 1px solid #f6f4f5;
      width: 100%;
      border-bottom: 1px solid #f6f4f5;
      h3 {
        margin-bottom: 4.8rem;
      }
      p {
        // font-size: 2rem;
        margin-bottom: 3.2rem;
        color: #373636;
      }
      div {
        font-size: 1.6rem;
        margin-bottom: 1.5rem;
        color: #242424;
      }
    }

    > form {
      padding: 3.2rem 5.2rem;
      border-top: 1px solid #f6f4f5;
      width: 100%;

      border-bottom: 1px solid #f6f4f5;
    }

    > footer {
      min-height: 9.2rem;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      margin: 0 5.2rem;
      > div {
        width: 100%;
        display: flex;
        justify-content: space-between;
      }

      @media ${media.small} {
        flex-direction: column;
      }

      button:last-child {
        margin-left: 1.9rem;
        @media ${media.small} {
          margin-left: 0;
          margin-top: 1rem;
        }
      }
    }
    > button:last-of-type {
      padding: 1.6rem;
    }
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;

  button {
    width: 182px;
    margin-bottom: 0px !important;
    &:last-child {
      margin-left: 15px;
    }
  }
`;

export const FooterWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  background-color: #fff;
  padding: 20px;

  position: absolute;
  bottom: -40px;
  right: -25px;
  left: -25px;

  border-top: 1px solid #eaeaea;

  button {
    width: 182px;
    margin-bottom: 0px !important;

    &:first-child {
      margin-right: 18px;
    }
  }
`;

export const ContentBox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;

  h1 {
    color: #212121;
    font-size: 18px;
    font-weight: 400;
    margin-bottom: 35px;
  }
`;

export const StyledForm = styled(Form)`
  display: flex;
  flex-direction: column;

  .lastInput {
    margin-bottom: 30px;
  }

  .input-text > input {
    color: #373636;
  }

  @media ${media.small} {
    padding: 0 5px;
  }

  table {
    margin-bottom: 30px;
  }

  th {
    font-size: 11px;
    text-transform: uppercase;
    font-weight: 500;
    padding: 0 0 10px;
    color: #262626;
  }

  td {
    font-size: 14px;
    padding: 10px;

    &:last {
      margin-bottom: 20px;
    }
  }

  .label > div > span {
    background: #46ae70;
    padding: 3px 6px;
    border-radius: 40px;
    color: #fff;
    font-weight: 500;
    margin-left: 10px;
    text-transform: uppercase;
    font-size: 10px;
  }

  .checkbox .label {
    width: 100%;
  }
`;

export const PaymentWrapper = styled.div`
  line-height: initial;
  h6,
  p,
  table {
    margin: 0;
    color: #727272;
  }
  h6,
  .total,
  .description {
    color: #373636;
    font-weight: 600;
  }
  .row {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
  }
  .subtitle {
    text-transform: uppercase;
    font-size: 9px;
    font-weight: 600;
    margin-bottom: 5px;
  }
  .no-gutters {
    padding-top: 0.55rem;
    padding-bottom: 0.55rem;
  }
  thead th {
    border-top: 2px solid #dee2e6;
  }
  .border-table tbody tr:last-child {
    border-bottom: 2px solid #dee2e6;
  }
  .col,
  .col-6 {
    white-space: nowrap;
    overflow: hidden;
  }
  @media (max-width: 768px) {
    overflow: scroll;
  }
`;
