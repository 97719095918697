import { takeLatest, call, put, all } from "redux-saga/effects";
import { activitiesSuccess, activitiesFailure, typesSuccess, typesFailure, setWithdraw } from "./actions";
import Activities from '../../../services/core/http/activities';

export function* getActivities({ payload }) {
  try {
    const response = yield call(Activities.get, { ...payload.data });
    yield put(activitiesSuccess(response));
  } catch (error) {
    console.log(error)
    yield put(activitiesFailure());
  }
}

export function* getTypes() {
  try {
    const response = yield call(Activities.getTypes);
    yield put(typesSuccess(response));
  } catch (error) {
    console.log(error)
    yield put(typesFailure());
  }
}

export default all([
  takeLatest("@activity/REQUEST", getActivities),
  takeLatest("@activity/TYPES_REQUEST", getTypes),
]);
