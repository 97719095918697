import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import Modal from '@alboompro/boom-components/data-display/modal';
import Button from '@alboompro/boom-components/general/button';

import { paymentLinksDuplicate } from '../../../../store/modules/paymentLinks/actions';

import { FooterContent, ModalContent, LabelPageCopy, InputPageNameCopy } from './styles';

export default function Clone({ id, name, visible, onClose }) {
  const dispatch = useDispatch();
  const [nameClone, setNameClone] = useState('');
  const { duplicatePending } = useSelector(state => state.paymentLinks);

  useEffect(() => {
    setNameClone(name);
  }, [name]);

  function usePrevious(value) {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  }

  const prevDuplicatePending = usePrevious(duplicatePending);

  useEffect(() => {
    if (prevDuplicatePending && !duplicatePending) {
      handleClose();
    }
  }, [duplicatePending]);

  function handleName({ target: { value } }) {
    return setNameClone(value);
  }

  function handleDuplicate() {
    dispatch(paymentLinksDuplicate({ id, nameClone }));
  }

  function handleClose() {
    setNameClone('');
    onClose();
  }

  return (
    <Modal
      title="Duplicar Link de Pagamento"
      visible={visible}
      onClose={() => handleClose()}
      bodyStyle={{ backgroundColor: '#f6fafe' }}
      backdropStyle={{ backgroundColor: 'rgba(0, 0, 0, 0.7)' }}
      backdropClosable={false}
      footer={
        <FooterContent>
          <Button textStyle={{ color: '#383838' }} onClick={() => handleClose()}>
            Cancelar
          </Button>
          <Button
            type="primary"
            loading={duplicatePending}
            onClick={handleDuplicate}
            disabled={!nameClone.length}
            style={{ backgroundColor: '#50AFF4' }}
          >
            Duplicar
          </Button>
        </FooterContent>
      }
    >
      <ModalContent>
        <LabelPageCopy>Nome do novo Link de Pagamento</LabelPageCopy>
        <InputPageNameCopy
          type="text"
          name="duplicate_page_name"
          placeholder="Exe: Venda de ebook"
          onChange={handleName}
          value={nameClone}
        />
      </ModalContent>
    </Modal>
  );
}
