export function activitiesRequest(data) {
  return {
    type: "@activity/REQUEST",
    payload: { data }
  };
}

export function activitiesSuccess(payload) {
  return {
    type: "@activity/SUCCESS",
    payload
  };
}

export function activitiesFailure() {
  return {
    type: "@activity/FAILURE"
  };
}

export function typesRequest() {
  return {
    type: "@activity/TYPES_REQUEST"
  };
}

export function typesSuccess(payload) {
  return {
    type: "@activity/TYPES_SUCCESS",
    payload
  };
}

export function typesFailure() {
  return {
    type: "@activity/TYPES_FAILURE"
  };
}

export function blockWithdraw() {
  return {
    type: "@activity/BLOCK_WITHDRAW"
  };
}
