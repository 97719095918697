import styled from "styled-components";

export const SkeletonWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

export const SkeletonRow = styled.div`
  display: flex;
  width: 100%;
  height: 62px;
  position: relative;
  
  :not(:last-of-type) {
    border-bottom: 1px solid #e8e8e8;
  }

  @media screen and (max-width: 767px) {
    flex-direction: column;
    height: 130px;
    gap: 8px;
    padding: 16px 0;
  }
`

export const SkeletonDateCol = styled.div`
  padding: 22px 16px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;

  div {
    margin-bottom: 0;
    height: 100%;
  }

  .date {
    width: 81px;
    height: 17px;
  }

  .time {
    width: 36px;
    height: 17px;
  }

  @media screen and (max-width: 767px) {
    padding: 0;
    gap: 10px;
    height: 14px;
    position: absolute;
    left: 0;
    top: 16px;
    
    .date {
      width: 45px;
      height: 14px;
    }

    .time {
      width: 25px;
      height: 14px;
    }
  }
`

export const SkeletonDescriptionCol = styled.div`
  padding: 8px 16px;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 4px;
  flex: 1;

  .texts {
    height: 34px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 4px;
  }

  .buttons {
    height: 17px;
    display: flex;
    align-items: center;
    gap: 20px;

    .payment {
      height: 100%;
      width: 122px;
    }

    .details {
      width: 90px;
      height: 100%;
    }
  }

  div {
    margin-bottom: 0;
  }

  @media screen and (max-width: 767px) {
    padding: 0;
    width: 100%;
    order: 2;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 12px;

    .buttons {
      width: 100%;
      justify-content: space-between;

      .payment {
        width: 80px;
      }

      .details {
        width: 122px;
      }
    }
  }
`

export const SkeletonValueCol = styled.div`
  padding: 8px 16px;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 4px;

  div {
    margin-bottom: 0;
  }

  .currency {
    width: 60px;
    height: 17px;
  }

  .value {
    width: 17px;
    height: 17px;
  }

  @media screen and (max-width: 767px) {
    padding: 0;
    height: 14px;
    order: 1;
    align-self: flex-end;

    .currency {
      display: none;
    }

    .value {
      width: 60px;
      height: 14px;
    }
  }
`