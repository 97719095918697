import axios from '../../axios';
import { format } from 'date-fns';
import moment from 'moment';

export default {
  check: async (username, alprotoken) => {
    if (!username) return false;

    const checkuser = await axios
      .get(`/accounts/check-username/${username}`, { headers: { Authorization: alprotoken } })
      .then(response => {
        if (response.status === 200 && response.data.data.success) {
          return true;
        }
        return false;
      })
      .catch(error => {
        return false;
      });

    return checkuser;
  },
  create: async (payload, alprotoken) => {
    let dob = null;
    if (payload.dob) {
      dob = payload.dob.split('/');
      dob = new Date(`${dob[2]}-${dob[1]}-${dob[0]}T00:00:00`);
      dob = format(dob, 'yyyy-MM-dd HH:mm:ss'); //"1987-09-08T12:25:29.957Z"
    }
    const data = {
      address: payload.street,
      neighborhood: payload.neighborhood,
      postal_code: payload.zipcode,
      complement: payload.complement,
      number: payload.street_number,
      phone: payload.phone_number,
      city: {
        name: payload.city,
        state_name: payload.state,
        initials: payload.uf,
        country_name: payload.country,
        country_ISO: payload.uf,
        ibge: payload.ibge,
        gia: payload.gia,
      },
      selling_intends: payload.selling_intends,
      segments: {
        parent: payload.customer_segment,
        child: payload.subsegment,
      },
      gateway_ref: '#AL-PAY',
      type: payload.doc_type === 'pf' ? 'person' : 'company',
      fullname: payload.fullname ? payload.fullname : payload.company_name,
      doc:
        payload.doc_type === 'pf'
          ? payload.ssn.replace(/[^\d]/g, '')
          : payload.rn.replace(/[^\d]/g, ''),
      birth_date: dob,
      company_name: payload.company_name,
      company_doc: payload.rn ? payload.rn.replace(/[^\d]/g, '') : null,
      username: payload.username,
      responsible_name: payload.responsible_name,
      display_name: payload.display_name,
      term: payload.term,
      bank_accounts: {
        bank_code: payload.bank_code,
        agencia: payload.bank_agency,
        agencia_dv: payload.bank_agency_vd,
        conta: payload.bank_account,
        conta_dv: payload.bank_account_vd,
        type: payload.bank_account_type ? payload.bank_account_type : 'conta_corrente',
        legal_name: payload.legal_name,
        document_number: payload.legal_name_doc
          ? payload.legal_name_doc.replace(/[^\d]/g, '')
          : null,
      },
    };
    return await axios.post('/accounts', data, { headers: { Authorization: alprotoken } });
  },
  generateSMSCode: async (phone) => {
    return await axios.post('sms-verification/create-phone-verification', { number: phone });
  },
  insertPhoneAndBank: async (payload) => { 
    return await axios.post(`/accounts/phone-and-bank`, payload); 
  }, 
  balance: async () => {
    const {
      data: {
        data: { balance },
      },
    } = await axios.get('/accounts/balance');
    return balance;
  },
  balanceDetails: async () => {
    const {
      data: {
        data: { balance },
      },
    } = await axios.get('/accounts/balance-details');
    return balance;
  },
  balanceOperations: async (queryParams) => {
    let params = {}
    if(typeof queryParams === "object") {
      params = queryParams
    }
    const {
      data: {
        data: balanceOperations,
      },
    } = await axios.get('/accounts/balance-operations', { params });
    return balanceOperations;
  },
  signIn: async token =>
    !token
      ? null
      : axios
          .get(`/sign-in`, { headers: { Authorization: token } })
          .then(({ status, data }) => status === 200 && data),
  put: async (id, data) => await axios.put(`/accounts/${id}`, data),
  get: async id => await axios.get(`/accounts/${id}`),
  getPayables: async (date = moment(new Date()).format('YYYY-MM-01')) =>
    await axios.get(`/accounts/payables`, {
      params: {
        date,
      },
    }),
  getPayablesDetails: async (ids = []) => await axios.post(`/accounts/payables-details`, { ids }),
  withdraw: async ({ amount, bank_account_id }) =>
    await axios.post('/requere-transfers', { amount, bank_account_id }),
  withdrawPutAccount: async payload => {
    await axios.put(`/bank-accounts/${payload.id}/`, payload);
    await axios.post('/requere-transfers', { amount: payload.amount });
  },
};
