import styled from 'styled-components';

export const Container = styled.div`
  display: grid;

  @media screen and (max-width: 767px) {
    width: 100%;
    padding: 0;
    margin: 0 0 16px !important;
  }

  div.minified-select {
    border: 1px solid #e8e8e8;
    border-radius: 5px;
    min-width: auto;
    padding: 0;

    &:hover {
      background-color: #bedcf1;
    }

    .react-select__control {
      padding: 4px 15px;
      min-height: 36px;

      @media screen and (max-width: 768px) {
        flex-direction: row-reverse;
        gap: 8px;
      }
    }

    .react-select__indicator {
      padding: 0;
    }
  }

  .Select.is-open {
    z-index: 1000 !important;
  }

  .react-select__value-container,
  .react-select__menu {
    font-size: 15px;
  }
  .react-select__value-container {
    padding: 2px 15px;
    line-height: 1.3em;
  }
  .react-select__control {
    border-radius: 3px;
    min-height: 49px;
    border-color: #e8e8e8;

    &.react-select__control--is-focused {
      box-shadow: 0 0 2px 1px #50aff4;
    }

    &:focus {
      box-shadow: 0 0 2px 1px #50aff4;
      outline: 0;
    }
    &:hover {
      border-color: #e8e8e8;
    }
  }
  .react-select__indicator-separator {
    display: none;
  }
`;
