import styled from 'styled-components';
import { Form } from '@rocketseat/unform';

export const StyledForm = styled(Form)`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const SlugWrapper = styled.div`
  position: relative;
`;

export const SlugPreview = styled.span`
  position: absolute;
  top: 80px;

  font-size: 12px;
  color: #747474;

  & ~ .input-text {
    margin-bottom: 54px;

    .error-msg {
      margin-top: 20px !important;
    }
  }
`;

export const FooterWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  background-color: #fff;
  padding: 20px;
  margin: 30px -25px 0;
  border-radius: 0 0 3px 3px;

  border-top: 1px solid #eaeaea;

  button {
    width: 182px;
    margin-bottom: 0px !important;

    &:first-child {
      margin-right: 18px;
    }
  }
`;
