import { takeLatest, call, put, all, take } from "redux-saga/effects";
import {
  bankAccountsSuccess,
  bankAccountsFailure,
  bankAccountsPostSuccess,
  bankAccountsPostFailure,
  bankAccountsPutSuccess,
  bankAccountsPutFailure,
  bankAccountsRequest,
  bankAccountsDeleteSuccess,
  bankAccountsDeleteFailure,
  bankAccountsPutAndWithdrawSuccess,
  bankAccountsPutAndWithdrawFailure,
  bankAccountsCodeStore
} from "./actions";
import BankAccounts from '../../../services/core/http/bankAccounts';
import Accounts from '../../../services/core/http/accounts';
import { toast } from 'react-toastify';

export function* codeStore({ payload }) {
  try {
    yield call(BankAccounts.codeStore, { ...payload });
    toast.success('Código de verificação enviado');
  } catch (error) {
    console.log(error)
    toast.error("Falha ao verificar conta bancária")
  }
}

export function* getBankAccounts({ payload }) {
  try {
    const response = yield call(BankAccounts.get, { ...payload.data });
    yield put(bankAccountsSuccess(response));
  } catch (error) {
    console.log(error)
    yield put(bankAccountsFailure());
  }
}

export function* postBankAccount({ payload }) {
  try {
    yield call(BankAccounts.post, { ...payload });
    yield put(bankAccountsPostSuccess());
    yield put(bankAccountsRequest());
    toast.success("Conta bancária cadastrada");
  } catch (error) {
    console.log(error)
    toast.error("Não foi possível criar a conta bancária, tente novamente")
    yield put(bankAccountsPostFailure());
  }
}

export function* putBankAccount({ payload, id }) {
  try {
    yield call(BankAccounts.put, { ...payload, id });
    yield put(bankAccountsPutSuccess());
    yield put(bankAccountsRequest());
    toast.success("Conta bancária atualizada");
  } catch (error) {
    console.log(error)
    toast.error("Não foi possível atualizar a conta bancária, tente novamente")
    yield put(bankAccountsPutFailure());
  }
}

export function* putBankAccountWithdraw({ payload, id }) {
  try {
    yield call(Accounts.withdrawPutAccount, { ...payload, id });
    yield put(bankAccountsPutSuccess());
    yield put(bankAccountsPutAndWithdrawSuccess());
    toast.success("Conta bancária atualizada");
  } catch (error) {
    console.log(error)
    toast.error("Não foi possível atualizar a conta bancária e sacar, tente novamente")
    yield put(bankAccountsPutAndWithdrawFailure());
  }
}

export function* deleteBankAccount({ id }) {
  try {
    yield call(BankAccounts.delete, id);
    yield put(bankAccountsDeleteSuccess());
    yield put(bankAccountsRequest());
    toast.success("Conta bancária removida");
  } catch (error) {
    console.log(error)
    toast.error("Não foi possível remover a conta bancária, tente novamente")
    yield put(bankAccountsDeleteFailure());
  }
}

export default all([
  takeLatest("@bankAccounts/REQUEST", getBankAccounts),
  takeLatest("@bankAccounts/POST", postBankAccount),
  takeLatest("@bankAccounts/PUT", putBankAccount),
  takeLatest("@bankAccounts/DELETE", deleteBankAccount),
  takeLatest("@bankAccounts/PUT_AND_WITHDRAW", putBankAccountWithdraw),
  takeLatest("@bankAccounts/CODE_STORE", codeStore)
]);
