import styled from 'styled-components';

export const Container = styled.div`
  margin: 40px 30px 0px 30px;
  @media (max-width: 768px) {
    margin: 15px 15px 0;
  }
`;

export const Title = styled.div`
  color: #343434;
  margin-bottom: 10px;
  font-size: 15px;
  font-weight: 500;

  /* @media screen and (max-width: 767px) {
    text-align: center;
  } */
`;

export const Header = styled.div`
  color: #4f4f4f;
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 40px;
`;

export const Advise = styled.div`
  color: #3e3e3e;
  font-size: 13px;
  font-weight: 400;
  margin-top: 16px;
`;

export const CheckboxLabel = styled.div`
  color: #3e3e3e;
  font-size: 13px;
  font-weight: 400;
`;
