import styled from 'styled-components';

export const Container = styled.div`
  max-width: 1080px;
  margin: 40px auto;

  @media screen and (max-width: 1600px) {
    margin: 40px auto;
  }

  @media screen and (max-width: 767px) {
    margin: 20px;
  }

  .search {
    align-items: center;
    display: flex;
    [for='search'] {
      margin: 0;
    }
    .label {
      margin: 0;
    }
    input {
      height: 2.5em;
      padding: 0 10px;
      background: #f6f5f6;
      font-size: 14px;
      border: none;
    }
    button {
      top: 0;
      right: 0;
      padding: 8px;
      background: none;

      img {
        width: 16px;
      }
    }
  }
  .filter {
    img {
      width: 15px;
    }
    .react-select__indicators {
      padding: 8px;
    }
  }
`;

export const StyledBox = styled.div`
  font-size: Poppins-Medium;
  background-color: ${props => props.background || '#fff'};
  min-height: 160px;
  color: white;
  text-transform: uppercase;
  position: relative;
  z-index: 0;
  text-align: center;
  h4 {
    padding: 35px 0 5px;
    font-size: 14px;
  }
  h3 {
    // ::before {
    //   content: "R$";
    //   font-size: 10px;
    //   position: absolute;
    //   margin-left: -18px;
    //   margin-top: 8px;
    // }
    font-size: 35px;
  }
  div {
    z-index: 1;
    position: relative;
  }
  img {
    position: absolute;
    height: 100px;
    top: 30px;
    left: 20px;
  }
  .btn {
    min-width: 180px;
    font-size: 14px;
    margin: 5px;
  }
`;
