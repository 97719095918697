import styled from "styled-components";
import media from "../../styles/media";

export const Container = styled.div`
  margin-bottom: 2em;
  margin-top: 4em;
  overflow-x: hidden;

  img.image {
    margin-bottom: 4em;
  }

  h1 {
    font-size: 49px;
    text-align: center;
    margin-bottom: 24px;

    @media ${media.small} {
      font-size: 3em;
    }
  }

  h3 {
    font-size: 24px;
    text-align: center;
    margin: 0;
    font-weight: 400;

    @media ${media.small} {
      font-size: 2em;
    }
  }
`;

export const Footer = styled.div`
  .footer {
    margin: 2em;
  }
`;
