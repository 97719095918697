import axios from "../../axios";
import { toast } from 'react-toastify';
export default {
  get: async (data) => {
    try {
      return await axios.post("/calculate-discount-amount", data);
    }
    catch (e) {
      const message = e.message || 'Não foi possível calcular os discontosa, por favor tente novamente mais tarde';
      toast.error(message);
      return Promise.reject(e);
    }
  }
};
