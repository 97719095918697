import styled, { keyframes } from 'styled-components';

export const Container = styled.div`
  .search {
    align-items: center;
    display: flex;
    flex-wrap: wrap;

    [for='search'] {
      margin: 0;
    }
    .label {
      margin: 0;
    }

    input {
      height: 2.5em;
      padding: 0 10px;
      background: #f6f5f6;
      font-size: 14px;
      border: none;
    }
    button {
      top: 0;
      right: 0;
      padding: 8px;

      img {
        width: 16px;
      }
    }
  }
  .filter {
    img {
      width: 15px;
    }
    .react-select__indicators {
      padding: 8px;
    }
  }

  .row > div {
    :nth-child(n + 4) {
      margin-top: 15px;
    }

    @media screen and (max-width: 991px) {
      :nth-child(n + 2) {
        margin-top: 15px;
      }

      :nth-child(4) {
        order: 3;
      }
    }
  }
`;

export const StyledBox = styled.div`
  background-color: ${props => props.background || '#fff'};
  border-radius: 10px;
  border: 1px solid #ebebeb;
  min-height: 180px;
  color: white;
  text-transform: uppercase;
  position: relative;
  z-index: 0;
  text-align: center;
  padding: 15px 20px;
  justify-content: center;

  @media screen and (max-width: 1040px) {
    min-height: 200px;
  }

  @media screen and (max-width: 767px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 10px;
  }

  .box {
    display: flex;
    position: relative;
    flex-direction: column;
    height: 140px;
    align-items: center;

    @media screen and (max-width: 991px) {
      height: auto;
    }
  }

  h4 {
    font-size: 16px;
    color: #383838;
    padding-top: 10px;
    text-transform: none;
    position: relative;

    .ref-month {
      position: absolute;
      width: 60px;
      font-weight: 400;
      color: #7b7b7b;
      text-transform: uppercase;
      font-size: 11px;
      line-height: 20px;
    }

    @media screen and (max-width: 1260px) {
      & {
        padding-top: 0px;
        margin-bottom: calc(0.5rem + 10px);
      }

      .ref-month {
        left: calc(50% - 30px);
        top: 18px;
      }
    }
  }

  h3 {
    color: #4a4a4a;
    font-size: 44.5px;
    font-weight: 400;
    margin: 0;

    & > span {
      position: relative;

      .currency-symbol {
        font-size: 14px;
        position: absolute;
        top: 8px;
        left: -18px;
      }

      .decimal-part {
        font-size: 18px;
        position: absolute;
        bottom: 8px;
      }
    }

    @media screen and (max-width: 767px) {
      font-size: 32px;

      & > span {
        .currency-symbol {
          top: 6px;
        }

        .decimal-part {
          bottom: 4px;
        }
      }
    }
  }
  div {
    z-index: 1;
    position: relative;
    width: 100%;
  }
  .skeleton-content {
    width: 60% !important;
    margin: auto;
  }
  img {
    position: absolute;
    height: 60px;
    top: 30px;
    left: 20px;

    @media screen and (max-width: 991px) {
      height: 30px;
      position: initial;
      margin-top: 20px;
    }
  }
  .btn {
    min-width: 180px;
    color: #50aff4;
    margin-top: 10px;
    background-color: #ffffff;
    border: 1px solid #50aff4;
    font-weight: 500;
    font-size: 15px;
    padding: 6px 20px;

    &:hover,
    &:focus,
    &:active {
      background-color: #daeefb !important;
      color: #50aff4;
      border: 1px solid #50aff4;
      box-shadow: none;
    }

    @media screen and (max-width: 991px) {
      min-width: 100%;
    }
  }

  hr {
    border: none;
    border-top: 1px solid #eaeaea;
    margin: 0;
  }
`;

/**
 * Styled Pre-info and Pos-info
 */
export const StyledPreNPos = styled.div`
  color: #4a4a4a;

  width: 100%;

  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 20px;

  span > {
    text-align: center;

    .pre-title,
    .pos-title {
      font-weight: 500;
      font-size: 11px;
      line-height: 13px;
      text-transform: uppercase;
      color: #484848;
      margin-bottom: 3px;
      position: relative;
    }

    .pre-value,
    .pos-value {
      font-weight: normal;
      font-size: 17px;
      line-height: 18px;
      color: #484848;
    }

    .pre-title,
    .pos-title {
      .ref-month {
        position: absolute;
        font-weight: 400;
        color: #7b7b7b;
        text-transform: uppercase;
        font-size: 10px;
        width: 50px;
        left: calc(100%);
      }
    }

    .pos-title {
      .ref-month {
        display: none;
      }
    }
  }

  @media screen and (max-width: 990px) {
    span {
      > {
        .pos-title {
          margin-bottom: 15px;

          .ref-month {
            display: block;
          }
        }
      }
    }
  }

  @media screen and (max-width: 1110px) {
    margin-top: 10px;

    span {
      > {
        .pre-title,
        .pos-title {
          margin-bottom: 15px;

          .ref-month {
            left: calc(50% - 25px);
            top: 13px;
          }
        }
      }

      &.pos {
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
    }
  }
`;

export const Card = styled.div`
  width: 100%;
  background-color: white;
  border-radius: 10px;
  border: 1px solid #ebebeb;

  @media (max-width: 768px) {
    &.remove {
      display: none;
    }
  }

  main {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 30px;

    @media (max-width: 768px) {
      padding: 20px;
    }

    p {
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0em;
      text-align: left;
      margin: 0;
      padding: 0;

      @media (max-width: 768px) {
        font-size: 14px;
      }

      span {
        text-transform: capitalize;
      }
    }

    strong {
      font-size: 30px;
      font-style: normal;
      font-weight: 400;
      line-height: 50px;
      letter-spacing: 0em;
      text-align: center;
      margin: 24px 0 20px;

      color: #212121;

      @media (max-width: 768px) {
        font-size: 28px;
        margin: 0;
      }

      &.noBefore:before {
        content: '';
      }

      &:before {
        content: 'R$';
        display: inline-block;
        margin-right: 10px;
        font-size: 34px;
        color: #212121;
        font-style: normal;
        font-weight: 400;
        line-height: 50px;
        letter-spacing: 0em;
        text-align: center;

        @media (max-width: 768px) {
          font-size: 28px;
        }
      }
    }

    button {
      background: #1b9dfa;
      border-radius: 3px;
      height: 42px;
      width: 175px;
      border: none;
      color: white;
      font-size: 15px;

      @media (max-width: 768px) {
        height: 33px;
      }
    }

    > span {
      display: flex;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 19px;
      letter-spacing: 0em;
      text-align: right;
      height: 45px;
      justify-content: center;
      align-items: center;
    }
  }

  div {
    padding: 22px 26px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media (max-width: 678px) {
      padding: 11px 13px;
    }

    &:first-of-type {
      border-top: 1px solid #ebebeb;
      border-bottom: 1px solid #ebebeb;
    }

    p {
      margin: 0;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 19px;
      letter-spacing: 0em;
      text-align: left;

      @media (max-width: 678px) {
        font-size: 12px;
      }

      span {
        text-transform: capitalize;
      }
    }

    strong {
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 19px;
      letter-spacing: 0em;
      text-align: right;

      @media (max-width: 678px) {
        font-size: 11px;
      }
    }
  }

  section {
    padding: 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    p {
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 19px;
      letter-spacing: 0em;
      text-align: center;

      span {
        text-transform: capitalize;
      }
    }

    strong {
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
      letter-spacing: 0em;
      text-align: center;
    }
  }
`;

export const MoreInfo = styled.div`
  margin-top: -35px;
  display: none;
  flex-direction: column;
  background-color: #ffffff;
  border: 1px solid #ebebeb;
  border-radius: 10px;

  @media (max-width: 768px) {
    display: flex;
  }

  > div {
    display: flex;
    flex-direction: column;

    section {
      display: flex;
      justify-content: space-between;
      padding: 11px 13px;

      p {
        margin: 0;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 14px;
        letter-spacing: 0em;
        text-align: left;

        span {
          text-transform: capitalize;
        }
      }

      strong {
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 14px;
        letter-spacing: 0em;
        text-align: right;
      }
    }
  }

  main {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 16px;

    p {
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 1.3;
      letter-spacing: 0em;
      text-align: center;
      margin: 0;

      span {
        text-transform: capitalize;
      }
    }

    strong {
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
      letter-spacing: 0em;
      text-align: center;
      margin-top: 13px;
    }
  }
`;

export const SeeMoreInfo = styled.div`
  display: none;
  justify-content: space-between;
  align-items: center;
  margin-top: ${props => (props.isOpened ? '10px' : '-35px')};
  background-color: #f2f6f9;
  height: 36px;
  border: 1px solid #ebebeb;
  border-radius: 10px;
  padding: 0 13px;

  @media (max-width: 768px) {
    display: flex;
  }

  p {
    margin: 0;
  }

  button {
    padding: 0 8px;
    border: none;
    background-color: transparent;
    transition: 0.3s;

    &.reverse {
      transform: rotate(180deg);
    }
  }
`;

export const LoadingAnimation = keyframes`
  from {
    background-position: 0px;
  }
  to {
    background-position: -200%;
  }
`;

export const Loading = styled.em`
  width: ${props => props.width};
  height: ${props => props.height};
  background-image: linear-gradient(90deg, #eeeeee 0px, #f9f9f9 50%, #eeeeee 100%);
  background-color: #eeeeee;
  background-size: 200%;
  animation: ${LoadingAnimation} 1.5s infinite linear;
  margin-top: ${props => (props.mt ? props.mt : '0px')};
`;
