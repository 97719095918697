import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Select from '../../components/Select';
import Input from '../../components/Input';
import RadioInput from '../../components/RadioInput';
import { Container, ContainerInner } from '../../styles/global';
import PageHeader from '../../components/PageHeader';
import Request from '../../services/core/http/fees';
import axios from '../../services/axios';
import Spinner from '../../components/Spinner';
import ShowSimulate from './ShowSimulate';
import { currencyFormat } from '../../utils';
import { ColWrapper, Col, FieldWrapper, RadioWrapper, PaymentMethod } from './styles';

export default function Payment() {
  const { REACT_APP_REQUESTS_MAX_TOTAL: limit } = process.env;
  const { payments_settings, account_id, loading } = useSelector(state => state.accounts);
  const dispatch = useDispatch();

  const [data, setData] = useState({
    amount: 0,
    amountFormatted: '',
    installments: 1,
  });

  const [fees, setFees] = useState([]);
  const [optionsFee, setOptionsFee] = useState({});
  const [feeId, setFeeId] = useState('');
  const [loadFee, setLoadFee] = useState(false);

  const [paymentMethod, setCheck] = useState('cc');

  const [receiptConfig, setReceiptConfig] = useState(1);

  const { amount, amountFormatted, installments } = data;

  const InstallmentsOptions = [
    { value: 1, label: 'Crédito à vista' },
    { value: 2, label: 'em 2x sem juros' },
    { value: 3, label: 'em 3x sem juros' },
    { value: 4, label: 'em 4x sem juros' },
    { value: 5, label: 'em 5x sem juros' },
    { value: 6, label: 'em 6x sem juros' },
    { value: 7, label: 'em 7x sem juros' },
    { value: 8, label: 'em 8x sem juros' },
    { value: 9, label: 'em 9x sem juros' },
    { value: 10, label: 'em 10x sem juros' },
    { value: 11, label: 'em 11x sem juros' },
    { value: 12, label: 'em 12x sem juros' },
  ];

  const receiptFees = [
    {
      value: 1,
      label: 'Receba em 30 dias' + (optionsFee.antecipation_days === 30 ? ' (Ativo)' : ''),
      parc_mult: 0,
      credit_fee: optionsFee.antecipation_days === 30 ? optionsFee.credit_fee / 100 : 0.0299,
      credit_parc_fee: optionsFee.antecipation_days === 30 ? optionsFee.credit_installments_fee / 100 : 0.0349,
    },
    {
      value: 2,
      label: 'Receba em 14 dias' + (optionsFee.antecipation_days === 14 ? ' (Ativo)' : ''),
      parc_mult: 16 / 30,
      credit_fee: optionsFee.antecipation_days === 14 ? optionsFee.credit_fee / 100 : 0.0299,
      credit_parc_fee: optionsFee.antecipation_days === 14 ? optionsFee.credit_installments_fee / 100 : 0.0349,
    },
    {
      value: 3,
      label: 'Receba em 2 dias' + (optionsFee.antecipation_days === 2 ? ' (Ativo)' : ''),
      parc_mult: 29 / 30,
      credit_fee: optionsFee.antecipation_days === 2 ? optionsFee.credit_fee / 100 : 0.0299,
      credit_parc_fee: optionsFee.antecipation_days === 2 ? optionsFee.credit_installments_fee / 100 : 0.0349,
    },
    {
      value: 4,
      label: 'Sem antecipação' + (optionsFee.antecipation_days === 0 ? ' (Ativo)' : ''),
      parc_mult: null,
      credit_fee: optionsFee.antecipation_days === 0 ? optionsFee.credit_fee / 100 : 0.0299,
      credit_parc_fee: optionsFee.antecipation_days === 0 ? optionsFee.credit_installments_fee / 100 : 0.0349,
    },
  ];

  console.log(optionsFee)

  const getAllFees = async () => {
    if (fees.length) return;
    let {
      data: {
        data: { fees: f },
      },
    } = await Request.get();

    setFees(
      f.map(fe => {
        const { antecipation_days } = fe[Object.keys(fe)[0]];
        if (optionsFee.antecipation_days === antecipation_days) {
          setFeeId(Object.keys(fe)[0]);
        }

        return {
          value: Object.keys(fe)[0],
          label: (
            <span style={{ color: antecipation_days === 2 && '#242424' }}>
              {`Receba em ${antecipation_days} dias`}
              {antecipation_days === 2 ? (
                <span style={{ fontSize: 10, color: '#7d7d7d', marginLeft: 5 }}>Em breve</span>
              ) : (
                ''
              )}
            </span>
          ),
        };
      }),
    );

    setLoadFee(true);
  };

  function handleAmount({ target: { value: v } }) {
    var value = parseInt(v.replace(/[^\d]/g, ''));
    const amount = value > parseInt(limit) ? parseInt(limit) : value;
    setData({ ...data, amount, amountFormatted: currencyFormat(amount / 100) });
  }

  useEffect(() => {
    const getFees = async () => {
      const {
        data: {
          data: {
            account: { fees: optionsFee },
          },
        },
      } = await axios.get('/accounts/fees');

      return setOptionsFee(optionsFee);
    };
    getFees();
  }, []);

  useEffect(() => {
    if (optionsFee.antecipation_days) {
      getAllFees();
    }
  }, [optionsFee]);

  useEffect(() => {
    if (account_id) {
      setData({
        invoiceEnable: payments_settings.invoice.enabled,
        invoiceExpirationDays: payments_settings.invoice.expiration_in_days,
        ccEnable: payments_settings.credit_card?.enabled,
        ccNoFeeInstallments: payments_settings.credit_card.installments_no_fees,
        ccFee: payments_settings.credit_card.installments_fees_payer,
        ccFeeFormatted: payments_settings.credit_card.installments_fees_payer?.toString(),
      });
    }
  }, [account_id, payments_settings]);

  return (
    <>
      <PageHeader
        title="Calculadora para simular pagamentos"
        subtitle="Simule as condições de pagamento para saber o quanto vai receber."
      />
      <ContainerInner>
        <ColWrapper>
          <Col>
            {/* <FieldWrapper>
              <span className="label custom">Prazo de recebimento</span>
              <Select
                icon="MdKeyboardArrowDown"
                size={24}
                name=""
                options={fees}
                initialValue={feeId}
                placeholder="Receba em 30 dias"
                onChange={obj => setFeeId(obj.value)}
                isOptionDisabled={option =>
                  option.value === '6db3a477-97fd-4e24-85f5-8a42c031d1eb'
                }
              />
            </FieldWrapper> */}

            {
              <FieldWrapper>
                <span className="label custom">Prazo de recebimento</span>
                <Select
                  icon="MdKeyboardArrowDown"
                  size={24}
                  name=""
                  options={receiptFees}
                  initialValue={receiptConfig}
                  onChange={obj => setReceiptConfig(obj.value)}
                />
              </FieldWrapper>
            }

            <Input
              label="Valor"
              name="amount"
              placeholder="R$ 0,00"
              value={amountFormatted}
              onChange={handleAmount}
            />

            <FieldWrapper>
              <span className="label custom">Método de pagamento</span>
              <RadioWrapper>
                <RadioInput
                  className="RequestPaymentRadio"
                  name="other"
                  type="radio"
                  value="cc"
                  label=""
                  checked={paymentMethod === 'cc'}
                  onClick={() => setCheck('cc')}
                >
                  <span>Cartão de crédito</span>
                </RadioInput>

                <RadioInput
                  className="RequestPaymentRadio"
                  name="other name"
                  type="radio"
                  value="bb"
                  label="  "
                  checked={paymentMethod === 'bb'}
                  onClick={() => setCheck('bb')}
                >
                  <span>Boleto bancário</span>
                </RadioInput>

                <RadioInput
                  className="RequestPaymentRadio"
                  name="other other name"
                  type="radio"
                  value="pp"
                  label="  "
                  checked={paymentMethod === 'pp'}
                  onClick={() => setCheck('pp')}
                >
                  <span>Pix</span>
                </RadioInput>
              </RadioWrapper>
            </FieldWrapper>

            <FieldWrapper disabled={!(paymentMethod === 'cc')}>
              <span className="label custom">Parcelamento</span>
              <Select
                icon="MdKeyboardArrowDown"
                name=""
                options={InstallmentsOptions}
                initialValue={installments}
                onChange={obj => setData({ ...data, installments: obj.value })}
              />
            </FieldWrapper>
          </Col>
          <Col>
            <ShowSimulate
              paymentMethod={paymentMethod}
              amount={amount ? amount : 0}
              installments={installments ? installments : 1}
              receiptConfig={receiptFees[receiptConfig - 1]}
            />
          </Col>
        </ColWrapper>
      </ContainerInner>
    </>
  );
}
