import React from 'react';
import PageHeader from '../../components/PageHeader';
import PaymentMethods from './components/PaymentMethods';

export default function Payment() {
  return (
    <>
      <PageHeader
        title="Métodos de pagamento"
        subtitle="Configure os métodos de pagamento padrão para sua conta."
      />
      <PaymentMethods />
    </>
  );
}
