import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import axios from '../../services/axios';
import Request from '../../services/core/http/fees';
import history from '../../services/history';
import Spinner from '../../components/Spinner/Absolute';

import { currencyFormat, percentFormat } from '../../utils';
import { Container, ContainerInner } from '../../styles/global';
import PageHeader from '../../components/PageHeader';
import {
  Section,
  SectionTitle,
  Table,
  THeader,
  Row,
  Col,
  TBody,
  AditionalInfo,
  AditionalInfos,
  Label,
} from './styles';

export default function TaxReceipt() {
  const { verify_status } = useSelector(state => state.accounts.user_data);
  const [loadedFees, setLoadedFees] = useState(false);
  const [accountFee, setAccountFee] = useState([]);
  const [fees, setFees] = useState([]);

  useEffect(() => {
    const fetch = async () => {
      const {
        data: {
          data: {
            account: { fees: accountFee },
          },
        },
      } = await axios.get('/accounts/fees');
      setAccountFee(accountFee);
    };
    fetch();
    getFees();
    // eslint-disable-next-line
  }, []);

  const getFees = async () => {
    let {
      data: {
        data: { fees },
      },
    } = await Request.get();
    setFees(
      fees.map(fee => {
        const { antecipation_days, credit_installments_fee, credit_fee } = fee[Object.keys(fee)[0]];
        return {
          id: Object.keys(fee)[0],
          title: `${antecipation_days} dias`,
          antecipation_days,
          cash_payment: credit_fee,
          term_payment: credit_installments_fee,
          is_default: accountFee.antecipation_days === antecipation_days,
        };
      }),
    );
    setLoadedFees(true);
    //setModal(true);
  };

  // const submitTax = async fee_id => {
  //   const {
  //     data: {
  //       data: {
  //         account: { fees },
  //       },
  //     },
  //   } = await Request.put(fee_id);
  //   setaccountFee(fees);
  // };

  //const fees3 = getFees();

  const credit_fees = accountFee.credit_fee;
  const credit_installments_fee = accountFee.credit_installments_fee;

  return (
    <>
      {/* Small */}
      <PageHeader
        title="Taxas e tarifas"
        subtitle="Veja abaixo as taxas e tarifas do Alboom Pay e confira as opções disponíveis de prazo de recebimento no cartão de crédito."
      />
      <ContainerInner>
        <Section>
          <SectionTitle>Geral</SectionTitle>
          <Table>
            <THeader>
              <Row>
                <Col width="70%">Serviço</Col>
                <Col width="30%" align="center">
                  Taxas e Tarifas
                </Col>
              </Row>
            </THeader>

            <TBody>
              <Row>
                <Col width="70%">Abertura e manutenção da conta</Col>
                <Col width="30%" align="center">
                  R$ 0,00
                </Col>
              </Row>

              <Row>
                <Col width="70%">Mensalidade</Col>
                <Col width="30%" align="center">
                  R$ 0,00
                </Col>
              </Row>

              <Row>
                <Col width="70%">Comissão de vendas</Col>
                <Col width="30%" align="center">
                  0%
                </Col>
              </Row>
            </TBody>
          </Table>
        </Section>

        <Section>
          <SectionTitle>Cartão de crédito</SectionTitle>
          <Table>
            <THeader>
              <Row>
                <Col width="40%">Prazo de recebimento*</Col>
                <Col width="30%" align="center">
                  Taxa no crédito à vista
                </Col>
                <Col width="30%" align="center">
                  Taxa no crédito à prazo
                </Col>
              </Row>
            </THeader>
            {!loadedFees ? (
              <Spinner />
            ) : (
              <TBody>
                <Row active={!accountFee.antecipation_automatic}>
                  <Col width="40%">
                    Sem antecipação
                    {!accountFee.antecipation_automatic && <Label>Atual</Label>}
                  </Col>
                  <Col width="30%" align="center">
                    {percentFormat(
                      (!accountFee.antecipation_automatic ? credit_fees : fees[0].cash_payment) /
                        100,
                    )}
                  </Col>
                  <Col width="30%" align="center">
                    {percentFormat(
                      (!accountFee.antecipation_automatic
                        ? credit_installments_fee
                        : fees[0].term_payment) / 100,
                    )}
                  </Col>
                </Row>

                <Row
                  active={accountFee.antecipation_automatic && accountFee.antecipation_days === 30}
                >
                  <Col width="40%">
                    Receba em 30 dias
                    {accountFee.antecipation_automatic && accountFee.antecipation_days === 30 && (
                      <Label>Atual</Label>
                    )}
                  </Col>
                  <Col width="30%" align="center">
                    {percentFormat(
                      (accountFee.antecipation_days === 30 ? credit_fees : fees[1].cash_payment) /
                        100,
                    )}
                  </Col>
                  <Col width="30%" align="center">
                    {percentFormat(
                      (accountFee.antecipation_days === 30
                        ? credit_installments_fee
                        : fees[1].term_payment) / 100,
                    )}
                  </Col>
                </Row>

                <Row
                  active={accountFee.antecipation_automatic && accountFee.antecipation_days === 14}
                >
                  <Col width="40%">
                    Receba em 14 dias
                    {accountFee.antecipation_automatic && accountFee.antecipation_days === 14 && (
                      <Label>Atual</Label>
                    )}
                  </Col>
                  <Col width="30%" align="center">
                    {percentFormat(
                      (accountFee.antecipation_days === 14 ? credit_fees : fees[2].cash_payment) /
                        100,
                    )}
                  </Col>
                  <Col width="30%" align="center">
                    {percentFormat(
                      (accountFee.antecipation_days === 14
                        ? credit_installments_fee
                        : fees[2].term_payment) / 100,
                    )}
                  </Col>
                </Row>

                <Row
                  active={accountFee.antecipation_automatic && accountFee.antecipation_days === 2}
                >
                  <Col width="40%">
                    Receba em 2 dias
                    {accountFee.antecipation_automatic && accountFee.antecipation_days === 2 && (
                      <Label>Atual</Label>
                    )}
                  </Col>
                  <Col width="30%" align="center">
                    {percentFormat(
                      (accountFee.antecipation_days === 2 ? credit_fees : fees[3].cash_payment) /
                        100,
                    )}
                  </Col>
                  <Col width="30%" align="center">
                    {percentFormat(
                      (accountFee.antecipation_days === 2
                        ? credit_installments_fee
                        : fees[3].term_payment) / 100,
                    )}
                  </Col>
                </Row>
              </TBody>
            )}
          </Table>
          <AditionalInfos small>
            *Para alterar o prazo de recebimento{' '}
            <span onClick={() => history.push('/config-anticipation')}>clique aqui</span> ou acesse
            Configurações &gt; Antecipação.
          </AditionalInfos>

          <Table marginTop="20px">
            <THeader>
              <Row>
                <Col width="70%">Serviço</Col>
                <Col width="30%" align="center">
                  Tarifa
                </Col>
              </Row>
            </THeader>
            <TBody>
              <Row>
                <Col width="70%">Tarifa fixa por transação no cartão de crédito bem sucedida</Col>
                <Col width="30%" align="center">
                  {currencyFormat(accountFee.transaction_fee / 100)}
                </Col>
              </Row>

              <Row>
                <Col width="70%">Taxa de antecipação</Col>
                <Col width="30%" align="center">
                  {percentFormat(accountFee.antecipation_fee_monthly / 100)} a.m.{' '}
                </Col>
              </Row>
            </TBody>
          </Table>
        </Section>

        <Section>
          <SectionTitle>Boleto e Pix</SectionTitle>
          <Table>
            <THeader>
              <Row>
                <Col width="70%">Serviço</Col>
                <Col width="30%" align="center">
                  Tarifa
                </Col>
              </Row>
            </THeader>
            <TBody>
              <Row>
                <Col width="70%">Tarifa fixa por Boleto pago</Col>
                <Col width="30%" align="center">
                  {currencyFormat(accountFee.invoice_fee / 100)}
                </Col>
              </Row>

              {/* <Row>
                <Col width="70%">Tarifa fixa por Pix pago</Col>
                <Col width="30%" align="center">
                  {currencyFormat(accountFee.pix_transaction_fee / 100)}
                </Col>
              </Row> */}

              <Row>
                <Col width="70%">Taxa por pagamento via Pix</Col>
                <Col width="30%" align="center">
                  {percentFormat(accountFee.pix_fee_percent / 100)}
                </Col>
              </Row>
            </TBody>
          </Table>
        </Section>

        {/* <Section>
          <SectionTitle>Outras taxas</SectionTitle>
          <Table>
            <THeader>
              <Row>
                <Col width="70%">Serviço</Col>
                <Col width="30%" align="center">
                  Tarifa
                </Col>
              </Row>
            </THeader>
            <TBody>
              <Row>
                <Col width="70%">Tarifa fixa por transação no cartão de crédito bem sucedida</Col>
                <Col width="30%" align="center">
                  {currencyFormat(accountFee.transaction_fee / 100)}
                </Col>
              </Row>

              <Row>
                <Col width="70%">Taxa de antecipação</Col>
                <Col width="30%" align="center">
                  {percentFormat(accountFee.antecipation_fee_monthly / 100)} a.m.{' '}
                  <span style={{ color: 'red' }}>*</span>
                </Col>
              </Row>

            </TBody>
          </Table>
          <AditionalInfos small>
            *Condição especial até 31/12/2021. Após essa data a taxa será 1,99% a.m.
          </AditionalInfos>
        </Section> */}

        <Section>
          <SectionTitle>Transferência para sua conta bancária</SectionTitle>
          <Table>
            <THeader>
              <Row>
                <Col width="70%">Serviço</Col>
                <Col width="30%" align="center">
                  Tarifa
                </Col>
              </Row>
            </THeader>
            <TBody>
              <Row>
                <Col width="70%">Transferência para Bradesco</Col>
                <Col width="30%" align="center">
                  R$ 0,00
                </Col>
              </Row>

              <Row>
                <Col width="70%">Transferência para outros Bancos</Col>
                <Col width="30%" align="center">
                  R$ 0,00
                  {/* {currencyFormat(accountFee.withdraw_fee / 100)} */}
                </Col>
              </Row>
            </TBody>
          </Table>
        </Section>

        {/*
        <Section>
          <div className="row justify-content-center">
            <div className="col-lg-12">
              <h4>Boleto</h4>
            </div>
            <div className="col-12 col-md-6 col-lg-6">
              <span className="subtitle">Recebimento em:</span>
              <Skeleton ready={loaded} paragraphs={2}>
                <h5>{accountFee.invoice_days ? accountFee.invoice_days : 'X'} dias úteis</h5>
                <p>após compensação</p>
              </Skeleton>
            </div>
            <div className="col-12 col-md-6 col-lg-6">
              <span className="subtitle">Taxa por boleto:</span>
              <Skeleton ready={loaded}>
                <h3>{currencyFormat(accountFee.invoice_fee / 100)}</h3>
              </Skeleton>
            </div>
          </div>
        </Section>

        <Section>
          <div className="row justify-content-center">
            <div className="col-lg-12">
              <h4>Cartão de crédito</h4>
            </div>
            <div className="col-12 col-md-6 col-lg-6">
              <span className="subtitle">Recebimento em:</span>
              <Skeleton ready={loaded} paragraphs={2}>
                <h5>
                  {accountFee.antecipation_days ? accountFee.antecipation_days : 'X'} dias
                  {/* <ChangeAntecipation onClick={getFees}>Alterar</ChangeAntecipation>
                </h5>
              </Skeleton>
            </div>
            <div className="col-12 col-md-6 col-lg-6">
              <span className="subtitle">Taxa:</span>
              <Skeleton ready={loaded} paragraphs={2}>
                <h3>
                  {accountFee.credit_fee} % <span>à vista</span>
                </h3>
                <h3>
                  {accountFee.credit_installments_fee} % <span>parcelado*</span>
                </h3>
              </Skeleton>
            </div>
            <div className="col-lg-12 text-center mt-4">
              <span className="Skeleton-line">
                <div>*Vendas parceladas terão acréscimo de</div>
                <div className="lineSkeleton">
                  <Skeleton ready={loaded} className="lineSkeleton">
                    <div>
                      &nbsp;
                      {accountFee.antecipation_fee_monthly
                        ? `${accountFee.antecipation_fee_monthly}%`
                        : 'X'}{' '}
                      ao mês
                    </div>
                  </Skeleton>
                </div>
              </span>
            </div>
          </div>
        </Section> */}
      </ContainerInner>
      {/* <Modal
        open={modal}
        initialData={
          fees.length &&
          fees.filter(f => f.antecipation_days === accountFee.antecipation_days).shift().id
        }
        accountFee={fees}
        submitAction={submitTax}
        closeAction={() => setModal(false)}
        title="Editar prazo de recebimento"
      /> */}
    </>
  );
}
