import axios from '../../axios';
import { toast } from 'react-toastify';

export default {
  get: async ({ search = null, filterBy: status, startDate = null, endDate = null }) => {
    return await axios.get('/payment-links', {
      params: {
        phrase: search,
      },
    });
  },
  create: async ({ name, description, amount, installments, payments_settings }) => {
    try {
      const data = {
        name,
        type: 'simple',
        description,
        origin: 'alboom-pay',
        discount: 0,
        shipping_address_required: false,
        shipping_enabled: false,
        shipping_amount: 0,
        installments: {
          max_installments: typeof installments === 'number' ? installments : installments.length,
        },
        payments_settings: payments_settings,
        discounts: [],
        order_items: [
          {
            item: description,
            quantity: 1,
            amount,
          },
        ],
      };

      return await axios
        .post('/payment-links', data)
        .then(response => response.status === 201 && response);
    } catch (e) {
      toast.error('Não foi possivel concluir essa solicitação');
    }
  },
  put: async order => {
    try {
      const data = {
        ...order,
        amountTotal: order.amount,
        installments: {
          max_installments:
            typeof order.installments === 'number' ? order.installments : order.installments.length,
        },
        payments_settings: order.payments_settings,
        order_items: [
          {
            item: order.description,
            amount: order.amount,
            quantity: 1,
          },
        ],
      };

      delete data.id;
      delete data.account;

      return await axios
        .put(`/payment-links/${order.id}`, data)
        .then(response => response.status === 200 && response);
    } catch (e) {
      toast.error('Não foi possivel concluir essa solicitação');
    }
  },
  delete: async id => {
    try {
      await axios
        .delete(`/payment-links/${id}`)
        .then(response => response.status === 204 && response);

      return { id };
    } catch (e) {
      toast.error('Não foi possivel concluir essa solicitação');
    }
  },
  duplicate: async ({ id, nameClone: name }) => {
    try {
      return await axios
        .post(`/payment-links/${id}/duplicate`, { name })
        .then(response => response.status === 201 && response);
    } catch (e) {
      toast.error('Não foi possivel concluir essa solicitação');
    }
  },
};
