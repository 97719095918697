import React, { useEffect, useState } from "react"
import Icon from '@alboompro/boom-components/general/icon'

import Accounts from "../../services/core/http/accounts"
import axios from '../../services/axios';

import DataRow from "./DataRow"
import PeriodSummary from "./PeriodSummary"
import FiltersPanel from "./FiltersPanel"
import NavigationPanel from "./NavigationPanel"
import OperationDetailsModal from "./OperationDetailsModal"
import AnticipationGroupModal from "./AnticipationGroupModal"
import OperationsSkeleton from "./OperationsSkeleton"

import {
  OperationsWrapper,
  OperationsHeader,
  OperationsData,
  TableWrapper,
  TableHeaderWrapper,
  TableHeaderColumn,
  EmptyOperationsWrapper,
  PanelWrapper,
} from "./styles"

import moment from 'moment'
import 'moment/locale/pt-br'
moment.locale('pt-br')

export default function BalanceOperations() {
  const [balanceOperationsData, setBalanceOperationsData] = useState([]) 
  const [currentPage, setCurrentPage] = useState(1)
  const [hasNextPage, setHasNextPage] = useState(false)
  const [periodBalance, setPeriodBalance] = useState({})
  const [paymentSelected, setPaymentSelected] = useState(null)
  const [anticipationMovementSelected, setAnticipationMovementSelected] = useState(null)
  const [loading, setLoading] = useState(true)

  const initialDateRange = {
    start: moment().subtract(29, 'days'),
    end: moment(),
  };

  const [dateRange, setDateRange] = useState({
    startDate: initialDateRange.start.format(),
    endDate: initialDateRange.end.format(),
  });

  useEffect(() => {
    async function fetchBalanceOperations() {
      try {
        setLoading(true)
        const { data, hasNextPage, periodBalance } = await Accounts.balanceOperations({ 
          startDate: dateRange.startDate, 
          endDate: dateRange.endDate, 
          page: currentPage,
        })
        setBalanceOperationsData(data)
        setHasNextPage(hasNextPage)
        setPeriodBalance(periodBalance)
        setLoading(false)
      } catch (error) {
        console.log('error fetching balance operations data');
      }
    }

    fetchBalanceOperations();
  }, [currentPage, dateRange]);

  const handleDateRangeUpdate = (startDate, endDate) => {
    setDateRange({ startDate, endDate })
    setCurrentPage(1)
  }

  const handlePageChange = (action) => {
    if(action === "previous") {
      if(currentPage !== 1) {
        setCurrentPage(state => state - 1)
      } 
    }
    else if (action === "next") {
      setCurrentPage(state => state + 1)
    }

    window.scroll({
      top: 0, 
      left: 0, 
      behavior: 'smooth'
    });
  }

  const handleOperationDetailsOpen = operation => {
    setPaymentSelected(operation);
  };

  const handleOperationDetailsClose = () => {
    setPaymentSelected(null);
  };

  const dateFormat = (date) => {
    let newDate = new Date(date)
    const dd = String(newDate.getDate()).padStart(2, "0");
    const mm = String(newDate.getMonth() + 1).padStart(2, "0");
    const yyyy = newDate.getFullYear();
  
    newDate = dd + '/' + mm + '/' + yyyy;
    return newDate;
  };

  const handleExportToCsv = async () => {
    try {
      await axios
        .get('/accounts/balance-operations', {
          params: {
            startDate: dateRange.startDate,
            endDate: dateRange.endDate,
            exportsToCSV: 'true',
            page: currentPage,
          },
          responseType: 'blob',
        })
        .then(res => {
          const href = URL.createObjectURL(res.data);
          const link = document.createElement('a');
          link.href = href;
          link.setAttribute('download', `account-statement-${dateFormat(dateRange.startDate)}-${dateFormat(dateRange.endDate)}.csv`);
          document.body.appendChild(link);
          link.click();
        });
    } catch (err) {
      console.log(err);
    }
  };

  const handleAnticipationMovementOpen = (movement) => {
    setAnticipationMovementSelected(movement)
  }
  
  const handleAnticipationMovementClose = () => {
    setAnticipationMovementSelected(null)
  }
  
  const tableColumns = [
    { label: "Data", styles: { width: "195px" } },
    { label: "Descrição", styles: { flex: "1" } },
    { label: "Valor (R$)", styles: { width: "110px" } },
  ]
  const hasAnyOperation = balanceOperationsData.length > 0;

  return (
    <>
      <OperationsWrapper>
        <OperationsHeader>
          <h2>Extrato da conta</h2>
          <p>Movimentações do seu saldo disponível</p>
        </OperationsHeader>

        <OperationsData>
          <PanelWrapper>
            <PeriodSummary
              periodBalance={periodBalance}
              isLoading={loading}
              dateRange={dateRange}
            />
            <FiltersPanel 
              initialDateRange={initialDateRange}
              handleDateRangeUpdate={handleDateRangeUpdate}
              exportToCsv={handleExportToCsv}
            />
          </PanelWrapper>
          
          <TableWrapper>
            <TableHeaderWrapper>
              {tableColumns.map(col => (
                <TableHeaderColumn key={col.label} style={col.styles}>
                  <span>{col.label}</span>
                </TableHeaderColumn>
              ))}
            </TableHeaderWrapper>

            {loading ? (
              <OperationsSkeleton
                tableColumns={tableColumns}
                rowsAmount={20}
              />
            ) : (
              hasAnyOperation ? (
                balanceOperationsData.map(operation => (
                  <DataRow 
                    key={operation.id} 
                    operation={operation} 
                    tableColumns={tableColumns}
                    handleOperationDetailsOpen={handleOperationDetailsOpen}
                    handleAnticipationMovementOpen={handleAnticipationMovementOpen}
                  />
                ))
              ) : (
                <EmptyOperationsWrapper>
                  <Icon 
                    kind="bold"
                    group="files-folders"
                    category="folders"
                    file="folder-empty-1-alternate.svg"
                    size="100"
                    color="#D9D9D9"
                  />
                  <span>Nenhuma movimentação neste período</span>
                </EmptyOperationsWrapper>
              )
            )}
          </TableWrapper>

          <NavigationPanel
            hasNextPage={hasNextPage}
            currentPage={currentPage}
            handlePageChange={handlePageChange}
            loading={loading}
          />
        </OperationsData>
      </OperationsWrapper>

      {paymentSelected && (
        <OperationDetailsModal paymentId={paymentSelected} onClose={handleOperationDetailsClose} />
      )}

      {anticipationMovementSelected && (
        <AnticipationGroupModal 
          movement={anticipationMovementSelected}
          onClose={handleAnticipationMovementClose}
        />
      )}
    </>
  );
}
