import axios from 'axios';
import history from '../services/history';
import moment from 'moment';
import { store } from '../store';

const { REACT_APP_API_URL, REACT_APP_API_TIMEOUT } = process.env;

const instance = axios.create({
  baseURL: REACT_APP_API_URL,
  timeout: REACT_APP_API_TIMEOUT,
});

instance.interceptors.request.use(function(config) {
  if (!config.headers.Authorization) {
    const {
      auth: { alpaytoken, alpaytokenexpires },
    } = store.getState();
    const expires = moment().diff(moment(alpaytokenexpires), 'seconds') > 0 ? true : false;
    if (expires) {
      //toast.info('Token expirada, reconectando...');
      setTimeout(() => window.location.reload(), 4000);
    }
    config.headers.Authorization = `Bearer ${alpaytoken}`;
  }
  return config;
});

instance.interceptors.response.use(
  function(response) {
    return response;
  },
  function(error) {
    // network error || 401
    if (error.response && error.response.status === 401) {
      history.push('/session-expired');
    }
    return Promise.reject(error);
  },
);

export default instance;
