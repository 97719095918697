import React from "react"
import Skeleton from '@alboompro/boom-components/feedback/skeleton'

import {
  SkeletonDateCol,
  SkeletonDescriptionCol,
  SkeletonRow,
  SkeletonValueCol,
  SkeletonWrapper
} from "./styles"

const { Line } = Skeleton

export default function OperationsSkeleton({ rowsAmount }) {
  const rows = []

  for(let i = 0; i < rowsAmount; i++) rows.push(i)

  return (
    <SkeletonWrapper>
      {rows.map(row => (
        <SkeletonRow key={row}>
          <SkeletonDateCol>
            <div className="date">
              <Line width="100%" height="100%"/>
            </div>
            <div className="time">
              <Line width="100%" height="100%"/>
            </div>
          </SkeletonDateCol>
          <SkeletonDescriptionCol>
            <div className="texts">
              <Line width="160px" height="15px"/>
              {row % 2 === 0 && <Line width="105px" height="15px"/>}
            </div>
            <div className="buttons">
              <div className="payment">
                <Line width="100%" height="100%"/>
              </div>
              <div className="details">
                <Line width="100%" height="100%"/>
              </div>
            </div>
          </SkeletonDescriptionCol>
          <SkeletonValueCol>
            <div className="currency">
              <Line width="100%" height="100%"/>
            </div>
            <div className="value">
              <Line width="100%" height="100%"/>
            </div>
          </SkeletonValueCol>
        </SkeletonRow>
      ))}
    </SkeletonWrapper>
  )
}