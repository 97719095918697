import { takeLatest, put, call, all } from 'redux-saga/effects';
import { postWithdrawFailure, postWithdrawSuccess } from './actions';
import { balanceDetailsRequest, balanceRequest } from "../balance/actions"
import Accounts from '../../../services/core/http/accounts';
import { toast } from 'react-toastify';

export function* postRequest({payload}) {
  try {
    yield call(Accounts.withdraw, payload);
    yield put(postWithdrawSuccess());
    yield put(balanceRequest());
    yield put(balanceDetailsRequest());
  } catch (error) {
    console.log(error);
    toast.error("Não foi possível solicitar saque, por favor tente novamente mais tarde.");
    yield put(postWithdrawFailure());
  }
}

export default all([
  takeLatest('@withdraw/POST_REQUEST', postRequest),
]);
