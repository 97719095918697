import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ordersRequest } from '../../../store/modules/orders/actions';
import { useParams } from 'react-router-dom';

/* -- Componentes -- */
import Input from '../../../components/Input';
import Textarea from '../../../components/Textarea';
import Checkbox from '../../../components/Checkbox';
import ContactPicker from '../../../components/ContactPicker';
import Button from '../../../components/Button';
import CustomerModal from '../../../components/Modal/Person';
import Customer from '../../../components/Customer';
import PaymentType from '../../../components/PaymentType';

/* -- Utils -- */
import { currencyFormat } from '../../../utils';
import { paymentRequestSimple } from '../../../validators/schema';
import {
  ButtonContainer,
  AmountWrapper,
  StyledValue,
  StyledForm,
  SubTitle,
  ContainerPaymentType,
} from './styles';
import { AddButton, Receiver } from '../styles';

export default function() {
  const { REACT_APP_REQUESTS_MAX_TOTAL: limit } = process.env;
  const loading = useSelector(state => state.orders.loading);
  const { installments_no_fees } = useSelector(
    state => state.accounts.payments_settings.credit_card,
  );

  const { payments_settings: paymentSettings, core_user: { beta_tests } } = useSelector(state => state.accounts);
  const { contactEncode } = useParams();
  const state = {
    editCustomer: {},
    customers: [],
    modal: false,
    shipping: false,
    message: `Aqui está a solicitação de pagamento conforme combinamos. Muito obrigado!`,
    description: '',
    total: 0,
    totalFormatted: '',
    settings: false,
    installments: installments_no_fees,
    payments_settings: paymentSettings,
  };
  const [data, setData] = useState(state);
  const dispatch = useDispatch();
  const {
    editCustomer,
    customers,
    modal,
    description,
    shipping,
    message,
    total,
    totalFormatted,
    installments,
    payments_settings,
  } = data;

  function handleQueryParams() {
    const decode = JSON.parse(atob(contactEncode));
    const { name, lastName, email } = decode;
    commitCustomer({ name: name + ' ' + lastName ? lastName : '', email: email });
  }

  function handleSubmit(payload) {
    dispatch(
      ordersRequest({
        ...payload,
        total,
        customers,
        description,
        shipping,
        message,
        installments,
        payments_settings,
      }),
    );
  }

  function handleAmount({ target: { value: v } }) {
    const value = parseInt(v.replace(/[^\d]/g, '')) || 0;
    const total = value > parseInt(limit) ? parseInt(limit) : value;
    return setData({ ...data, total });
  }

  function beginEditCustomer(id) {
    //setData({ ...data, editCustomer: { ...customers[id], id }, modal: true });

    return setData({ ...data, editCustomer: { ...customers[id], id }, modal: true });
  }

  function removeCustomer(id) {
    const customers = [...data.customers];
    customers.splice(id, 1);
    setData({ ...data, customers });
  }

  function commitCustomer(customer) {
    if (Object.keys(editCustomer).length) {
      customers[customer.id] = customer;
      return setData({ ...data, customers, editCustomer: {}, modal: false });
    }
    return setData({ ...data, customers: [...customers, customer], modal: false });
  }

  const removeCustomerBeta = useCallback((id) => {
    const newCustomers = customers

    console.log(id);
    // console.log(newCustomers);

    newCustomers.splice(id, 1);

    return setData(d => ({
      ...d,
      customers: newCustomers,
    }));
  }, [data])

  const addCustomer = useCallback((contact) => {
    const newCustomers = customers

    newCustomers.push({
      id: newCustomers.length,
      name: contact.lastName ? contact.name+' '+contact.lastName : contact.name,
      email: contact.emails[0].email
    })

    return setData(d => ({
      ...d,
      customers: newCustomers,
    }));
  }, [data])

  function handlePaymentType(type) {
    // console.log(type);

    setData(d => ({
      ...d,
      installments: type.ccFee,
      payments_settings: type.method,
    }));
  }

  useEffect(() => {
    setData(d => ({ ...d, total, totalFormatted: total ? currencyFormat(total / 100) : '' }));
  }, [total]);

  useEffect(() => {
    setData(d => ({ ...d, payments_settings: paymentSettings }));
  }, [paymentSettings]);

  useEffect(() => {
    setData(d => ({ ...d, installments: installments_no_fees }));
  }, [installments_no_fees]);

  useEffect(() => {
    if (contactEncode) {
      handleQueryParams();
    }
  }, []);

  return (
    <>
      <StyledForm onSubmit={handleSubmit} schema={paymentRequestSimple}>
        <AmountWrapper>
          <SubTitle>Valor</SubTitle>
          <StyledValue
            autoComplete="off"
            name="total"
            onChange={handleAmount}
            value={totalFormatted}
            placeholder="R$ 0,00"
          />
        </AmountWrapper>

        <Input
          name="description"
          label="Descrição"
          placeholder="Descreva brevemente o produto e/ou serviço"
          maxlength="100"
          value={description}
          onChange={e => setData({ ...data, description: e.target.value })}
        />

        <Receiver>
          <p className="description">Solicitação de pagamento para:</p>
          {beta_tests.includes('contacts') && (
            <ContactPicker onSelect={addCustomer}></ContactPicker>
          )}
          {customers.length > 0 &&
            customers.map((customer, id) => (
              <Customer
                {...customer}
                key={id}
                id={id}
                amount={totalFormatted}
                onEdit={() => beginEditCustomer(id)}
                onRemove={beta_tests.includes('contacts') ? () => removeCustomerBeta(id) : () => removeCustomer(id)}
              />
            ))}
          {!beta_tests.includes('contacts') && (
            <AddButton type="button" onClick={() => setData({ ...data, modal: true })}>
              <img
                src="https://icons.alboompro.com/v1/bold/interface-essential/remove-add/50AFF4/add-circle.svg"
                alt=""
              />
              Adicionar cliente
            </AddButton>
          )}
        </Receiver>
        <Textarea
          name="message"
          label="Sua mensagem"
          value={message}
          maxlength="255"
          onChange={e => setData({ ...data, message: e.target.value })}
          inlineStyle={{ marginBottom: '0' }}
        />

        <Checkbox
          name="enable_address"
          label=" "
          value={shipping}
          onChange={() => setData({ ...data, shipping: !shipping })}
          infoSize={18}
        >
          <p>Solicitar endereço de entrega</p>
        </Checkbox>

        {installments && payments_settings.credit_card.enabled !== null && (
          <ContainerPaymentType>
            <PaymentType
              initialValues={{ method: payments_settings, ccFee: installments }}
              handlePaymentType={handlePaymentType}
            />
          </ContainerPaymentType>
        )}
        <ButtonContainer>
          <Button
            htmlType="submit"
            className="buttonBlue buttonLarge"
            disabled={loading}
            loading={loading}
          >
            Enviar solicitação
          </Button>
        </ButtonContainer>
      </StyledForm>
      {modal && (
        <CustomerModal
          initialData={editCustomer}
          title="Adicionar cliente"
          submitText="Adicionar"
          onSubmit={commitCustomer}
          onClose={() => setData({ ...data, editCustomer: {}, modal: false })}
        />
      )}
    </>
  );
}
