/* eslint-disable no-undef */
import React, { Component } from 'react';
import moment from 'moment';

import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  CardInfoRow,
  CardInfoLabel,
  CardInfoValue,
} from '../styles';

class PaymentRequest extends Component {
  render() {
    const { payer, requested_at } = this.props;
    
    return (
      <Card>
        <CardHeader>
          <CardTitle>Pagamento solicitado para</CardTitle>
        </CardHeader>
        <CardBody>

          <CardInfoRow>
            <CardInfoLabel>Nome:</CardInfoLabel>
            <CardInfoValue>{payer.name}</CardInfoValue>
          </CardInfoRow>

          <CardInfoRow>
            <CardInfoLabel>E-mail:</CardInfoLabel>
            <CardInfoValue>{payer.email}</CardInfoValue>
          </CardInfoRow>

          <CardInfoRow>
            <CardInfoLabel>Data da solicitação:</CardInfoLabel>
            <CardInfoValue>
              {moment(requested_at).format('DD/MM/YYYY [às] HH:mm')}
            </CardInfoValue>
          </CardInfoRow>

        </CardBody>
      </Card>
    );
  }
}

PaymentRequest.propTypes = {};

export default PaymentRequest;
