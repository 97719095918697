/* eslint-disable no-undef */
import React, { Component } from 'react';
import { currencyFormat, percentFormat } from '../../../utils';
import {
  AmountWrapper,
  Amount,
  FeeDetails,
  InfoRow,
  InfoLabel,
  InfoValue,
  DetailsTitle,
  FeePercent,
} from '../styles';

class ShowSimulate extends Component {
  render() {
    const { paymentMethod, amount, installments, receiptConfig } = this.props;

    const {
      transaction_fee = 70,
      boleto_fee = 299,
      antecipation_fee = 0.0199,
      pix_fee_percent = 0.0099,
    } = this.props;

    function creditFee() {
      return installments == 1 ? receiptConfig.credit_fee : receiptConfig.credit_parc_fee;
    }

    function parc_fee(parc, partial_days) {
      //return 0

      if (parc == 0) return 0;

      let parc_amount = (amount * (1 - creditFee())) / installments;

      var calc =
        antecipation_fee * parc_amount * (parc - 1) + antecipation_fee * partial_days * parc_amount;

      return calc + parc_fee(parc - 1, partial_days);
    }

    function installmentsFee() {
      //if (installments == 1) return 0

      if (receiptConfig.parc_mult == null) return 0;

      return parc_fee(installments, receiptConfig.parc_mult) * 1.07;
    }

    function netAmount() {
      let netAmount = 0;

      if (paymentMethod === 'cc') {
        let credit_fee = creditFee();
        let installments_fee = installmentsFee();
        const credit_fee_amount = amount * credit_fee;

        netAmount = amount - transaction_fee - credit_fee_amount - installments_fee;
      } else if (paymentMethod === 'bb') {
        netAmount = amount - boleto_fee;
      } else if (paymentMethod === 'pp') {
        const pix_fee_amount = amount * pix_fee_percent;

        netAmount = amount - pix_fee_amount;
      }

      if (netAmount < 0) return 0;
      return netAmount;
    }

    const renderFeeDetails = method => {
      if (method === 'cc') {
        return (
          <FeeDetails>
            <DetailsTitle>Detalhes</DetailsTitle>
            <InfoRow>
              <InfoLabel>Valor pago:</InfoLabel>
              <InfoValue>{currencyFormat(amount / 100)}</InfoValue>
            </InfoRow>

            <InfoRow>
              <InfoLabel>Taxa de crédito:</InfoLabel>
              <InfoValue>
                {currencyFormat((amount * creditFee()) / 100)}
                <FeePercent>{percentFormat(creditFee())}</FeePercent>
              </InfoValue>
            </InfoRow>

            {receiptConfig.parc_mult != null && (
              <InfoRow>
                <InfoLabel>Taxa de antecipação:</InfoLabel>
                <InfoValue>
                  {currencyFormat(installmentsFee() / 100)}
                  <FeePercent>1,99% a.m.</FeePercent>
                </InfoValue>
              </InfoRow>
            )}

            <InfoRow>
              <InfoLabel>Taxa de transação:</InfoLabel>
              <InfoValue>{currencyFormat(transaction_fee / 100)}</InfoValue>
            </InfoRow>
            <InfoRow>
              <InfoLabel>Total das taxas:</InfoLabel>
              <InfoValue>
                {currencyFormat((transaction_fee + amount * creditFee() + installmentsFee()) / 100)}
              </InfoValue>
            </InfoRow>
            <InfoRow bold>
              <InfoLabel>Valor líquido recebido:</InfoLabel>
              <InfoValue>{currencyFormat(netAmount() / 100)}</InfoValue>
            </InfoRow>
          </FeeDetails>
        );
      } else if (method === 'bb') {
        return (
          <FeeDetails>
            <DetailsTitle>Detalhes</DetailsTitle>
            <InfoRow>
              <InfoLabel>Valor pago:</InfoLabel>
              <InfoValue>{currencyFormat(amount / 100)}</InfoValue>
            </InfoRow>
            <InfoRow>
              <InfoLabel>Taxa de boleto:</InfoLabel>
              <InfoValue>{currencyFormat(boleto_fee / 100)}</InfoValue>
            </InfoRow>
            <InfoRow bold>
              <InfoLabel>Valor líquido recebido:</InfoLabel>
              <InfoValue>{currencyFormat(netAmount() / 100)}</InfoValue>
            </InfoRow>
          </FeeDetails>
        );
      } else if (method === 'pp') {
        return (
          <FeeDetails>
            <DetailsTitle>Detalhes</DetailsTitle>
            <InfoRow>
              <InfoLabel>Valor pago:</InfoLabel>
              <InfoValue>{currencyFormat(amount / 100)}</InfoValue>
            </InfoRow>
            <InfoRow>
              <InfoLabel>Taxa do Pix:</InfoLabel>
              <InfoValue>{currencyFormat((pix_fee_percent * amount) / 100)}</InfoValue>
              <FeePercent>0,99%.</FeePercent>
            </InfoRow>
            <InfoRow>
              <InfoLabel>Total das taxas:</InfoLabel>
              <InfoValue>{currencyFormat((pix_fee_percent * amount) / 100)}</InfoValue>
            </InfoRow>
            <InfoRow bold>
              <InfoLabel>Valor líquido recebido:</InfoLabel>
              <InfoValue>{currencyFormat(netAmount() / 100)}</InfoValue>
            </InfoRow>
          </FeeDetails>
        );
      }
    };

    return (
      <div>
        <AmountWrapper>
          <h4>Você recebe</h4>
          <Amount>{currencyFormat(netAmount() / 100)}</Amount>
        </AmountWrapper>
        {renderFeeDetails(paymentMethod)}
      </div>
    );
  }
}

ShowSimulate.propTypes = {};

export default ShowSimulate;
