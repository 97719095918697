import React from 'react';
import { useDispatch } from 'react-redux';

import Button from '@alboompro/boom-components/general/button';
import PageHeader from '../../components/PageHeader';
import MyCheckout from '../../components/MyCheckout';

import { modalOpen } from '../../store/modules/modal/actions';

export default function() {
  const dispatch = useDispatch();

  return (
    <>
      <PageHeader
        title="Meus links de pagamento"
        subtitle="Crie e gerencie links de pagamento para vender online."
        actions={
          <Button
            onClick={() => dispatch(modalOpen({ type: 'Checkout', data: true }))}
            style={{ height: 35, backgroundColor: '#50AFF4' }}
            type="primary"
          >
            Criar link de pagamento
          </Button>
        }
        breakLine
      />
      <MyCheckout />
    </>
  );
}
