import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  min-height: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #5E5E5E;
  font-size: 17px;

  .text {
    padding-top: 20px;
  }

  @media screen and (max-width: 768px) {
    text-align: center;
  }

`;
