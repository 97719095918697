import produce from "immer";

const INITIAL_STATE = {
  list: [],
  loading: false
};

export default function banks(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case "@banks/REQUEST": {
        draft.loading = true;
        break;
      }
      case "@banks/SUCCESS": {
        draft.list = action.payload.data.data.banks;
        draft.loading = false;
        break;
      }
      case "@banks/FAILURE": {
        draft.loading = false;
        break;
      }
      default:
        break;
    }
  });
}
