import React from 'react';
import { Header, Title, Subtitle, Label, LabelText, TitleContainer, ActionsContainer } from './styles';

export default function PageHeader({ title, subtitle, actions, label, labelIcon, breakLine }) {
  return (
    <Header breakLine={breakLine}>
      <div>
        <TitleContainer>
          <Title>{title}</Title>
          {label && (
            <Label>
              {labelIcon}
              {label && <LabelText>{label}</LabelText>}
            </Label>
          )}
        </TitleContainer>
        {subtitle && <Subtitle>{subtitle}</Subtitle>}
      </div>
      {actions && <ActionsContainer>{actions}</ActionsContainer>}
    </Header>
  );
}
