import styled from "styled-components";
export const Container = styled.div`
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

export const Wrapper = styled.div`
  height: auto;

  .container {
    text-align: center;
    margin: 0 15px;

    h1 {
      font-size: 15em;
      line-height: 190px;
      text-align: center;
      }
    }
  }
`;
