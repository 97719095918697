import React, { useState, useEffect } from 'react';
import moment from 'moment';
import Icon from '@alboompro/boom-components/general/icon';
import axios from '../../services/axios';
import history from '../../services/history';
import { Wrapper, Header, Content, Footer, Alert } from './styles';

const Steps = ({
  createdAt,
  verifyStatus,
  verificationRequestedAt,
  verifiedAt,
  totalTransactions,
  lastTransactionAt,
  allowAntecipation,
}) => {
  const createdDate = moment(createdAt).format('DD/MM/YYYY'),
    verificationRequestedDate = moment(verificationRequestedAt).format('DD/MM/YYYY'),
    verifiedDate = moment(verifiedAt).format('DD/MM/YYYY'),
    lastTransactionDate = moment(lastTransactionAt).format('DD/MM/YYYY'),
    daysOfAccountCreated = moment().diff(createdAt, 'days'),
    daysMissingToFullAnticipation = 30 - daysOfAccountCreated,
    transactionsMissingToFullAnticipation = 10 - totalTransactions,
    [fees, setFees] = useState({});

  const getFees = async () => {
    await axios.get('/accounts/fees').then(({ data: { data: { account: { fees: options } } } }) =>
      setFees(options),
    );
  };

  useEffect(() => {
    getFees();
  }, []);

  return (
    <>
      {/* Sem antecipação e conta não verificada */}
      {fees.antecipation_automatic === false &&
        (verifyStatus === 'not_verified' || verifyStatus === 'verifying') && (
          <Alert>
            <Icon
              kind="bold"
              group="interface-essential"
              category="alerts"
              file="alert-circle.svg"
              color="#1B9DFA"
            />
            <p>
              Sua conta Alboom Pay não está verificada. Solicite a verificação da sua conta para
              liberar as opções de antecipação nas vendas no cartão de crédito.{' '}
              <a onClick={() => history.push('/account-alboom-pay')}>
                Clique aqui e solicite agora mesmo.
              </a>
            </p>
          </Alert>
        )}

      {/* Sem antecipação e conta verificada  */}
      {fees.antecipation_automatic === false && verifyStatus === 'verified' && (
        <Alert>
          <Icon
            kind="bold"
            group="interface-essential"
            category="alerts"
            file="alert-circle.svg"
            color="#1B9DFA"
          />
          <p>
            Sua conta Alboom Pay foi verificada com sucesso, você agora pode ativar a antecipação de
            30 dias nas suas vendas parceladas no cartão de crédito e receber o dinheiro muito mais
            rápido.
          </p>
        </Alert>
      )}

      {/* Sem antecipação, e histórico concluído */}
      {fees.antecipation_automatic === false &&
        verifyStatus === 'verified' &&
        totalTransactions >= 10 &&
        daysOfAccountCreated >= 30 && (
          <Alert>
            <Icon
              kind="bold"
              group="interface-essential"
              category="alerts"
              file="alert-circle.svg"
              color="#1B9DFA"
            />
            <p>
              Parabéns! Você atingiu o histórico necessário para liberação da antecipação de 14 e 2
              dias. Aproveite para receber pagamentos em 30, 14 ou 2 dias úteis.
            </p>
          </Alert>
        )}

      {/* Com antecipação de 30 dias e sem histórico concluído  */}
      {fees.antecipation_automatic === true &&
        fees.antecipation_days === 30 &&
        verifyStatus === 'verified' &&
        (totalTransactions < 10 || daysOfAccountCreated < 30) && (
          <Alert>
            <Icon
              kind="bold"
              group="interface-essential"
              category="alerts"
              file="alert-circle.svg"
              color="#1B9DFA"
            />
            <p>
              Gostaria de receber suas vendas no cartão em 14 ou 2 dias? Para liberação automática
              dessas opções você só precisará de 10 transações bem sucedidas no cartão de crédito e
              30 dias de uso.
            </p>
          </Alert>
        )}

      {/* Com antecipação de 30 dias e com histórico concluído  */}
      {fees.antecipation_automatic === true &&
        fees.antecipation_days === 30 &&
        verifyStatus === 'verified' &&
        totalTransactions >= 10 &&
        daysOfAccountCreated >= 30 && (
          <Alert>
            <Icon
              kind="bold"
              group="interface-essential"
              category="alerts"
              file="alert-circle.svg"
              color="#1B9DFA"
            />
            <p>
              As opções de antecipação de 14 e 2 dias estão liberadas para sua conta. Aproveite, se
              desejar :-. <a onClick={() => history.push('/tax-receipt')}>Conheça as taxas.</a>
            </p>
          </Alert>
        )}

      {/* Com antecipação de 14 ou 2 dias e com histórico concluído  */}
      {(fees.credit_fee === 3.99 || fees.credit_fee === 4.99) &&
        verifyStatus === 'verified' &&
        totalTransactions >= 10 &&
        daysOfAccountCreated >= 30 && (
          <Alert>
            <Icon
              kind="bold"
              group="interface-essential"
              category="alerts"
              file="alert-circle.svg"
              color="#1B9DFA"
            />
            <p>
              Que bom que está curtindo o Alboom Pay. Estamos trabalhando em mais novidades e
              melhorias, e caso tenha um pedido, ou sugestão, clique{' '}
              <a onClick={() => window._urq.push(['Feedback_Open'])}>aqui</a> e ajude a gente a
              criar a melhor ferramenta de pagamentos do mercado.
            </p>
          </Alert>
        )}
      <Wrapper>
        <div>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <Header status="complete">Nível 1</Header>
            <Content>
              <Icon
                kind="bold"
                group="interface-essential"
                category="form-validation"
                file="check-circle-1.svg"
                color="#1B9DFA"
              />
              <h1>Recebimento sem antecipação</h1>
              <p>Em 30 dias ou conforme fluxo na venda no cartão de crédito</p>
            </Content>
            <Footer status="complete">
              <h1>Conta criada</h1>
              <p>Data: {createdDate}</p>
            </Footer>
          </div>
        </div>
        <div>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <Header status={(allowAntecipation || verifyStatus === 'verified') && 'complete'}>
              Nível 2
            </Header>
            <Content>
              {allowAntecipation || verifyStatus === 'verified' ? (
                <Icon
                  kind="bold"
                  group="interface-essential"
                  category="form-validation"
                  file="check-circle-1.svg"
                  color="#1B9DFA"
                />
              ) : (
                <Icon
                  kind="bold"
                  group="interface-essential"
                  category="time"
                  file="time-clock-circle.svg"
                  size="64"
                  color="#ACACAC"
                />
              )}

              <h1>Liberação de antecipação em 30 dias</h1>
              <p>Venda parcelada no cartão poderá ser antecipada em 30 dias</p>
            </Content>
            <Footer status={(allowAntecipation || verifyStatus === 'verified') && 'complete'}>
              <h1>Conta verificada</h1>
              {allowAntecipation && verifyStatus === 'not_verified' ? (
                <p>Antecipação liberada pela equipe Alboom</p>
              ) : (
                <p>
                  Status:{' '}
                  {verifyStatus === 'verified'
                    ? 'verificada'
                    : verifyStatus === 'verifying'
                    ? 'em verificação'
                    : 'não verificada'}
                </p>
              )}
              {verifyStatus === 'not_verified' ? (
                <p>
                  Solicite a verificação{' '}
                  <a onClick={() => history.push('/account-alboom-pay')}>aqui</a>.
                </p>
              ) : verifyStatus === 'verifying' ? (
                <p>Verificação solicitada em: {verificationRequestedDate}</p>
              ) : (
                <p>Conta verificada em: {verifiedDate}</p>
              )}
            </Footer>
          </div>
        </div>
        <div>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <Header
              status={
                (allowAntecipation ||
                  (verifyStatus === 'verified' &&
                    totalTransactions >= 10 &&
                    daysOfAccountCreated >= 30)) &&
                'complete'
              }
            >
              Nível 3
            </Header>
            <Content>
              {allowAntecipation ||
              (verifyStatus === 'verified' &&
                totalTransactions >= 10 &&
                daysOfAccountCreated >= 30) ? (
                <Icon
                  kind="bold"
                  group="interface-essential"
                  category="form-validation"
                  file="check-circle-1.svg"
                  color="#1B9DFA"
                />
              ) : (
                <Icon
                  kind="bold"
                  group="interface-essential"
                  category="time"
                  file="time-clock-circle.svg"
                  size="64"
                  color="#ACACAC"
                />
              )}
              <h1>Liberação de antecipação em 14 e 2 dias</h1>
              <p>Venda parcelada no cartão poderá ser antecipada em 14 ou 2 dias</p>
            </Content>
            <Footer
              status={
                (allowAntecipation ||
                  (verifyStatus === 'verified' &&
                    totalTransactions >= 10 &&
                    daysOfAccountCreated >= 30)) &&
                'complete'
              }
            >
              <h1>Histórico</h1>
              {(allowAntecipation && <p>Antecipação liberada pela equipe Alboom</p>) || (
                <>
                  <p>
                    10 transações no cartão.{' '}
                    {totalTransactions >= 10
                      ? `Última transação em: ${lastTransactionDate}`
                      : `Faltam ${transactionsMissingToFullAnticipation} transações`}
                  </p>
                  <p>
                    30 dias de conta.{' '}
                    {daysOfAccountCreated >= 30
                      ? `Alcançado em: ${moment(createdAt)
                          .add('30', 'days')
                          .format('DD/MM/YYYY')}`
                      : `Faltam ${daysMissingToFullAnticipation} dias.`}
                  </p>
                </>
              )}
            </Footer>
          </div>
        </div>
      </Wrapper>
    </>
  );
};

export default Steps;
