import React, { useState } from 'react';
import { Container, SearchInput, SearchButton } from './styles';
import searchIcon from '../../assets/static/images/icons/search@3x.png';

const SearchBar = ({ className, handleSearch, validateSearch, loading, slug }) => {
  const [search, setSearch] = useState(!slug ? '' : slug);

  const handleOnChange = e => {
    const { value } = e.target;
    
    if (validateSearch) {
      validateSearch(value) && setSearch(value);
    } else {
      setSearch(value);
    }
  };

  return (
    <Container className={className}>
      <SearchInput
        type="text"
        value={search}
        onKeyDown={e =>
          e.key === 'Enter' || (e.key === 'Backspace' && e.target.value === '' && !loading)
            ? handleSearch(search)
            : null
        }
        onChange={handleOnChange}
        placeholder="Pesquisar"
      />
      <SearchButton>
        <img src="https://icons.alboompro.com/v1/bold/interface-essential/search/bdbdbd/search-alternate.svg" alt="" onClick={() => handleSearch(search)} />
      </SearchButton>
    </Container>
  );
};

export default SearchBar;
