import styled from 'styled-components';

export const Container = styled.div`
  margin: 40px auto;
  max-width: ${props => (props.small ? '740px;' : '1080px')};

  @media screen and (max-width: 1380px) {
    margin: 40px auto;
    padding: 0 40px;
  }

  @media screen and (max-width: 767px) {
    margin: 20px;
    padding: 0;
  }

  .react-select__menu:hover,
  .react-select__control:hover {
    cursor: pointer;
  }

  .CountrySelectWizard {
    .react-select__control {
      min-height: 50px;
    }
  }
`;

export const Alert = styled.div`
  width: calc(100% - 80px);
  max-width: 1080px;
  margin: 72px auto !important;
  display: flex;
  border-radius: 6px;
  border-left: 1px solid #e3e3e3;

  @media (max-width: 768px) {
    width: calc(100% - 40px);
    margin: 10px auto !important;
  }

  > img {
    @media (max-width: 768px) {
      display: none;
    }
  }

  > div {
    position: relative;
    display: inline-block;
    background-color: white;
    flex: 1;
    padding: 20px 58px;
    border-top: 1px solid #e3e3e3;
    border-right: 1px solid #e3e3e3;
    border-bottom: 1px solid #e3e3e3;
    border-radius: 6px;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;

    @media (max-width: 768px) {
      padding: 20px;
    }

    h1 {
      font-size: 24px;
      font-style: normal;
      font-weight: 500;
      line-height: 29px;
      letter-spacing: 0em;
      text-align: left;
      margin-bottom: 14px;

      @media (max-width: 768px) {
        font-size: 17px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: 0em;
        text-align: left;
      }
    }

    p {
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 1.3;
      letter-spacing: 0em;
      text-align: left;
      max-width: 728px;
      margin-bottom: 20px;

      @media (max-width: 768px) {
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 1.3;
        letter-spacing: 0em;
        text-align: left;
      }
    }

    button {
      font-size: 13px;
      font-style: normal;
      font-weight: 500;
      line-height: 16px;
      letter-spacing: 0em;
      text-align: left;
    }

    div.floatIcon {
      position: absolute;
      left: -43.5px;
      top: 50%;
      transform: translateY(-50%);
      border-radius: 50%;
      background-color: #1b9dfa;
      border: 3px solid white;
      box-shadow: 0px 2px 4px #888;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 71px;
      height: 71px;

      @media (max-width: 768px) {
        display: none;
      }
    }
  }
`;
