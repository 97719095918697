import styled from 'styled-components';

export const Table = styled.table`
  width: 100%;

  @media (max-width: 768px) {
    display: none;
  }

  th {
    text-transform: uppercase;
    text-align: left;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 12px;
    color: #494949;
    padding: 10px;
    background-color: white;

    &:nth-child(2) {
      text-align: center;
    }

    div {
      display: flex;
      flex-direction: column;

      span {
        display: block;
        margin-top: 3px;
        font-size: 11px;
        font-weight: 400;
        line-height: 11px;
        letter-spacing: 0em;
        color: #929292;
        text-transform: none;
      }
    }
  }

  td {
    padding: 15px 10px;

    .radioLabel {
      display: block;
      position: relative;
      cursor: pointer;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;

      input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        margin-top: -4px;
        margin-left: 8px;

        &:checked ~ .checkmark .indicator {
          background-color: #2196f3;
        }
      }

      .checkmark {
        position: absolute;
        top: 0;
        left: 0;
        height: 17px;
        width: 17px;
        border-radius: 50%;
        border: 1px solid #c5c5c5;
        margin-top: -4px;
        margin-left: 8px;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .indicator {
        display: block;
        width: 9px;
        height: 9px;
        border-radius: 50%;
      }
    }

    &:first-child {
      img {
        margin-left: 8px;
      }
    }

    &:nth-child(2) {
      color: #494949;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 14px;
      letter-spacing: 0em;
      text-align: center;
      padding-right: 80px;
    }

    &:last-child {
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px;
      letter-spacing: 0em;
      text-align: left;
      color: #494949;
    }

    span {
      display: block;
      max-width: 450px;
    }
  }
`;

export const TableRow = styled.tr`
  background: #fafafa;
  cursor: pointer;

  &:not(:last-child) {
    border-bottom: 2px solid #ffffff;
  }

  ${props => !props.isEnable && 'cursor: default'};

  &:hover {
    ${props => props.isEnable && 'background: rgba(27, 157, 250, 0.1)'}
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;

  @media (max-width: 768px) {
    flex-direction: column;
  }

  button {
    width: 50%;

    @media (max-width: 768px) {
      width: 100%;

      &:first-of-type {
        order: 2;
        margin-bottom: 5px;
      }

      &:last-of-type {
        order: 1;
      }
    }

    &:last-child {
      margin-left: 60px;

      @media (max-width: 768px) {
        margin-left: 0px;
      }
    }
  }
`;

export const UsePolicyAlert = styled.p`
  display: block;
  margin-top: 23px;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.3;
  letter-spacing: 0em;
  text-align: left;
  margin-bottom: 60px;
  color: #515151;

  @media (max-width: 768px) {
    margin-bottom: 20px;
  }

  a {
    display: inline !important;
    margin: 0 !important;
    padding: 0 !important;
    font-size: 13px !important;
    font-style: normal !important;
    font-weight: 400 !important;
    line-height: 1.3 !important;
    letter-spacing: 0em !important;
    text-align: left !important;
    text-transform: none !important;
    text-decoration: underline !important;
    color: #1ea3fb !important;
  }
`;

export const ModalContent = styled.div`
  padding: 20px 40px;

  h3 {
    color: #515151;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 131%;
    margin-bottom: 20px;
    margin-left: -15px;

    &::before {
      content: '⚠️';
      display: inline-block;
      margin-right: 8px;
    }
  }

  ul {
    list-style: disc;

    li {
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      line-height: 1.3;
      letter-spacing: 0em;
      text-align: left;

      & + li {
        margin-top: 14px;
      }
    }
  }

  table {
    width: 100%;
    margin: 38px 0;

    th {
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 14px;
      letter-spacing: 0em;
      text-align: center;
      padding: 10px 0;
      border-bottom: 1px solid #ebebeb;
    }

    td {
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 14px;
      color: #414141;
      text-align: center;
      padding: 10px 0;

      &:first-child {
        font-weight: 500;
        text-align: right;
      }
    }
  }
`;

export const Accordion = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #fafafa;
  display: none;
  border: 1px solid #e3e3e3;
  border-radius: 10px;

  @media (max-width: 768px) {
    display: block;
  }

  > section {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 22px 18px;
    border: 1px solid #e3e3e3;

    &:first-child {
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
    }

    &.border {
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
    }

    section {
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        margin-top: -4px;
        margin-right: 16px;
      }

      .radioLabel {
        display: block;
        position: relative;
        cursor: pointer;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        margin-right: 35px;

        input {
          position: absolute;
          opacity: 0;
          cursor: pointer;
          margin-top: -5px;

          &:checked ~ .checkmark .indicator {
            background-color: #2196f3;
          }
        }

        .checkmark {
          position: absolute;
          top: 0;
          left: 0;
          height: 17px;
          width: 17px;
          border-radius: 50%;
          border: 1px solid #c5c5c5;
          margin-top: -5px;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        .indicator {
          display: block;
          width: 9px;
          height: 9px;
          border-radius: 50%;
        }
      }

      h1 {
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 12px;
        letter-spacing: 0em;
        margin-bottom: 0;
      }
    }
  }

  div {
    display: none;
    padding: 14px 24px;
    background-color: #ffffff;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
    border: 1px solid #e3e3e3;

    &.active {
      display: block;
    }

    &:last-of-type {
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
    }
  }

  button {
    background-color: transparent;
    border: none;
    padding: 0 6px;
    transition: 0.2s;

    &.opened {
      transform: rotate(180deg);
    }
  }
`;
