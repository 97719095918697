import React from 'react';
import { set, get } from '../../../utils/cookies.js';

import { Container, Content, Title, CloseButton, Link, Stripe } from './styles';
class PromoMacBook extends React.PureComponent {
  state = {
    visible: true,
    image: null,
    title: null,
    list: false,
    subtitle: null,
    validate: null,
    button: null,
    link: null,
    rules: null,
    load: null,
  };

  handleCloseButton = () => {
    this.setState({ visible: false });
    set('prosite-macbook', 'disabled', 5);
  };

  render() {
    const { visible } = this.state;

    return (
      visible &&
      !get('prosite-macbook') && (
        <Stripe>
          <Container>
            <Content>
                <Title>
                  💻 Quer ganhar um incrível MacBook Pro M1 da Apple?{' '}
                  <Link href="https://lp.alboompro.com/sorteiomacbook?utm_campaign=tarja-painel-sorteio-macbook" target="_blank">Saiba Mais</Link>
                </Title>
            </Content>
            <CloseButton onClick={this.handleCloseButton}>&#10005;</CloseButton>
          </Container>
        </Stripe>
      )
    );
  }
}

// PromoMacBook.propTypes = {
//   type: React.PropTypes.number,
// }

export default PromoMacBook;
