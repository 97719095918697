export function AlproRequest(token) {
  return {
    type: '@auth/ALPRO_REQUEST',
    payload: { token },
  };
}

export function AlproSuccess(data) {
  return {
    type: '@auth/ALPRO_SUCCESS',
    payload: { data },
  };
}

export function AlproFailure() {
  return {
    type: '@auth/ALPRO_FAILURE',
  };
}

export function AlpayFailure() {
  return {
    type: '@auth/ALPAY_FAILURE',
  };
}

export function AlpayNew() {
  return {
    type: '@auth/ALPAY_NEW_CUSTOMER',
  };
}

export function AlpayNewDisabled() {
  return {
    type: '@auth/ALPAY_NEW_CUSTOMER_DISABLED',
  };
}

export function AlpayLogged(data) {
  return {
    type: '@auth/ALPAY_LOGGED',
    payload: { data },
  };
}
