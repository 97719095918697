export function ordersRequest(data) {
  return {
    type: "@order/ORDERS_REQUEST",
    payload: { data }
  };
}

export function ordersSuccess(data) {
  return {
    type: "@order/ORDERS_SUCCESS",
    payload: { data }
  };
}

export function ordersFailure() {
  return {
    type: "@order/ORDERS_FAILURE"
  };
}

export function ordersClear() {
  return {
    type: "@order/ORDERS_CLEAR"
  };
}


export function ordersListRequest(params) {
  return {
    type: "@order/ORDERS_GET_LIST",
    params
  };
}

export function ordersListRequestSuccess(payload) {
  return {
    type: "@order/ORDERS_GET_LIST_SUCCESS",
    payload
  };
}
