import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { Container } from './styles';

//How to use Components property: https://react-select.com/components

export default function SelectInput({
  className,
  containerStyle,
  clear,
  options,
  onChange,
  initialValue,
  placeholder,
  components,
  disabled,
  required,
  backgroundColor,
  ...rest
}) {
  const styles = value => ({
    control: (provided, state) => ({
      ...provided,
      backgroundColor: backgroundColor ? backgroundColor : '#ffffff',
    }),
    menuPortal: base => ({ ...base, zIndex: 9999 }),
  });
  const [selectedOption, setSelectedOption] = useState(null);
  const [customOptions, setCustomOptions] = useState([]);
  useEffect(() => {
    // console.log(options);
    setCustomOptions(
      options &&
        options.map(option => {
          let label = <div>{option.label}</div>;
          if (option.icon)
            label = (
              <div>
                <img src={option.icon} alt={option.label} style={{ width: 20, marginRight: 8 }} />{' '}
                {option.label}
              </div>
            );
          return {
            value: option.value,
            label: label,
          };
        }),
    );
    clear && setSelectedOption(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options]);

  useEffect(() => {
    initialValue &&
      customOptions &&
      setSelectedOption(customOptions.filter(opt => opt.value === initialValue));
  }, [initialValue, customOptions]);

  function handleChange(values) {
    setSelectedOption(values);
    onChange(values);
  }

  return (
    <Container style={containerStyle}>
      <Select
        value={selectedOption}
        onChange={handleChange}
        options={customOptions}
        className={className}
        placeholder={placeholder}
        classNamePrefix="react-select"
        noOptionsMessage={() => 'Não há opções'}
        components={components}
        menuPortalTarget={document.body}
        styles={styles(backgroundColor)}
        isSearchable={false}
        theme={theme => ({
          ...theme,
          colors: {
            ...theme.colors,
            primary50: '#f1f9ff',
            primary25: '#f1f9ff',
            primary: '#50AFF4',
          },
        })}
        {...rest}
      />
      {!disabled && (
        <input
          className={className}
          tabIndex={-1}
          autoComplete="off"
          style={{ opacity: 0, height: 0, width: rest.width ? rest.width : '100%' }}
          value={selectedOption || ''}
          required={required}
          onChange={() => {}}
        />
      )}
    </Container>
  );
}

SelectInput.propTypes = {
  components: PropTypes.object,
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  className: PropTypes.string,
  containerStyle: PropTypes.object,
  initialValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

SelectInput.defaultProps = {
  placeholder: 'Selecione uma opção',
  className: '',
};
