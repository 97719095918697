export function postWithdraw(payload) {
  return {
    type: '@withdraw/POST_REQUEST',
    payload
  };
}

export function postWithdrawSuccess() {
  return {
    type: '@withdraw/POST_SUCCESS'
  };
}

export function postWithdrawFailure() {
  return {
    type: '@withdraw/POST_FAILURE',
  };
}
