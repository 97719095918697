import styled from 'styled-components';

export const Container = styled.div`
  position: absolute;
  background: rgba(255, 255, 255, 0.3);
  border-radius: 10px;
  width: 100%;
  height: 100%;
  z-index: ${({children}) =>  children.props.zindex ? children.props.zindex : "999"};
  .center {
    left: calc(50% - 20px);
    position: absolute;
    top: calc(50% - 20px);
  }
`;
