import styled from "styled-components";

export const OperationsWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`

export const OperationsHeader = styled.div`
  margin-bottom: 40px;
  
  display: flex;
  flex-direction: column;
  gap: 4;

  font-size: 1rem;
  line-height: 1.5;

  h2 {
    font-size: 1.375rem;
  }

  p {
    font-size: 1rem;
  }

  @media screen and (max-width: 767px) {
    margin-bottom: 12px;

    h2 {
      font-size: 1.125rem; // 18px
    }
    
    p {
      font-size: 0.875rem; // 14px
    }
  }
`

export const OperationsData = styled.div`
  width: 100%;
  padding: 30px;
  border-radius: 10px;
  background-color: #ffffff;
  border: 1px solid #ebebeb;

  display: flex;
  flex-direction: column;
  gap: 40px;

  @media screen and (max-width: 767px) {
    padding: 20px;
    gap: 6px;
  }
`

export const PanelWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media screen and (max-width: 767px) {
    flex-direction: column;
    gap: 16px;

    padding-bottom: 12px;
    border-bottom: 1px solid #e8e8e8;
  }
`

export const TableWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;

  @media screen and (min-width: 767px) {
    margin: 0 -30px;
  }
`

export const TableHeaderWrapper = styled.div`
  padding: 0 30px;
  height: 42px;
  background-color: transparent;
  border-bottom: 1px solid #e8e8e8;
  
  display: flex;

  @media screen and (max-width: 767px) {
    display: none;
  }
`

export const TableHeaderColumn = styled.div`
  padding: 8px 16px;
  height: 100%;

  display: flex;

  font-size: 0.8125rem; // 13px
  text-transform: uppercase;

  :last-of-type {
    justify-content: end;
    padding-right: 0;
  }
  
  span {
    color: #262626;
    font-weight: 500;
  }
`

export const EmptyOperationsWrapper = styled.div`
  font-size: 0.875rem; // 14px
  margin: 136px 0;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 18px;

  @media screen and (max-width: 767px) {
    font-size: 0.8125rem; // 13px
    margin: 70px 0 90px;
    gap: 8px;
    span {
      max-width: 80%;
      text-align: center;
    }
  }
`