import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { ButtonBuilder as PaymentButtonBuilder } from '@alboom/payment-button-builder-vitejs'

import Button from '@alboompro/boom-components/general/button';
import PageHeader from '../../components/PageHeader';
import MyButtons from '../../components/MyButtons';

import { modalOpen } from '../../store/modules/modal/actions';
import { paymentButtonsRequest } from '../../store/modules/paymentButtons/actions';

export default function() {
  const [buttonBuilderVisible, setButtonBuilderVisible] = useState(false);
  const [paymentButtonId, setPaymentButtonId] = useState("");
  const dispatch = useDispatch();

  const toggleButtonBuilderModal = () => {
    setButtonBuilderVisible(false);
  }

  const handleCreateButtonClick = () => {
    setPaymentButtonId("")
    setButtonBuilderVisible(true)
  }

  const handleEditButtonClick = (id) => {
    setPaymentButtonId(id)
    setButtonBuilderVisible(true)
  }
  
  const onUpdate = () => {
    dispatch(paymentButtonsRequest());
  }

  return (
    <>
      <PageHeader
        title="Botões de pagamento"
        subtitle="Crie e gerencie botões de pagamento para utilizar em seu site ou em landing pages."
        actions={
          <Button
            onClick={handleCreateButtonClick}
            type="primary"
            style={{ backgroundColor: "#50AFF4", height: "35px", width: "100%"}}
          >
            Criar botão
          </Button>
        }
        breakLine
      />
      <MyButtons onPaymentButtonEdit={handleEditButtonClick}/>

      <PaymentButtonBuilder
        visible={buttonBuilderVisible}
        onClose={toggleButtonBuilderModal}
        paymentButtonId={paymentButtonId}
        onUpdate={onUpdate}
        language={"pt-BR"}
        consumer="alboompay" 
        environment={process.env.NODE_ENV}
      />
    </>
  );
}