import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import propTypes from 'prop-types';
import {
  Container,
  Header,
  Wrapper,
  ReportTable,
  SearchBar,
  Tbody,
  Thead,
  TableTitle,
} from './styles';
import Select from '../Select';
import DateRangePicker from 'react-bootstrap-daterangepicker';

import { currencyFormat, validateSearch } from '../../utils';
import {
  activitiesRequest,
  typesRequest,
  blockWithdraw,
} from '../../store/modules/activities/actions';
import Spinner from '../Spinner/Absolute';
import Icon from '@alboompro/boom-components/general/icon';
import moment from 'moment';
import 'moment/locale/pt-br';
moment.locale('pt-br');

export default function Activity({ title, height }) {
  const { data: activities, loading, types } = useSelector(state => state.activities);
  const { created_at } = useSelector(state => state.auth);

  const {
    user_data: { verify_status },
  } = useSelector(state => state.accounts);

  const dispatch = useDispatch();
  const [newDates, setNewDates] = useState({
    start: moment().subtract(29, 'days'),
    end: moment(),
  });

  const { start, end } = newDates;
  const [label, setLabel] = useState('Últimos 30 dias');
  const [withdrawBlock, setWithdrawBlock] = useState(false);
  const [initialSettings, setInitialSettings] = useState({
    startDate: start.toDate(),
    endDate: end.toDate(),
    ranges: {
      'Últimos 7 dias': [
        moment()
          .subtract(6, 'days')
          .toDate(),
        moment().toDate(),
      ],
      'Últimos 30 dias': [
        moment()
          .subtract(29, 'days')
          .toDate(),
        moment().toDate(),
      ],
      'Este mês': [
        moment()
          .startOf('month')
          .toDate(),
        moment().toDate(),
      ],
      'Mês anterior': [
        moment()
          .subtract(1, 'months')
          .date(1)
          .toDate(),
        moment()
          .startOf('month')
          .date(0)
          .toDate(),
      ],
      'Últimos 6 meses': [
        moment()
          .subtract(179, 'days')
          .toDate(),
        moment().toDate(),
      ],
      'Últimos 12 meses': [
        moment()
          .subtract(365, 'days')
          .toDate(),
        moment().toDate(),
      ],
      'Todo o período': [
        moment()
          .subtract(moment().diff(moment(new Date(created_at)), 'days') + 1, 'days')
          .toDate(),
        moment().toDate(),
      ],
    },
    autoApply: true,
    locale: { customRangeLabel: 'Personalizado' },
  });

  const [data, setData] = useState({
    phrase: '',
    startDate: moment()
    .subtract(29, 'days')
    .format('YYYY-MM-DDT23:59:59'),
    endDate: moment(new Date()).format('YYYY-MM-DDT23:59:59'),
    filterBy: '',
    submit: true, //get data on component init
  });

  const { phrase, startDate, endDate, filterBy, submit } = data;

  var filters = [
    {
      label: 'Todos',
      value: 1,
    },
  ];
  var typesMap = {
    INPUT: 'Entrada',
    OUTPUT: 'Saída',
    INFO: 'Informativo',
  };
  Object.keys(types).map(type =>
    filters.push({
      label: typesMap[type],
      value: types[type],
    }),
  );

  useEffect(() => {
    if (!Object.keys(types).length) dispatch(typesRequest());
  }, [types, dispatch]);

  useEffect(() => {
    if (submit) {
      dispatch(
        activitiesRequest({
          phrase,
          startDate,
          endDate,
          filterBy: filterBy && filterBy !== 1 ? filterBy : null,
        }),
      );
      setData(d => ({ ...d, submit: false }));
    }
  }, [submit, phrase, startDate, endDate, filterBy, dispatch]);

  const handleFilter = f => setData(d => ({ ...d, filterBy: f.value, submit: true }));

  const handleDateChange = (start, end) => {
    const startDate = start.format(),
    endDate = end.format();
    setData(d => ({ ...d, startDate, endDate, submit: true }));
    if (
      start.format('DD/MM/YYYY') ===
        moment()
          .subtract(6, 'days')
          .format('DD/MM/YYYY') &&
      end.format('DD/MM/YYYY') === moment().format('DD/MM/YYYY')
    ) {
      setLabel('Últimos 7 dias');
    } else if (
      start.format('DD/MM/YYYY') ===
        moment()
          .subtract(29, 'days')
          .format('DD/MM/YYYY') &&
      end.format('DD/MM/YYYY') === moment().format('DD/MM/YYYY')
    ) {
      setLabel('Últimos 30 dias');
    } else if (
      start.format('DD/MM/YYYY') ===
        moment()
          .startOf('month')
          .format('DD/MM/YYYY') &&
      end.format('DD/MM/YYYY') === moment().format('DD/MM/YYYY')
    ) {
      setLabel('Este mês');
    } else if (
      start.format('DD/MM/YYYY') ===
        moment()
          .subtract(1, 'months')
          .date(1)
          .format('DD/MM/YYYY') &&
      end.format('DD/MM/YYYY') ===
        moment()
          .startOf('month')
          .date(0)
          .format('DD/MM/YYYY')
    ) {
      setLabel('Mês anterior');
    } else if (
      start.format('DD/MM/YYYY') ===
        moment()
          .subtract(179, 'days')
          .format('DD/MM/YYYY') &&
      end.format('DD/MM/YYYY') === moment().format('DD/MM/YYYY')
    ) {
      setLabel('Últimos 6 meses');
    } else if (
      start.format('DD/MM/YYYY') ===
        moment()
          .subtract(365, 'days')
          .format('DD/MM/YYYY') &&
      end.format('DD/MM/YYYY') === moment().format('DD/MM/YYYY')
    ) {
      setLabel('Últimos 12 meses');
    } else if (
      start.format('DD/MM/YYYY') ===
        moment()
          .subtract(moment().diff(moment(new Date(created_at)), 'days') + 1, 'days')
          .format('DD/MM/YYYY') &&
      end.format('DD/MM/YYYY') === moment().format('DD/MM/YYYY')
    ) {
      setLabel('Todo o período');
    } else {
      setLabel(start.format('DD/MM/YYYY') + ' - ' + end.format('DD/MM/YYYY'));
    }
  };
  const handleSearch = () => validateSearch(phrase) && setData(d => ({ ...d, submit: true }));

  let withdrawAmountDay = 0;

  activities.forEach(activity => {
    const date = moment(activity.created_at)
      .add(1, 'days')
      .format('YYYY-MM-DD HH:mm');
    const currentDate = moment().format('YYYY-MM-DD HH:mm');
    const sameDayWithdraw = moment(currentDate).isBefore(date);

    if (sameDayWithdraw && activity.type === 'Saque solicitado') {
      withdrawAmountDay += activity.amount;
    }
  });

  return (
    <Container>
      {loading && <Spinner />}
      <Header>
        <Wrapper className="row">
          <TableTitle className="col-lg-4">{title}</TableTitle>
          <DateRangePicker initialSettings={initialSettings} onCallback={handleDateChange}>
            <div
              id="reportrange"
              style={{
                background: '#fff',
                cursor: 'pointer',
                padding: '4px 15px',
                minWidth: '180px',
                minHeight: '36px',
                lineHeight: '1.8',
                border: '1px solid #ebebeb',
                borderRadius: '3px',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <img
                src="https://icons.alboompro.com/v1/bold/interface-essential/date-calendar/6F6F6F/calendar-3.svg"
                style={{ width: '16px', height: '16px' }}
              />
              <span style={{ marginLeft: '8px' }}>{label}</span>
              <img
                src="https://icons.alboompro.com/v1/bold/arrows-diagrams/arrows/6F6F6F/arrow-button-down.svg"
                style={{ marginLeft: '10px', width: '10px', height: '10px' }}
              />
            </div>
          </DateRangePicker>
          <Select
            className="minified-select col"
            placeholder="Filtrar"
            initialValue={filterBy}
            components={{
              DropdownIndicator: () => (
                <Icon
                  kind="bold"
                  group="interface-essential"
                  category="controls"
                  file="settings-slider-alternate.svg"
                  size="64"
                  color="#898989"
                />
              ),
            }}
            options={filters}
            onChange={handleFilter}
            containerStyle={{ marginLeft: '15px' }}
          />
          <SearchBar className="col">
            <input className='sizeInputSearch'
              type="text"
              value={phrase}
              onKeyDown={e =>
                e.key === 'Enter' || (e.key === 'Backspace' && e.target.value === '' && !loading)
                  ? handleSearch()
                  : null
              }
              onChange={e => setData(d => ({ ...d, phrase: e.target.value }))}
              placeholder="Pesquisar"
            />
            <button>
              <img src="https://icons.alboompro.com/v1/bold/interface-essential/search/bdbdbd/search-alternate.svg" alt="" onClick={handleSearch} />
            </button>
          </SearchBar>
        </Wrapper>
      </Header>

      <ReportTable height={height}>
        <div className="wrap">
          <table>
            <Thead>
              <tr>
                <th className="ActivityDescription">Descrição</th>
                <th></th>
                <th className="ActivityDate">Data</th>
                <th className="ActivityAmount">Valor</th>
              </tr>
            </Thead>
            <Tbody>
              {activities.map((activity, id) => {
                let amount = 0;
                let icon = activity.icon.replace('/000000/', '/44ADF8/');
                icon = activity.icon.replace('/FFFFFF/', '/44ADF8/');
                let iconType = 'notification';
                switch (activity.type) {
                  case 'Alterações em contas de banco':
                    amount = null;
                    icon =
                      'https://icons.alboompro.com/v1/regular/money-payments-finance/finance-savings/44ADF8/saving-bank-1.svg';
                    break;
                  case 'Configurações ajustadas':
                    amount = null;
                    icon =
                      'https://icons.alboompro.com/v1/regular/interface-essential/settings/44ADF8/wench.svg';
                    break;
                  case 'Aviso de pagamento do boleto':
                    amount = null;
                    icon =
                      'https://icons.alboompro.com/v1/regular/interface-essential/alert/44ADF8/alarm-bell.svg';
                    break;
                  case 'Envio de solicitação':
                    amount = currencyFormat(activity.amount / 100);
                    activity.title === 'Link de pagamento criado'
                      ? (icon =
                          'https://icons.alboompro.com/v1/regular/interface-essential/link-unlink/44ADF8/hyperlink-3.svg')
                      : (icon =
                          'https://icons.alboompro.com/v1/regular/money-payments-finance/currencies/44ADF8/currency-dollar-circle.svg');
                    break;
                  case 'Boleto gerado':
                    amount = currencyFormat(activity.amount / 100);
                    icon =
                      'https://icons.alboompro.com/v1/regular/money-payments-finance/accounting-billing/44ADF8/accounting-document.svg';
                    break;
                  case 'Pagamento Recebido':
                    amount = `+ ${currencyFormat(activity.amount / 100)}`;
                    icon =
                      'https://bifrost.alboompro.com/products/alboompay/static/img/dollar_increase.svg';
                    iconType = 'cashin';
                    break;
                  case 'Pagamento solicitado':
                    icon =
                      'https://icons.alboompro.com/v1/regular/money-payments-finance/currencies/44ADF8/currency-dollar-circle.svg';
                    break;
                  case 'Saque solicitado':
                    amount = `- ${currencyFormat(activity.amount / 100)}`;
                    icon =
                      'https://bifrost.alboompro.com/products/alboompay/static/img/dollar_decrease.svg';
                    iconType = 'cashout';
                    if (verify_status !== 'verified') {
                      const date = moment(activity.created_at)
                        .add(1, 'days')
                        .format('YYYY-MM-DD HH:mm');
                      const currentDate = moment().format('YYYY-MM-DD HH:mm');
                      const sameDayWithdraw = moment(currentDate).isBefore(date);
                      if (sameDayWithdraw && !withdrawBlock && withdrawAmountDay > 50000) {
                        dispatch(blockWithdraw());
                        setWithdrawBlock(true);
                      }
                    }
                    break;
                    case 'Recompensa recebida':
                      amount = currencyFormat(activity.amount / 100);
                      iconType = 'reward';
                    break;
                  default:
                    amount = currencyFormat(activity.amount / 100);
                    break;
                }
                return (
                  <tr key={id}>
                    <td>
                      <span className={iconType}>
                        <img src={icon} alt="icon" />
                      </span>
                    </td>
                    <td>
                      <p className="ActivityTitle">{activity.title}</p>
                      <p className="ActivityDescription">{activity.description}</p>
                    </td>
                    <td className="DateRow">
                      <p className="ActivityDate">
                        {moment(activity.created_at).format('DD/MM/YY [às] HH:mm')}
                      </p>
                    </td>
                    <td>{amount && <div className="ActivityAmount">{amount}</div>}</td>
                  </tr>
                );
              })}
            </Tbody>
          </table>
        </div>
      </ReportTable>
    </Container>
  );
}

Activity.propTypes = {
  title: propTypes.string,
};

Activity.defaultProps = {
  title: 'Activity Title',
};
