import styled from "styled-components"

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  
  font-size: 0.8125rem; // 13px
  color: #353535;

  @media screen and (max-width: 767px) {
    font-size: 0.75rem; // 12px
  }
`

export const DataHeader = styled.div`
  padding: 0 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  font-weight: 500;

  .anticipation {
    padding-left: 28px;
  }

  @media screen and (max-width: 767px) {
    padding: 0 12px;

    .anticipation {
      padding-left: 4px;
    }
  }
`

export const DataWrapper = styled.div`
  display: flex;
  flex-direction: column;

  background-color: #ffffff;
  border: 1px solid #EBEBEB;
  border-radius: 3px;
`

export const DataRow = styled.div`
  padding: 12px 16px;
  font-weight: 500;

  display: flex;
  justify-content: space-between;
  align-items: center;

  :not(:last-of-type) {
    border-bottom: 1px solid #EBEBEB;
  }

  .installment {
    min-width: 50px;
    text-align: center;
  }

  @media screen and (max-width: 767px) {
    padding: 12px;
  }
`

export const DateWrapper = styled.div`
  font-weight: 400;

  display: flex;
  align-items: center;
  gap: 8px;

  .original-date {
    color: #727272;
    text-align: right;
  }

  .arrow-return-up {
    display: none;
  }

  span {
    width: 80px;
    text-align: left;
  }

  @media screen and (max-width: 767px) {
    margin-right: 28px;
    position: relative;
    flex-direction: column;
    align-items: flex-end;
    gap: 4px;

    span {
      text-align: right;
    }

    img {
      :first-of-type {
        display: none;
      }
    }

    .arrow-return-up {
      display: block;
      position: absolute;
      top: 50%;
      right: -20px;
      transform: translateY(calc(-50% - 2px));
    }

    .original-date {
      order: 2;
    }
  }
`

export const TotalWrapper = styled.div`
  margin-top: 10px;
  padding-right: 12px;

  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 24px;

  font-weight: 500;
`
