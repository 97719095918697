/* eslint-disable no-undef */
import React, { Component } from 'react';
import moment from 'moment';
import { currencyFormat, percentFormat, estimatedPaidRealease } from '../../../../../utils';
import Icon from '@alboompro/boom-components/general/icon';

import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  CardInfoRow,
  CardInfoLabel,
  CardInfoValue,
  FeeDetailsCard,
  FeeDetailsTitle,
  FeePercent,
  FeeDetails,
  DaysToRelease,
  Tooltip,
  WarningFees,
} from '../styles';
//import { Tooltip } from '../../../../InfoDescription/styles';

class PaymentDetails extends Component {
  state = {
    feeDetails: false,
  };

  render() {
    const { payment, paid_at, fees } = this.props;

    function feeAtPastDate(date) {
      const newFeeDate = new Date('2021-01-01T01:00:46.303Z');
      const newFeeDate2 = new Date('2022-05-20T01:00:46.303Z');

      let fee = '';

      if (date > newFeeDate2.toISOString()) {
        fee = '1,99% a.m.';
      } else {
        if (date > newFeeDate.toISOString()) {
          fee = '1,69% a.m.';
        } else {
          fee = '1,35% a.m.';
        }
      }

      return fee;
    }

    return (
      <Card>
        <CardHeader>
          <CardTitle>Dados do pagamento recebido</CardTitle>
        </CardHeader>
        <CardBody>
          <CardInfoRow>
            <CardInfoLabel>Valor bruto pago:</CardInfoLabel>
            <CardInfoValue>
              {currencyFormat(payment.paylink.amount / 100)} <WarningFees> {payment.paylink.amount > payment.order.amountTotal && payment.order.status === 'paid' ? "pago com acréscimo" : ""} </WarningFees>
            </CardInfoValue>
          </CardInfoRow>

          {payment.paylink.amount > payment.order.amountTotal && payment.order.status === 'paid' && (
            <CardInfoRow>
              <CardInfoLabel>Valor original:</CardInfoLabel>
              <CardInfoValue>{currencyFormat(payment.order.amountTotal / 100)}</CardInfoValue>
            </CardInfoRow>
          )}

          <CardInfoRow>
            <CardInfoLabel>Valor líquido recebido:</CardInfoLabel>
            <CardInfoValue>
              <strong>{currencyFormat((payment.paylink.amount - payment.paymentFees.totalFee) / 100)}</strong>
            </CardInfoValue>
          </CardInfoRow>
          <CardInfoRow>
            <CardInfoLabel> Total das taxas:</CardInfoLabel>
            <CardInfoValue>
              {currencyFormat(payment.paymentFees.totalFee / 100)}
              <FeeDetails
                opened={this.state.feeDetails}
                onClick={() => this.setState({ feeDetails: !this.state.feeDetails })}
              >
                Ver detalhes
                <Icon
                  kind="bold"
                  group="arrows-diagrams"
                  category="arrows"
                  file="arrow-button-down-2.svg"
                  size="8"
                  color="#787878"
                />
              </FeeDetails>
            </CardInfoValue>
          </CardInfoRow>
          {payment.paylink.payment_method === 'credit_card' ? (
            <FeeDetailsCard ref="creditFeeDetails" opened={this.state.feeDetails}>
              <FeeDetailsTitle>Detalhes das taxas</FeeDetailsTitle>
              <CardInfoRow>
                <CardInfoLabel>Crédito:</CardInfoLabel>
                <CardInfoValue>
                  {currencyFormat(payment.paymentFees.credit_fee / 100)}
                  {payment.paymentFees.credit_fee_percent && (
                    <FeePercent>
                      {percentFormat(payment.paymentFees.credit_fee_percent / 100)}
                    </FeePercent>
                  )}
                </CardInfoValue>
              </CardInfoRow>

              <CardInfoRow>
                <CardInfoLabel>Antecipação:</CardInfoLabel>
                <CardInfoValue>
                  {currencyFormat(payment.paymentFees.total_installments_fees / 100)}

                  <FeePercent>{feeAtPastDate(payment.created_at)}</FeePercent>
                </CardInfoValue>
              </CardInfoRow>

              <CardInfoRow>
                <CardInfoLabel>Transação:</CardInfoLabel>
                <CardInfoValue>
                  {currencyFormat(payment.paymentFees.transaction_fee / 100)}
                </CardInfoValue>
              </CardInfoRow>

              {payment.order.origin == 'proof' ? (
                <div>
                  <FeeDetailsTitle>Taxas de e-commerce</FeeDetailsTitle>
                  <CardInfoRow>
                    <CardInfoLabel>Alboom Proof:</CardInfoLabel>
                    <CardInfoValue>
                      {payment.paymentFees.provider_fee
                        ? currencyFormat(payment.paymentFees.provider_fee / 100)
                        : 'R$ 0,00'}
                      <FeePercent>
                        {payment.paymentFees.provider_fee &&
                          payment.paymentFees.provider_fee_percent}
                        %
                      </FeePercent>
                    </CardInfoValue>
                  </CardInfoRow>
                </div>
              ) : (
                ''
              )}
            </FeeDetailsCard>
          ) : payment.paylink.payment_method === 'boleto' ? (
            <FeeDetailsCard ref="creditFeeDetails" opened={this.state.feeDetails}>
              <FeeDetailsTitle>Detalhes das taxas</FeeDetailsTitle>
              <CardInfoRow>
                <CardInfoLabel>Boleto pago:</CardInfoLabel>
                <CardInfoValue>{currencyFormat(payment.paymentFees.totalFee / 100)}</CardInfoValue>
              </CardInfoRow>
              {payment.paymentFees.provider_fee && payment.order.origin == 'proof' ? (
                <div>
                  <FeeDetailsTitle>Taxas de e-commerce</FeeDetailsTitle>
                  <CardInfoRow>
                    <CardInfoLabel>Alboom Proof:</CardInfoLabel>
                    <CardInfoValue>
                      {currencyFormat(payment.paymentFees.provider_fee / 100)}
                      <FeePercent>{payment.paymentFees.provider_fee_percent}%</FeePercent>
                    </CardInfoValue>
                  </CardInfoRow>
                </div>
              ) : (
                ''
              )}
            </FeeDetailsCard>
          ) : (
            <FeeDetailsCard ref="creditFeeDetails" opened={this.state.feeDetails}>
              <FeeDetailsTitle>Detalhes das taxas</FeeDetailsTitle>
              <CardInfoRow>
                <CardInfoLabel>Taxa do Pix:</CardInfoLabel>
                <CardInfoValue>
                  {currencyFormat(payment.paymentFees.pix_fee / 100)}
                  <FeePercent>
                    {payment.paymentFees.pix_fee_percent && payment.paymentFees.pix_fee_percent}%
                  </FeePercent>
                </CardInfoValue>
              </CardInfoRow>
              {payment.paymentFees.provider_fee && payment.order.origin == 'proof' ? (
                <div>
                  <FeeDetailsTitle>Taxas de e-commerce</FeeDetailsTitle>
                  <CardInfoRow>
                    <CardInfoLabel>Alboom Proof:</CardInfoLabel>
                    <CardInfoValue>
                      {currencyFormat(payment.paymentFees.provider_fee / 100)}
                      <FeePercent>{payment.paymentFees.provider_fee_percent}%</FeePercent>
                    </CardInfoValue>
                  </CardInfoRow>
                </div>
              ) : (
                ''
              )}
            </FeeDetailsCard>
          )}
          <CardInfoRow>
            <CardInfoLabel>Método:</CardInfoLabel>
            <CardInfoValue>
              {payment.paylink.payment_method === 'credit_card'
                ? 'Cartão de crédito'
                : payment.paylink.payment_method === 'boleto'
                ? 'Boleto'
                : 'Pix'}
            </CardInfoValue>
          </CardInfoRow>
          {payment.paylink.payment_method === 'credit_card' && (
            <CardInfoRow>
              <CardInfoLabel>Parcelamento:</CardInfoLabel>
              <CardInfoValue>{payment.paylink.installments.installments}x</CardInfoValue>
            </CardInfoRow>
          )}
          <CardInfoRow>
            <CardInfoLabel>Data do pagamento:</CardInfoLabel>
            <CardInfoValue>
              {paid_at ? moment(paid_at).format('DD/MM/YYYY [às] HH:mm') : <span>&#8213;</span>}
            </CardInfoValue>
          </CardInfoRow>
          {fees.antecipation_automatic &&
            estimatedPaidRealease(paid_at, payment.paylink.payment_method, fees) && (
              <CardInfoRow>
                <CardInfoLabel>
                  <Tooltip
                    tooltip="Data estimada que o valor líquido ficará disponível para saque."
                    width="270px"
                  ></Tooltip>
                  {/* <Tooltip>
                  <Icon
                    kind="bold"
                    group="interface-essential"
                    category="alerts"
                    file="information-circle.svg"
                    size="14"
                    color="#B2B2B2"
                  />
                </Tooltip> */}
                  Data estimada de liberação:
                </CardInfoLabel>
                <CardInfoValue>
                  ~ {estimatedPaidRealease(paid_at, payment.paylink.payment_method, fees, 'date')}
                  <DaysToRelease>
                    em {estimatedPaidRealease(paid_at, payment.paylink.payment_method, fees)} dias
                  </DaysToRelease>
                </CardInfoValue>
              </CardInfoRow>
            )}
        </CardBody>
      </Card>
    );
  }
}

PaymentDetails.propTypes = {};

export default PaymentDetails;
