import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;

  .button {
    // font-size: 2rem;
    border-radius: 3px;
    border: none;
  }

  .buttonBlue {
    color: #fff;
    width: 100%;
    padding: 1em 0;
    margin-bottom: 0;
  }

  .buttonGray {
    background: #eee;
    width: 100%;
    padding: 1em 0;
    div {
      color: #373737;
    }
  }

  .mb {
    @media (max-width: 768px) {
      margin-bottom: 16px;
    }
  }

  form p {
    line-height: 1;
    margin: 0;
    padding: 0;
    text-transform: none;
  }

  .checkbox span.label svg {
    margin-left: 0.3rem;
    margin-bottom: 0;
    width: 15px;
  }

  label.container.checkbox {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 5px 0 15px;
  }

  .checkbox .checkmark:after {
    margin-left: 4px;
  }
`;

export const Info = styled.p`
  font-size: 18px;
  color: #474747;
  margin-top: 20px !important;
  text-align: center;
`;
