import { Label, Title, Container } from './styles';
import React from 'react';

export default function Input({title,label})
    {
        return(
            <Container>
                <Title>{title}</Title>
                <Label>{label}</Label>
            </Container>);
        };