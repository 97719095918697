import styled from 'styled-components';
import media from '../../styles/media';

export const Title = styled.div`
  color: #2e2e2e;
  font-size: 26px;
  line-height: 26px;
  font-weight: 500;
  margin-bottom: 30px;
`;

export const Content = styled.div`
  h1 {
    font-size: 30px;
    margin: 30px 0;
    text-align: center;
  }
  select {
    margin-bottom: 80px;
    border: none;
  }
`;
export const OptionContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  // border-top: 1px solid #eee;
  // padding: 2em;
  @media ${media.small} {
    display: block;
  }
`;

export const Option = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 5px;
  padding: 15px 0;

  border: ${props => (props.checked ? '1px solid #50AFF4' : '1px solid #E8E8E8')};

  background-color: ${props => (props.checked ? '#F7F7F7' : '#FFFFFF')};

  &:hover {
    background-color: #f8f8f8;
  }

  @media screen and (max-width: 767px) {
    margin-bottom: 10px;
    padding: 0;
  }

  &:first-child {
    margin-right: 25px;
  }

  [type='radio'] {
    margin-top: 5px;
    margin-right: 10px;
  }

  label {
    display: flex;
    padding: 12px 12px 0 12px;
  }

  .label {
    text-align: left;

    > h3 {
      font-size: 18px;
      line-height: 18px;
      font-weight: 500;
      margin-bottom: 0;

      @media screen and (max-width: 767px) {
        font-size: 14px;
      }
    }

    span {
      font-size: 13px;
      line-height: 13px;
      font-weight: 400;
      display: inline-block;
    }
  }
`;

export const Receiver = styled.div`
  text-align: left;

  margin-bottom: 30px;

  button.icons {
    border: none;
    margin-left: 7px;
  }

  p {
    &.description {
      font-size: 15px;
      margin-top: 1em;
      font-weight: 500;
      text-align: left;
      margin-bottom: 10px;
      color: #616161;
    }

    &.name {
      // font-size: 1.75em;
      text-transform: uppercase;
      text-align: left;
      display: flex;
      line-height: 1;
      span {
        overflow: hidden;
      }
    }
    &.value {
      // font-size: 1.75em;
      text-transform: uppercase;
      text-align: right;

      @media ${media.small} {
        text-align: left;
      }
    }
    &.info {
      text-transform: none;
      // font-size: 1.5em;
      line-height: 1.2em;
      text-align: left;

      span {
        display: block;
      }
    }
  }
`;

export const AddButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  background: #f2f9ff;
  border: 1px dashed #50aff4;
  border-radius: 3px;
  cursor: pointer;
  text-align: left;
  color: #44adf8;
  font-size: 15px;
  padding: 10px 0;
  width: 100%;
  outline: 0;

  &:hover {
    background-color: #e5f3ff;
  }

  &:focus {
    outline: 0;
  }

  > img {
    width: 16px;
    height: 16px;
    margin-right: 10px;
  }
`;
