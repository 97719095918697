import produce from 'immer';
import moment from 'moment';

const INITIAL_STATE = {
  balanceAvailable: null,
  balanceAwaiting: null,
  balanceTotal: null,
  received: {
    all_time: null,
    this_month: null,
    last_month: null,
    last_year: null,
  },
  pending: {
    total: null,
    count: null,
  },
  transactions: {
    all_time: null,
    this_month: null,
    last_month: null,
    last_month_count: null,
  },
  loading: false,
  loaded: false,
  payables: {
    loading: false,
    date: moment(new Date()).format('YYYY-MM-01'),
    data: [],
    daily: [],
  },
};

export default function balanceControl(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case '@balance/BALANCE_REQUEST': {
        draft.loading = true;
        break;
      }
      case '@balance/BALANCE_DETAILS_REQUEST': {
        draft.loadingDetails = true;
        break;
      }
      case '@balance/BALANCE_SUCCESS': {
        const { waiting_funds, available } = action.payload;

        draft.loading = false;
        draft.loaded = true;
        draft.balanceAvailable = available.amount / 100;
        draft.balanceAwaiting = waiting_funds.amount / 100;
        draft.balanceTotal = (available.amount + waiting_funds.amount) / 100;
        break;
      }
      case '@balance/BALANCE_DETAILS_SUCCESS': {
        const { received, transactions, pending } = action.payload;

        draft.loadingDetails = false;
        draft.received.all_time = received.all_time / 100;
        draft.received.this_month = received.this_month / 100;
        draft.received.last_month = received.last_month / 100;
        draft.received.last_year = received.last_year / 100;

        draft.pending.total = pending.total / 100;
        draft.pending.count = pending.count;

        draft.transactions = transactions;
        break;
      }
      case '@balance/BALANCE_REQUEST_FAIL': {
        draft.loading = false;
        break;
      }
      case '@balance/PAYABLES_REQUEST': {
        draft.payables.date = action.date;
        draft.payables.loading = true;
        break;
      }
      case '@balance/PAYABLES_DETAILS_REQUEST': {
        draft.payables.loading = true;
        break;
      }
      case '@balance/PAYABLES_SUCCESS': {
        draft.payables.data = action.payload.data.data.payables;
        draft.payables.loading = false;
        break;
      }
      case '@balance/PAYABLES_DETAILS_SUCCESS': {
        draft.payables.daily = action.payload.data.data.payables;
        draft.payables.loading = false;
        break;
      }
      case '@balance/PAYABLES_REQUEST_FAIL':
      case '@balance/PAYABLES_REQUEST_DETAILS_FAIL':
        draft.payables.daily = [];
        draft.payables.loading = false;
        break;
      default:
        break;
    }
  });
}
