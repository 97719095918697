/* eslint-disable no-undef */
import React, { Component } from 'react';
import { currencyFormat } from '../../../../../utils';

import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  OrderTable,
  OrderHeader,
  OrderBody,
  OrderItem,
  OrderFooter,
  OrderText,
} from '../styles';

class OrderDetails extends Component {
  render() {
    const { order } = this.props;
    return (
      <Card>
        <CardHeader>
          <CardTitle align="left">Detalhes do pedido</CardTitle>
        </CardHeader>
        <CardBody>
          <OrderTable>
            <OrderHeader>
              <span>Descrição da cobrança</span>
              {order.type === 'advanced' && <span>Qtd.</span>}
              <span>Valor</span>
            </OrderHeader>
            <OrderBody>
              {order.order_items &&
                order.order_items.map((orderItem, index) => (
                  <OrderItem key={`${orderItem.item}-${index}`}>
                    <span>{orderItem.item}</span>
                    {order.type === 'advanced' && <span>{orderItem.quantity}</span>}
                    <span>{currencyFormat(orderItem.amount / 100)}</span>
                  </OrderItem>
                ))}
            </OrderBody>
            <OrderFooter>
              <div>
                {(order.discount > 0 || order.shipping_amount > 0) && (
                  <OrderText>
                    <p>Sub-total:</p>
                    <p>{currencyFormat(order.amount / 100)}</p>
                  </OrderText>
                )}
                {order.discount > 0 && (
                  <OrderText>
                    <p>Descontos:</p>
                    <p>- {currencyFormat(order.discount / 100)}</p>
                  </OrderText>
                )}
                {order.shipping_amount > 0 && (
                  <OrderText>
                    <p>Frete:</p>
                    <p>{currencyFormat(order.shipping_amount / 100)}</p>
                  </OrderText>
                )}
                <OrderText>
                  <p>Total:</p>
                  <p>{currencyFormat(order.amountTotal / 100)}</p>
                </OrderText>
              </div>
            </OrderFooter>
          </OrderTable>
        </CardBody>
      </Card>
    );
  }
}

OrderDetails.propTypes = {};

export default OrderDetails;
