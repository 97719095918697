export function modalOpen(payload) {
  return {
    type: "@modal/OPEN",
    payload
  };
}

export function modalClose() {
  return {
    type: "@modal/CLOSE",
  };
}

