import axios from '../../axios';
import { toast } from 'react-toastify';

export default {
  get: async ({ search = null }) => {
    return await axios.get('/payment-button', {
      params: {
        phrase: search,
      },
      headers: {
        Authorization: window.USER_TOKEN,
      },
    });
  },
  delete: async id => {
    try {
      await axios
        .delete(`/payment-button/${id}`, {
          headers: {
            Authorization: window.USER_TOKEN,
          },
        })
        .then(response => response.status === 204 && response);

      return { id };
    } catch (e) {
      toast.error('Não foi possivel concluir essa solicitação');
    }
  },
  duplicate: async ({ id, nameClone: name }) => {
    try {
      return await axios
        .post(`/payment-links/${id}/duplicate`, { name })
        .then(response => response.status === 201 && response);
    } catch (e) {
      toast.error('Não foi possivel concluir essa solicitação');
    }
  }
};