import produce from "immer";

const INITIAL_STATE = {
  data: [],
  types: {},
  withdraw: true,
  loading: false
};

export default function activities(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case "@activity/REQUEST": {
        draft.loading = true;
        break;
      }
      case "@activity/BLOCK_WITHDRAW": {
        draft.withdraw = false;
        break;
      }
      case "@activity/SUCCESS": {
        draft.data = action.payload.data.data.activities;
        draft.loading = false;
        break;
      }
      case "@activity/TYPES_REQUEST": {
        draft.loading = true;
        break;
      }
      case "@activity/TYPES_SUCCESS": {
        draft.types = action.payload.data.data.activities_type;
        draft.loading = false;
        break;
      }
      case "@activity/TYPES_FAILURE": {
        draft.loading = false;
        break;
      }
      case "@activity/FAILURE": {
        draft.loading = false;
        break;
      }
      default:
        break;
    }
  });
}
