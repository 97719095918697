import { takeLatest, put, call, all } from 'redux-saga/effects';
import {
  AlproSuccess,
  AlpayLogged,
  AlpayFailure,
  AlpayNew,
  AlpayNewDisabled,
  AlproFailure,
} from './actions';
import { authPayments } from '../accounts/actions';
import AuthAlPay from '../../../services/core/http/accounts';
import moment from 'moment';

export function* Alpro() {
  const { REACT_APP_LOGIN_URL: alproUrl, REACT_APP_APP_CODE: appCode } = process.env;
  const ref = window.location.href;
  const host = window.location.host;
  const path = window.location.pathname || '/';
  const url = new URL(ref);
  try {
    const alprotoken = url.searchParams.get('token');
    if (!alprotoken)
      window.location.href = `${alproUrl}/login?srv=${appCode}&host=${window.location.protocol}//${host}&redir=${path}`;
      window.USER_TOKEN = alprotoken;
    yield put(AlproSuccess(alprotoken));
  } catch (error) {
    //toast.error('Erro ao se conectar a aplicação, por favor recarregue a página ou tente novamente mais tarde.')
    yield put(AlproFailure());
  }
}

export function* Alpay({ payload: { data: alprotoken } }) {
  try {
    const { data } = yield call(AuthAlPay.signIn, alprotoken);
    data.expiresIn = data.expiresIn && moment().add(data.expiresIn, 'seconds');

    yield put(AlpayLogged(data));
    yield put(authPayments(data));
  } catch (error) {
    if (error.response && error.response.status === 422) {
      const {
        data: {
          data: { userId },
        },
      } = error.response;

      if (userId > 100000) {
        return yield put(AlpayNewDisabled());
      } else {
        return yield put(AlpayNew());
      }
    }

    yield put(AlpayFailure());
  }
}

export default all([
  takeLatest('@auth/ALPRO_REQUEST', Alpro),
  takeLatest('@auth/ALPRO_SUCCESS', Alpay),
]);
