import styled from 'styled-components';

export const GeneralContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  background-color: #F6FAFE;
  word-break: break-word;
`;

export const Descriptions = styled.p`
  font-weight: 400;
  font-size: 14px;
  color: #3E3E3E;
  background-color: #F6FAFE;
  margin-bottom: 0px;
  line-height: 180%;
`;

export const ScriptContainer = styled.div`
  position: relative;
  background: #FFFFFF;
  width: 100%;
  height: 120px;
  border-radius: 3px;
  border: 1px dashed #9fcef0;
  padding: 20px 20px;

  @media screen and (max-width: 770px) {
    width: 100%;
    height: 160px;
  }
`

export const StyledCode = styled.code` 
  font-size: 14px;
  color: #3E3E3E;
  word-break: break-all;
  font-family: 'Courier';
  line-height: 1.3;
 `

export const TitleDescription = styled.p`
  font-weight: 500;
  font-size: 16px;
  color: #3E3E3E;
  display: block;
  align-self: flex-start;
  margin: 30px 0 15px 0;
`;

export const DescriptionsHeader = styled.p` 
  margin-top: 21px;
  font-weight: 400;
  font-size: 14px;
  color: #3E3E3E;

  @media screen and (max-width: 767px) {
    line-height: 21px;
    margin-top: 8px;
  }
`;

export const IconSuggestion = styled.img`
  width: 12px;
  height: 12px;
  margin-right: 6px;
`;

export const LinkHelpCenter = styled.a`
  color: #50AFF4;

  &:hover {
    text-decoration: underline
  }
`;

export const FooterDescription = styled.p`
  font-size: 14px;
  margin-top: 40px;
  line-height: 21px;
`;

export const ButtonCopy = styled.button`
  color: #50AFF4;
  background: #E2F3FE;
  border-radius: 2px 0px;
  align-items: center;
  width: 120px;
  border: none;
  height: 37px;
  position: absolute;
  bottom: 0;
  right: 0;
`;

export const CopyButtonContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
`;
