import axios from '../../axios';
import { toast } from 'react-toastify';
export default {
  get: async ({ search = null, filterBy: status, startDate = null, endDate = null }) => {
    return await axios.get('/payments', {
      params: {
        status,
        startDate,
        endDate,
        phrase: search,
      },
    });
  },
  create: async ({
    total: simpleamount,
    discounts = [],
    message,
    customers,
    freight,
    type,
    description = 'Cobrança Alboom Pay',
    installments,
    payments_settings,
    shipping,
    itens,
    ...rest
  }) => {
    try {
      if (!customers.length) {
        throw Error('Você deve inserir pelo menos um cliente.');
      }

      var data = {
        payers: customers,
        type: 'simple',
        description,
        origin: 'alboom-pay',
        discount: 0,
        shipping_address_required: shipping,
        shipping_enabled: Boolean(shipping),
        shipping_amount: freight || 0,
        installments: {
          max_installments: installments,
        },
        payments_settings,
        message,
        discounts,
      };

      switch (type) {
        case 'advanced':
          if (!itens.length) throw Error('Você deve inserir pelo menos um item.');
          data.order_items = itens;
          data.type = type;
          break;
        default:
          if (!simpleamount) throw Error('Erro ao capturar valor, por favor tente novamente.');
          data.order_items = [
            {
              item: description,
              quantity: 1,
              amount: simpleamount,
            },
          ];
          break;
      }

      return await axios
        .post('/orders', data)
        .then(response => response.status === 201 && response.data.data.orders);
    } catch (e) {
      const message =
        e.message ||
        'Não foi possível criar uma solicitação de pagamento, tente novamente mais tarde';
      toast.error(message);
      return Promise.reject(e);
    }
  },
  details: async orderId => {
    return await axios.get(`/payment/${orderId}`);
  },
  desactivateLink: async orderID => {
    try {
      if (!orderID) {
        throw Error(
          'Erro ao capturar identificação da solicitação de pagamento, tente novamente mais tarde.',
        );
      }
      await axios.put(`/inactive-order/${orderID}`);
      return toast.success('Solicitação desativada com sucesso');
    } catch (e) {
      const message =
        e.message || 'Erro ao desativar solicitação';
      toast.error(message);
      return Promise.reject(e);
    }
  },
};
