import React from "react";
import PropTypes from "prop-types";
import { Container } from "./styles";

export default function Col({ width, mr, children, ...rest }) {
  return (
    <Container width={width} mr={mr} {...rest}>
      {children && <span>{children}</span>}
    </Container>
  );
}

Col.propTypes = {
  width: PropTypes.string,
  mr: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object
  ])
};

Col.defaultProps = {
  width: "100%",
  mr: "0",
  children: ""
};
