import React, { PropTypes, Component } from 'react';
import ClipboardButton from 'react-clipboard.js';

import Modal from '@alboompro/boom-components/data-display/modal';
import Icon from '@alboompro/boom-components/general/icon';

import {
  ModalContent,
  TitleShare,
  LinkShare,
  WrapperCopy,
  DescripitionShare,
  SocialLink,
  SocialContainer,
  QrCodeImage,
  CopyLink,
  QrCodeInformation,
  QrCodeTitle,
  QrCodeContainer,
  QrCodeDownload,
  QrCodeSugestion,
} from './styles';

class Social extends Component {
  state = {
    copy: false,
  };

  icons = url => [
    {
      urlShare: `https://www.facebook.com/sharer/sharer.php?u=${url}`,
      urlIcon: 'https://storage.alboom.ninja/static/icon/social_media/facebook.svg',
    },
    {
      urlShare: `http://twitter.com?status=${url}`,
      urlIcon: 'https://storage.alboom.ninja/static/icon/social_media/twitter.svg',
    },
    {
      urlShare: `https://www.linkedin.com/shareArticle?mini=true&url=${url}&title=title&summary=summary`,
      urlIcon: 'https://storage.alboom.ninja/static/icon/social_media/linkedin.svg',
    },
    {
      urlShare: `https://api.whatsapp.com/send?text=${url}`,
      urlIcon: 'https://storage.alboom.ninja/static/icon/social_media/whatsapp.svg',
    },
    {
      urlShare: `http://www.facebook.com/dialog/send?app_id=882466608465785&link=${url}&redirect_uri=${url}`,
      urlIcon: 'https://storage.alboom.ninja/static/icon/social_media/messenger.svg',
    },
  ];

  handleCopyURL = () => {
    this.setState({ copy: true });

    setTimeout(() => {
      this.setState({ copy: false });
    }, 3000);
  };

  download = () => {
    const { slug } = this.props;
    fetch(`https://api.qrserver.com/v1/create-qr-code/?data=${slug}&size=500x500`, {
      method: "GET",
      headers: {}
    })
      .then(response => {
          response.arrayBuffer().then(function(buffer) {
          const url = window.URL.createObjectURL(new Blob([buffer]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "qr-code.png");
          document.body.appendChild(link);
          link.click();
        });
      })
      .catch(err => {
        console.log(err);
      });
  };

  render() {
    const { title, description, visible, slug, onClose } = this.props;
    const url = slug;
    
    return (
      <Modal
        title={title}
        visible={visible}
        onClose={() => onClose && onClose()}
        bodyStyle={{ backgroundColor: '#f6fafe' }}
        backdropStyle={{ backgroundColor: 'rgba(0, 0, 0, 0.7)' }}
        backdropClosable={false}
      >
        <ModalContent style={{ padding: '0 30px' }}>
          <TitleShare>{description}</TitleShare>
          <LinkShare>
            <span title={url}>{url}</span>
            <WrapperCopy copied={this.state.copy}>
              <Icon
                kind="bold"
                group="interface-essential"
                category="form-validation"
                file="check-circle-1.svg"
                size="16"
                color="#45AE70"
              />
              <ClipboardButton data-clipboard-text={url}>
                <CopyLink onClick={this.handleCopyURL}>
                  {this.state.copy ? 'Copiado' : 'Copiar'}
                </CopyLink>
              </ClipboardButton>
            </WrapperCopy>
          </LinkShare>

          <QrCodeContainer>
            <QrCodeImage src={`https://api.qrserver.com/v1/create-qr-code/?data=${slug}&size=132x132`} alt="QR Code do link de pagamento"/>
            <QrCodeInformation>
              <QrCodeTitle>QR Code</QrCodeTitle>
              <QrCodeDownload onClick={this.download}>
                  <img src = {`https://icons.alboompro.com/v1/bold/internet-networks-servers/upload-download/50AFF4/download-bottom.svg`}/>
                  Baixar QR code
              </QrCodeDownload>
              {/*
              Aguardando link para lançar
              
              <QrCodeSugestion href="#">
                <img src = {`https://icons.alboompro.com/v1/bold/work-office-companies/ideas-creativity/878787/bulb-1.svg`}/>
                Ideias de uso
              </QrCodeSugestion> */}
            </QrCodeInformation>
          </QrCodeContainer>

          <DescripitionShare> Ou compartilhe direto na redes sociais: </DescripitionShare>

          <SocialContainer>
            {this.icons(url).map((icon, index) => (
              <SocialLink key={index} href={`${icon.urlShare}`} target="_blank">
                <img src={icon.urlIcon} />
              </SocialLink>
            ))}
          </SocialContainer>
        </ModalContent>
      </Modal>
    );
  }
}

export default Social;
