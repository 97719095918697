import styled from "styled-components";
import media from "../../styles/media";

export const Container = styled.div`
  margin-bottom: 20px;

  @media ${media.small} {

  }
`;
