import produce from 'immer';

const INITIAL_STATE = {
  alprotoken: null,
  alpaytoken: null,
  alpaytokenexpires: null,
  alpaynewcustomer: false,
  alpaynewcustomerdisabled: false,
  created_at: null,
};

export default function core(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case '@auth/ALPRO_SUCCESS': {
        draft.alprotoken = action.payload.data;
        break;
      }
      case '@auth/ALPRO_FAILURE': {
        break;
      }
      case '@auth/ALPAY_NEW_CUSTOMER': {
        draft.alpaynewcustomer = true;
        break;
      }
      case '@auth/ALPAY_NEW_CUSTOMER_DISABLED': {
        draft.alpaynewcustomer = true;
        draft.alpaynewcustomerdisabled = true;
        break;
      }
      case '@auth/ALPAY_LOGGED': {
        draft.created_at = action.payload.data.created_at;
        draft.alpaynewcustomer = false;
        draft.alpaytoken = action.payload.data.token;
        draft.alpaytokenexpires = action.payload.data.expiresIn;
        break;
      }
      default:
    }
  });
}
