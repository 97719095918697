import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { useSelector } from 'react-redux';
import Activity from '../../components/Activity';
import PageHeader from '../../components/PageHeader';
import BalanceControl from '../../components/BalanceControl';
import Button from '../../components/Button';
import history from '../../services/history';
import Notification from '../../components/Notification';
import BlackFriday2023 from '../../components/Stripes/BFBR23';
import CyberMondayBRA2023 from '../../components/Stripes/CWBR23';
import { ButtonsContainer, UserGreeting } from './styles';
import Icon from '@alboompro/boom-components/general/icon';
import ButtonTooltip from '../../components/Button/ButtonTooltip';

export default function Dashboard() {
  const [hideAmount, setHideAmount] = useState(false);
  const { user_data } = useSelector(state => state.accounts);

  useEffect(() => {
    const globalStoragedSettings = JSON.parse(localStorage.getItem('__alboompay'));

    setHideAmount((globalStoragedSettings && globalStoragedSettings.hideAmount) || false);
  }, []);

  function handleHideAmount() {
    localStorage.setItem(
      '__alboompay',
      JSON.stringify({
        hideAmount: !hideAmount,
      }),
    );

    setHideAmount(!hideAmount);
  }

  const now = moment();
  return (
    <>
      <PageHeader
        title="Saldo e atividades"
        actions={
          <ButtonsContainer>
            <ButtonTooltip
              style={{ marginRight: '15px' }}
              onClick={handleHideAmount}
              tooltip={hideAmount ?  'Mostrar saldo' : 'Ocultar saldo'}
            >
              <Icon 
                kind="bold" 
                group="interface-essential" 
                category="view" 
                file={hideAmount ? "view-1.svg" : "view-off.svg"} 
                size="18" 
                color="#666666" 
              />
            </ButtonTooltip>

            <Button
              onClick={() => history.push('/payment-request')}
              style={{ backgroundColor: '#1B9DFA', borderRadius: 3, padding: '15px 30px' }}
              type="primary"
            >
              Solicitar pagamento
            </Button>
          </ButtonsContainer>
        }
      />
      <BalanceControl hideAmount={hideAmount} />
      <Activity title="Atividades" height="calc(100vh - 360px);" />
    </>
  );
}
