export function authPayments(payload) {
  return {
    type: '@accounts/AFTER_SIGNIN',
    payload,
  };
}

export function putAccountsRequest(id, payload) {
  return {
    type: '@accounts/PUT_REQUEST',
    id,
    payload,
  };
}

export function putAccountsRequestFailure() {
  return {
    type: '@accounts/PUT_FAILURE',
  };
}

export function putAccountsRequestSuccess(payload) {
  return {
    type: '@accounts/PUT_SUCCESS',
    payload,
  };
}

export function getAccountsRequest(id) {
  return {
    type: '@accounts/GET_REQUEST',
    id
  };
}

export function getAccountsRequestSuccess(payload) {
  return {
    type: '@accounts/GET_SUCCESS',
    payload
  };
}

export function getAccountsRequestFailure() {
  return {
    type: '@accounts/GET_FAILURE',
  };
}

export function generateSMSCode(payload){
  return {
    type: '@accounts/PUT_GENERATE_SMS',
    payload
  }
}

export function generateSMSCodeSuccess(payload){
  return {
    type: '@accounts/PUT_GENERATE_SMS_SUCCESS',
    payload
  }
}

export function generateSMSCodeFailure() {
  return {
    type: '@accounts/PUT_GENERATE_SMS_FAILURE',
  };
}

export function insertPhoneAndBankRequest(payload, bankId) {
  return {
    type: '@accounts/INSERT_PHONE_BANK_REQUEST',
    payload,
    bankId,
  };
}

export function insertPhoneAndBankRequestSuccess(payload) {
  return {
    type: '@accounts/INSERT_PHONE_BANK_SUCCESS',
    payload,
  };
}

export function insertPhoneAndBankRequestFailure() {
  return {
    type: '@accounts/INSERT_PHONE_BANK_FAILURE',
  };
}