import axios from "../../axios";
// import { toast } from 'react-toastify';
export default {
  get: async ({ filterBy:type_id = null, phrase = null, startDate = null, endDate = null }) => {
    return await axios.get("/activities", {
      params: {
        type_id,
        phrase,
        startDate,
        endDate
      }
    });
  },
  getTypes: async () => {
    return await axios.get("/activities-types");
  }
};
