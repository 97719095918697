import styled from 'styled-components';

export const Container = styled.div`
  max-width: 1080px;
  margin: 40px auto;

  @media screen and (max-width: 1600px) {
    margin: 40px auto;
  }

  @media screen and (max-width: 767px) {
    margin: 20px;
  }
`;

export const Content = styled.div`
  margin-top: 1.5em;
  background: #fff;
  border: 1px solid #ebebeb;
  border-radius: 10px;
  overflow: hidden;
`;

export const Wrapper = styled.div`
  justify-content: space-between;
  align-items: center;
`;

export const Footer = styled.div`
  padding: 1em;
  > * {
    flex-basis: 1;
    flex-grow: 0;
  }
  span {
    line-height: 2.5;
  }
`;

export const StyledBox = styled.div`
  font-size: Poppins-Medium;
  background-color: ${props => props.background || '#fff'};
  min-height: 160px;
  color: white;
  text-transform: uppercase;
  position: relative;
  z-index: 0;
  text-align: center;
  h4 {
    padding: 35px 0 0px;
    font-size: 14px;
    font-weight: 400;
  }
  h3 {
    ::before {
      content: 'R$';
      font-size: 14px;
      position: absolute;
      margin-left: -22px;
      margin-top: 8px;
    }
    font-size: 44.5px;
    font-weight: 400;
  }
  div {
    z-index: 1;
    position: relative;
  }
  img {
    position: absolute;
    height: 100px;
    top: 30px;
    left: 20px;
  }
  .btn {
    min-width: 180px;
    font-size: 14px;
    margin: 5px;
  }
`;
