import axios from "../../axios";

export default {
  list: async alprotoken => {
    const banks = await axios.get('/banks', { headers : { Authorization: alprotoken } })
      .then( banks => banks.data );
    return banks;
  },
  get: async () => await axios.get('/banks-list')
};
