import styled from 'styled-components';

export const Tooltip = styled.span`
  display: none;
  opacity: 0;
  visibility: hidden;
  z-index: 8;

  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  text-align: left;
  line-height: 1.3;

  position: absolute !important;
  background: #ffffff;
  color: #343434;

  width: ${props => (props.width ? props.width : 'auto')};
  height: ${props => (props.height ? props.height : 'auto')};

  opacity: 0;
  left: 105%;
  bottom: -120%;
  padding: 14px 12px;

  border: 1px solid #e7e7e7;
  border-radius: 5px;
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.1);

  transition: 0.2s all cubic-bezier(0.55, 0.3, 0.5, 0.9);

  /* depreciated
    @media (max-width: 576px) {
    width: ${props => (props.media_width ? props.media_width : '250px')};
    height: ${props => (props.media_height ? props.media_height : 'auto')};
    }
  */
`;

export const InfoStyle = styled.div`
  display: flex;
  align-items: center;
  position: relative;

  div {
    &:hover ${Tooltip} {
      display: block;
      opacity: 1;
      visibility: visible;
    }

    // Do not implement Tooltip in Mobile
    @media (max-width: 576px) {
      display: none;
    }
  }
`;
