import styled from 'styled-components';

export const PaymentWrapper = styled.div`
  padding-bottom: 40px;

  &:first-child {
    margin-bottom: 40px;
  }

  .tabTitle {
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: 15px;
    letter-spacing: 0em;
    text-align: left;
    color: #343434;
    text-transform: uppercase;
    margin-bottom: 20px;
  }

  .tabDescription {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: left;
    color: #4f4f4f;

    & + p {
      margin-top: -8px;
      margin-bottom: 60px;
    }
  }
`;

export const CCTitle = styled.h1`
  margin-top: 42px;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 15px;
  letter-spacing: 0em;
  text-align: left;
  color: #343434;
  margin-bottom: 20px;
`;
