import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import InfoDescription from '../../../components/InfoDescription';
import Select from '../../../components/Select';
import RadioInput from '../../../components/RadioInput';
import Button from '../../../components/Button';
import Spinner from '../../../components/Spinner';
import Checkbox from '../../../components/Checkbox';
import { ContainerInner } from '../../../styles/global';
import { putAccountsRequest } from '../../../store/modules/accounts/actions';
import {
  ButtonWrapper,
  PaymentWrapper,
  PaymentRadio,
  PaymentOption,
  OptionDescription,
  OptionList,
} from '../styles';

const PaymentMethods = () => {
  const [data, setData] = useState({
    invoiceEnable: '',
    invoiceExpirationDays: '',
    ccEnable: '',
    ccNoFeeInstallments: '',
    ccFee: '',
    ccFeeFormatted: '',
    pixEnable: '',
  });
  const {
    invoiceEnable,
    invoiceExpirationDays,
    ccEnable,
    ccNoFeeInstallments,
    ccFee,
    pixEnable,
  } = data;

  const { payments_settings, account_id, loading } = useSelector(state => state.accounts);
  const [fees, setFees] = useState([]);
  const [optionsFee, setOptionsFee] = useState({});
  const [feeId, setFeeId] = useState('');
  const [loadFee, setLoadFee] = useState(false);
  const [defaultPayment, setDefaultPayment] = useState('');
  const dispatch = useDispatch();

  const options = [
    { value: 3, label: '3 dias' },
    { value: 5, label: '5 dias' },
    { value: 7, label: '7 dias' },
    { value: 10, label: '10 dias' },
  ];

  const ccFeeOptions = [
    { value: 1, label: 'em até 1x sem juros' },
    { value: 2, label: 'em até 2x sem juros' },
    { value: 3, label: 'em até 3x sem juros' },
    { value: 4, label: 'em até 4x sem juros' },
    { value: 5, label: 'em até 5x sem juros' },
    { value: 6, label: 'em até 6x sem juros' },
    { value: 7, label: 'em até 7x sem juros' },
    { value: 8, label: 'em até 8x sem juros' },
    { value: 9, label: 'em até 9x sem juros' },
    { value: 10, label: 'em até 10x sem juros' },
    { value: 11, label: 'em até 11x sem juros' },
    { value: 12, label: 'em até 12x sem juros' },
  ];

  const Submit = () => {
    dispatch(
      putAccountsRequest(account_id, {
        payments_settings: {
          invoice: {
            enabled: invoiceEnable,
            expiration_in_days: invoiceExpirationDays,
          },
          credit_card: {
            enabled: ccEnable,
            installments_no_fees: ccNoFeeInstallments,
            installments_fees_payer: ccFee,
          },
          pix: {
            enabled: pixEnable,
          },
        },
      }),
    );
  };

  const Reset = () => {
    setData({
      invoiceExpirationDays: payments_settings.invoice.expiration_in_days,
      ccNoFeeInstallments: payments_settings.credit_card.installments_no_fees,
      ccEnable: payments_settings.credit_card.enabled,
      invoiceEnable: payments_settings.invoice.enabled,
      pixEnable: payments_settings.pix?.enabled ?? false,
    });
  };

  useEffect(() => {
    if (account_id) {
      setData({
        invoiceEnable: payments_settings.invoice.enabled,
        invoiceExpirationDays: payments_settings.invoice.expiration_in_days,
        ccEnable: payments_settings.credit_card.enabled,
        ccNoFeeInstallments: payments_settings.credit_card.installments_no_fees,
        pixEnable: payments_settings.pix?.enabled ?? false,
      });
    }
  }, [account_id, payments_settings]);

  return (
    <ContainerInner padding="45px 25px">
      <PaymentWrapper>
        <h1 className="tabTitle">Configurações de Pagamento</h1>
        <p className="tabDescription">
          Aqui você configura métodos e prazos padrões para seus pagamentos.
        </p>
        <p className="tabDescription">
          Não se preocupe, você poderá personalizar estas configurações quando criar um link ou
          solicitar um pagamento.
        </p>
        <OptionDescription>Métodos de pagamento padrão de sua conta</OptionDescription>
        <PaymentRadio>
          <Checkbox
            checked={ccEnable}
            name="credit_card"
            infoSize={18}
            onChange={() => setData({ ...data, ccEnable: !ccEnable })}
            labelClassName="checkbox"
          >
            Cartão de crédito
          </Checkbox>

          <Checkbox
            checked={invoiceEnable}
            name="invoice"
            infoSize={18}
            onChange={() => setData({ ...data, invoiceEnable: !invoiceEnable })}
            labelClassName="checkbox"
          >
            Boleto
          </Checkbox>

          <Checkbox
            checked={pixEnable}
            name="pix"
            infoSize={18}
            onChange={() => setData({ ...data, pixEnable: !pixEnable })}
            labelClassName="checkbox"
          >
            Pix
          </Checkbox>
        </PaymentRadio>
        {/* <PaymentHeader>
          <PaymentTitle>
            Boleto
            {invoiceEnable ? (
              <PaymentLabel>Ativado</PaymentLabel>
            ) : (
              <PaymentLabel disabled>Desativado</PaymentLabel>
            )}
          </PaymentTitle>
          <PaymentEnable>
            <Switch
              onChange={handleInvoiceChange}
              checked={Boolean(invoiceEnable)}
              uncheckedIcon={false}
              checkedIcon={false}
              onColor="#50AFF4"
              height={25}
              handleDiameter={23}
            />
          </PaymentEnable>
        </PaymentHeader> */}
        <PaymentOption>
          <OptionDescription style={{ width: 'fit-content' }}>
            <InfoDescription
              tooltip="Este é o vencimento que aparecerá ao cliente quando ele gerar o boleto para pagamento."
              width="325px"
            >
              Vencimento padrão de boletos&nbsp;
            </InfoDescription>
          </OptionDescription>
          <OptionList>
            <Select
              icon="MdKeyboardArrowDown"
              size={24}
              name=""
              options={options}
              initialValue={invoiceExpirationDays}
              placeholder="3 dias"
              className="dropdown-form minified-select"
              onChange={obj => setData({ ...data, invoiceExpirationDays: obj.value })}
            />
          </OptionList>
        </PaymentOption>
        {/* </PaymentWrapper>

      <PaymentWrapper> */}
        {/* <PaymentHeader>
          <PaymentTitle>
            Cartão de crédito
            {ccEnable ? (
              <PaymentLabel>Ativado</PaymentLabel>
            ) : (
              <PaymentLabel disabled>Desativado</PaymentLabel>
            )}
          </PaymentTitle>
          <PaymentEnable>
            <Switch
              onChange={handleCcChange}
              checked={Boolean(ccEnable)}
              uncheckedIcon={false}
              checkedIcon={false}
              onColor="#50AFF4"
              height={25}
              handleDiameter={23}
            />
          </PaymentEnable>
        </PaymentHeader> */}
        <PaymentOption>
          <OptionDescription>
            Número máximo de parcelas sem juros padrão de sua conta:
          </OptionDescription>
          <OptionList>
            <Select
              icon="MdKeyboardArrowDown"
              size={24}
              name=""
              options={ccFeeOptions}
              initialValue={ccNoFeeInstallments}
              placeholder="12 parcelas"
              className="dropdown-form minified-select"
              onChange={obj => setData({ ...data, ccNoFeeInstallments: obj.value })}
            />
          </OptionList>
        </PaymentOption>
        {/* <PaymentOption>
          <OptionDescription>Defina o prazo de recebimento</OptionDescription>
          {loadFee && (
            <OptionList>
              <Select
                icon="MdKeyboardArrowDown"
                size={24}
                name=""
                isDisabled={optionsFee.payments_on_last_30_days}
                options={fees}
                initialValue={feeId}
                placeholder="Receba em 30 dias"
                className="dropdown-form minified-select"
                onChange={obj => setFeeId(obj.value)}
                isOptionDisabled={option => option.value === '6db3a477-97fd-4e24-85f5-8a42c031d1eb'}
              />
            </OptionList>
          )}
        </PaymentOption> */}

        {/* <PaymentMethodsCards>
          <strong>Bandeiras aceitas:</strong>
          Visa, Mastercard, Elo, Hiper, Aura, Dinners, Amex, JCB e Discover.
        </PaymentMethodsCards> */}
      </PaymentWrapper>

      <ButtonWrapper>
        <Button className="buttonGray" onClick={Reset}>
          Cancelar alterações
        </Button>
        <Button className="buttonBlue" onClick={Submit} disabled={loading}>
          {loading ? <Spinner size={20} /> : 'Salvar'}
        </Button>
      </ButtonWrapper>
    </ContainerInner>
  );
};

export default PaymentMethods;
