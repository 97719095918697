import styled from 'styled-components'

export const Stripe = styled.div`
  /* padding: 5px;
  margin: 5px -5px; */
`

export const Container = styled.div`
  display: flex;
  justify-content: center;
  /* background: linear-gradient(90deg, #FAF1F4 7.97%, #F8E9E4 41.84%, #F7E9EC 81.04%); */
  background-color: #f2bd7e;
  position: relative;
  height: auto;
  border-radius: 2px;
  padding: 0px 40px;

  > img {
    /* width: 170px; */
    /* height: 96px; */
    /* margin: -4px 0 -24px 0; */
  }

  @media screen and (max-width: 960px) {
    flex-direction: column;
    padding: 0px 12px 0px 0px;

    img {
      display: none;
    }
  }
`

export const Content = styled.div`
  display: flex;
  flex-direction: row;
  padding: 10px 0;
  justify-content: center;
  align-items: center;
  max-width: 1170px;
  width: 100%;
  @media screen and (max-width: 960px) {
    padding: 12px;
    flex-direction: column;
    align-items: center;
  }
`

export const ContentLeft = styled.div`
  color: #140853;
  text-align: left;
  font-weight: 400;
  margin-right: 4%;
  width: 80%;
  height: 100%;
  display: flex;
  align-itens: center;
  justify-content: center;

  @media screen and (max-width: 768px) {
    max-width: 100%;
    width: 100%;
    font-size: 14px;
  }
`

export const Title = styled.p`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 21px;
  width: 100%;
  height: 100%;

  @media screen and (max-width: 768px) {
    max-width: 100%;
    width: 100%;
    flex-direction: column;
    align-items: center;
    font-size: 17px;
  }
`

export const Description = styled.p`
  font-size: 14px;
  margin-left: 21px;
`

export const Strong = styled.span`
  font-weight: 600;
  font-size: 22px;
  margin-right: 5px;

  @media screen and (max-width: 768px) {
    font-size: 17px;
  }
`

export const BreakMobileLine = styled.div`
  @media screen and (max-width: 768px) {
    margin-bottom: 6px;
  }
`

export const Button = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
  background-color: #140853;
  font-size: 16px;
  font-weight: 400;
  text-align: center;
  color: #ffffff;
  padding: 8px 20px;
  width: 14%;
  height: 100%;

  @media screen and (max-width: 768px) {
    height: auto;
    width: 50%;
    margin-top: 5px;
  }
`

export const CloseButton = styled.span`
  font-size: 18px;
  position: absolute;
  top: 18px;
  right: 18px;
  color: #140853;
  cursor: pointer;
  z-index: 3;

  @media (max-width: 600px) {
    font-size: 25px;
    top: 5px;
    right: 5px;
  }
`
