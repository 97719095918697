import { hot } from 'react-hot-loader/root';
import { setConfig } from 'react-hot-loader'
import React from 'react';
import { Provider } from 'react-redux';
import { store } from './store';
import Routes from './routes';
import './styles/sass/_main.scss';

setConfig({
  showReactDomPatchNotification: false
})

function App() {
  return (
    <Provider store={store}>
      <Routes />
    </Provider>
  );
}

export default process.env.NODE_ENV === "development" ? hot(App) : App
