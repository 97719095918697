import React, { useRef, useEffect } from "react";
import PropTypes from "prop-types";

import { useField } from "@rocketseat/unform";
import { Wrapper, TextareaField, Label, MaxLength } from "./styles";

export default function Textarea({
  label,
  type,
  customMessage,
  name,
  size,
  required,
  children,
  value,
  onChange,
  maxlength,
  inlineStyle,
  ...rest
}) {
  const ref = useRef(null);
  const { fieldName, registerField, error } = useField(name);

  const renderLabel = label;

  useEffect(() => {
    if (!ref.current) return;
    registerField({
      name: fieldName,
      ref: ref.current,
      path: "value"
    });
  }, [ref.current, fieldName]); //eslint-disable-line

  return (
    <Wrapper htmlFor={fieldName} style={inlineStyle}>
      {error && <span className="error-msg">{error}</span>}
      <Label className="label">
        {renderLabel}{" "}
      </Label>
      <TextareaField
        name={fieldName}
        ref={ref}
        id={fieldName}
        value={value}
        aria-label={fieldName}
        required={required}
        onChange={onChange}
        maxLength={maxlength}
        {...rest}
      />
      {maxlength && <MaxLength>{value.length}/{maxlength}</MaxLength>}
      {children}
    </Wrapper>
  );
}

Textarea.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  required: PropTypes.bool,
  size: PropTypes.number,
  children: PropTypes.element,
  value: PropTypes.string,
  onChange: PropTypes.func,
  maxlength: PropTypes.string,
  inlineStyle: PropTypes.object
};

Textarea.defaultProps = ({ name }) => ({
  label: "",
  size: null,
  children: null,
  onChange: () => {},
  inlineStyle: null
});
