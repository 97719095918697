import styled from 'styled-components';

export const Button = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 82px;
  height: 19px;
  border: 0px;
  position: absolute;
  left: 50%;
  bottom: 0;
  transform: translateX(-50%);
  background: rgba(0, 0, 0, 0.6);
  color: #dcdcdc;
  text-align: center;
  font-size: 11px;
  border-radius: 0 0 50% 50%;
`;

export const EditImage = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 50%;
  position: relative;
  overflow: hidden;
  max-width: 110px;
  max-height: 110px;

  @media (max-width: 1200px) {
    margin: 0 auto;
  }

  label {
    position: relative;
    margin: 0px;
    border-radius: 50%;

    @media screen and (max-width: 1200px) {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .hoverImage {
    background: #e9e9e9a8;
    position: absolute;
    height: 100%;
    width: 100%;
    border-radius: 50%;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    opacity: 0;
    &:hover {
      opacity: 1;
    }
  }
  .editImage {
    width: auto;
    height: auto;
    background: none;
    border: none;
  }
`;

export const UserInfo = styled.div`
  padding-left: 32px;
  display: flex;
  flex-direction: column;
  align-self: center;

  @media (max-width: 1200px) {
    padding-left: 0px;
  }
`;

export const Name = styled.div`
  font-size: 26px;
  font-weight: 500;
  color: #343434;
  margin-bottom: 8px;

  @media (max-width: 1200px) {
    margin-top: 16px;
    text-align: center;
    font-size: 22px;
  }
`;

export const Document = styled.div`
  font-size: 17px;
  color: #2f2f2f;
  margin-bottom: 8px;

  @media (max-width: 1200px) {
    margin-top: 6px;
    text-align: center;
  }
`;

export const Username = styled.div`
  font-size: 17px;
  color: #2f2f2f;

  @media (max-width: 1200px) {
    text-align: center;
  }
`;

export const SocialReason = styled.div`
  font-size: 17px;
  color: #2f2f2f;
  margin-bottom: 8px;

  @media (max-width: 1200px) {
    display: none;
  }
`;

export const Initials = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 110px;
  height: 110px;
  font-size: 40px;
  color: #ffffff;
  background-color: #d467e5;
  border-radius: 50%;
  overflow: hidden;
`;
