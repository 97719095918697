import React from 'react';

import {
  GeneralContainer,
  ContainerImage,
  Descriptions,
  TitleDescription,
  TextContainer,
  Icon,
  LinkHelpCenter
} from '../UseMyProsite/styles';

export default function UseMyProsite() {
  return (
    <GeneralContainer>
      <ContainerImage src="https://bifrost.alboompro.com/products/alboompay/draggin_payment_button2.gif" />
      <TextContainer>
        <TitleDescription>Passo a passo</TitleDescription>
        <Descriptions> 
          1. Vá até o seu editor de página ou Landing Page;
         </Descriptions>
         <Descriptions> 
          2. Arraste o elemento “Botão de pagamento” o local desejado;
         </Descriptions>
        <Descriptions>
          <Icon src="https://icons.alboompro.com/v1/bold/interface-essential/alerts/000000/information-circle.svg" />
          Caso ainda tenha dúvidas, acesse nossa <LinkHelpCenter href="https://suporte.alboom.com.br/hc/pt-br">central de ajuda</LinkHelpCenter> ou entre em contato conosco.
        </Descriptions>
      </TextContainer>
    </GeneralContainer>
  );
}
