import styled from 'styled-components';

export const ContainerGeneral = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 0;
  height: 560px;
  
  div.tabsContainer {
    @media screen and (max-width: 768px) {
      margin-top: 25px;
      margin-left: 0;
    }

    @media screen and (max-width: 1600px) {
      margin-top: 25px;
    }
  }

  div.tabs {
    padding: 0;
    margin: 0;
  }
  
  div.tabs a {
    width: 50%;
    text-align: center;
    
    @media screen and (max-width: 768px) {
      width: auto;
      text-align: center;
    }
  }
`;