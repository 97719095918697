import styled from 'styled-components';
import media from '../../styles/media';

export const Container = styled.div`
  margin-top: 5em;
  margin-bottom: 5em;
  background: #ffffff;
  border: 1px solid #ebebeb;
  border-radius: 10px;
  overflow: hidden;

  div.pl {
    padding-left: 5px;
  }
  div.pr {
    padding-right: 5px;
  }
  input:disabled {
    background: #eee;
  }
  .checkbox {
    margin-bottom: 0.5em;
    margin-top: 0.5em;
  }

  .CountrySelectWizard {
    .react-select__control {
      min-height: 53px;
    }
  }
`;

export const CountryLabel = styled.span`
  display: flex;
  align-items: center;
  position: relative;
  margin-bottom: 8px;
  color: #282828;
  font-size: 15px;
  font-weight: 400;
  margin-top: 3px;
`;

export const SubCategory = styled.h6`
  color: #434343;
  margin-bottom: 10px;
  margin-top: 30px;
  font-size: 17px;
  font-weight: 500;

  @media screen and (max-width: 767px) {
    text-align: center;
  }
`;

export const AccountType = styled.div`
  display: flex;
  margin-top: 0.7rem;
  margin-bottom: 0.7rem;
  justify-content: initial;

  .radio-custom {
    opacity: 0.6;
    cursor: not-allowed;
  }

  @media screen and (max-width: 767px) {
    flex-direction: column;

    .radio-custom {
      &:first-child {
        padding: 7px 25px !important;
      }
    }
  }

  h6 {
    margin: 0;
    font-weight: 400;
    text-transform: capitalize;
  }
  .radio-custom {
    margin: 0;
    padding: 7px 25px;
    margin-bottom: 0.5em;
    &:first-child {
      padding-left: 0;
    }
  }
`;

export const Content = styled.div`
  width: 100%;
  background: #fff;
  position: relative;
  form {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 40px;
    h3 {
      font-size: 1.8em;
    }

    label {
      text-transform: uppercase;
    }
  }
  h1 {
    text-align: center;
    margin-bottom: 5.9rem;
  }
  .label.country {
    display: flex;
    margin-bottom: 0.3em;
    color: #444444;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 500;
    line-height: 21px;
  }
  .loading {
    background: #00000014;
    z-index: 999;
    border-radius: 5px;
    height: 100%;
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    svg {
      position: absolute;
      top: 50%;
      left: calc(50% - 20px);
      transform: translateY(-50%);
    }
  }

  @media ${media.small} {
    padding: 4.5rem 3rem;
  }
`;

export const AddressWrap = styled.div`
  position: relative;

  @media screen and (max-width: 767px) {
    .inputs-wrapper {
      flex-direction: column;

      > div {
        max-width: 100%;
      }
    }

    .street {
      max-width: calc(65% - 5px);
      padding: 0 5px 0 0;
    }

    .number {
      padding: 0;
      max-width: 40%;
    }
  }
`;

export const Header = styled.div`
  max-width: 72.6rem;
  background: #fff;
  width: 100%;
  padding: 1.5em;
  border-bottom: 1px solid #eee;
  z-index: 2;
  h5 {
    margin: 0;
  }
`;

export const ContentArea = styled.div`
  display: flex;
  position: relative;

  @media (max-width: 768px) {
    flex-direction: column;

    > div {
      width: 100%;
    }

    .tabsContainer {
      order: 2;
    }
  }
`;

export const AccountStatus = styled.div`
  width: 272px;
  margin-top: 70px;
  border-radius: 10px;
  border: 1px solid #ebebeb;
  max-height: 500px;
  background-color: white;
  box-sizing: border-box;
  position: sticky;
  top: 70px;
  right: 0;

  @media (max-width: 768px) {
    order: 1;
    margin-top: 0px;
    margin-bottom: 20px;
    height: auto !important;
    position: unset;
  }

  div.top {
    width: 100%;
    background-color: #1b9dfa;
    height: 40px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    box-sizing: border-box;
    position: relative;

    .nonVerifiedIcon {
      width: 41px;
      height: 41px;
      position: absolute;
      left: 50%;
      top: 20px;
      transform: translateX(-50%);
      background-color: white;
      border-radius: 50%;
      border: 3px solid white;
      filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    }

    .iconStatus {
      width: 41px;
      height: 41px;
      position: absolute;
      left: 50%;
      top: 20px;
      transform: translateX(-50%);
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      border: 3px solid white;
      filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));

      img {
        width: 20px;
        height: 20px;
      }
    }
  }

  div.status {
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 36px 16px 20px;
    box-sizing: border-box;

    strong {
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 16.8px;
      letter-spacing: 0em;
      text-align: center;
      margin-bottom: 26px;
    }

    p {
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      line-height: 130%;
      letter-spacing: 0em;
      text-align: left;
      margin-bottom: 20px;
    }

    ul {
      padding-left: 32px;
      margin-bottom: 0px;

      li {
        list-style: disc;
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: 14px;
        text-align: left;
        color: #414141;

        & + li {
          margin-top: 12px;
        }

        &:last-child {
          margin-bottom: 24px;
        }
      }
    }
  }
`;

export const ModalContent = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  img {
    width: 27px;
    height: 27px;
    margin-top: 3px;
    margin-bottom: 13px;
  }

  strong {
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 19px;
    text-align: center;
  }

  ul {
    align-self: flex-start;
    margin-left: 24px;

    p {
      margin-top: 40px;
      font-size: 15px;
      font-style: normal;
      font-weight: 400;
      line-height: 18px;
      text-align: left;
    }

    li {
      font-size: 15px;
      font-style: normal;
      font-weight: 400;
      line-height: 18px;
      text-align: left;
      margin-left: 10px;
    }

    li::before {
      content: '';
      width: 5px;
      height: 5px;
      font-size: 50px;
      vertical-align: middle;
      display: inline-block;
      margin-top: -3px;
      margin-right: 8px;
      border-radius: 50%;
      background-color: #585858;
    }

    li + li {
      margin-top: 13px;
    }
  }
`;
