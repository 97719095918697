import styled from 'styled-components';
import { Form } from '@rocketseat/unform';

export const VerifyStyledForm = styled(Form)`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  img {
    width: 25px;
    height: 25px;
    margin-right: 10px;
  }

  .input-text {
    align-items: center;
    margin-bottom: 8px;

    .code-confirmation {
      border-radius: 3px;
      border: 1px solid #e3e3e3;
      width: 266px;
      padding: 17px 0 17px 46px;
      font-size: 16px;
      line-height: 16px;
      letter-spacing: 44px;
      text-shadow: 0px 0px 1px #000;
    }

    .container-phone {
      border-radius: 3px;
      border: 1px solid #e3e3e3;
      padding: 17px 0 17px 8px;
      line-height: 16px;
    }

    .code-confirmation:focus {
      border: 1px solid #50AFF4;
    }

    .sms-code-full {
      color: transparent;
    }

    .sms-code-error {
      border: 1px solid red;
    }
  }

  .re-send {
    text-decoration: none;
    color: #878787;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;
  }

  .re-send2 {
    text-decoration: none;
    color: #878787;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;
  }

  p {
    text-align: center;
  }
`;

export const VerifyContentBox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
`;

export const VerifyFooterWrapper = styled.div`
  display: inline-flex;
  justify-content: flex-end;
  padding: 32px 0 0 0;
  margin-bottom: 30px;
  
  button {
    width: 182px;
    margin-bottom: 0px !important;

    &:first-child {
      margin-right: 18px;
    }
  }
`;