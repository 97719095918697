import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { modalClose } from '../../../../store/modules/modal/actions';
import { bankAccountsDelete } from '../../../../store/modules/bankAccounts/actions';
import Delete from '../../Delete';

export default function () {
  const { data, type, visible } = useSelector(state => state.modal);
  const dispatch = useDispatch();

  const Submit = () => {
    dispatch(bankAccountsDelete(data.id));
    dispatch(modalClose());
  };

  return (
    <Delete
      visible={type === 'bankAccountsDelete' && visible}
      title="Deletar conta"
      description="Tem certeza que deseja deletar essa conta?"
      onClose={() => dispatch(modalClose())}
      onConfirm={Submit}
    />
  );
}
