import React from 'react';
import { useDispatch } from 'react-redux';
import { modalOpen } from '../../store/modules/modal/actions';
import Button from '@alboompro/boom-components/general/button';
import MyAccounts from '../../components/Myaccounts';
import PageHeader from '../../components/PageHeader';

export default function() {
  const dispatch = useDispatch();

  return (
    <>
      <PageHeader
        title="Contas bancárias"
        subtitle="Configuração de contas bancárias para saques."
        actions={
          <Button
            onClick={() => dispatch(modalOpen({ type: 'bankAccounts' }))}
            style={{ height: 35, backgroundColor: '#50AFF4' }}
            type="primary"
          >
            Adicionar conta
          </Button>
        }
      />
      <MyAccounts />
    </>
  );
}
