import styled from 'styled-components';
import media from '../../styles/media';

export const Container = styled.div`
  margin-top: 5em;
  margin-bottom: 5em;
  background: #ffffff;
  border: 1px solid #ebebeb;
  border-radius: 10px;
  overflow: hidden;

  div.pl {
    padding-left: 5px;
  }
  div.pr {
    padding-right: 5px;
  }
  input:disabled {
    background: #eee;
  }
  .checkbox {
    margin-bottom: 0.5em;
    margin-top: 0.5em;
  }

  .CountrySelectWizard {
    .react-select__control {
      min-height: 53px;
    }
  }
`;

export const SelectLabel = styled.span`
  display: flex;
  align-items: center;
  position: relative;
  margin-bottom: 8px;
  color: #282828;
  font-size: 15px;
  font-weight: 400;
  margin-top: 3px;
`;

export const SubCategory = styled.h6`
  color: #434343;
  margin-bottom: 10px;
  margin-top: 30px;
  font-size: 15px;
  font-weight: 500;

  @media screen and (max-width: 767px) {
    text-align: center;
  }
`;

export const AccountType = styled.div`
  display: flex;
  margin-top: 0.7rem;
  margin-bottom: 0.7rem;
  justify-content: initial;

  .radio-custom {
    opacity: 0.6;
    cursor: not-allowed;
  }

  @media screen and (max-width: 767px) {
    flex-direction: column;

    .radio-custom {
      &:first-child {
        padding: 7px 15px !important;
      }
    }
  }

  h6 {
    margin: 0;
    font-weight: 400;
    text-transform: capitalize;
  }
  .radio-custom {
    margin: 0;
    padding: 7px 25px;
    margin-bottom: 0.5em;
    &:first-child {
      padding-left: 0;
    }
  }
`;

export const Content = styled.div`
  width: 100%;
  background: #fff;
  position: relative;
  form {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 40px;
    h3 {
      font-size: 1.8em;
    }

    label {
      text-transform: uppercase;
    }
  }
  h1 {
    text-align: center;
    margin-bottom: 5.9rem;
  }
  .label.country {
    display: flex;
    margin-bottom: 0.3em;
    color: #444444;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 500;
    line-height: 21px;
  }
  .loading {
    background: #00000014;
    z-index: 999;
    border-radius: 5px;
    height: 100%;
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    svg {
      position: absolute;
      top: 50%;
      left: calc(50% - 20px);
      transform: translateY(-50%);
    }
  }

  @media ${media.small} {
    padding: 4.5rem 3rem;
  }
`;

export const AddressWrap = styled.div`
  position: relative;
  margin: 20px 0px 40px;

  @media screen and (max-width: 767px) {
    .inputs-wrapper {
      flex-direction: column;

      > div {
        max-width: 100%;
      }
    }
  }
`;

export const Header = styled.div`
  max-width: 72.6rem;
  background: #fff;
  width: 100%;
  padding: 1.5em;
  border-bottom: 1px solid #eee;
  z-index: 2;
  h5 {
    margin: 0;
  }
`;

export const RadioText = styled.div`
  font-size: 14px;
`;

export const RadioSubtitle = styled.div`
  color: #666666;
`;

export const RadioTitle = styled.div`
  font-size: 17px;
  font-weight: 500;
  white-space: nowrap;
`;

export const RadioLabel = styled.div`
  display: flex;
  flex-direction: column;
`;

export const CategorySubtitle = styled.div`
  color: #3e3e3e;
  font-size: 15px;
  font-weight: 400;
  margin: 15px 0px 25px;
`;

export const CheckboxLabel = styled.div`
  color: #3e3e3e;
  font-size: 13px;
  font-weight: 400;
`;

export const DocumentCard = styled.div`
  background-color: #fafafa;
  padding: 15px 20px 1px;
  margin-top: 20px;
  
  .title {
    color: #434343;
    font-weight: 500;
    font-size: 15px;
    margin-bottom: 10px;
  }
  ul {
    list-style: disc;
    padding-left: 20px;
  }
`;
export const CopyLink = styled.div`
  font-size: 13px;
  color: #1b9dfa;
  text-decoration: underline;
  text-align: right;

  &:hover {
    cursor: pointer;
    text-decoration: none;
  }
  @media (max-width: 576px) {
    text-align: left;
    padding: 5px 0;
  }
`;

export const StickyButtons = styled.div`
  background: #ffffff;
  box-shadow: 0px -2px 6px rgba(0, 0, 0, 0.13);
  position: sticky;
  bottom: 0;
  padding: 1px 20px 0px;
  border-radius: 0 0 10px 10px;

  @media (max-width: 768px) {
    width: calc(100% + 20px);
    margin: 0 -10px;
  }

  .disabled {
    user-select: none;
    pointer-events: none;
    opacity: 0.5;
  }
`;

export const RadioCategory = styled.div`
  font-weight: 400;
  font-size: 15px;
  color: #282828;
  margin: 38px 0px 22px 0px;
`;

export const FilesUploaded = styled.ul`
  border: 1px solid #e7e7e7;
  border-radius: 5px;
  // max-height: 260px;
  // overflow-y: auto;
`;

export const File = styled.li`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 21px 14px 18px 34px;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 15px;
  letter-spacing: 0em;
  text-align: left;

  & + li {
    border-top: 1px solid #e7e7e7;
  }

  > div {
    > svg {
      margin-right: 22px;
    }

    a {
      display: flex;
      flex-direction: row;
      align-items: center;
      border: none;
      padding: 10px 11px 10px 14px;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 14px;
      letter-spacing: 0em;
      border-radius: 3px;
      color: #4c4c4c;
      background-color: transparent;
      transition: 0.2s;
      text-decoration: none;
      cursor: pointer;

      > svg {
        margin-right: 9px;
      }

      &:hover {
        background-color: rgba(27, 157, 250, 0.14);
      }

      &.exclude {
        margin-left: 4px;
        color: #ff6e6e;

        &:hover {
          background-color: rgba(220, 98, 75, 0.2);
        }
      }
    }
  }

  @media (max-width: 768px) {
    flex-direction: column;
    padding: 15px;

    div:first-child {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 10px;

      > svg {
        margin: 0 0 10px 0;
      }
    }
  }
`;
