import Icon from '@alboompro/boom-components/general/icon/Icon';
import React from 'react';
import { InfoStyle, Tooltip } from './styles.js';

const InfoDescription = ({ children, className, tooltip, ...props }) => {
  return (
    <InfoStyle className={className}>
      {children}
      <div className="icon-tooltip">
        <Icon
          kind="bold"
          group="interface-essential"
          category="alerts"
          file="information-circle.svg"
          size="14"
          color="#B2B2B2"
        />
        <Tooltip className="text-tooltip" {...props}>
          {tooltip}
        </Tooltip>
      </div>
    </InfoStyle>
  );
};

export default InfoDescription;
