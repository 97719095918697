import { takeLatest, call, put, all } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import {
  BUTTON_PAYMENT_GET,
  BUTTON_PAYMENT_DELETE,
  BUTTON_PAYMENT_DUPLICATE,
  buttonPaymentDeleteSuccess,
  paymentButtonsRequestSuccess,
  paymentButtonsRequestFailure,
  paymentButtonsDuplicateSuccess,
} from './actions';

import paymentButtonsRequest from '../../../services/core/http/paymentButtons';

export function* getPaymentLinks({ payload }) {
  try {
    const response = yield call(paymentButtonsRequest.get, { ...payload });
    yield put(paymentButtonsRequestSuccess(response));
  } catch (error) {
    yield put(paymentButtonsRequestFailure());
  }
}

export function* deletePaymentButton({ payload }) {
  try {
    const response = yield call(paymentButtonsRequest.delete, payload);
    yield put(buttonPaymentDeleteSuccess(response));
    toast.success('Botão excluído com sucesso');
  } catch (error) {
    yield put(paymentButtonsRequestFailure());
  }
}

export function* duplicatePaymentButton({ payload }) {
  try {
    const response = yield call(paymentButtonsRequest.duplicate, payload);
    yield put(paymentButtonsDuplicateSuccess(response));
    toast.success('Botão de pagamento duplicado');
  } catch (error) {
    yield put(paymentButtonsRequestFailure());
  }
}

export default all([
  takeLatest(BUTTON_PAYMENT_GET, getPaymentLinks),
  takeLatest(BUTTON_PAYMENT_DELETE, deletePaymentButton),
  takeLatest(BUTTON_PAYMENT_DUPLICATE, duplicatePaymentButton),
]);