import React, { useState } from 'react';
// import Modal from '..';
import Modal from '@alboompro/boom-components/data-display/modal';
import { FooterWrapper, ContentBox, StyledForm } from '../styles';
import Button from '../../Button';
import Input from '../../Input';
//import ContactPicker from '../../ContactPicker';
import { paymentRequestSimpleModal } from '../../../validators/schema';

export default function ModalPersonComponent({
  onClose: childOnClose,
  onSubmit: childOnSubmit,
  initialData,
  submitText,
}) {
  const [data, setData] = useState({
    visible: true,
    name: initialData ? initialData.name : '',
    email: initialData ? initialData.email : '',
    ref_id: initialData ? initialData.ref_id : '',
    id: initialData ? initialData.id : '',
  });
  const { visible, name, email, ref_id, id } = data;

  function onSubmit(data) {
    console.log(data)
    setData({ ...data, visible: false });
    // document.getElementsByTagName('body')[0].style = null; //Fix bug overflow
    childOnSubmit({
      ...data,
      id,
    });
  }

  function onClose() {
    setData({ ...data, visible: false });
    // document.getElementsByTagName('body')[0].style = null; //Fix bug overflow
    childOnClose();
  }

  // function onSelectContact(contact) {

  //   //console.log(data)
  //   let newData = data

  //   data.name = contact.name
  //   data.email = contact.emails[0].email
  //   setData({ data: newData});
  // }

  return (
    <Modal
      className="modal-add"
      title="Adicionar cliente"
      visible={visible}
      width={550}
      backdropStyle={{ backgroundColor: '#000000b8', zIndex: 999 }}
      backdropClosable={false}
      background={'#FFF'}
      bodyStyle={{ padding: '40px 25px', backgroundColor: '#f6fafe' }}
      onClose={onClose}
      closeButton={true}
    >
      <div>
        <ContentBox>
          <h1>Adicione o contato de quem irá receber a solicitação de pagamento.</h1>
          <StyledForm onSubmit={onSubmit} schema={paymentRequestSimpleModal}>
            <Input name="name" label="Nome e sobrenome" defaultValue={name} />
            <Input name="email" type="email" defaultValue={email} label="Email" />
          {/* <StyledForm onSubmit={onSubmit}>
            <ContactPicker onSelect={onSelectContact}></ContactPicker>
            <Input type="hidden" name="name" label="Nome e sobrenome" defaultValue={name} />
            <Input type="hidden" name="email" defaultValue={email} label="Email" /> */}
            <Input
              className="lastInput"
              name="ref_id"
              label="Adicione um códido de pedido (se houver)"
              defaultValue={ref_id}
            />
            <FooterWrapper>
              <Button onClick={onClose} className="buttonDefault buttonLarge">
                Cancelar
              </Button>
              <Button htmlType="submit" className="buttonBlue">
                {submitText || 'Adicionar'}
              </Button>
            </FooterWrapper>
          </StyledForm>
        </ContentBox>
      </div>
    </Modal>
  );
}
