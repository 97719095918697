import styled from 'styled-components';

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;

  @media screen and (max-width: 767px) {
    // flex-direction: ${props => (props.breakLine ? 'column' : 'row')};
    flex-direction: column;
    justify-content: space-between;
    align-items: start;
    padding: 20px;
  }
`;

export const Title = styled.h1`
  font-size: 22px;
  font-weight: 500;
  color: #212121;
  line-height: 1.3;
  margin: 0px;

  @media screen and (max-width: 767px) {
    font-size: 18px;
    text-align: start;
  }
`;

export const Subtitle = styled.h2`
  font-size: 16px;
  font-weight: 300;
  line-height: 1.3;
  color: #383838;
  min-height: 22px;
  margin-top: 5px;

  @media screen and (max-width: 767px) {
    font-size: 14px;
    margin-bottom: 15px;
    text-align: start;
    line-height: 21px;
  }
`;

export const Label = styled.div`
  margin-left: 15px;
  background: #ffffff;
  border: 1px solid #d5d5d5;
  border-radius: 100px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 3px;
  max-height: 100%;
`;

export const LabelText = styled.div`
  font-size: 14px;
  line-height: 100%;
  padding: 0 10px;
  color: #525252;
  display: flex;
  align-items: center;
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  display: flex;
  align-items: center;
  margin-bottom: 5px;

  @media screen and (max-width: 767px) {
    width: 100%;
    align-items: start;
  }
`;

export const ActionsContainer = styled.div`
  @media screen and (max-width: 768px) {
    width: 100%;
  }
`