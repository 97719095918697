import React from "react"
import Icon from '@alboompro/boom-components/general/icon';

import {
  PanelWrapper,
  ChangePageButton,
  CurrentPageBox,
} from "./styles"

export default function NavigationPanel({
  hasNextPage,
  currentPage,
  handlePageChange,
  loading,
}) {
  const isPreviousPageDisabled = currentPage === 1
  const isNextPageDisabled = !hasNextPage

  return (
    <PanelWrapper>
      <ChangePageButton
        disabled={isPreviousPageDisabled || loading}
        action="previous"
        onClick={() => handlePageChange("previous")}
      >
        <Icon
          kind="regular"
          group="arrows-diagrams"
          category="arrows"
          file="arrow-left-1.svg"
          size="14"
          color={isPreviousPageDisabled ? "#D7D7D7" : "#A8A8A8"}
        />
      </ChangePageButton>
      <CurrentPageBox>
        <span>{currentPage}</span>
      </CurrentPageBox>
      <ChangePageButton
        disabled={isNextPageDisabled || loading}
        action="next"
        onClick={() => handlePageChange("next")}
      >
        <Icon
          kind="regular"
          group="arrows-diagrams"
          category="arrows"
          file="arrow-right-1.svg"
          size="14"
          color={isNextPageDisabled ? "#D7D7D7" : "#A8A8A8"}
        />
      </ChangePageButton>
    </PanelWrapper>
  )
}