import { takeLatest, call, put, all } from "redux-saga/effects";
import { ordersSuccess, ordersFailure, ordersListRequestSuccess } from "./actions";
import Orders from '../../../services/core/http/orders';

export function* orders({ payload }) {
  try {
    const response = yield call(Orders.create, payload.data);
    yield put(ordersSuccess(response));
  } catch (error) {
    yield put(ordersFailure());
  }
}

export function* getList({ params }) {
  try {
    const response = yield call(Orders.get, { ...params });
    yield put(ordersListRequestSuccess(response));
  } catch (error) {
    console.log(error)
    yield put(ordersFailure());
  }
}

export default all([
  takeLatest("@order/ORDERS_REQUEST", orders),
  takeLatest("@order/ORDERS_GET_LIST", getList),
]);
