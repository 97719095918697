import React, { useEffect, useState } from 'react';
import Modal from '@alboompro/boom-components/data-display/modal';
import { statusPagarme, currencyFormat } from '../../../../utils';
import Order from '../../../../services/core/http/orders';
import { modalClose } from '../../../../store/modules/modal/actions';
import { useSelector, useDispatch } from 'react-redux';
import PayerDetails from './PayerDetails';
import PaymentDetails from './PaymentDetails';
import PaymentRequest from './PaymentRequest';
import ShippingAddress from './ShippingAddress';
import OrderDetails from './OrderDetails';
import Spinner from '../../../Spinner/Absolute';
import Icon from '@alboompro/boom-components/general/icon';

import {
  PaymentWrapper,
  Header,
  HeaderRow,
  PaymentCardHalf,
  PaymentAmount,
  PaymentCode,
  PaymentStatus,
  ModalTitle,
  PayerName,
  PaymentType,
  CopyCode,
  CloseIcon,
  CloseWrapper,
  Card,
  CardHeader,
  CardTitle,
  EmptyInfos,
  CopyCodeTooltip
} from './styles';

export default function PaymentsDetailsModal() {
  const [isPaymentCodeCopied, setIsPaymentCodeCopied] = useState(false);
  const [details, setDetails] = useState(null);
  const dispatch = useDispatch();
  const modal = useSelector(state => state.modal);
  const { fees } = useSelector(state => state.accounts.user_data);

  const order = modal.data;

  function onClose() {
    dispatch(modalClose());
    setDetails(null);
  }

  useEffect(() => {
    const fetch = async () => {
      if (order.status !== statusPagarme[0]) {
        // pending
        const {
          data: {
            data: { payment: details },
          },
        } = await Order.details(order.payment_id);
        setDetails(details);
      }
    };
    fetch();
  }, [order]);

  const handleCopyClipboard = id => {
    if (isPaymentCodeCopied) return;
    setIsPaymentCodeCopied(true);
    const dummy = document.createElement('textarea');
    document.body.appendChild(dummy);
    dummy.value = id.toUpperCase();
    dummy.select();
    document.execCommand('copy');
    document.body.removeChild(dummy);
    setTimeout(() => {
      setIsPaymentCodeCopied(false)
    }, 3000)
  }

  const renderPaymentType = order => {
    // TODO: Colocar ID dinamico
    if (order.origin == 'proof') {
      return (
        <div>
          <img
            src="https://bifrost.alboompro.com/static/alboompay/icons/proof_logo_pay.svg"
            width="61"
          />
          | Pedido do Proof:{' '}
          <a href={`https://proof.alboompro.com/orders/${order.provider_id}`} target="_blank">
            #{order.provider_id && order.provider_id}
          </a>
        </div>
      );
    }

    if (order.kind == 'paymentLink') {
      return `Link de pagamento: ${order.name}`;
    }

    if (order.kind == 'paymentRequest') {
      return 'Solicitação de pagamento';
    }

    return 'Tipo order';
  };

  return (
    <Modal
      className="modal-add"
      title="Detalhes do Pagamento"
      visible={modal.visible && modal.type === 'paymentDetails'}
      width={720}
      height={'95%'}
      backdropStyle={{ backgroundColor: '#000000b8', zIndex: '999999' }}
      backdropClosable={true}
      background={'#FFF'}
      bodyStyle={{
        padding: '0',
        backgroundColor: '#ffffff',
        height: '95%',
        maxHeight: '95vh',
        position: 'relative',
        borderRadius: '3px',
      }}
      onClose={onClose}
      closeButton={true}
      headerStyle={{ display: 'none' }}
    >
      {!!order.id && !!details ? (
        <PaymentWrapper>
          <Header>
            <CloseWrapper>
              <CloseIcon onClick={() => onClose()}>
                <Icon
                  kind="bold"
                  group="interface-essential"
                  category="form-validation"
                  file="close.svg"
                  size="16"
                  color="#5F5F5F"
                />
              </CloseIcon>
            </CloseWrapper>

            <HeaderRow>
              <PaymentCardHalf>
                <ModalTitle>Detalhes do pagamento</ModalTitle>
                <PayerName>
                  {order.kind === 'paymentLink' ? details.paylink.customer.name : order.payers.name}
                </PayerName>
                <PaymentType title={order.kind === 'paymentLink' ? order.name : ''}>
                  {renderPaymentType(order)}
                </PaymentType>
              </PaymentCardHalf>

              <PaymentCardHalf>
                <PaymentAmount>
                  <PaymentStatus
                    className={`${order.inactive_since ? `desactived` : ''} ${order.status}`}
                  >
                    {order.inactive_since
                      ? 'Cancelado'
                      : statusPagarme[
                          order.payment_method === 'pix' && order.status === 'waiting_payment'
                            ? 'waiting_pix'
                            : order.status
                        ]}
                  </PaymentStatus>
                  {currencyFormat(order.amount / 100)}
                </PaymentAmount>
                <PaymentCode>
                  <CopyCode isCopied={isPaymentCodeCopied} onClick={() => handleCopyClipboard(order.id)}>
                    {isPaymentCodeCopied ? (
                      <>
                        <Icon
                          kind="bold"
                          group="interface-essential"
                          category="form-validation"
                          file="check-circle-1.svg"
                          size="15"
                          color="#45AE70"
                        />
                        <CopyCodeTooltip>Copiado</CopyCodeTooltip>
                      </>
                    ) : (
                      <>
                        <Icon
                          kind="bold"
                          group="files-folders"
                          category="common-files"
                          file="common-file-double-1-alternate.svg"
                          size="15"
                          color="#787878"
                        />
                        <CopyCodeTooltip>Copiar</CopyCodeTooltip>
                      </>  
                    )}
                  </CopyCode>
                  {order.id}
                </PaymentCode>
              </PaymentCardHalf>
            </HeaderRow>
          </Header>

          {!!details &&
          !!details.paylink.customer &&
          Object.getOwnPropertyNames(details).length > 0 ? (
            <PaymentDetails payment={details} paid_at={order.paid_transaction_at} fees={fees} />
          ) : (
            <Card>
              <CardHeader>
                <CardTitle>Dados do pagamento recebido</CardTitle>
              </CardHeader>
              <EmptyInfos>Pagamento ainda não efetuado</EmptyInfos>
            </Card>
          )}

          {!!details &&
          !!details.paylink.customer &&
          Object.getOwnPropertyNames(details).length > 0 ? (
            <PayerDetails
              payer={details.paylink.customer}
              address={details.paylink.billing.address}
            />
          ) : (
            <Card>
              <CardHeader>
                <CardTitle>Pagamento realizado por</CardTitle>
              </CardHeader>
              <EmptyInfos>Pagamento ainda não efetuado</EmptyInfos>
            </Card>
          )}

          {order.payers && order.kind == 'paymentRequest' && (
            <PaymentRequest payer={order.payers} requested_at={order.created_at} />
          )}

          {order.shipping_address_required && Object.getOwnPropertyNames(details).length > 0 ? (
            <ShippingAddress title="Dados de entrega" address={details.paylink.billing.address} />
          ) : (
            <Card>
              <CardHeader>
                <CardTitle>Dados de entrega</CardTitle>
              </CardHeader>
              <EmptyInfos height="80">
                {order.shipping_address_required
                  ? 'Pagamento ainda não efetuado'
                  : 'Endereço de entrega não foi solicitado'}
              </EmptyInfos>
            </Card>
          )}

          {!!details && !!details.order && Object.getOwnPropertyNames(details).length > 0 && (
            <OrderDetails order={details.order} />
          )}
        </PaymentWrapper>
      ) : (
        <Spinner />
      )}
    </Modal>
  );
}
