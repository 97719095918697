import React from 'react';
import Icon from '@alboompro/boom-components/general/icon';

import {
  Wrapper,
  IconWrapper,
  AlertMessage,
  AlertTitle,
  AlertDescription
} from './styles';

export default function({ title, warning = false, children }) {

  return (
    <Wrapper warning={warning}>
      <IconWrapper>
        <Icon
          kind="bold"
          group="interface-essential"
          category="alerts"
          file="alert-circle.svg"
          size="60"
          color={warning ? "#E9D737" : "EE358E"}
        />
      </IconWrapper>
      <AlertMessage>
        <AlertTitle>{title}</AlertTitle>
        <AlertDescription>{children}</AlertDescription>
      </AlertMessage>
    </Wrapper>
  );
}
