import React from 'react';
import PropTypes from 'prop-types';
import Icon from '@alboompro/boom-components/general/icon';
import {
  CustomerWrap,
  CustomerName,
  CustomerEmail,
  CustomerInfo,
  CustomerAmount,
  Button,
  CustomerActions
} from './styles';

export default function Customer({ id, amount, name, email, ref_id, onEdit, onRemove }) {
  return (
    <CustomerWrap>
      <CustomerInfo>
        <p className="name">
          <CustomerName>{name}</CustomerName>
        </p>
        <CustomerEmail>{email}</CustomerEmail>
        <span>{ref_id && `REF: ${ref_id}`}</span>
      </CustomerInfo>
      <CustomerAmount>
        <p className="value">{amount || 'R$ 0,00'}</p>
      </CustomerAmount>
      <CustomerActions>
        {/* <Button type="button" className="icons" onClick={onEdit}>
          <Icon
            kind="bold"
            group="interface-essential"
            category="edit"
            file="pencil-1.svg"
            size="16"
            color="#808080"
          />
        </Button> */}
        <Button type="button" className="icons remove" onClick={onRemove}>
          <Icon
            kind="bold"
            group="interface-essential"
            category="delete"
            file="bin-1.svg"
            size="16"
            color="#DC624B"
          />
        </Button>
      </CustomerActions>
    </CustomerWrap>
  );
}

Customer.propTypes = {
  amount: PropTypes.string,
  name: PropTypes.string,
  email: PropTypes.string,
  ref_id: PropTypes.string,
  onEdit: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
};
