import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getAccountsRequest, putAccountsRequest } from '../../../store/modules/accounts/actions';
import { Form } from '@rocketseat/unform';
import Input from '../../Input';
import Button from '../../Button';
import { Container, Title, Header } from './styles';

export default function SocialMedia() {
  const { account_id, user_data, loading: accountsLoading } = useSelector(state => state.accounts);
  const dispatch = useDispatch();
  const initialData = {
    facebook: '',
    instagram: '',
    whatsapp: '',
    google_business: '',
    linkedin: '',
    twitter: '',
    reclameaqui: '',
  };
  const [data, setData] = useState(initialData);
  const { facebook, instagram, whatsapp, google_business, linkedin, twitter, reclameaqui } = data;
  const handleSubmit = () => {
    dispatch(putAccountsRequest(account_id, data));
  };

  const handleCancel = () => {
    setData(d => ({
      ...d,
      facebook: user_data.facebook,
      instagram: user_data.instagram,
      whatsapp: user_data.whatsapp,
      google_business: user_data.google_business,
      linkedin: user_data.linkedin,
      twitter: user_data.twitter,
      reclameaqui: user_data.reclameaqui,
    }));
  };
  useEffect(() => {
    !!account_id && dispatch(getAccountsRequest(account_id));
  }, [account_id, dispatch]);

  useEffect(() => {
    setData(d => ({
      ...d,
      facebook: user_data.facebook,
      instagram: user_data.instagram,
      whatsapp: user_data.whatsapp,
      google_business: user_data.google_business,
      linkedin: user_data.linkedin,
      twitter: user_data.twitter,
      reclameaqui: user_data.reclameaqui,
    }));
  }, [user_data]);

  function maskPhoneNumber(value) {
    if (!value) return value;
    const onlyNums = value.replace(/[^\d]/g, '');

    if (onlyNums.length <= 2) return `(${onlyNums.slice(0, 2)}`;
    if (onlyNums.length <= 7) return `(${onlyNums.slice(0, 2)}) ${onlyNums.slice(2, 7)}`;
    if (onlyNums.length <= 11)
      return `(${onlyNums.slice(0, 2)}) ${onlyNums.slice(2, 7)}-${onlyNums.slice(7, 11)}`;
    return `(${onlyNums.slice(0, 2)}) ${onlyNums.slice(2, 7)}-${onlyNums.slice(7, 11)}`;
  }

  return (
    <Container>
      <Header>
        <Title className="text-uppercase">Adicione suas Redes sociais</Title>A existência de redes
        sociais encorajam o comprador a fazer negócios com você.
      </Header>
      <Form onSubmit={handleSubmit}>
        <Input
          icon="facebook"
          size={30}
          name="facebook"
          label="Facebook"
          placeholder="Endereço URL"
          value={facebook}
          onChange={e => setData({ ...data, facebook: e.target.value })}
        />
        <Input
          icon="instagram"
          size={30}
          name="instagram"
          label="Instagram"
          placeholder="Endereço URL"
          value={instagram}
          onChange={e => setData({ ...data, instagram: e.target.value })}
        />
        <Input
          icon="whatsApp"
          size={30}
          name="whatsapp"
          label="WhatsApp"
          placeholder="Número: (XX) XXXXX-XXXX"
          value={whatsapp}
          onChange={({ target: { value } }) =>
            setData({ ...data, whatsapp: maskPhoneNumber(value) })
          }
        />
        <Input
          icon="googleBusiness"
          size={30}
          name="google_business"
          label="Google Meu Negócio"
          placeholder="Endereço URL"
          value={google_business}
          onChange={e => setData({ ...data, google_business: e.target.value })}
        />
        <Input
          icon="linkedIn"
          size={30}
          name="linkedin"
          label="LinkedIn"
          placeholder="Endereço URL ou nome de usuário"
          value={linkedin}
          onChange={e => setData({ ...data, linkedin: e.target.value })}
        />
        <Input
          icon="twitter"
          size={30}
          name="twitter"
          label="Twitter"
          placeholder="Endereço URL ou nome de usuário"
          value={twitter}
          onChange={e => setData({ ...data, twitter: e.target.value })}
        />
        <Input
          icon="reclameAqui"
          size={30}
          name="reclameaqui"
          label="Reclame Aqui"
          placeholder="Endereço URL ou nome de usuário"
          value={reclameaqui}
          onChange={e => setData({ ...data, reclameaqui: e.target.value })}
        />

        <div style={{ paddingTop: '192px' }} className="row mt-4">
          <div className="col-lg-6 col-md-6">
            <Button className="buttonDefault buttonLarge" onClick={handleCancel}>
              Restaurar
            </Button>
          </div>
          <div className="col-lg-6 col-md-6">
            <Button className="buttonBlue buttonLarge" htmlType="submit" disabled={accountsLoading}>
              Salvar
            </Button>
          </div>
        </div>
      </Form>
    </Container>
  );
}
