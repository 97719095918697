import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import Icon from '@alboompro/boom-components/general/icon';

import ButtonDropdown from '../Button/ButtonDropdown';
import Spinner from '../Spinner/Absolute';

import { currencyFormat } from '../../utils';
import { TableTitle } from '../../styles/global';
import EmptyTable from '../EmptyTable';
import Clone from  '../Modal/PaymentButton/Clone'
import { modalOpen, modalClose } from '../../store/modules/modal/actions'

import ModalPaymentButtons from '../Modal/PaymentButton';
import Delete from '../Modal/Delete';
import history from '../../services/history';

import {
  paymentButtonsRequest,
  paymentButtonsDelete,
} from '../../store/modules/paymentButtons/actions';

import {
  Container,
  Header,
  Wrapper,
  SearchBar,
  DropdownItem,
  TableWrapper,
  TableHeader,
  TableBody,
  TableItem,
  ButtonName,
  ButtonDescription,
  ButtonAmount,
  ButtonActions
} from './styles';

function Options({ button, handleEmbeedButton, handleClone, handleRemove, onPaymentButtonEdit }) {
  const [visible, setVisible] = useState(false);
 
  return (
    <ButtonDropdown
      primaryButton={{
        text: 'Incorporar',
        handleClick: () => handleEmbeedButton(button.id),
        width: 160
      }}
      style={{fontSize:'13px'}}
      visible={visible}
      handleVisible={visible => setVisible(visible)}
      >
      <DropdownItem onClick={() => onPaymentButtonEdit(button.id)}>
        <Icon
          kind="bold"
          group="interface-essential"
          category="edit"
          file="pencil-1.svg"
          size="12"
          color="#3E3E3E"
          />
        Editar
      </DropdownItem>
      <DropdownItem onClick={() => handleClone(button.id, button.name)}>
        <Icon
          kind="bold"
          group="files-folders"
          category="common-files"
          file="common-file-double-1-alternate.svg"
          size="13"
          color="#3E3E3E"
        />
        Duplicar
      </DropdownItem>
      <DropdownItem onClick={() => {history.push(`/payments?search=${button.id}`)}}>
        <Icon
          kind="light"
          group="money-payments-finance"
          category="cash-payments"
          file="cash-payment-bills.svg"
          size="14"
          color="#3E3E3E"
        />
        Ver pagamentos
      </DropdownItem>
      <DropdownItem onClick={() => handleRemove(button.id)}>
        <Icon
          kind="regular"
          group="interface-essential"
          category="delete"
          file="bin-1.svg"
          size="13"
          color="#EF5D5D"
        />
        <span style={{ color: '#EF5D5D' }}>Excluir</span>
      </DropdownItem>
    </ButtonDropdown>
  );
}

export default function MyButtons({ onPaymentButtonEdit }) {
  const dispatch = useDispatch();
  const { paymentButtons, getPending, deletePending } = useSelector(state => state.paymentButtons);
  const { visible } = useSelector(state => state.modal);
  const [removeVisible, setRemoveVisible] = useState(false);
  const [idOrder, setIdOrder] = useState(null);
  const [search, setSearch] = useState('');
  const [paymentButtonId, setPaymentButtonId] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [nameClone, setNameClone] = useState('');
  const [cloneVisible, setCloneVisible] = useState(false);

  useEffect(() => {
    dispatch(paymentButtonsRequest());
  }, []);

  useEffect(() => {
    !visible && dispatch(paymentButtonsRequest({ search }));
  }, [visible]);

  function handleEmbeedButton(buttonId) {
    setPaymentButtonId(buttonId);
    dispatch(modalOpen({ type: 'paymentButtons', data: false }));
    setModalVisible(true);
  }

  function handleRemove(id) {
    setRemoveVisible(!removeVisible);
    setIdOrder(id);
  }

  function handleSearch() {
    dispatch(paymentButtonsRequest({ search }));
  }

  function handleClone(id, name) {
    setCloneVisible(!cloneVisible);
    setNameClone(`Copia de ${name}`);
    setIdOrder(id);
  }

  return (
    <Container>
      {getPending && <Spinner />}
      <Header>
        <Wrapper className="row aligm-items-center">
          <TableTitle className="col-lg-4"></TableTitle>
          <div className="col-sm-12 d-flex align-items-center filters">
            <SearchBar className="col search">
              <input
                type="text"
                placeholder="Buscar"
                onChange={e => setSearch(e.target.value)}
                onKeyDown={e =>
                  e.key === 'Enter' ||
                  (e.key === 'Backspace' && e.target.value === '' && !getPending)
                    ? handleSearch()
                    : null
                }
              />
              <button onClick={handleSearch}> 
                <img src="https://icons.alboompro.com/v1/bold/interface-essential/search/bdbdbd/search-alternate.svg" alt="" />
              </button>
            </SearchBar>
          </div>
        </Wrapper>
      </Header>

      <TableWrapper>
        <TableHeader>
          <ButtonName>Nome do botão</ButtonName>
          <ButtonDescription>Descrição</ButtonDescription>
          <ButtonAmount>Valor</ButtonAmount>
          <ButtonActions></ButtonActions>
        </TableHeader>

        {paymentButtons.length > 0 ? (
        <TableBody>
          {paymentButtons.map((item) => (
            <TableItem key={item.id}>
              <ButtonName>{item.name}</ButtonName>
              <ButtonDescription>{item.description}</ButtonDescription>
              <ButtonAmount>{currencyFormat(item.amountTotal / 100)}</ButtonAmount>
              <ButtonActions>
                <Options
                  button={item}
                  handleEmbeedButton={handleEmbeedButton}
                  onPaymentButtonEdit={onPaymentButtonEdit}
                  handleRemove={handleRemove}
                  handleClone={handleClone}
                />
              </ButtonActions>
            </TableItem>
          ))}
        </TableBody>
        ) : (
          <EmptyTable search={search} text={"Nenhum botão de pagamento"} />
        )}
      </TableWrapper> 

      {modalVisible && <ModalPaymentButtons onClose={() => dispatch(modalClose())} paymentButtonId={paymentButtonId}/>}

      <Clone visible={cloneVisible} id={idOrder} name={nameClone} onClose={handleClone} />

      <Delete
        visible={removeVisible}
        title={<div style={{color: '#808080', fontSize: '18px'}}>Excluir o botão de pagamento</div>}
        description={<p style={{ color: '#808080', fontSize: '14px', lineHeight: '150%' }}>A exclusão do botão irá afetar todos os lugares em que ele se encontra. Verifique os locais em que ele foi inserido antes de excluir.</p>}
        loading={deletePending}
        onClose={handleRemove}
        onConfirm={() => dispatch(paymentButtonsDelete(idOrder))}
      />

    </Container>
  );
}
