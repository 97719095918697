export function banksRequest(data) {
  return {
    type: "@banks/REQUEST",
    payload: { data }
  };
}

export function banksSuccess(payload) {
  return {
    type: "@banks/SUCCESS",
    payload
  };
}

export function banksFailure() {
  return {
    type: "@banks/FAILURE"
  };
}
