import styled from 'styled-components';

export const FooterContent = styled.div`
  display: flex;
  justify-content: flex-end;

  button:not(:first-child) {
    margin-left: 15px;
  }
`;

export const ModalContent = styled.div`
  font-size: 16px;
  color: #363636;
  font-weight: 400;
  padding: 40px 20px;

  p {
    margin: 0;
  }
`;
