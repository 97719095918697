import React from 'react';
import { useSelector } from 'react-redux';
import { currencyFormat } from '../../utils';
import Spinner from "../Spinner/Absolute";
import moment from 'moment';
import {
  Container,
  Header,
  Wrapper,
  ReportTable,
  Title,
  Footer
} from './styles';

export default function () {
  const { loading, daily: dailyPayables } = useSelector(state => state.balance.payables);
  const total = dailyPayables.reduce((a, b) => a + (b.amount || 0), 0);

  return (
    <Container>
      <Header>
        <Wrapper className="row">
          <Title className="col">Pagamento Diário</Title>
          <span className="col text-right">{moment(new Date()).format("DD/MM/YYYY")}</span>
        </Wrapper>
      </Header>

      <ReportTable>
        <div className="wrap">
          {loading && <Spinner />}
          <table>
            <tbody>
              {
                dailyPayables.map(payable =>
                  <tr key={payable.external_id}>
                    <td>
                      <strong>{payable.name}</strong>
                      <div>
                        <small>{payable.external_id && `cod: ${payable.external_id}`}</small>
                      </div>
                    </td>
                    <td>
                      <p>{currencyFormat(payable.amount / 100)}</p>
                    </td>
                  </tr>
                )
              }
              <tr><td></td></tr>
            </tbody>
          </table>
        </div>
      </ReportTable>

      <Footer>
        <Wrapper className="row">
          <span className="col text-right">
            <strong>Total do dia:</strong> {currencyFormat(total / 100)}
          </span>
        </Wrapper>
      </Footer>
    </Container>
  );
}
