import styled from 'styled-components'

export const PageSettingsContent = styled.div`
  background-color: #fff;
  border-radius: 10px;
  padding: 7px;
`

export const SettingsOptionItem = styled.div`
  padding: 10px 12px;
  display: flex;
  align-items: center;
  cursor: pointer;
  border-radius: 3px;
  margin-bottom: 2px;
  position: relative;
  opacity: ${props => props.disabled ? '0.7' : '1'};
  cursor: ${props => props.disabled && 'not-allowed'};

  &:nth-child(1) {
    margin-bottom: 6px;
  }

  &:nth-child(2) {
    margin-bottom: 0;

    &:before {
      content: '';
      display: block;
      width: 100%;
      height: 1px;
      background-color: #E4E4E4;
      position: absolute;
      top: -3px;
      left: 0;
    }

    &:hover {
      background-color: ${props => props.disabled ? 'transparent' : '#FAE7E4'};
    }
  }

  &:hover {
    background-color: #F1F9FF;
  }
  label {
    display: flex;
    cursor: pointer;
  }
`

export const SettingsTitle = styled.div`
  margin-left: 10px;
  font-size: 13px;
  color: #212121;
  line-height: 1.3;

  &:nth-child(3) {
    color: #e83535;
  }
`

export const MenuItem = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  &:not(:last-child) {
    padding: 0 25px;
  }

  &:last-child {
    button {
      padding: 16px 35px 18px;
      border: none;
      cursor: pointer;
      &:first-child {
        color: #4b4b4b;
      }
      &:last-child {
        background-color: #45ae70;
        color: #fff;
      }
    }
  }
`

export const PageSettingsContainer = styled(MenuItem)`
  user-select: none;
  cursor: pointer;
  border-radius: 2px;
  padding: 10px;
  background-color: #fff;

  > img {
    max-width: 16px;
  }

  &:hover {
    background-color: #eaf6ff;
  }

  > div {
    &:nth-child(2) {
      top: 100%;
      right: 15px;
      margin-top: 10px;
      z-index: 1;
    }
  }
`

export const EditPageLink = styled.a`
  display: inherit;
`
