import produce from 'immer'; 
import {
  BUTTON_PAYMENT_GET,
  BUTTON_PAYMENT_GET_SUCCESS,
  BUTTON_PAYMENT_POST,
  BUTTON_PAYMENT_POST_SUCCESS,
  BUTTON_PAYMENT_PUT,
  BUTTON_PAYMENT_PUT_SUCCESS,
  BUTTON_PAYMENT_DELETE,
  BUTTON_PAYMENT_DELETE_SUCCESS,
  BUTTON_PAYMENT_FAILURE,
  BUTTON_PAYMENT_DUPLICATE,
  BUTTON_PAYMENT_DUPLICATE_SUCCESS
} from './actions';

const INITIAL_STATE = {
  paymentButtons: [],
  getPending: false,
  postPending: false,
  deletePending: false,
  duplicatePending: false,
  orders: [],
};

export default function paymentButtons(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case BUTTON_PAYMENT_GET:
        draft.getPending = true;
        break;
      case BUTTON_PAYMENT_GET_SUCCESS:
        draft.paymentButtons = action.payload.data.data.orders;
        draft.getPending = false;
        break;
      case BUTTON_PAYMENT_POST:
        draft.postPending = true;
        break;
      case BUTTON_PAYMENT_POST_SUCCESS:
        draft.paymentButtons = [action.payload.data.data.paymentButton, ...state.paymentButtons];
        draft.postPending = false;
        break;
      case BUTTON_PAYMENT_PUT:
        draft.postPending = true;
        break;
      case BUTTON_PAYMENT_PUT_SUCCESS:
        draft.paymentButtons = [
          ...state.paymentButtons.map(paymentButton =>
            paymentButton.id === action.payload.data.data.paymentButton.id
              ? action.payload.data.data.paymentButton
              : paymentButton,
          ),
        ];
        draft.postPending = false;
        break;
      case BUTTON_PAYMENT_DELETE:
        draft.deletePending = true;
        break;
      case BUTTON_PAYMENT_DELETE_SUCCESS:
        draft.paymentButtons = [
          ...state.paymentButtons.filter(paymentButton => paymentButton.id !== action.payload.id),
        ];
        draft.deletePending = false;
        break;
      case BUTTON_PAYMENT_DUPLICATE:
        draft.duplicatePending = true;
        break;
      case BUTTON_PAYMENT_DUPLICATE_SUCCESS:
        draft.paymentButtons = [action.payload.data.data.order, ...(state.paymentButtons ? state.paymentButtons : [])];
        draft.duplicatePending = false;
        break;
      case BUTTON_PAYMENT_FAILURE:
        draft.getPending = false;
        draft.postPending = false;
        draft.deletePending = false;
        draft.duplicatePending = false;
        break;
      default:
    }  
  });
}