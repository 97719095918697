import styled from "styled-components";
export const Container = styled.div`
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

`;

export const Wrapper = styled.div`
  height: auto;

  .container {
    text-align: center;

    > img {
      margin-bottom: 40px;
    }

    h1 {
      font-size: 58px;
      color: #898989;
      text-align: center;
      margin-bottom: 20px;
    }
    h2 {
      font-size: 26px;
      color: #6A6A6A;
      font-weight: 400;
      margin-bottom: 70px;
    }
  }

  p, a {
    font-size: 14px;
    margin: 10px;
  }

  a {
    display: inline-block;
    margin: 0;
    border-radius: 3px;
    background-color: #50aff4;
    font-size: 18px;
    padding: 14px 40px;
    color: #ffffff;
    min-width: 200px;

    &:hover {
      color: #ffffff;
      opacity: 0.8;
      text-decoration: none;
    }
  }
`;

export const AlboomPayLogo = styled.div`
  text-align: center;
  position: absolute;
  bottom: 20px;
  width: 100%;
  left: 0;
  > img {
    width: 160px;
  }
`;