import styled from 'styled-components';

export const CustomerWrap = styled.div`
  margin-bottom: 10px;
  background-color: #f8f8f8;
  border-radius: 3px;
  padding: 12px 25px;
  display: flex;
  justify-content: space-between;

  @media screen and (max-width: 767px) {
    font-size: 12px;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: 5px;
    width: 100%;
  }

  p.name {
    align-items: center;
  }
`;

export const CustomerInfo = styled.div`
  display: flex;
  flex-direction: column;
  width: 70%;

  @media screen and (max-width: 767px) {
    width: 100%;
    align-items: center;
  }
`;

export const CustomerAmount = styled.div`
  display: flex;
  align-items: center;

  p.value {
    font-size: 20px;
    font-weight: 400;
    color: #383838;
  }
`;

export const CustomerActions = styled.div`
  display: flex;
  align-items: center;
  padding-left: 20px;

  @media screen and (max-width: 767px) {
    padding-left: 0px;
  }

  p.value {
    font-size: 18px;
    font-weight: 500;
    color: #696969;
  }
`;

export const CustomerName = styled.span`
  text-transform: none;
  font-weight: 400;
  font-size: 16px;
  color: #323232;
  margin-bottom: 3px;

  max-width: 30vw;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  @media screen and (max-width: 768px) {
    max-width: 60vw;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

export const CustomerEmail = styled.span`
  font-size: 14px;
  color: #616161;

  max-width: 30vw;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  @media screen and (max-width: 768px) {
    max-width: 60vw;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

export const Button = styled.button`
  padding: 6px;
  border-radius: 3px;
  background-color: transparent;

  &:hover {
    background-color: #ebebeb;
  }
  &.remove {
    &:hover {
      background-color: #f3dfdb;
    }
  }
`;
