import styled from 'styled-components';

export const ModalContent = styled.div`
  padding: 20px;
`;

export const TitleShare = styled.p`
  font-size: 16px;
  line-height: 19px;
  margin-top: 40px;
  margin-bottom: 15px;
  display: inline-block;
`;
export const LinkShare = styled.div`
  padding: 15px 10px;
  width: 100%;
  border-radius: 1px;
  border: 1px solid #d3d3d3;
  outline: none;
  margin-bottom: 30px;
  display: flex;
  justify-content: space-between;
  background-color: #fff;
  align-items: center;
  font-size: 14px;
  border-radius: 3px;

  > span {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    width: 80%;
    color: #383838;
  }
`;
export const WrapperCopy = styled.div`
  display: flex;
  align-items: center;

  button {
    border: none !important;
    background-color: transparent !important;
    outline: none !important;
  }

  > img {
    transition: all 0.1s ease-out;
    margin-right: 5px;
    opacity: ${props => (props.copied ? '1' : '0')};
  }

  span {
    color: ${props => (props.copied ? '#45AE70' : '#24b2cd')};
  }
`;

export const DescripitionShare = styled.p`
  font-size: 16px;
  line-height: 19px;
  margin-bottom: 15px;
  display: inline-block;
`;

export const SocialContainer = styled.div`
  display: flex;
  margin-bottom: 50px;
`;

export const SocialLink = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  width: 45px;
  height: 45px;
  border-radius: 5px;
  margin-right: 20px;
  box-shadow: 0px 0px 11px rgba(0, 0, 0, 0.1);  
`;

export const CopyLink = styled.span`
  color: #24b2cd;
  font-size: 14px;
  cursor: pointer;
  user-select: none;
`;

export const QrCodeContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 30px;

  @media screen and (max-width: 440px) {
    display: flex;
    align-items: center;
    flex-direction: column;
  }
`;

export const QrCodeImage = styled.img`
  box-shadow: 0px 0px 11px rgba(0, 0, 0, 0.1);
  padding: 5px;
  width: 132px;
  height: 132px;
  background-color: #fff;
`;

export const QrCodeInformation = styled.div`
  margin: 0 0 0 24px;
`;

export const QrCodeTitle = styled.div`
  color: #212121;
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 12px;
`;

export const QrCodeDownload = styled.a`
  display: flex;
  align-items: center;
  color: #50AFF4;
  margin: 0 0 12px 0;
  text-decoration: none;
  cursor: pointer;

  :hover {
    text-decoration: underline;
  }  

  img {
    width: 12px;
    margin-right: 5px;
  }
`;

export const QrCodeSugestion = styled.a`
  color: #878787;
  display: flex;
  align-items: center;
  margin: 0;
  text-decoration: none;
  cursor: pointer;

  :hover {
    color: #878787;
    text-decoration: underline;
  }

  img {
    width: 12px;
    margin-right: 5px;
  }
`;