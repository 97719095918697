import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import Modal from '@alboompro/boom-components/data-display/modal';
import Button from '@alboompro/boom-components/general/button';
import Input from '../../Input';
import PaymentType from '../../PaymentType';

import { paymentLinksPost, paymentLinksPut } from '../../../store/modules/paymentLinks/actions';

import { currencyFormat } from '../../../utils';
import { paymentLinkRequestSimple } from '../../../validators/schema';

import { StyledForm, FooterWrapper } from './styles';

export default function LinkPayment({ order, onClose }) {
  const { REACT_APP_REQUESTS_MAX_TOTAL: limit } = process.env;

  const dispatch = useDispatch();

  const { installments_no_fees } = useSelector(
    state => state.accounts.payments_settings.credit_card,
  );
  const { visible, data: newLink } = useSelector(state => state.modal);
  const { postPending } = useSelector(state => state.paymentLinks);
  const { payments_settings: paymentsSettings } = useSelector(state => state.accounts);

  function usePrevious(value) {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  }

  const prevPostPending = usePrevious(postPending);

  const [data, setData] = useState({
    name: '',
    amount: order.amount ? order.amount : '',
    amountFormatted: '',
    description: '',
    installments: order ? order.max_installments : installments_no_fees,
    payments_settings: order ? order.payments_settings : paymentsSettings,
  });

  const [edit, setEdit] = useState(false);

  const { name, amount, amountFormatted, description, payments_settings, installments } = data;

  useEffect(() => {
    if (order) {
      setData(d => ({
        ...d,
        id: order.id,
        name: order.name,
        amount: order.amount,
        description: order.description,
        installments: order.max_installments ? order.max_installments : order.installments.length,
      }));

      setEdit(true);
    } else {
      setData(d => ({
        ...d,
        name: '',
        amount: 0,
        amountFormatted: '',
        description: '',
        installments: installments_no_fees,
      }));

      setEdit(false);
    }
  }, [order]);

  useEffect(() => {
    if (prevPostPending && !postPending) {
      handleClose();
    }
  }, [postPending]);

  useEffect(() => {
    setData(d => ({
      ...d,
      amount,
      amountFormatted: amount ? currencyFormat(amount / 100) : '',
    }));
  }, [amount]);

  function handleSubmit(formData) {

    if (!edit || newLink) {
      dispatch(paymentLinksPost({ ...formData, ...data }));
    } else {
      dispatch(paymentLinksPut({ ...formData, ...data }));
    }
  }

  function handleAmount({ target: { value: v } }) {
    const value = parseInt(v.replace(/[^\d]/g, '')) || 0;
    const amount = value > parseInt(limit) ? parseInt(limit) : value;

    return setData({ ...data, amount });
  }

  function handleInput({ target: { name, value } }) {
    return setData({ ...data, [name]: value });
  }

  function handlePaymentType(type) {
    setData(d => ({
      ...d,
      installments: type.ccFee,
      payments_settings: type.method,
    }));
  }

  function handleClose() {
    onClose();

    setData(d => ({
      ...d,
      name: '',
      amount: 0,
      amountFormatted: '',
      description: '',
      installments: null,
    }));
  }

  return (
    <Modal
      title={edit ? 'Editar link de pagamento' : 'Criar link de pagamento'}
      visible={visible}
      backdropClosable={false}
      onClose={() => handleClose()}
      width={620}
      headerStyle={{
        borderRadius: '3px 3px 0 0',
      }}
      bodyStyle={{
        padding: '25px 25px 0',
        backgroundColor: '#f6fafe',
        position: 'relative',
        minHeight: '550px',
        borderRadius: '0 0 3px 3px',
      }}
    >
      {
        <StyledForm onSubmit={handleSubmit} schema={paymentLinkRequestSimple}>
          <Input
            name="name"
            value={name}
            placeholder="Escolha um nome para este link"
            label="Defina um nome para o link de pagamento"
            onChange={handleInput}
          />

          <Input name="amount" value={amountFormatted} label="Valor" onChange={handleAmount} />

          <Input
            name="description"
            value={description}
            placeholder="Descreva o produto ou serviço"
            label="Descrição"
            onChange={handleInput}
            maxlength={100}
          />

          <PaymentType
            initialValues={{ method: payments_settings, ccFee: installments }}
            handlePaymentType={handlePaymentType}
          />

          <FooterWrapper>
            <Button onClick={() => handleClose()} className="buttonDefault">
              Cancelar
            </Button>
            <Button
              htmlType="submit"
              className="buttonBlue"
              loading={postPending}
              disabled={postPending}
            >
              {edit ? 'Salvar' : 'Criar Link'}
            </Button>
          </FooterWrapper>
        </StyledForm>
      }
    </Modal>
  );
}
