import Wizard from '../../components/Wizard';
import React, { useState, useEffect } from 'react';
import {
  WrapperLogo,
  Logo,
  Title,
  Subtitle,
  TypeTitle,
  AccountType,
  SubCategory,
  Footer,
} from './styles';
import { Field } from 'react-final-form';
import { MdInfoOutline } from 'react-icons/md';
import Select from '../../components/Select';
import Checkbox from '../../components/Checkbox';
import zipcodes from '../../services/core/http/zipcodes';
import RadioInput from '../../components/RadioInput';
import RadioLabel from '../../components/RadioLabel';
import Notification from '../../components/Notification'
import moment from 'moment';

export default function Wizard1() {
  const [doc_type, setDocType] = useState('pf');
  const [term, setTerm] = useState(false);

  useEffect(() => {
    var dt_init = document.getElementsByName('doc_type');
    dt_init = dt_init ? Array.from(dt_init).find(el => el.checked) : '';
    dt_init && setDocType(dt_init.value);
    dt_init && window.setFormValue('doc_type', 'dt_init.value');
  }, []);

  function maskSsn(value) {
    if (!value) return value;
    const onlyNums = value.replace(/[^\d]/g, '');
    if (onlyNums.length <= 3) return onlyNums;

    if (onlyNums.length <= 6) return `${onlyNums.slice(0, 3)}.${onlyNums.slice(3, 6)}`;

    if (onlyNums.length <= 9)
      return `${onlyNums.slice(0, 3)}.${onlyNums.slice(3, 6)}.${onlyNums.slice(6, 9)}`;

    return `${onlyNums.slice(0, 3)}.${onlyNums.slice(3, 6)}.${onlyNums.slice(
      6,
      9,
    )}-${onlyNums.slice(9, 11)}`;
  }

  function maskZipcode(value) {
    if (!value) return value;
    const onlyNums = value.replace(/[^\d]/g, '');
    if (onlyNums.length <= 5) return onlyNums;

    if (onlyNums.length === 8) {
      zipcodes.get(onlyNums).then(response => {
        if (response.status === 200) {
          const { logradouro, bairro, localidade, uf, ibge, gia } = response.data;
          window.setFormValue('city', localidade);
          window.setFormValue('neighborhood', bairro);
          window.setFormValue('street', logradouro);
          window.setFormValue('state', uf);
          window.setFormValue('country', 'Brasil');
          window.setFormValue('ibge', ibge);
          window.setFormValue('gia', gia);
        }
      });
    }

    if (onlyNums.length <= 8) return `${onlyNums.slice(0, 5)}-${onlyNums.slice(5, 8)}`;

    return `${onlyNums.slice(0, 5)}-${onlyNums.slice(5, 8)}`;
  }

  function maskDob(value) {
    if (!value) return value;
    const onlyNums = value.replace(/[^\d]/g, '');
    if (onlyNums.length <= 2) {
      if (onlyNums > 31 || onlyNums < 0) return 31;
      return onlyNums;
    }

    let months = onlyNums.slice(2, 4);
    if (onlyNums.length <= 4) {
      if (months > 12 || months < 0 || (months.length === 2 && months < 1)) months = 12;
      return `${onlyNums.slice(0, 2)}/${months}`;
    }

    if (onlyNums.length === 6) {
      let years = onlyNums.slice(4, 6);
      if (years < 19 || years > 20) years = 19;
      return `${onlyNums.slice(0, 2)}/${months}/${years}`;
    }

    if (onlyNums.length === 8) {
      let years = onlyNums.slice(4, 8);
      const currentDate = moment(new Date()).format('YYYY');
      if (years < 1900 || years > currentDate) years = currentDate;
      return `${onlyNums.slice(0, 2)}/${months}/${years}`;
    }

    return `${onlyNums.slice(0, 2)}/${onlyNums.slice(2, 4)}/${onlyNums.slice(4, 8)}`;
  }

  function maskRn(value) {
    if (!value) return value;
    const onlyNums = value.replace(/[^\d]/g, '');
    if (onlyNums.length <= 2) return onlyNums;

    if (onlyNums.length <= 5) return `${onlyNums.slice(0, 2)}.${onlyNums.slice(2, 5)}`;

    if (onlyNums.length <= 8)
      return `${onlyNums.slice(0, 2)}.${onlyNums.slice(2, 5)}.${onlyNums.slice(5, 8)}`;

    if (onlyNums.length <= 12)
      return `${onlyNums.slice(0, 2)}.${onlyNums.slice(2, 5)}.${onlyNums.slice(
        5,
        8,
      )}/${onlyNums.slice(8, 12)}`;

    return `${onlyNums.slice(0, 2)}.${onlyNums.slice(2, 5)}.${onlyNums.slice(
      5,
      8,
    )}/${onlyNums.slice(8, 12)}-${onlyNums.slice(12, 14)}`;
  }

  function fullname(value) {
    return /^[A-Za-záàâãéèêíïóôõöúçñ]{1,}(?: [A-Za-záàâãéèêíïóôõöúçñ]{1,}){1,}$/.test(value)
      ? undefined
      : 'Preencha o Nome e Sobrenome sem a utilização de simbolos ou caracteres especiais';
  }

  function validateSsn(value) {
    return /^\d{3}\.\d{3}\.\d{3}-\d{2}$/.test(value) ? undefined : 'Número de CPF inválido.';
  }

  function validateRn(value) {
    return /^\d{2}\.\d{3}\.\d{3}\/\d{4}-\d{2}$/.test(value)
      ? undefined
      : 'Número de CNPJ inválido.';
  }

  function validateDob(value) {
    return /^\d{2}\/\d{2}\/\d{4}$/.test(value) ? undefined : 'Data inválida';
  }

  function validateZipCode(value) {
    return /^\d{5}-\d{3}$/.test(value) ? undefined : 'Cep inválido';
  }

  function Error({ name }) {
    return (
      <Field
        name={name}
        subscribe={{ touched: true, error: true }}
        render={({ meta: { touched, error } }) => (touched && error ? <span>{error}</span> : null)}
      />
    );
  }

  const countries = [
    {
      label: 'Brasil',
      value: 'brasil',
      icon: 'https://bifrost.alboompro.com/core/flags/br.svg',
    },
  ];

  return (
    <Wizard.Page>
      <WrapperLogo>
        <Logo
          src="https://bifrost.alboompro.com/products/alboompay/AlboomPay2022-full.svg"
          alt="Logo Alboom Pay"
        />
      </WrapperLogo>
      <div className="row justify-content-center title-pages">
        {/* <div className="col-8 col-lg-8">
          <Title>Configurar sua conta Alboom Pay é simples e rápida.</Title>
        </div> */}
        <div className="col-lg-12 text-center">
          <Subtitle>Complete seus dados.</Subtitle>
        </div>
      </div>

      <div className="row justify-content-center">
        <div className="col-lg-7">
          <TypeTitle>Selecione o tipo de conta</TypeTitle>
          <AccountType>
            <div className="row centered">
              <div className="col-6">
                <RadioInput
                  name="doc_type_pf"
                  type="radio"
                  value="pf"
                  label="  "
                  checked={doc_type === 'pf'}
                  onChange={e => {
                    setDocType('pf');
                    window.setFormValue('doc_type', 'pf');
                  }}
                >
                  <RadioLabel
                    title="Pessoa Física (CPF)"
                    label="Sem CNPJ"
                  />
                </RadioInput>
              </div>

              <div className="col-6">
                <RadioInput
                  name="doc_type_pj"
                  type="radio"
                  value="pj"
                  label="  "
                  checked={doc_type === 'pj'}
                  onChange={e => {
                    setDocType('pj');
                    window.setFormValue('doc_type', 'pj');
                  }}
                >
                  <RadioLabel
                    title="Pessoa Jurídica (CNPJ)"
                    label="MEI, LTDA, S/A, SS"
                  />
                </RadioInput>
              </div>
            </div>
          </AccountType>
        </div>
      </div>
      {doc_type === 'pf' ? (
        <div className="row justify-content-center">
          <div className="col-lg-7">
            <label className="input-text">
              <Error name="fullname" />
              <Field
                className="input-field"
                name="fullname"
                component="input"
                type="text"
                validate={fullname}
                maxLength="200"
                required
              />
              <span className="label">Nome completo</span>
            </label>

            <label className="input-text">
              <Field name="display_name" component="input" type="text" maxLength="50" required className="input-field" />
              <span className="label">
                Nome do negócio (Nome Fantasia)
                <div>
                  <MdInfoOutline
                    size={20}
                    style={{ fill: '#373737', width: 15, height: 15, marginTop: -4 }}
                  />
                  <div className="message">Nome de exibição</div>
                </div>
              </span>
            </label>

            <div className="row justify-content-center">
              <div className="col-7 col-lg-7 pr">
                <label className="input-text">
                  <Error name="ssn" />
                  <Field
                    name="ssn"
                    component="input"
                    type="text"
                    parse={maskSsn}
                    validate={validateSsn}
                    required
                    autoComplete="off"
                    minLength="14"
                    className="input-field"
                  />
                  <span className="label">CPF</span>
                </label>
              </div>

              <div className="col-5 col-lg-5 pl">
                <label className="input-text">
                  <Error name="dob" />
                  <Field
                    className="input-field"
                    validate={validateDob}
                    name="dob"
                    component="input"
                    type="text"
                    parse={maskDob}
                    placeholder="dd/mm/aaaa"
                    required
                    autoComplete="off"
                  />
                  <span className="label">
                    Data de Nasc.
                    <div>
                      <div className="message">apenas numeros</div>
                    </div>
                  </span>
                </label>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="row justify-content-center">
          <div className="col-lg-7">
          <Notification
              title="Importante!"
            >
              Prossiga apenas se você possui uma conta bancária jurídica com o mesmo CNPJ.
            </Notification>
            <label className="input-text">
              <Field name="company_name" component="input" type="text" maxLength="200" required className="input-field" />
              <span className="label">Razão Social</span>
            </label>

            <label className="input-text">
              <Field name="display_name" component="input" type="text" maxLength="50" required className="input-field" />
              <span className="label">
                Nome do negócio (Nome Fantasia)
                <div>
                  <MdInfoOutline
                    size={20}
                    style={{ fill: '#373737', width: 15, height: 15, marginTop: -4 }}
                  />
                  <div className="message">apenas letras, números e sublinhado</div>
                </div>
              </span>
            </label>

            <label className="input-text">
              <Field name="responsible_name" component="input" type="text" maxLength="200" className="input-field" />
              <span className="label">Nome do responsável (opcional)</span>
            </label>

            <label className="input-text">
              <Error name="rn" />
              <Field
                name="rn"
                component="input"
                type="text"
                parse={maskRn}
                validate={validateRn}
                required
                autoComplete="off"
                minLength="18"
                className="input-field"
              />
              <span className="label">CNPJ</span>
            </label>
          </div>
        </div>
      )}

      <div className="row justify-content-center">
        <div className="col-lg-7">
          <SubCategory className="text-uppercase font-weight-bold">Endereço</SubCategory>
          <div className="row justify-content-center">
            <div className="col-5 col-lg-5 pr">
              <label className="input-text">
                <Field name="country">
                  {props => (
                    <Select
                      options={countries}
                      initialValue="brasil"
                      className="CountrySelectWizard"
                      disabled
                      onChange={value => {
                        props.input.onChange(value.value);
                      }}
                    />
                  )}
                </Field>
                <span className="label" style={{ marginBottom: '0.3rem' }}>
                  País
                </span>
                <Error name="country" />
              </label>
            </div>
            <div className="col-7 col-lg-7 pl">
              <label className="input-text">
                <Error name="zipcode" />
                <Field
                  name="zipcode"
                  component="input"
                  type="text"
                  validate={validateZipCode}
                  parse={maskZipcode}
                  minLength="9"
                  required
                  className="input-field"
                  style={{ marginTop: '-3px'}}
                />
                <span className="label">
                  Cep
                  <div>
                    <MdInfoOutline
                      size={20}
                      style={{ fill: '#373737', width: 15, height: 15, marginTop: -4 }}
                    />
                    <div className="message">
                      Informe o Cep para carregar o endereço automaticamente.
                    </div>
                  </div>
                </span>
              </label>
            </div>
          </div>
        </div>
      </div>

      <div className="row justify-content-center">
        <div className="col-lg-7">
          <div className="row justify-content-center">
            <div className="col-9 col-lg-9 pr">
              <label className="input-text">
                <Field name="street" component="input" type="text" maxLength="200" required className="input-field" />
                <span className="label">Rua</span>
              </label>
            </div>
            <div className="col-3 col-lg-3 pl">
              <label className="input-text">
                <Field
                  name="street_number"
                  component="input"
                  type="text"
                  maxLength="200"
                  required
                  className="input-field"
                />
                <span className="label">Número</span>
              </label>
            </div>
          </div>
        </div>
      </div>

      <div className="row justify-content-center">
        <div className="col-lg-7">
          <div className="row justify-content-center">
            <div className="col-6 col-lg-6 pr">
              <label className="input-text">
                <Field name="complement" component="input" type="text" maxLength="200" className="input-field" />
                <span className="label">Complemento</span>
              </label>
            </div>
            <div className="col-6 col-lg-6 pl">
              <label className="input-text">
                <Field name="neighborhood" component="input" type="text" maxLength="200" required className="input-field" />
                <span className="label">Bairro</span>
              </label>
            </div>
          </div>

          <div className="row justify-content-center">
            <div className="col-6 col-lg-6 pr">
              <label className="input-text">
                <Field name="city" component="input" type="text" maxLength="200" required className="input-field" />
                <span className="label">Cidade</span>
              </label>
            </div>
            <div className="col-6 col-lg-6 state pl">
              <label className="input-text">
                <Field name="state" component="input" type="text" maxLength="200" required className="input-field" />
                <span className="label">Estado</span>
              </label>
            </div>
          </div>
        </div>
      </div>

      <div className="row justify-content-center">
        <div className="col-lg-7">
          <Footer>
            <Field name="term" className="terms" required>
              {props => {
                setTerm(props.input.value);
                return (
                  <Checkbox
                    name={props.input.name}
                    required={props.required}
                    label=" "
                    value={term}
                    onChange={e => {
                      setTerm(!term);
                      return props.input.onChange(!term);
                    }}
                    infoSize={18}
                  >
                    <p>
                      {' '}
                      Li e aceito os{' '}
                      <a href="https://www.alboompro.com/terms-of-use/" target="blank">
                        termos de uso
                      </a>{' '}
                      da Alboom.
                    </p>
                  </Checkbox>
                );
              }}
            </Field>
          </Footer>
        </div>
      </div>
    </Wizard.Page>
  );
}
