import React from 'react';
import accountFail from '../../assets/static/images/not-created/not-created.png';
import Mail from '../../assets/static/images/icons/mail.png';

import { Container } from './styles';

export default function CreateAccountFail() {
  return (
    <>
      <Container>
        <div className="row justify-content-center text-center">
          <div className="col-11 col-lg-6">
            <img src={accountFail} alt="account fail" className="image" />
          </div>
        </div>

        <div className="row justify-content-center">
          <div className="col-11 col-lg-5">
            <h1>Ops! Não foi possível criar a sua conta.</h1>
          </div>
        </div>

        <div className="row justify-content-center">
          <div className="col-11 col-lg-6">
            <h3 className="mb-5">
              Infelizmente, não conseguimos concluir seu cadastro. Por favor, entre em contato com
              nossa equipe:
            </h3>

            <h3>
              <img src={Mail} alt="Mail" className="pr-4" />
              <span>alboompay@alboompro.com</span>
            </h3>
          </div>
        </div>
      </Container>
    </>
  );
}
