import React, { useState, useEffect } from 'react';

import Select from '../Select';
import Checkbox from '../Checkbox';

import { Methods, Settings, Label, SelectContainer } from './styles';

export default function PaymentType({ initialValues, handlePaymentType }) {
  const { method, ccFee } = initialValues;
  const [data, setData] = useState({ method, ccFee });
  const [disabledFeeOptions, setDisabledFeeOptions] = useState(!data.method.credit_card.enabled);
  const [disabledInvoiceOptions, setDisabledInvoiceOptions] = useState(
    !data.method.invoice.enabled,
  );

  const ccFeeOptions = [
    { value: 1, label: 'em até 1x sem juros' },
    { value: 2, label: 'em até 2x sem juros' },
    { value: 3, label: 'em até 3x sem juros' },
    { value: 4, label: 'em até 4x sem juros' },
    { value: 5, label: 'em até 5x sem juros' },
    { value: 6, label: 'em até 6x sem juros' },
    { value: 7, label: 'em até 7x sem juros' },
    { value: 8, label: 'em até 8x sem juros' },
    { value: 9, label: 'em até 9x sem juros' },
    { value: 10, label: 'em até 10x sem juros' },
    { value: 11, label: 'em até 11x sem juros' },
    { value: 12, label: 'em até 12x sem juros' },
  ];

  const invoiceExpirationOptions = [
    { value: 3, label: '3 dias' },
    { value: 5, label: '5 dias' },
    { value: 7, label: '7 dias' },
    { value: 10, label: '10 dias' },
  ];

  useEffect(() => {
    handlePaymentType(data);
  }, [data]);

  function handleMethod(type, value) {
    let newValue = value;

    if (type === 'method') {
      if (method.credit_card && method.invoice) {
        switch (value) {
          case 1:
            newValue = {
              ...data.method,
              credit_card: {
                ...data.method.credit_card,
                enabled: !data.method.credit_card.enabled,
              },
            };
            setDisabledFeeOptions(data.method.credit_card.enabled);
            break;
          case 2:
            newValue = {
              ...data.method,
              invoice: {
                ...data.method.invoice,
                enabled: !data.method.invoice.enabled,
              },
            };
            setDisabledInvoiceOptions(data.method.invoice.enabled);
            break;
          case 3:
            newValue = {
              ...data.method,
              pix: {
                ...data.method.pix,
                enabled: !data.method.pix?.enabled ?? true,
              },
            };
            break;
          default:
            break;
        }
      }
    }

    setData(data => ({ ...data, [type]: newValue }));
  }

  return (
    <>
      <Label>Métodos de pagamento</Label>
      <Methods>
        <Checkbox
          checked={data.method.credit_card.enabled}
          name="credit_card"
          infoSize={18}
          onChange={() => handleMethod('method', 1)}
          labelClassName="checkbox"
        >
          <p>Cartão de crédito</p>
        </Checkbox>

        <Checkbox
          checked={data.method.invoice.enabled}
          name="invoice"
          infoSize={18}
          onChange={() => handleMethod('method', 2)}
          labelClassName="checkbox method"
        >
          <p>Boleto</p>
        </Checkbox>

        <Checkbox
          checked={data.method.pix ? data.method.pix.enabled : false}
          name="pix"
          infoSize={18}
          onChange={() => handleMethod('method', 3)}
          labelClassName="checkbox method"
        >
          <p>Pix</p>
        </Checkbox>
      </Methods>
      <Settings>
        <SelectContainer>
          <Label>Parcelamento no cartão de crédito</Label>
          <Select
            icon="MdKeyboardArrowDown"
            size={24}
            name="ccFeeOptions"
            options={ccFeeOptions}
            initialValue={data.ccFee}
            placeholder="em até 12x sem juros"
            onChange={obj => handleMethod('ccFee', obj.value)}
            isDisabled={disabledFeeOptions}
          />
        </SelectContainer>

        <SelectContainer>
          <Label>Vencimento do boleto</Label>
          <Select
            icon="MdKeyboardArrowDown"
            size={24}
            name="invoiceExpirationOptions"
            options={invoiceExpirationOptions}
            initialValue={data.method.invoice.expiration_in_days}
            onChange={obj =>
              setData(data => ({
                ...data,
                method: {
                  ...data.method,
                  invoice: { ...data.method.invoice, expiration_in_days: obj.value },
                },
              }))
            }
            isDisabled={disabledInvoiceOptions}
          />
        </SelectContainer>
      </Settings>
    </>
  );
}
