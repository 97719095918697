import React from 'react';

import { Container, Tooltip } from './styles';

export default function ButtonTooltip({ tooltip, children, ...rest }) {
  return <Container {...rest}>
    {children}
    <Tooltip>{tooltip}</Tooltip>
    </Container>;
}
