import React, { useRef, useEffect } from 'react';

import Modal from '@alboompro/boom-components/data-display/modal';
import Button from '@alboompro/boom-components/general/button';

import { FooterContent, ModalContent } from './styles';

export default function Delete({ title, description, loading, visible, onClose, onConfirm }) {
  function usePrevious(value) {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  }

  const prevLoading = usePrevious(loading);

  useEffect(() => {
    if (prevLoading && !loading) {
      onClose();
    }
  }, [loading]);

  return (
    <Modal
      title={title}
      visible={visible}
      // closeButton={false}
      onClose={() => onClose()}
      // headerStyle={{ padding: '20px' }}
      bodyStyle={{ backgroundColor: '#f6fafe' }}
      backdropStyle={{ backgroundColor: 'rgba(0, 0, 0, 0.7)' }}
      backdropClosable={false}
      footer={
        <FooterContent>
          <Button textStyle={{ color: '#383838' }} onClick={() => onClose()}>
            Cancelar
          </Button>
          <Button
            type="primary"
            style={{ backgroundColor: '#ef5d5d' }}
            loading={loading}
            onClick={() => onConfirm()}
          >
            Tenho certeza
          </Button>
        </FooterContent>
      }
    >
      <ModalContent>
        {typeof description === 'string' ? <p>{description}</p> : description}
      </ModalContent>
    </Modal>
  );
}
