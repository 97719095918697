import Wizard from '../../components/Wizard';
import React, { useEffect, useState } from 'react';
import { WrapperLogo, Logo, Subtitle } from './styles';
import { Field } from 'react-final-form';
import Banks from '../../services/core/http/banks';
import Select from '../../components/Select';
import { useSelector } from 'react-redux';

export default function Wizard3() {
  const [banks, setBanks] = useState([]);
  let doc =
    window.getFormValues.doc_type == 'pj' ? window.getFormValues.rn : window.getFormValues.ssn;
  let name =
    window.getFormValues.doc_type == 'pj'
      ? window.getFormValues.company_name
      : window.getFormValues.fullname;
  const { alprotoken } = useSelector(state => state.auth);
  window.setFormValue('legal_name_doc', doc);
  window.setFormValue('legal_name', name);

  const bankAccountTypes = [
    { value: 'conta_corrente', label: 'Conta corrente' },
    { value: 'conta_corrente_conjunta', label: 'Conta corrente conjunta' },
    { value: 'conta_poupanca', label: 'Conta poupança' },
    { value: 'conta_poupanca_conjunta', label: 'Conta poupança conjunta' },
  ];

  function legalname(value) {
    return /^[A-Za-záàâãéèêíïóôõöúçñ0-9]{1,}(?: [A-Za-záàâãéèêíïóôõöúçñ0-9]{1,}){1,}$/.test(value)
      ? undefined
      : 'Preencha o Nome e Sobrenome sem a utilização de simbolos ou caracteres especiais';
  }

  function Error({ name }) {
    return (
      <Field
        name={name}
        subscribe={{ touched: true, error: true }}
        render={({ meta: { touched, error } }) => (touched && error ? <span>{error}</span> : null)}
      />
    );
  }

  function maskSsn(value) {
    if (!value) return value;
    const onlyNums = value.replace(/[^\d]/g, '');
    if (onlyNums.length <= 3) return onlyNums;

    if (onlyNums.length <= 6) return `${onlyNums.slice(0, 3)}.${onlyNums.slice(3, 6)}`;

    if (onlyNums.length <= 9)
      return `${onlyNums.slice(0, 3)}.${onlyNums.slice(3, 6)}.${onlyNums.slice(6, 9)}`;

    return `${onlyNums.slice(0, 3)}.${onlyNums.slice(3, 6)}.${onlyNums.slice(
      6,
      9,
    )}-${onlyNums.slice(9, 11)}`;
  }

  function maskBankAccount(value) {
    if (!value) return value;
    const onlyNums = value.replace(/[^\d]/g, '');
    return onlyNums;
  }

  useEffect(() => {
    async function fetchData() {
      const request = await Banks.list(alprotoken);
      const banks = request.data
        ? request.data.banks.map(({ name, bank_code, icon }) => {
            return {
              value: bank_code,
              label: name,
              icon,
              bank_code,
            };
          })
        : [];
      setBanks(banks);
    }
    fetchData();
  }, [alprotoken]);

  return (
    <Wizard.Page>
      <WrapperLogo>
        <Logo
          src="https://bifrost.alboompro.com/products/alboompay/AlboomPay2022-full.svg"
          alt="Logo Alboom Pay"
        />
      </WrapperLogo>
      <div className="row justify-content-center title-pages">
        <div className="col-lg-12 text-center">
          <Subtitle>
            Adicione uma conta bancária para transferir seu dinheiro do Alboom Pay
          </Subtitle>
        </div>
      </div>

      <div className="row justify-content-center">
        <div className="col-lg-7">
          <label className="input-text">
            <Field name="bank_code" required>
              {props => {
                return (
                  <Select
                    options={banks}
                    required
                    initialValue={props.input.value}
                    onChange={value => {
                      props.input.onChange(value.value);
                    }}
                  />
                );
              }}
            </Field>
            <span className="label">Banco</span>
          </label>

          <label className="input-text">
            <Error name="legal_name" />
            <Field
              name="legal_name"
              component="input"
              type="text"
              validate={legalname}
              maxLength="30"
              required
              disabled
              className="disabled input-field"
            />
            <span className="label">
              {window.getFormValues.doc_type === 'pj' ? 'Razão Social' : 'Nome do Titular'}
            </span>
          </label>

          <label className="input-text">
            <Field
              name="legal_name_doc"
              component="input"
              type="text"
              parse={maskSsn}
              required
              minLength="14"
              disabled
              className="disabled input-field"
            />
            <span className="label">CPF/CNPJ</span>
          </label>

          <div className="row justify-content-between">
            <div className="number">
              <label className="input-text">
                <Field
                  name="bank_agency"
                  component="input"
                  type="text"
                  parse={maskBankAccount}
                  maxLength="4"
                  required
                  className="input-field"
                />
                <span className="label">Número da agência</span>
              </label>
            </div>
            <div className="digit">
              <label className="input-text">
                <Field
                  name="bank_agency_vd"
                  component="input"
                  type="text"
                  parse={maskBankAccount}
                  maxLength="1"
                  className="input-field"
                />
                <span className="label">Dígito (Se houver)</span>
              </label>
            </div>
          </div>

          <div className="row justify-content-between">
            <div className="number">
              <label className="input-text">
                <Field
                  name="bank_account"
                  component="input"
                  type="text"
                  parse={maskBankAccount}
                  required
                  maxLength="11"
                  className="input-field"
                />
                <span className="label">Número da conta</span>
              </label>
            </div>
            <div className="digit">
              <label className="input-text">
                <Field
                  name="bank_account_vd"
                  component="input"
                  type="text"
                  parse={maskBankAccount}
                  maxLength="1"
                  required
                  className="input-field"
                />
                <span className="label">Dígito</span>
              </label>
            </div>
          </div>
          <label className="input-text">
            <Field name="bank_account_type" required>
              {props => {
                return (
                  <Select
                    options={bankAccountTypes}
                    initialValue={props.input.value || 'conta_corrente'}
                    onChange={value => {
                      props.input.onChange(value.value);
                    }}
                  />
                );
              }}
            </Field>
            <span className="label">Tipo da conta</span>
          </label>
        </div>
      </div>
    </Wizard.Page>
  );
}
