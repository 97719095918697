import styled, { css } from "styled-components"

export const DataWrapper = styled.div`
  display: flex;
  padding: 0 30px;
  font-size: 0.8125rem; // 13px
  line-height: 130%;
  
  :not(:last-of-type) {
    border-bottom: 1px solid #e8e8e8;
  }

  @media screen and (max-width: 767px) {
    flex-direction: column;
    gap: 8px;
    padding: 18px 0;
    position: relative;
    font-size: 0.6875rem; // 11px
  }
`

export const MobileDateCard = styled.div`
  display: none;

  font-size: 0.625rem; // 10px
  color: #2E2E2E;
  
  .time {
    color: #5E5E5E;
  }

  @media screen and (max-width: 767px) {
    display: flex;
    align-items: center;
    gap: 8px;

    position: absolute;
    left: 0;
    top: 18px;
  }
`

export const DateCard = styled.div`
  padding: 25px 16px;
  height: 100%;
  width: ${({ colWidth }) => colWidth};

  display: flex;
  align-items: center;
  gap: 8px;

  font-weight: 500;
  
  .date {
    min-width: 80px;
    text-align: left;
  }

  .time {
    min-width: 35px;
    text-align: left;
    font-weight: 400;
    color: #686868;
  }

  @media screen and (max-width: 767px) {
    display: none;
  }
`

export const DescriptionCard = styled.div`
  padding: 8px 16px;
  font-weight: 500;
  color: #262626;

  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 4px;
  flex: 1;

  .descriptionWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  
  .buttonsWrapper {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 20px;
  }
  
  .client-name {
    color: #5E5E5E;
    font-weight: 400;
    font-size: 0.6875rem; //11px
  }

  @media screen and (max-width: 767px) {
    width: 100%;
    gap: 0;
    order: 2;
    padding: 0;

    flex-direction: column;
    align-items: flex-start;
    gap: 8px;

    .client-name {
      font-size: 0.625rem; // 10px
    }

    .descriptionWrapper {
      gap: 4px;
    }

    .buttonsWrapper {
      width: 100%;
      gap: 10px;
      justify-content: space-between;
    }
  }

  @media screen and (min-width: 768px) and (max-width: 1024px) {
    .buttonsWrapper {
      flex-direction: column;
      gap: 4px;
    }

    .descriptionWrapper {
      gap: 4px;
      max-width: 60%;
    }
  }
`

export const InstallmentsDetailsButton = styled.button`
  background-color: #F5F5F5;
  color: #4B4B4B;
  border-radius: 4px;
  border: 0;
  padding: 6px 20px;
  min-height: 30px;
  min-width: 122px;

  @media screen and (max-width: 767px) {
    order: 2;
    padding: 0;
    min-height: 0px;
    min-width: 0px;
    width: 98px;
    height: 22px;
  }
`

export const PaymentDetailsButton = styled.button`
  background-color: transparent;
  border: 0;
  color: #50AFF4;

  display: flex;
  align-items: center;
  gap: 6px;

  img {
    width: 18px;
    height: 18px;
    margin-bottom: 2px;
  }

  @media screen and (max-width: 767px) {
    order: 1;

    img {
      width: 15px;
      height: 15px;
      margin-bottom: 0;
    }

    span {
      min-width: 88px;
    }
  }
`

export const PaymentWrapper = styled.div`
  display: flex;
  align-items: center;
  font-size: 0.875rem; //14px
  gap: 4px;
  margin-bottom: 2px;

  .paymentDetails {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #D9D9D9;
    min-width: 82px;
    padding: 2px 8px;
    border-radius: 20px;
    margin-left: 8px;
    font-size: 0.625rem; //10px
    color: #414141;
    text-transform: uppercase;
  }
  
  .iconWrapper {
    position: relative;
    margin: 0 0 -2px 4px;

    img {
      min-width: 15px;
      min-height: 15px;
    }

    &:hover {
      .tooltip {
        opacity: 1;
        transition: 0.2s opacity 0.3s cubic-bezier(0.55, 0.3, 0.5, 0.9);
      }
    }
  }

  .tooltip {
    position: absolute;
    right: 0;
    transform: translate(50%, 10%);
    background-color: #595959;
    border-radius: 3px;
    text-align: center;
    padding: 4px 16px;
    color: #ffffff;
    font-size: 0.625rem; // 10px
    letter-spacing: 0.4px;
    font-weight: 400;
    opacity: 0;
    pointer-events: none;
    z-index: 99;
    white-space: nowrap;
  }

  @media screen and (max-width: 767px) {
    flex-direction: column;
    align-items: flex-start;
    line-height: 130%;
    font-size: 0.8125rem; //13px

    .tooltip {
      transform: translate(0%, 20%);
      width: 180px;
      text-align: justify;
      padding: 4px 10px;
      white-space: normal;
    }

    .paymentDetails {
      margin-left: auto;
      max-height: 17px;
      font-size: 0.5625rem; //9px
      min-width: 80px;
    }
  }
`

export const ValueCard = styled.div`
  width: ${({ colWidth }) => colWidth};
  padding: 8px 16px;
  
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 4px;
  
  color: ${({ profit }) => profit ? "#44AE70" : "#262626"};
  font-weight: 500;

  :last-of-type {
    padding-right: 0;
  }

  span {
    ${({ profit }) => css`
      ::before {
        content: "${profit ? "+" : "-"}"
      }
    `}
  }

  img {
    margin-bottom: 2px;
  }

  @media screen and (max-width: 767px) {
    width: 100%;
    padding: 0;
    order: 1;
    justify-content: flex-start;

    img {
      display: none;
    }

    span {
      margin-left: auto;
      ${({ profit }) => css`
        ::before {
          content: "${profit ? "+R$ " : "-R$ "}"
        }
      `}
    }
  }
`