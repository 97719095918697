import styled from 'styled-components';

export const Container = styled.div`
  margin-top: 20px;
  border: 1px solid #ebebeb;
  border-radius: 10px;
  background: #fff;
  position: relative;

  .react-select__control {
    border: none;
  }
`;

export const ReportTable = styled.div`
  position: relative;
  .wrap {
    /* height: ${props => (props.height ? props.height : 'calc(100vh - 180px);')}; */
    min-height: 400px;
    overflow: auto;
  }
  table {
    width: 100%;
  }
`;

export const TableTitle = styled.h5`
  font-weight: normal;
  font-size: 20px;
  margin: 0;
  width: 30%;

  @media screen and (max-width: 767px) {
    font-weight: 500;
    font-size: 16px;
    padding: 0px !important;
    width: 100%;
  }
`;

export const Thead = styled.thead`
  border-bottom: 1px solid #F1F1F1;
  position: sticky;
  top: 0;
  z-index: 1;
  background: #fff;

  tr {
    padding-left: 10px;
  }

  th.ActivityDescription {
    padding-left: 30px;

    @media screen and (max-width: 767px) {
      padding-left: 10px;
    }
  }

  th.ActivityDate {
    text-align: center;

    @media screen and (max-width: 767px) {
      display: none;
    }
  }

  th.ActivityAmount {
    text-align: right;
    padding-right: 30px;

    @media screen and (max-width: 767px) {
      padding-right: 15px;
    }
  }

  th {
    font-size: 16px;
    padding-bottom: 12px;
    font-weight: normal;

    @media screen and (max-width: 767px) {
      font-size: 10px;
      padding-bottom: 6px;
    }
  }
`;

export const Tbody = styled.tbody`
  width: 100%;

  td {
    padding: 15px 10px;
    font-size: 14px;
    line-height: 1.2em;
    color: #262626;
    border-bottom: 1px solid #F1F1F1;

    @media screen and (max-width: 767px) {
      padding: 15px 5px 15px 10px;
    }

    p.ActivityTitle {
      font-size: 18px;
      color: #262626;
      margin: 0 0 5px 0;

      @media screen and (max-width: 767px) {
        display: none;
      }
    }

    p.ActivityDate {
      font-size: 13px;
      color: #4D4D4D;
      text-align: center;

      @media screen and (max-width: 767px) {
        font-size: 12px;
        text-align: right !important;
      }
    }

    p.ActivityDateMobile {
      font-size: 16px;
      color: #4D4D4D;
      font-size: 12px;
      text-align: right !important;
      display: none;

      @media screen and (max-width: 767px) {
        display: block;
      }
    }

    p.ActivityDescription {
      font-size: 14px;
      color: #4d4d4d;
      margin: 0;

      @media screen and (max-width: 767px) {
        font-size: 12px;
      }
    }

    div.ActivityAmount {
      font-weight: 500;
      font-size: 17px;

      @media screen and (max-width: 767px) {
        font-size: 12px;
      }
    }

    span {
      border-radius: 50%;
      display: block;
      padding: 10px;
      border: 1px solid #44adf8;
      width: 40px;
      height: 40px;
      background-color: rgb(244, 250, 255);

      img {
        width: 20px;

        @media screen and (max-width: 767px) {
          width: 20px;
        }
      }

      @media screen and (max-width: 767px) {
        padding: 10px;
      }
    }

    span.reward {
      background: #fbd7e8;
      border-color: #e8308a;
    }

    span.cashin {
      background: #6FCF97;
      border-color: #6FCF97;
      padding-right: 7.72px;

      img {
        width: 34.29px;
      }
      @media screen and (max-width: 767px) {
        padding-right: 7.15px;

        img {
          width: 22.86px;
        }
      }
    }

    span.cashout {
      background: #F67979;
      padding-right: 7.72px;
      border-color: #F67979;

      img {
        width: 34.29px;
      }
      @media screen and (max-width: 767px) {
        padding-right: 7.15px;

        img {
          width: 22.86px;
        }
      }
    }

    :first-child {
      padding: 15px 10px 15px 30px;
      width: 45px;

      @media screen and (max-width: 767px) {
        padding: 10px 5px;
        display: table-cell; 
        vertical-align: middle;
      }
    }

    :nth-child(2) {
      padding: 15px 10px 15px 0px;

      @media screen and (max-width: 767px) {
        padding: 15px 10px;
      }
    }

    :last-child {
      text-align: right;
      padding-right: 30px;

      @media screen and (max-width: 767px) {
        width: 100px;
        padding: 15px 15px 15px 5px;
      }
    }

    div {
      font-size: 24px;
    }

    p {
      color: #727272;
      margin: 5px 0 0;
    }
  }

  td.DateRow {
    @media screen and (max-width: 767px) {
      display: none;
    }
  }
`;

export const Header = styled.div`
  padding: 20px 30px;
  margin-bottom: 20px;

  img {
    width: 16px;
    height: 16px;
  }
  input {
    width: 100%;
    border: none;
    background: none;
  }
  > * {
    flex-basis: 1;
    flex-grow: 0;
  }
`;

export const Wrapper = styled.div`
  justify-content: space-between;
  align-items: center;

  .filters {
    @media (max-width: 768px) {
      flex-direction: column;
      align-items: stretch;
    }
  }

  .dateFilter {
    @media screen and (max-width: 768px) {
      display: none;
    }
  }
`;

export const SearchBar = styled.div`
  position: relative;
  min-width: 125px !important;
  color: #373636;
  display: flex;
  align-items: center;
  padding: 0 0 0 15px !important;

  @media screen and (max-width: 880px) {
    &.col {
      width: 100%;
      padding: 10px 0 0 0 !important;
    }
  }

  @media screen and (max-width: 992px) {
    margin: 0 !important;
  }

  @media screen and (max-width: 1147px) {
    margin-top: 10px;
  }

  button {
    position: absolute;
    right: 15px;
    background: transparent;
    border: none;
    outline-color: transparent;
  }
  img {
    width: 16px;
    height: 16px;
  }
  input {
    width: 100%;
    padding: 10px 15px;
    background-color: #ffffff;
    border: 1px solid #e8e8e8;
    border-radius: 3px;

    &:focus {
      box-shadow: 0 0 2px 1px #50aff4;
    }
  }
  > * {
    flex-basis: 1;
    flex-grow: 0;
  }

  .sizeInputSearch {
    max-height: 36px;
  }
`;
