import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';

import { useField } from '@rocketseat/unform';
import * as FontAwesome from 'react-icons/md';

export default function Checkbox({
  label,
  left,
  type,
  customMessage,
  infoSize,
  value,
  info,
  name,
  icon,
  size,
  iconStyle,
  children,
  checked: InitialState,
  onChange,
  required,
  labelClassName,
  ...rest
}) {
  const ref = useRef(null);
  const { fieldName, registerField, defaultValue, error } = useField(name);
  // const [ checked, setChecked ] = useState(false);

  const InfoIcon = FontAwesome[info];

  useEffect(() => {
    if (!ref.current) return;
    // setChecked(InitialState || value);
    registerField({
      name: fieldName,
      ref: ref.current,
      path: 'value',
    });
  }, [ref.current, fieldName]); //eslint-disable-line

  function onChanceHandler(e) {
    onChange(e);
    // setChecked(!checked)
  }

  return (
    <label className={labelClassName} htmlFor={fieldName} icon={icon} left={left}>
      {error && <span>{error}</span>}

      <input
        name={fieldName}
        type="checkbox"
        ref={ref}
        id={fieldName}
        aria-label={fieldName}
        defaultValue={defaultValue}
        required={required}
        value={InitialState}
        checked={InitialState}
        onChange={onChanceHandler}
        {...rest}
      />
      <span className="checkmark" />
      <span className="label">
        {children}
        <div>
          {info && <InfoIcon size={infoSize} />}
          {customMessage && <div className="message">{customMessage}</div>}
        </div>
      </span>
    </label>
  );
}

Checkbox.propTypes = {
  onChange: PropTypes.func,
  checked: PropTypes.bool,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  icon: PropTypes.string,
  info: PropTypes.string,
  type: PropTypes.string,
  left: PropTypes.bool,
  required: PropTypes.bool,
  labelClassName: PropTypes.string,
  customMessage: PropTypes.string,
  size: PropTypes.number,
  infoSize: PropTypes.number,
  iconStyle: PropTypes.objectOf(PropTypes.any),
  children: PropTypes.element,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
};

Checkbox.defaultProps = {
  icon: '',
  label: '',
  labelClassName: 'container checkbox',
  size: null,
  iconStyle: null,
  children: null,
  required: false,
  onChange: e => {
    console.log(e);
  },
};
