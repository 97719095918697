import styled from "styled-components";

export const ChangeAntecipation = styled.button`
  cursor: pointer;
  font-size: 13px;
  font-weight: 400;
  border: 0;
  outline: 0;
  color: #44adf8;
  text-decoration: underline;
  margin-left: 10px;
  background-color: transparent;
  &:hover {
    color: #70b2e0;
    outline: 0;
  }
  &:focus {
    color: #44adf8;
    outline: 0;
  }
`;

export const Section = styled.div`
  margin-bottom: 60px;






  .Skeleton-line {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
  }

  .lineSkeleton .skeleton-content {
    width: 60px;
    margin: 0 8px;

    > div {
      margin: 0;
    }
  }
`;


export const SectionTitle = styled.h3`
  color: #282828;
  font-size: 18px;
  margin-bottom: 30px;
  padding-left: 15px;
`;

export const Table = styled.div`
  font-size: 14px;
  margin-bottom: 20px;
  margin-top: ${props => ( props.marginTop ? props.marginTop : "0px" )};
`;

export const THeader = styled.div`
  border-bottom: 1px solid #ececec;
  font-weight: 500;
  font-size: 14px;
  color: #383838;
  margin-bottom: 5px;
`;

export const TBody = styled.div`
  color: #383838;
`;

export const Row = styled.div`
  display: flex;
  padding: 10px 15px;
  border-radius: 3px;
  background-color: ${props => ( props.active ? "#DCEDF9" : "transparent" )};
`;

export const Col = styled.div`
  width: ${props => ( props.width ? props.width : "50%" )};
  justify-content: ${props => ( props.align ? props.align : "flex-start" )};
  display: flex;
  align-items: center;
`;

export const AditionalInfos = styled.div`
  font-size: ${props => ( props.small ? "12px" : "15px" )};
  padding-left: 15px;
  margin-bottom: 15px;

  > span {
    color: #3299e4;
    text-decoration: underline;
    cursor: pointer;
  }
`;

export const Label = styled.span`
  background-color: #50AFF4;
  border-radius: 30px;
  font-size: 10px;
  text-transform: uppercase;
  font-weight: 500;
  color: #ffffff;
  padding: 3px 10px;
  text-align: center;
  margin-left: 10px;
`;

export const AditionalInfo = styled.span`
  display: block;
  font-size: 12px;
  margin-top: 5px;
`;
