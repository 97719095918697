/* eslint-disable react/no-array-index-key */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Container, ContainerInner } from './styles';

const Tabs = ({ tabs, initialTab, containerStyle, componentStyle }) => {
  const [activeTab, setActiveTab] = useState(initialTab);
  return (
    
    <Container style={containerStyle} className="tabsContainer">
      <div className="tabs">
        {tabs.map(({ label }, index) => (
          <a
            className={activeTab === index ? 'active' : null}
            onClick={() => setActiveTab(index)}
            key={index}
          >
            {label}
          </a>
        ))}
      </div>

      <ContainerInner style={componentStyle}>{tabs[activeTab].component}</ContainerInner>
    </Container>
  );
};

Tabs.propTypes = {
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
      component: PropTypes.element.isRequired,
    }),
  ).isRequired,
  initialTab: PropTypes.number,
};

Tabs.defaultProps = {
  initialTab: 0,
};

export default Tabs;
