import styled from 'styled-components';

export const FooterContent = styled.div`
  display: flex;
  justify-content: flex-end;

  button:not(:first-child) {
    margin-left: 15px;
  }
`;

export const ModalContent = styled.div`
  padding: 0 30px;
`;

export const LabelPageCopy = styled.label`
  font-size: 16px;
  line-height: 19px;
  margin-top: 40px;
  display: inline-block;
`;

export const InputPageNameCopy = styled.input`
  padding: 13px;
  width: 100%;
  font-size: 14px;
  border-radius: 3px;
  border: 1px solid #ebebeb;

  margin-top: 15px;
  outline: none;
  margin-bottom: 60px;

  &:focus {
    box-shadow: 0 0 2px 1px #50aff4;
  }


`;
