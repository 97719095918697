import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
// import BasilClass from 'basil.js';
import '@alboom/boom-header';

export default function BoomHeader() {
  useEffect(() => {
    return () => window.__bh && !window.__bh.hidden && window.__bh.hide();
  }, []);

  const {
    core_user: { beta_tests },
  } = useSelector(state => state.accounts);

  const getHeader = () => {
    if (!window.__bh || window.__bh.hidden) {
      window.BoomHeader.params = {
        application: process.env.REACT_APP_APP_CODE,
        coreToken: window.USER_TOKEN,
        theme: 'light',
        contactsBeta: beta_tests.includes('contacts'),
        upgradable: false,
        referral: true,
        logoAlboom: true
      };

      window.BoomHeader.show(instance => {
        instance.on('change-lang', lang => console.log(lang));
        instance.on('logout-user', _ => handlerLogout());
      });
    }
  };

  const handlerLogout = () => {
    window.location.href = `${process.env.REACT_APP_ACCOUNTS_URL}`;
  };

  return <div>{getHeader()}</div>;
}
