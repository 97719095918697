import React, { useEffect } from 'react';
import axios from '../../services/axios';
import Wizard from '../../components/Wizard';
import cx from 'classnames';
import { WrapperLogo, Logo, Title } from './styles';
import { Field } from 'react-final-form';
import { ReactComponent as PhoneActionCheck } from '../../assets/static/images/icons/phone-action-check-1.svg';
import Icon from '@alboompro/boom-components/general/icon';
import { useSelector } from 'react-redux';
import { useState } from 'react';

export default function Wizard5({
  setDisableButton,
  sendingMessage,
  isSMSCorrect,
  setIsSMSCorrect,
}) {
  const [cursorEnable, setCursor] = useState (true)
  const { alprotoken } = useSelector(state => state.auth);

  function sendSMSCode() {
    const phoneNumberFormatted = `+55${window.getFormValues['phone_number']
      .replace('(', '')
      .replace(')', '')
      .replace(' ', '')
      .replace('-', '')}`;

    axios
      .post(
        '/sms-verification/create',
        { number: phoneNumberFormatted },
        { headers: { Authorization: alprotoken } },
      )
      .then(
        ({
          data: {
            data: { success },
          },
        }) => {
          console.log(success);
        },
      )
      .catch(error => console.log(error));
  }

  useEffect(() => {
    setDisableButton(true);
    setIsSMSCorrect(null);
    sendSMSCode();
  }, []);

  function maskCode(value) {
    setCursor(true);

    if (!value) return value;
    const onlyNums = value.replace(/[^\d]/g, '');

    if (onlyNums.length <= 1) {
      setDisableButton(true);
      return `${onlyNums.slice(0, 1)}`;
    }
    if (onlyNums.length <= 2) {
      setDisableButton(true);
      return `${onlyNums.slice(0, 2)}`;
    }
    if (onlyNums.length <= 3) {
      setDisableButton(true);
      return `${onlyNums.slice(0, 3)}`;
    }
    if (onlyNums.length === 4) {
      setDisableButton(false);
      setCursor(false);
      return `${onlyNums.slice(0, 4)}`;
    }
  }
  console.log(cursorEnable)
  return (
    <Wizard.Page>
      <WrapperLogo>
        <Logo
          src="https://bifrost.alboompro.com/products/alboompay/AlboomPay2022-full.svg"
          alt="Logo Alboom Pay"
        />
      </WrapperLogo>
      <div className="row justify-content-center title-pages">
        <div className="col-lg-12">
          <Title>Seu código de confirmação</Title>
        </div>
      </div>

      <div style={{ marginTop: '48px', textAlign: 'center' }}>
        <PhoneActionCheck className="phone-action-check" />
        <p
          style={{
            fontSize: '15px',
            lineHeight: '18px',
            display: 'block',
            textAlign: 'center',
            marginTop: '50px',
            marginBottom: '40px',
          }}
        >
          Seu código foi enviado para {window.getFormValues['area_code']}{' '}
          {window.getFormValues['phone_number']}.
        </p>
      </div>

      <div className="row justify-content-between col-lg-7 phone-area">
        <div className="sms-confirmation">
          <label className="input-text">
            <Field
              name="sms_code"
              component="input"
              parse={maskCode}
              type="text"
              maxLength="4"
              minLength="4"
              required
              className={cx("code-confirmation", { "sms-code-full" : !cursorEnable})}
            />
            <span className="label sms-code-label">Código de confirmação</span>
            {sendingMessage && <div class="loader" />}
            {isSMSCorrect === null ? null : isSMSCorrect === true ? (
              <div className="icon-validate">
                <Icon
                  kind="bold"
                  group="interface-essential"
                  category="form-validation"
                  file="check-circle-1-alternate.svg"
                  size="23"
                  color="#44adf8"
                />
                <span>Confirmado</span>
              </div>
            ) : (
              <div className="icon-validate incorrect">
                <Icon
                  kind="bold"
                  group="interface-essential"
                  category="delete"
                  file="delete-2-alternate.svg"
                  size="23"
                  color="#DC624B"
                />
                <span>Código incorreto</span>
              </div>
            )}
          </label>
          <a onClick={() => sendSMSCode()}>Enviar novamente</a>
        </div>
      </div>
    </Wizard.Page>
  );
}
