import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  border: 2px dashed #1b9dfa;
  border-radius: 9px;
  padding: 26px;

  &:hover {
    cursor: pointer;
  }

  &.smaller {
    flex-direction: row;
    justify-content: center;
    padding: 15px;

    img {
      margin-right: 17.5px;
    }
  }
`;

export const Title = styled.div`
  font-size: 24px;
  color: #1b9dfa;
  margin: 13px 0px 13px;

  &.smaller {
    margin: 0;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: left;
  }
`;

export const Subtitle = styled.div`
  font-size: 15px;
  color: #282828;

  &.smaller {
    display: none;
  }
`;
