import produce from "immer";

const INITIAL_STATE = {
  data: [],
  visible: false,
  type: null
};

export default function orders(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case "@modal/OPEN": {
        draft.visible = true;
        draft.type = action.payload.type;
        draft.data = action.payload.data;
        break;
      }
      case "@modal/CLOSE":
        return { ...draft, ...INITIAL_STATE }
      default:
        break;
    }
  });
}
